import { KeyboardArrowDown } from '@mui/icons-material'
import { Autocomplete, AutocompleteProps, TextFieldProps } from '@mui/material'
import DeepcellTextField from './DeepcellTextField'

export interface DeepcellAutocompleteProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> extends Partial<AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>> {
  label: string
  inputProps?: TextFieldProps
}

export function DeepcellAutocomplete<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>({
  label,
  inputProps,
  ...props
}: DeepcellAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>): JSX.Element {
  return (
    <Autocomplete
      {...props}
      options={props.options ?? []}
      renderInput={(params) => <DeepcellTextField {...params} {...inputProps} label={label} />}
      popupIcon={<KeyboardArrowDown />}
    />
  )
}

export default DeepcellAutocomplete
