import {
    Box,
    Card,
    CardActions,
    CardContent,
    CardMedia,
    Stack,
    styled,
    Typography,
} from '@mui/material'
import { DeepcellPrimaryButton } from 'components/shared'
import React, { useState } from 'react'
import PopupIFrame from './PopupIFrame'

interface DataSetCardProps {
    title: string
    body: React.ReactNode
    onExplore(): void
    imgSrc: string
    primary?: boolean
    videoUrl?: string
}
const Ribbon = styled('div')(({ theme }) => ({
    backgroundColor: `${theme.palette.success.main}`,
    position: 'absolute',
    color: `${theme.palette.primary.main}`,
    width: 150,
    zIndex: 3,
    textAlign: 'center',
    textTransform: 'uppercase',
    padding: 5,
    top: 210,
    marginLeft: 0,
    span: {},
}))
export const DataSetCard = ({
    body,
    imgSrc,
    onExplore,
    title,
    primary,
    videoUrl = '',
}: DataSetCardProps): JSX.Element => {
    const [showPopupIframe, setShowPopupIframe] = useState<boolean>(false)
    return (
        <>
            <Card
                sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    overflowY: 'auto',
                }}
            >
                {primary && (
                    <Ribbon>
                        <span>Recommended</span>
                    </Ribbon>
                )}

                <CardMedia
                    component="img"
                    image={imgSrc}
                    style={{
                        filter: 'sepia(30%) saturate(300%) brightness(100%) hue-rotate(170deg)',
                        borderRadius: '50%',
                        height: 170,
                        width: 170,
                        alignSelf: 'center',
                        marginTop: 16,
                    }}
                />
                <CardContent sx={{ px: 5, minHeight: '200px' }}>
                    <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        style={{ alignSelf: 'center' }}
                    >
                        {title}
                    </Typography>
                    <Typography variant="h4" style={{ alignSelf: 'center' }}>
                        {body}
                    </Typography>
                </CardContent>
                <CardActions disableSpacing sx={{ mt: 'auto', px: 5, justifyContent: 'center' }}>
                    <Stack direction="column" alignItems="center" spacing={1}>
                        <Box sx={{ minHeight: '40px' }}>
                            <DeepcellPrimaryButton
                                contained
                                onClick={onExplore}
                                sx={{ mx: 'auto !important', width: 100 }}
                            >
                                Explore
                            </DeepcellPrimaryButton>
                        </Box>
                        <Box sx={{ minHeight: '40px' }}>
                            {videoUrl && (
                                <DeepcellPrimaryButton
                                    onClick={() => setShowPopupIframe(true)}
                                    text
                                    sx={{
                                        textTransform: 'none',
                                        textDecoration: 'underline',
                                        textEmphasisStyle: 'italic',
                                    }}
                                >
                                    Watch a walk-through video
                                </DeepcellPrimaryButton>
                            )}
                        </Box>
                    </Stack>
                </CardActions>
            </Card>
            <PopupIFrame
                open={showPopupIframe}
                handleCancel={() => setShowPopupIframe(false)}
                title={title}
                videoUrl={videoUrl}
            />
        </>
    )
}

export default DataSetCard
