import { Alert, Box, LinearProgress, Snackbar, SnackbarCloseReason, styled } from '@mui/material'
import Parser from 'html-react-parser'
import React from 'react'
import useNotificationSlice from 'redux/slices/hooks/useNotificationSlice'
import { createPortal } from 'react-dom'

const NotificationAlert = styled(Alert)({
  width: '100%',
  '& a': { color: 'white', fontWeight: 'bold' },
})

const ProgressClass = styled(LinearProgress)({
  position: 'relative',
  top: '-10px',
  margin: '0 20px',
})

const StyledSnackbar = styled(Snackbar)({
  '&.MuiSnackbar-anchorOriginBottomCenter': {
    bottom: '100px',
  },
})

export const DeepcellNotification = (): JSX.Element => {
  const { notification, clearNotification } = useNotificationSlice()

  const handleClose = (_event?: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) => {
    if (!notification.closeOnClickaway && reason === 'clickaway') {
      return
    }
    clearNotification()
  }

  const attachToDOMNode = () => {
    if (document?.fullscreenElement) return document.fullscreenElement
    return document.body
  }

  return createPortal(
    <StyledSnackbar
      open={notification.open}
      autoHideDuration={notification.timeout}
      onClose={handleClose}
      anchorOrigin={{
        vertical: notification.origin?.vertical ?? 'top',
        horizontal: notification.origin?.horizontal ?? 'center',
      }}
    >
      <Box>
        <NotificationAlert
          variant="filled"
          onClose={handleClose}
          severity={notification.type}
          icon={notification.showIcon ? undefined : false}
          sx={{ width: '100%' }}
        >
          {Parser(notification.message ?? '')}
        </NotificationAlert>
        {notification.showProgress && <ProgressClass />}
      </Box>
    </StyledSnackbar>,
    attachToDOMNode()
  )
}

export default DeepcellNotification
