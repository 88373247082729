import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CELL_GROUPS_CATEGORY_KEY } from 'components/cell-visualizations/Compare/useDataCategory'
import {
  CELL_IMAGE_NORMAL_SIZE,
  CELL_IMAGE_NORMAL_SPACING,
} from 'components/cell-visualizations/shared'
import { CellInfo } from 'components/cell-visualizations/tsv/types'
import _ from 'lodash'
import { getSimpleReducerMethods } from 'utils/reduxHelpers'
import { DatasetItem, datasetItems } from '../types/DatasetItem'
import {
  CellDataField,
  CellImagesFilter,
  CellInfoDimension,
  CellInfoGroups,
  MergedCellGroup,
  PaginationParams,
  PinnedCellGroup,
  ZoomThreshold,
} from './types'

export const imageCountDefault = 10
export const defaultPagination = { page: 0, cellsPerPage: imageCountDefault }

export interface CellsByGroup {
  groupId: number
  groupName: string
  cellIds: string[]
}

export interface CellVisualizationsState {
  /**
   * Indicates the version of this state. Used for matching stateVersion to logic in the backend
   * @important Whenever CellVisualizationsState is changed, please increment stateVersion
   */
  // Keep this in sync with the saveVersionConfig function in useSessionApi.ts
  // That function omits specific fields from this state that should not be persisted when saving
  stateVersion: 1
  fileName?: string
  dataRevision: number
  name?: string
  isDirty: boolean
  zoomThresholds?: ZoomThreshold[]
  exportCellMorphotype: CellsByGroup[]
  pinnedCells?: PinnedCellGroup[]
  mergedPinnedCells?: MergedCellGroup[]
  morphotypesToRemove?: PinnedCellGroup[]

  /**
   * Unfiltered, unedited arrow file data. Don't use this data if your object needs data that could be
   * hidden, removed, or filtered. Use the cellInfo from usePlotData instead
   */
  cellsData?: CellInfo[]

  cellInfoGroups: CellInfoGroups
  selectedCellInfoGroupName: keyof CellInfoGroups

  cellImagesFilter: CellImagesFilter

  groupCompare: {
    selectedFeatures: CellDataField[]
    selectedDataCategory: string // Datafield.label value
    selectedDataFields: { [key: string]: string[] } // key is a DataField.label value,
  }
  selectedComparisonDimensions?: CellInfoDimension[]
  showCellGroups?: boolean
  showCompare?: boolean
  showColorBy?: boolean

  selectedDataset: DatasetItem

  classifierName?: string
  projectCode?: string

  plotImgSrc?: string
  plotDisplayType: 'scatter' | 'density'
}

export const cellVisualizationsInitialState: CellVisualizationsState = {
  stateVersion: 1,
  isDirty: false,
  pinnedCells: [],
  morphotypesToRemove: [],
  cellInfoGroups: {},
  exportCellMorphotype: [],
  selectedCellInfoGroupName: '',
  cellImagesFilter: {
    displayImages: false,
    imageSize: CELL_IMAGE_NORMAL_SIZE,
    spacingAdjust: CELL_IMAGE_NORMAL_SPACING,
  },
  groupCompare: {
    selectedFeatures: [],
    selectedDataCategory: CELL_GROUPS_CATEGORY_KEY,
    selectedDataFields: {},
  },
  dataRevision: 1,
  selectedDataset: datasetItems[0],
  mergedPinnedCells: undefined,
  cellsData: undefined,
  fileName: undefined,
  zoomThresholds: undefined,
  showCellGroups: undefined,
  showCompare: undefined,
  showColorBy: undefined,
  name: undefined,
  projectCode: undefined,
  plotDisplayType: 'scatter',
  selectedComparisonDimensions: undefined,
}

export const cellVisualizationsSlice = createSlice({
  name: 'cellVisualizations',
  initialState: cellVisualizationsInitialState,
  reducers: {
    ...getSimpleReducerMethods(cellVisualizationsInitialState),
    setMorphotypeToRemoveHighlight: (
      state,
      action: PayloadAction<{ id: number; shouldHighlight: boolean }>
    ) => {
      const { id, shouldHighlight } = action.payload
      const morphotype = state.morphotypesToRemove?.find((m) => m.id === id)
      if (morphotype) {
        morphotype.isHighlighted = shouldHighlight
      }
    },
    setGroupCompare(
      state,
      action: PayloadAction<{
        groupCompare: CellVisualizationsState['groupCompare']
        eventsManager: {
          sendCompareByEvent(
            dataCategory: string,
            selectedComparisionOptions: CellDataField[],
            dataFieldsToCompare: { [key: string]: string[] }
          ): void
        }
      }>
    ) {
      const { groupCompare, eventsManager } = action.payload
      state.groupCompare = groupCompare
      state.isDirty = true
      eventsManager.sendCompareByEvent(
        groupCompare.selectedDataCategory,
        groupCompare.selectedFeatures,
        groupCompare.selectedDataFields
      )
    },
    /**
     * Only add cell ids to the group that don't already exist, if already exists remove
     * @param state
     * @param action action.payload - Contains an object groupId, cellId and groupName
     */
    appendCellToExport: (
      state,
      action: PayloadAction<{ groupId: number; cellId: string; groupName: string }>
    ) => {
      const { groupId, cellId, groupName } = action.payload

      const groupIndex = state.exportCellMorphotype.findIndex((cellGroup) => {
        return cellGroup.groupId === groupId
      })

      // Group already exists
      if (groupIndex !== -1) {
        const currentGroup = state.exportCellMorphotype[groupIndex]
        // Check if the Cell Id exists in the group
        const cellIndex = currentGroup.cellIds.findIndex((cell) => {
          return cell === cellId
        })

        // Cell Id already exist, remove from list (uncheck)
        if (cellIndex !== -1) {
          if (currentGroup.cellIds.length <= 10) {
            currentGroup.cellIds.splice(cellIndex, 1)
          }
          // If no cells exists, remove the group
          if (currentGroup.cellIds.length === 0) {
            state.exportCellMorphotype.splice(groupIndex, 1)
          }
        } else if (currentGroup.cellIds.length < 10) {
          // Append the Cell Id to the group
          currentGroup.cellIds.push(cellId)
        }
      } else {
        // Create the group and append the Cell Id
        state.exportCellMorphotype.push({ groupId, groupName, cellIds: [cellId] })
      }
    },
    setGroupCompareSelectedGroups(state, action: PayloadAction<number[]>) {
      state.pinnedCells = state.pinnedCells?.map((x) => ({
        ...x,
        isInSelectedGroup: action.payload.includes(x.id),
      }))
      state.isDirty = true
    },
    incrementDataRevision(state) {
      state.dataRevision += 1
    },
    setCellImagesFilter: (
      state,
      action: PayloadAction<{
        cellImagesFilter: CellImagesFilter
        eventsManager: { sendDisplayImagesByEvent(_: CellImagesFilter): void }
      }>
    ) => {
      const { cellImagesFilter, eventsManager } = action.payload
      eventsManager.sendDisplayImagesByEvent(cellImagesFilter)
      state.cellImagesFilter = cellImagesFilter
    },
    /**
     * Replaces the current state with the given payload.
     * @param state Current state
     * @param action action.payload contains the new state to apply
     *    Any fields that are missing will be set to their default initial value
     */
    setStore(state, action: PayloadAction<Partial<CellVisualizationsState>>) {
      // Using "state = action.payload" throws an error so we resort to this
      const newStore = action.payload
      _.keys(state).forEach((k) => {
        const key = k as keyof typeof state
        const val = newStore[key] ?? cellVisualizationsInitialState[key]

        ;(state[key] as typeof val) = val
      })
      state.isDirty = false
    },
    setPinnedGroupValue: (
      state,
      action: PayloadAction<{
        pinnedGroupId: number
        key: keyof PinnedCellGroup
        value: boolean | string | PaginationParams
      }>
    ) => {
      const { pinnedGroupId, key, value } = action.payload
      state.pinnedCells = state.pinnedCells?.map((pinnedCell) =>
        pinnedCell.id === pinnedGroupId
          ? {
              ...pinnedCell,
              [key]: value,
            }
          : pinnedCell
      )
    },
    setCellsData(state, action: PayloadAction<CellInfo[] | undefined>) {
      state.isDirty = false
      state.cellsData = action.payload
    },
    setPinnedCells: (
      state,
      action: PayloadAction<{
        pinnedCells: PinnedCellGroup[]
        eventsManager: { sendPinnedCellsEvent(_: PinnedCellGroup): void }
      }>
    ) => {
      const { pinnedCells, eventsManager } = action.payload
      const recentlyAddedPin = pinnedCells.at(0)
      if (recentlyAddedPin) eventsManager.sendPinnedCellsEvent(recentlyAddedPin)
      state.pinnedCells = pinnedCells
      state.isDirty = true
    },
    setMergedPinnedCells: (
      state,
      action: PayloadAction<{
        pinnedCells: MergedCellGroup[]
        eventsManager: {
          sendMergedPinnedCellsEvent(_: Pick<PinnedCellGroup, 'id' | 'cells'>[]): void
        }
      }>
    ) => {
      const { pinnedCells, eventsManager } = action.payload
      eventsManager.sendMergedPinnedCellsEvent(pinnedCells)
      state.mergedPinnedCells = pinnedCells
      state.isDirty = true
    },
    addPinnedCellGroup: (state, action: PayloadAction<PinnedCellGroup>) => {
      state.pinnedCells = state.pinnedCells
        ? [...state.pinnedCells, action.payload]
        : [action.payload]
      state.isDirty = true
    },
    clearExportMorphotype: (state) => {
      state.exportCellMorphotype = []
    },
    deletePinnedGroup: (
      state,
      action: PayloadAction<{
        targetPinnedCellId: number
        eventsManager: { sendDeletePinEvent(_: string): void }
      }>
    ) => {
      const { targetPinnedCellId, eventsManager } = action.payload
      const deletedPin = state.pinnedCells?.filter((x) => x.id === targetPinnedCellId)
      if (deletedPin && deletedPin.length > 0) eventsManager.sendDeletePinEvent(deletedPin[0].name)
      else eventsManager.sendDeletePinEvent(`pinnedCellId ${targetPinnedCellId}`)
      state.pinnedCells = state.pinnedCells?.filter((x) => x.id !== targetPinnedCellId)
      state.isDirty = true
    },
  },
})

export const CellVisualizationsActions = cellVisualizationsSlice.actions
export const CellVisualizationsReducer = cellVisualizationsSlice.reducer
