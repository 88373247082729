import { styled } from '@mui/material'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import DeepcellLabel from './DeepcellLabel'

const StyledTextField = styled(TextField)(({ theme }) => ({
  background: theme.palette.background.default,
  borderRadius: theme.spacing(1),
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: `1px solid ${theme.palette.primary.dark}`,
    },
    '&:hover fieldset': {
      borderColor: theme.palette.primary.dark,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.dark,
    },
  },
}))

export const DeepcellTextField = ({
  value = '',
  label,
  id,
  ...rest
}: TextFieldProps): JSX.Element => {
  return (
    <div style={{ width: '100%' }}>
      {label ? <DeepcellLabel id={id || ''} label={label as string} /> : null}
      <StyledTextField
        // aria-label helps make getByLabelText work in tests, so we can try and test using what the user sees
        inputProps={{ 'data-testid': 'deepcell-text-field', 'aria-label': label as string }}
        hiddenLabel
        fullWidth
        value={value ?? ''}
        size="small"
        {...rest}
      />
    </div>
  )
}

export default DeepcellTextField
