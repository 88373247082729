import React, { ReactElement } from 'react'
import { useHistory } from 'react-router-dom'
import { AppState, Auth0Provider } from '@auth0/auth0-react'
import { auth0Config } from '../config'

// From https://auth0.com/blog/complete-guide-to-react-user-authentication/
// This handles redirecting users back to the original page elegantly

interface Props {
    children: React.ReactNode
}

function Auth0ProviderWithHistory(props: Props): ReactElement {
    const { children } = props
    const { domain, clientId } = auth0Config

    const history = useHistory()

    const onRedirectCallback = (appState?: AppState | undefined) => {
        history.push(appState?.returnTo || window.location.pathname)
    }

    const getOrgId = () => {
        return localStorage.getItem('orgId') || undefined
    }

    return (
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            audience="https://api.deepcell.com"
            redirectUri={window.location.origin}
            onRedirectCallback={onRedirectCallback}
            organization={getOrgId()}
        >
            {children}
        </Auth0Provider>
    )
}

export default Auth0ProviderWithHistory
