import * as ROUTES from 'constants/routes'
import { Transitions } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'
import Grid from '@mui/material/Grid'
import { styled } from '@mui/system'
import { ReactQueryDevtools } from 'react-query/devtools'
import { Redirect, Route, useRouteMatch } from 'react-router-dom'
import useTracking from 'utils/useTracking'
import HomePage from './Home'
import LoginPage from './LoginPage'
import CellBrowsingPage from './cell-browsing/CellBrowsingPage'
import { CellVisualizationDemoLandingPage } from './cell-visualizations/demo'
import useCellVisualizationUrlParams from './cell-visualizations/useCellVisualizationUrlParams'
import DemoRegister from './demo-register'
import RunsPage from './runs/RunPage'
import DeepcellFooter from './shared/DeepcellFooter'
import DeepcellHeader from './shared/DeepcellHeader'
import PrivateRoute from './shared/PrivateRoute'
import useFlags, { useLoadFlagsFromRemoteEffect } from './shared/useFlags'
import { CellVisSelection } from './cell-visualizations/sessionSelect'
import CellVisualizationsPage from './cell-visualizations'

const Root = styled('div')({
  display: 'flex',
  height: '100%',
  overflow: 'hidden',
})

const NavigationHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  justifyContent: 'flex-end',
  minHeight: 48,
}))

const GridContent = styled(Grid)(({ theme }) => {
  const transitions = theme.transitions as Transitions
  return {
    flexGrow: 1,
    transition: transitions.create('margin', {
      easing: transitions.easing.sharp,
      duration: transitions.duration.leavingScreen,
    }),
  }
})

const CellVizOrRedirect = () => {
  const { demoEnabled } = useFlags()
  const { sessionId } = useCellVisualizationUrlParams()

  if (!sessionId) {
    if (demoEnabled === 'yes') return <Redirect to={{ pathname: ROUTES.DEMO_DATASETS }} />
    return <CellVisSelection />
  }
  return <CellVisualizationsPage />
}

export default function NavigationMenu(): JSX.Element {
  // Enable analytics tracking here at the top level component below the <Router>
  useTracking()
  useLoadFlagsFromRemoteEffect()

  const isRegister = useRouteMatch(ROUTES.DEMO_REGISTER)
  const isDemoDatasets = useRouteMatch(ROUTES.DEMO_DATASETS)

  return (
    <Root>
      <CssBaseline />
      <>
        {(!isRegister || !isDemoDatasets) && <DeepcellHeader />}
        <GridContent>
          {!isRegister && !isDemoDatasets && <NavigationHeader />}
          <Route exact path={ROUTES.LOGIN} component={LoginPage} />
          <Route exact path={ROUTES.HOME} component={HomePage} />
          <Route exact path={ROUTES.DEMO_REGISTER} component={DemoRegister} />
          <PrivateRoute exact path={ROUTES.CELL_BROWSING} component={CellBrowsingPage} />
          <PrivateRoute exact path={ROUTES.RUNS} component={RunsPage} />
          <PrivateRoute
            exact
            path={ROUTES.ALL_CELL_VISUALIZATION_ROUTES}
            component={CellVizOrRedirect}
          />
          <PrivateRoute
            exact
            path={ROUTES.DEMO_DATASETS}
            component={CellVisualizationDemoLandingPage}
          />
          <DeepcellFooter />
        </GridContent>
        <ReactQueryDevtools />
      </>
    </Root>
  )
}
