import { Alert, Box, Snackbar, styled } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import useFlags from './useFlags'
import { formatTimestampToHumanReadable } from './date-utils'

const NotificationAlert = styled(Alert)({
  width: '100vw',
  '& a': { color: 'white', fontWeight: 'bold' },
  borderRadius: '0px',
  backgroundColor: '#dbe1fa',
  padding: '0px 16px',
})

const StyledSnackbar = styled(Snackbar)({
  '&.MuiSnackbar-anchorOriginBottomCenter': {
    bottom: '0px',
  },
  '&.MuiSnackbar-root': {
    top: '40px',
    left: '0px',
    display: 'block',
  },
})

export const DeepcellDowntimeAlert = (): JSX.Element => {
  const [open, setOpen] = useState(true)
  const { downtime } = useFlags()
  let downtimeInLocalTimeZone = downtime

  const attachToDOMNode = () => {
    if (document?.fullscreenElement) return document.fullscreenElement
    return document.body
  }

  if (downtime && downtime.length > 0) {
    const [startDowntimeStr, endDowntimeStr] = downtime.split(' - ')
    try {
      downtimeInLocalTimeZone = `${formatTimestampToHumanReadable(
        startDowntimeStr.trim(),
        'MM-DD-YYYY HH:mm'
      )} - ${formatTimestampToHumanReadable(endDowntimeStr.trim(), 'MM-DD-YYYY HH:mm')}`
    } catch (error) {
      // error converting the timestamp specified in the firebase
    }
  }

  useEffect(() => {
    // Set the Snackbar open state based on the presence of the downtime flag
    setOpen(Boolean(downtime))
  }, [downtime])

  const handleClose = () => {
    setOpen(false)
  }

  if (downtimeInLocalTimeZone && downtimeInLocalTimeZone.length > 0) {
    return createPortal(
      <StyledSnackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={open}
      >
        <Box>
          <NotificationAlert variant="filled" severity="warning" onClose={handleClose}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ marginRight: 1 }}>
                Please be advised that there will be scheduled downtime across Axon:
              </Box>
              <Box sx={{ fontWeight: 600 }}>{downtimeInLocalTimeZone}</Box>
            </Box>
          </NotificationAlert>
        </Box>
      </StyledSnackbar>,
      attachToDOMNode()
    )
  }
  return <></>
}

export default DeepcellDowntimeAlert
