import { useState, useEffect, RefObject, useCallback } from 'react'
import { useTheme, styled, keyframes } from '@mui/material'
import { ArrowDownward } from '@mui/icons-material'

interface MoreContentArrowProps {
    divRef: RefObject<HTMLDivElement>
}

const arrowAnimation = keyframes`
  0% {
    transform: translate(0, -10px);
  }
  100% {
    transform: translate(0, 10px);
  }
`

const ContentBelowDiv = styled('div')({
    position: 'fixed',
    bottom: '5%',
    right: '5%',
    transform: 'translate(0, 50%)',
    zIndex: 9999,
})

const ArrowDiv = styled('div')(({ theme }) => ({
    width: '24px',
    height: '24px',
    borderRadius: '50%',
    backgroundColor: `${theme.palette.success.main}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'background-color 0.3s ease',
    animation: `${arrowAnimation} 2s ease-in-out infinite`,
}))

const MoreContentArrow: React.FC<MoreContentArrowProps> = ({
    divRef,
}: MoreContentArrowProps): JSX.Element => {
    const [showArrow, setShowArrow] = useState<boolean>(false)
    const theme = useTheme()

    const handleScroll = useCallback(() => {
        const currentDiv = divRef && divRef.current
        if (!currentDiv) {
            return
        }

        const { scrollHeight, scrollTop, clientHeight } = currentDiv
        setShowArrow(scrollTop + clientHeight <= scrollHeight - 100)
    }, [divRef])

    useEffect(() => {
        const currentDiv = divRef.current
        if (!currentDiv) {
            return undefined
        }
        handleScroll()
        currentDiv.addEventListener('scroll', handleScroll)
        window.addEventListener('resize', handleScroll)

        return () => {
            currentDiv.removeEventListener('scroll', handleScroll)
            window.removeEventListener('resize', handleScroll)
        }
    }, [divRef, handleScroll])

    return (
        <>
            {showArrow && (
                <ContentBelowDiv data-testid="contentBelowArrowElement">
                    <ArrowDiv>
                        <ArrowDownward
                            width="16"
                            height="16"
                            style={{
                                color: `${theme.palette.info.main}`,
                                transition: 'transform 0.3s ease',
                            }}
                        />
                    </ArrowDiv>
                </ContentBelowDiv>
            )}
        </>
    )
}

export default MoreContentArrow
