import { Typography } from '@mui/material'
import { DeepcellDialog } from 'components/shared'

export const EMBEDDING_MAX = 40

interface EmbeddingDensityWarningProps {
  open: boolean
  onConfirm(): void
  onCancel(): void
  count: number
}
export const EmbeddingDensityWarning = ({
  onCancel,
  onConfirm,
  open,
  count,
}: EmbeddingDensityWarningProps): JSX.Element => {
  return (
    <DeepcellDialog
      handleCancel={onCancel}
      handleConfirm={onConfirm}
      open={!!open}
      titleLabel="Comparison Plots Warning"
      okLabel="Create Anyway"
    >
      <Typography sx={{ mb: 2 }}>
        This will create {count} plots which is beyond the recommended maximum of {EMBEDDING_MAX}{' '}
        plots.
      </Typography>
      <Typography align="center">Do you still want to create comparison plots anyway?</Typography>
    </DeepcellDialog>
  )
}

export default EmbeddingDensityWarning
