import { Box, Stack } from '@mui/material'

export const IndexedContent = (
    props: React.PropsWithChildren<{ index?: string; subIndex?: string }>
): JSX.Element => {
    const { index, subIndex, children } = props

    const Index = () => {
        if (index) {
            return (
                <Box sx={{ position: 'absolute', left: '20px' }}>
                    {subIndex ? (
                        <>
                            <h3 style={{ marginBottom: 0 }}>{index}</h3>
                            <div>{subIndex}</div>
                        </>
                    ) : (
                        <h3>{index}</h3>
                    )}
                </Box>
            )
        }

        return null
    }

    return (
        <Stack direction="row">
            <Index />
            {children}
        </Stack>
    )
}

export default IndexedContent
