import ImagesearchRollerRoundedIcon from '@mui/icons-material/ImagesearchRollerRounded'
import { Box, Stack } from '@mui/material'
import { DeepcellDrawer } from 'components/shared/drawer'
import _ from 'lodash'
import { useState } from 'react'
import { useCellVisualizationsSlice } from 'redux/slices/hooks/useCellVisualizationsSlice'
import { DENSITY_ATTRIBUTE } from 'utils/constants'
import { ColorByDropdown } from './ColorByDropdown'
import FiltersApplied from './FiltersApplied'
import { FiltersAppliedDetails } from './FiltersAppliedDetails'
import { ToggleVisibilityButton } from './ToggleVisibilityButton'
import Legend from './Legend'

export const colorByButtonId = 'colorByButtonId'

const RollerIcon = () => <ImagesearchRollerRoundedIcon sx={{ ml: 1 }} />

export const ColorByDrawer = ({ showPreFilter = false }: { showPreFilter?: boolean }): JSX.Element => {
  const [showFiltersAppliedDetails, setShowFiltersAppliedDetails] = useState(false)

  const {
    setShowColorBy,
    cellVisualizations: { showColorBy, plotDisplayType },
    hiddenCellInfoGroupValues,
    selectedCellInfoGroup: { isContinuous, category },
  } = useCellVisualizationsSlice()
  const isDensity = plotDisplayType === DENSITY_ATTRIBUTE
  const showToggle = !isContinuous && category
  const showFilter = showToggle && _.keys(hiddenCellInfoGroupValues).length > 0
  let headerText;
  if (showPreFilter === true) {
    headerText = 'Color By';
  } else if (isDensity) {
    headerText = 'Filter By';
  } else {
    headerText = 'Filter / Color By';
  }

  return (
    <DeepcellDrawer
      buttonText={headerText}
      floatingButtonProps={{
        id: colorByButtonId,
        startIcon: <RollerIcon />,
      }}
      drawerButtonProps={{
        startIcon: <RollerIcon />,
      }}
      onResolve={(isOpen) => setShowColorBy(isOpen)}
      open={showColorBy}
    >
      {showFiltersAppliedDetails ? (
        <FiltersAppliedDetails onBackClick={() => setShowFiltersAppliedDetails(false)} />
      ) : (
        <Stack spacing={2}>
          <ColorByDropdown showPreFilter={showPreFilter} />
          {showFilter ? (
            <FiltersApplied onDetailsClick={() => setShowFiltersAppliedDetails(true)} />
          ) : null}
          <Box sx={{ height: '4px', alignSelf: 'end' }}>
            {showToggle && !showPreFilter ? <ToggleVisibilityButton /> : null}
          </Box>
          {category || (isDensity && showPreFilter) ? <Legend showPreFilter={showPreFilter} /> : null}
        </Stack>
      )}
    </DeepcellDrawer>
  )
}

export default ColorByDrawer
