import * as ROUTES from 'constants/routes'
import MenuIcon from '@mui/icons-material/Menu'
import { Menu, MenuItem, styled } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import useAuthTokens from 'utils/useAuthTokens'

const StyledNavTopMenu = styled(Menu)({
  '& .MuiMenu-paper': {
    top: 48,
    width: 200,
  },

  '& a': {
    textDecoration: 'none',
  },
})

export const TopNavMenu = (): JSX.Element => {
  const { permissions } = useAuthTokens()

  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null)
  const handleClose = () => setMenuAnchor(null)

  return (
    <>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        sx={{ mr: 2 }}
        onClick={(e) => setMenuAnchor(e.currentTarget)}
      >
        <MenuIcon />
      </IconButton>
      <StyledNavTopMenu
        keepMounted
        open={Boolean(menuAnchor)}
        anchorEl={menuAnchor}
        onClose={handleClose}
      >
        {permissions.has('runs:read') && (
          <RouterLink to={ROUTES.RUNS}>
            <MenuItem onClick={handleClose}>Runs</MenuItem>
          </RouterLink>
        )}
        {permissions.has('cells:read') && (
          <RouterLink to={ROUTES.CELL_BROWSING}>
            <MenuItem onClick={handleClose}>Cell Browsing</MenuItem>
          </RouterLink>
        )}
        {permissions.has('cells:graph_visualization:read') && (
          <RouterLink to={ROUTES.CELL_VISUALIZATIONS}>
            <MenuItem onClick={handleClose}>Cell Visualization</MenuItem>
          </RouterLink>
        )}
      </StyledNavTopMenu>
    </>
  )
}

export default TopNavMenu
