import { createSvgIcon } from '@mui/material'

const CheckedIcon = createSvgIcon(
  <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_387_625)">
      <rect x="6.75977" y="6.46484" width="21.1632" height="21.1632" rx="3" fill="#A4EFC0" />
    </g>
    <path
      d="M21.5084 11.3371C21.7174 11.1183 21.9955 10.9974 22.284 10.9998C22.5725 11.0022 22.8489 11.1278 23.0549 11.35C23.2608 11.5722 23.3802 11.8737 23.3878 12.1909C23.3954 12.5081 23.2907 12.8161 23.0957 13.05L17.1766 21.1903C17.0748 21.3109 16.9519 21.4076 16.8154 21.4748C16.6788 21.5419 16.5314 21.5781 16.3818 21.5812C16.2322 21.5842 16.0836 21.5541 15.9449 21.4925C15.8062 21.431 15.6802 21.3393 15.5744 21.223L11.649 16.9065C11.5397 16.7945 11.4521 16.6594 11.3912 16.5093C11.3304 16.3592 11.2977 16.1972 11.2951 16.0329C11.2925 15.8687 11.3199 15.7055 11.3759 15.5531C11.4319 15.4008 11.5151 15.2624 11.6208 15.1462C11.7265 15.03 11.8523 14.9384 11.9908 14.8769C12.1294 14.8154 12.2778 14.7851 12.4272 14.788C12.5766 14.7909 12.7239 14.8269 12.8604 14.8938C12.9969 14.9606 13.1197 15.0571 13.2216 15.1773L16.328 18.5916L21.4802 11.373C21.4895 11.3604 21.4979 11.3485 21.5084 11.3371Z"
      fill="#5F55D1"
    />
    <defs>
      <filter
        id="filter0_d_387_625"
        x="0.759766"
        y="0.464844"
        width="33.1631"
        height="33.1633"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="3" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_387_625" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_387_625" result="shape" />
      </filter>
    </defs>
  </svg>,
  'Checked'
)

export default CheckedIcon
