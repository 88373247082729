import { SAMPLE_DISTRIBUTION } from 'utils/constants'
import { Box } from '@mui/material'
import ScrollTop from 'components/shared/ScrollTop'
import CellBrowsingCellId from './CellBrowsingCellId'
import CellBrowsingSampleDistribution from './CellBrowsingSampleDistribution'
import CellBrowsingSortBy from './CellBrowsingSortBy'
import useCellBrowsingQueryParams from '../useCellBrowsingQueryParams'
import { CellBrowsingAction } from '../types'

interface CellBrowsingGridViewProps {
  handleQueryUpdate: (action: CellBrowsingAction) => void
}

const CellBrowsingGridView = ({ handleQueryUpdate }: CellBrowsingGridViewProps): JSX.Element => {
  const { search } = useCellBrowsingQueryParams()

  return (
    <Box>
      <ScrollTop />
      {search.sortOrderKey !== SAMPLE_DISTRIBUTION && !search.cellId ? (
        <CellBrowsingSortBy searchOptions={search} handleQueryUpdate={handleQueryUpdate} />
      ) : null}
      {search.cellId ? (
        <CellBrowsingCellId
          searchOptions={search}
          handleQueryUpdate={handleQueryUpdate}
          cellId={search.cellId}
        />
      ) : null}
      {search.sortOrderKey === SAMPLE_DISTRIBUTION && search.runIds.length !== 0 ? (
        <CellBrowsingSampleDistribution handleQueryUpdate={handleQueryUpdate} />
      ) : null}
    </Box>
  )
}

export default CellBrowsingGridView
