import { Box, Card, DialogContentText } from '@mui/material'
import DeepcellDialog from 'components/shared/DeepcellDialog'
import React, { useState } from 'react'
import { PinnedCellGroup } from 'redux/slices'
import { useCellVisualizationsSlice } from 'redux/slices/hooks/useCellVisualizationsSlice'
import useEventsManager from 'redux/slices/hooks/useEventsManager'
import useFlagCondition from 'components/shared/useFlagCondition'
import { EditPinnedNamePopover } from './EditPinnedNamePopover'
import { PinnedGroupContent } from './PinnedGroupContent'
import { TrainClassifierButton } from './TrainClassifierButton'

interface PinnedGroupsProps {
  onMergeClick?: (pcg: PinnedCellGroup) => void
}

export const PinnedGroups = ({ onMergeClick }: PinnedGroupsProps): JSX.Element => {
  const demoEnabled = useFlagCondition('demoEnabled')
  // Show Train Classifier only to Deepcell Internal users for now until this workflow is useful to
  // external users
  const showTrainClassifier = !demoEnabled
  const {
    cellVisualizations: { mergedPinnedCells: originalMergedPinnedCells },
    visibleMergedPinnedCells: mergedPinnedCells,
    visiblePinnedCells: pinnedCells,
    setMergedPinnedCells,
    deletePinnedGroup,
  } = useCellVisualizationsSlice()
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [targetPinnedCell, setTargetPinnedCell] = useState<PinnedCellGroup>()
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const eventsManager = useEventsManager()

  const handleChangeIconClick =
    (pinnedCellGroup: PinnedCellGroup) => (e: React.MouseEvent<HTMLElement>) => {
      setTargetPinnedCell(pinnedCellGroup)
      setAnchorEl(e.currentTarget)
    }

  const handleDeleteIconClick = (pinnedCellGroup: PinnedCellGroup) => () => {
    setTargetPinnedCell(pinnedCellGroup)
    setOpenDeleteDialog(true)
  }

  const closeDeleteDialog = () => {
    setOpenDeleteDialog(false)
    setTargetPinnedCell(undefined)
  }

  const handleDeleteConfirm = () => {
    if (targetPinnedCell) {
      deletePinnedGroup({ targetPinnedCellId: targetPinnedCell.id, eventsManager })
      if (originalMergedPinnedCells)
        setMergedPinnedCells({
          pinnedCells: originalMergedPinnedCells.filter((x) => x.id !== targetPinnedCell.id),
          eventsManager,
        })
    }
    closeDeleteDialog()
  }

  // if seperate pinnedGroups have the same id, they have been merged and should be shown here as one group
  // TODO: This code hurts my eyes. Make it butter? Maybe use Immer?
  const mergedPinnedCellGroups = pinnedCells?.reduce((acc, pinnedCellGroup) => {
    const existingPinnedCellGroupIndex = acc.findIndex((x) => x.id === pinnedCellGroup.id)
    if (existingPinnedCellGroupIndex > -1) {
      return acc.map((accPinnedCellGroup, i) => {
        if (i === existingPinnedCellGroupIndex) {
          const points = mergedPinnedCells?.find((x) => x.id === accPinnedCellGroup.id)?.cells
            .points
          return {
            ...accPinnedCellGroup,
            cells: {
              ...accPinnedCellGroup.cells,
              points,
            },
          }
        }
        return accPinnedCellGroup
      })
    }
    return [...acc, pinnedCellGroup]
  }, [] as PinnedCellGroup[])

  const enableMergeButton = mergedPinnedCellGroups && mergedPinnedCellGroups?.length > 1

  return (
    <>
      <Card elevation={0}>
        {showTrainClassifier && (
          <Box display="flex" justifyContent="center">
            <TrainClassifierButton />
          </Box>
        )}
        {mergedPinnedCellGroups?.map((pinnedCellGroup) => (
          <PinnedGroupContent
            key={pinnedCellGroup.id}
            pinnedCellGroup={pinnedCellGroup}
            onChange={handleChangeIconClick(pinnedCellGroup)}
            onDelete={handleDeleteIconClick(pinnedCellGroup)}
            onMerge={() => onMergeClick && onMergeClick(pinnedCellGroup)}
            enableMerge={enableMergeButton}
          />
        ))}
      </Card>
      <EditPinnedNamePopover
        anchorEl={anchorEl}
        pinnedCell={targetPinnedCell ?? { id: -1, name: '', cells: {} }}
        onClose={() => setAnchorEl(null)}
      />
      <DeepcellDialog
        open={openDeleteDialog}
        handleConfirm={handleDeleteConfirm}
        handleCancel={closeDeleteDialog}
        okLabel="Yes"
        cancelLabel="No"
        titleLabel="Confirm Delete"
      >
        <DialogContentText>{`Are you sure you want to delete ${targetPinnedCell?.name}?`}</DialogContentText>
      </DeepcellDialog>
    </>
  )
}

export default PinnedGroups
