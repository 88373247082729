import { PayloadAction } from '@reduxjs/toolkit'
import { WritableDraft } from 'immer/dist/internal'
import { keys } from 'lodash'
import { Expand } from './Expand'

export type SimpleReducerMethods<T> = {
  [K in keyof Required<T> as K extends string ? `set${Capitalize<K>}` : never]: (
    state: WritableDraft<T>,
    action: PayloadAction<T[K]>
  ) => void
}

/**
 * Given an initial state, return an object of methods to be passed to the reducer parameter in the slice.
 * The object of methods will contain simple 'set' methods for each property found in the state
 * @note Only properties found in the state will have set methods created for them. It's up to the developer to ensure
 * that every property that should have an associated set method appears in the state object
 */
export const getSimpleReducerMethods = <T>(initialState: T): Expand<SimpleReducerMethods<T>> =>
  keys(initialState).reduce((acc, k) => {
    const key = k as keyof typeof initialState
    const capitalKey = String(key)[0].toUpperCase() + String(key).slice(1)
    const reducerName = `set${capitalKey}`
    return {
      ...acc,
      [reducerName]: (state: WritableDraft<T>, action: PayloadAction<T[typeof key]>) => {
        type ValType = typeof action.payload
        ;(state[key] as ValType) = action.payload
      },
    }
  }, {} as Expand<SimpleReducerMethods<T>>)
