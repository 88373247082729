import { createSvgIcon } from '@mui/material'

const GridViewIcon = createSvgIcon(
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="35" height="35" rx="3.5" fill="white" stroke="#A4EFC0" />
    <path
      d="M11.125 13C11.125 11.9645 11.9645 11.125 13 11.125H14.875C15.9105 11.125 16.75 11.9645 16.75 13V14.875C16.75 15.9105 15.9105 16.75 14.875 16.75H13C11.9645 16.75 11.125 15.9105 11.125 14.875V13Z"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.125 21.125C11.125 20.0895 11.9645 19.25 13 19.25H14.875C15.9105 19.25 16.75 20.0895 16.75 21.125V23C16.75 24.0355 15.9105 24.875 14.875 24.875H13C11.9645 24.875 11.125 24.0355 11.125 23V21.125Z"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.25 13C19.25 11.9645 20.0895 11.125 21.125 11.125H23C24.0355 11.125 24.875 11.9645 24.875 13V14.875C24.875 15.9105 24.0355 16.75 23 16.75H21.125C20.0895 16.75 19.25 15.9105 19.25 14.875V13Z"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.25 21.125C19.25 20.0895 20.0895 19.25 21.125 19.25H23C24.0355 19.25 24.875 20.0895 24.875 21.125V23C24.875 24.0355 24.0355 24.875 23 24.875H21.125C20.0895 24.875 19.25 24.0355 19.25 23V21.125Z"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  'GridView'
)

export default GridViewIcon
