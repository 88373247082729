import { Stack, styled } from '@mui/material'
import CloseIcon from 'components/shared/icons/CloseIcon'
import { FunctionComponent, ReactNode } from 'react'

interface SectionCardProps {
  children: ReactNode
  title?: string
  closeAction?: () => void
  actionableBar?: JSX.Element
  paddingCSS?: string
}

const StyledCard = styled('div')({
  width: '100%',
  borderRadius: '4px',
  border: 'none',
  background: '#FFF',
  boxShadow:
    '0px 1px 12px 0px rgba(0, 0, 0, 0.12), 0px 4px 8px 0px rgba(0, 0, 0, 0.02), 0px 1px 4px 0px rgba(0, 0, 0, 0.01)',
  display: 'flex',
  flexDirection: 'column',
})

const TitleBar = styled('div')({
  height: '45px',
  borderBottom: '1px solid #DEDEDE',
  color: '#172623',
  fontSize: '14px',
  fontWeight: 'bold',
  lineHeight: '20px',
  padding: '0 14px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})

const ContentContainer = styled('div')<Pick<SectionCardProps, 'paddingCSS'>>(({ paddingCSS }) => ({
  padding: paddingCSS ?? '10px 14px',
}))

const SectionCard: FunctionComponent<SectionCardProps> = ({
  children,
  title,
  closeAction,
  actionableBar,
  paddingCSS,
}: SectionCardProps): JSX.Element => {
  return (
    <StyledCard>
      {title && (
        <TitleBar>
          {title}
          <Stack direction="row" gap="24px" alignItems="center">
            {actionableBar || null}
            {closeAction && <CloseIcon sx={{ cursor: 'pointer' }} onClick={closeAction} />}
          </Stack>
        </TitleBar>
      )}
      <ContentContainer paddingCSS={paddingCSS}>{children}</ContentContainer>
    </StyledCard>
  )
}

export default SectionCard
