import { DEFAULT_REMOTE_CONFIG, RemoteConfigSettings } from 'config'
import firebase from 'firebase/app'
import 'firebase/remote-config'
import { useEffect } from 'react'
import { useInterval } from 'react-use'
import useRemoteFlagsSlice from 'redux/slices/hooks/useRemoteFlagsSlice'

const getFlags = async () => {
  const remoteConfig = firebase.remoteConfig()
  let flags = DEFAULT_REMOTE_CONFIG

  remoteConfig.defaultConfig = DEFAULT_REMOTE_CONFIG
  try {
    await remoteConfig.fetchAndActivate()
    const remoteFlags = remoteConfig.getAll()
    // flags = _.mapValues(remoteConfig.getAll(), String) as RemoteConfigSettings
    flags = Object.keys(remoteFlags).reduce((acc, key) => {
      return { ...acc, [key]: remoteFlags[key].asString() }
    }, {} as RemoteConfigSettings)
  } catch (error) {
    console.error(error)
  }

  return flags
}

// Loosely based on https://medium.com/javascript-in-plain-english/create-feature-flags-for-your-react-app-with-firebase-remote-config-92d17c5e518d
// If additional logic around versions in the flags is useful, go look up that article again
//
// Currently, this provides access to config settings with a defined type (RemoteConfigSettings),
// ignoring all other settings that may exist on Firebase which are not supported by this app yet
export const useFlags = (): RemoteConfigSettings => {
  const { remoteFlags } = useRemoteFlagsSlice()

  return remoteFlags
}

export const useLoadFlagsFromRemoteEffect = (): void => {
  const { remoteFlags, set, markAsNotLoaded } = useRemoteFlagsSlice()

  // Retrieve remote config every 10 minutes because a user might have Axon open for days
  useInterval(() => {
    markAsNotLoaded()
  }, 1000 * 60 * 10)

  useEffect(() => {
    if (remoteFlags.isLoadedFromRemote) return

    const getFlagsFromFirebase = async () => {
      const flags = await getFlags()
      set(flags)
    }
    getFlagsFromFirebase()
  }, [remoteFlags.isLoadedFromRemote, set])
}

export default useFlags
