import { Annotations, Shape } from 'plotly.js'
import { useWindowSize } from 'react-use'
import { ScatterPlotMetadata } from 'redux/slices'
import { useCellVisualizationsSlice } from 'redux/slices/hooks/useCellVisualizationsSlice'
import {
  getBanner,
  getFixedAnnotation,
  getFullGridLine,
  getHorizontalBanner,
  getVerticalBanner,
} from './utils'

// FYI: Shapes at the end of the array will show up on top

export const useEmbeddingShapesAndAnnotations = ({
  maxX,
  maxY,
  minX,
  minY,
}: ScatterPlotMetadata): {
  shapes: Partial<Shape>[]
  annotations: Partial<Annotations>[]
} => {
  const { height: windowHeight, width: windowWidth } = useWindowSize()
  const bannerWidth = 50 / windowWidth
  const bannerHeight = 50 / windowHeight

  const {
    cellVisualizations: { cellInfoGroups },
    visibleSelectedComparisonDimensions,
  } = useCellVisualizationsSlice()

  const shapes = [] as Partial<Shape>[]
  const annotations = [] as Partial<Annotations>[]

  if (!visibleSelectedComparisonDimensions || !visibleSelectedComparisonDimensions.length)
    return { shapes, annotations }

  const width = maxX - minX
  const height = maxY - minY
  const middleWidth = (maxX + minX) / 2
  const middleHeight = (maxY + minY) / 2

  visibleSelectedComparisonDimensions[0].values.forEach((value, i) => {
    // Vertical grid lines
    if (i > 0) {
      shapes.push(
        getFullGridLine({ orientation: 'vertical', location: minX - width / 4 + width * 1.5 * i })
      )
    }

    // Titles for each column value
    annotations.push(
      getFixedAnnotation({
        text: value,
        fixed: 'y',
        x: middleWidth + width * 1.5 * i,
        y: 1 - bannerHeight * 1.5,
      })
    )
  })

  // Top banners
  shapes.push(getHorizontalBanner({ top: 1, bottom: 1 - bannerHeight, fillcolor: '#E8E9FF' }))
  shapes.push(
    getHorizontalBanner({
      top: 1 - bannerHeight,
      bottom: 1 - bannerHeight * 2,
      fillcolor: '#F3FFF8',
    })
  )

  if (visibleSelectedComparisonDimensions[1]) {
    visibleSelectedComparisonDimensions[1].values.forEach((value, i) => {
      // Horizontal Grid Lines
      if (i > 0) {
        shapes.push(
          getFullGridLine({
            orientation: 'horizontal',
            location: maxY + height / 4 - height * 1.5 * i,
          })
        )
      }

      // Left banners
      shapes.push(getVerticalBanner({ left: 0, right: bannerWidth, fillcolor: '#E8E9FF' }))
      shapes.push(
        getBanner({
          top: 1 - bannerHeight,
          bottom: 0,
          left: bannerWidth,
          right: bannerWidth * 2,
          fillcolor: '#F3FFF8',
        })
      )

      const leftTitle = cellInfoGroups[visibleSelectedComparisonDimensions[1].field]?.label ?? ''
      // Left Main Header
      annotations.push(
        getFixedAnnotation({
          text: leftTitle,
          fixed: 'x+y',
          x: bannerWidth / 2,
          y: 0.5,
          angle: 270,
        })
      )

      // Titles for each row value
      annotations.push(
        getFixedAnnotation({
          text: value,
          fixed: 'x',
          x: bannerWidth * 1.5,
          y: middleHeight - height * 1.5 * i,
          angle: 270,
        })
      )
    })
  }

  const topTitle = cellInfoGroups[visibleSelectedComparisonDimensions[0].field]?.label ?? ''
  // Top Main Header
  annotations.push(
    getFixedAnnotation({
      text: topTitle,
      fixed: 'x+y',
      x: 0.5,
      y: 1 - bannerHeight / 2,
    })
  )

  return { shapes, annotations }
}

export default useEmbeddingShapesAndAnnotations
