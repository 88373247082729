import { createSvgIcon } from '@mui/material'

const SecondaryDropdownIcon = createSvgIcon(
    <svg width="14" height="auto" viewBox="0 0 14 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M13 5L7.3391 1.36085C6.99924 1.14237 6.56131 1.14986 6.22913 1.37983L1 5"
            stroke="#35363F"
            strokeWidth="1.5"
        />
    </svg>,
    'SecondaryDropdown'
)

export default SecondaryDropdownIcon
