import {
  Card,
  CardContent,
  Divider,
  FilledInput,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  PopoverProps,
  Stack,
  Typography,
} from '@mui/material'
import DeepcellPopover from 'components/shared/DeepcellPopover'
import DeepcellPrimaryButton from 'components/shared/DeepcellPrimaryButton'
import CopyIcon from 'components/shared/icons/CopyIcon'
import ShareIcon from 'components/shared/icons/Share'
import { useState } from 'react'
import { useLocation } from 'react-use'
import copy from 'copy-to-clipboard'

export const ShareButton = (): JSX.Element => {
  const { href } = useLocation()
  const [el, setEl] = useState<PopoverProps['anchorEl']>(null)
  const [copiedText, setCopiedText] = useState('')

  const onCopy = () => {
    setCopiedText('')
    copy(href ?? '')
    setTimeout(() => setCopiedText('Copied!'), 100)
  }
  const onClose = () => {
    setCopiedText('')
    setEl(null)
  }

  return (
    <>
      <DeepcellPrimaryButton
        contained
        startIcon={<ShareIcon sx={{ fill: (t) => t.palette.primary.main }} />}
        onClick={(e) => setEl(e.currentTarget)}
      >
        Share
      </DeepcellPrimaryButton>
      <DeepcellPopover
        headerTitle="Share Session"
        open={!!el}
        anchorEl={el}
        onClose={onClose}   
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Divider />
        <Card elevation={0}>
          <CardContent sx={{ mb: -3 }}>
            <Stack spacing={2}>
              <Typography variant="h5">Share with link</Typography>
              <FormControl variant="filled">
                <FilledInput
                  disableUnderline
                  sx={{
                    borderRadius: 2,
                    width: '450px',
                    pr: 2,
                    alignItems: 'center',
                    color: (t) => t.palette.text.disabled,
                  }}
                  inputProps={{ style: { padding: '20px' } }}
                  value={href}
                  readOnly
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton edge="end">
                        <CopyIcon onClick={onCopy} data-testid="copy-button" />
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <FormHelperText sx={{ fontSize: 12, textAlign: 'right' }}>
                  {copiedText}
                </FormHelperText>
              </FormControl>
              <DeepcellPrimaryButton
                contained
                onClick={onClose}
                sx={{ width: 100, alignSelf: 'center' }}
              >
                Done
              </DeepcellPrimaryButton>
              <Typography alignSelf="center" color={(t) => t.palette.text.secondary}>
                This link will only work for users in your organization
              </Typography>
            </Stack>
          </CardContent>
        </Card>
      </DeepcellPopover>
    </>
  )
}

export default ShareButton
