import _ from 'lodash'
import { useMemo } from 'react'
import { useCellVisualizationsSlice } from 'redux/slices/hooks/useCellVisualizationsSlice'
import { CATEGORICAL_COLOR_PALETTE } from '../shared'
import { CellInfo } from '../tsv/types'
import generateCellInfoCombinations from './generateCellInfoCombinations'

const useCellDataLogic = () => {
  const {
    cellVisualizations: { selectedCellInfoGroupName, cellInfoGroups },
    prefilteredCellIdSet,
    hiddenCellInfoGroupValues,
    visibleSelectedComparisonDimensions,
  } = useCellVisualizationsSlice()

  const hiddenAttributeNames = useMemo(
    () => _.keys(hiddenCellInfoGroupValues ?? {}) as (keyof typeof hiddenCellInfoGroupValues)[],
    [hiddenCellInfoGroupValues]
  )

  const { isContinuous } = cellInfoGroups[selectedCellInfoGroupName] ?? {}

  /**
   * A single cell could be part of multiple hidden attributes. Return the first one.
   */
  const getHiddenAttributeName = (cell: CellInfo) => {
    let hiddenAttributeName = ''
    for (let i = 0; i < hiddenAttributeNames.length; i += 1) {
      const attrName = hiddenAttributeNames[i]
      if (attrName) {
        const existingHiddenVal = hiddenCellInfoGroupValues[attrName]?.find(
          (c) => c === cell[attrName]
        )
        if (existingHiddenVal) {
          hiddenAttributeName = `${attrName}${existingHiddenVal}`
          i = hiddenAttributeNames.length + 1
        }
      }
    }

    return hiddenAttributeName
  }

  const getDataForCell = (cell: CellInfo) => {
    /**
     * A cell can be toggled as removed/not removed in pre-filtering step
     * A cell can be toggled as hidden/shown in the standard cell vis step
     * */
    const isRemoved = prefilteredCellIdSet.has(cell.CELL_ID)
    const isHidden = isRemoved || Boolean(getHiddenAttributeName(cell))

    let name
    let splitOutput
    let color

    if (!isHidden) {
      splitOutput = visibleSelectedComparisonDimensions
        ? generateCellInfoCombinations(cell, visibleSelectedComparisonDimensions)
        : []

      const selectedCellValue = selectedCellInfoGroupName ? cell[selectedCellInfoGroupName] : ''

      name = isContinuous ? '' : `${selectedCellInfoGroupName}${selectedCellValue}`

      const { color: defaultColor } =
        cellInfoGroups[selectedCellInfoGroupName]?.data.find(
          (c) => c.value === selectedCellValue
        ) ?? {}

      color = isContinuous ? selectedCellValue ?? '' : defaultColor ?? CATEGORICAL_COLOR_PALETTE[0]
    }

    return { name, color, isHidden, isRemoved, splitOutput, cell }
  }

  return { getDataForCell, getHiddenAttributeName }
}

export const useCellData = (
  ...params: Parameters<typeof useCellDataLogic>
): ReturnType<typeof useCellDataLogic> => {
  return useCellDataLogic(...params)
}

export default useCellData
