import { CellInfo } from 'components/cell-visualizations/tsv/types'
import useDataFieldsInDataset from 'components/cell-visualizations/useDataFieldsInDataset'

export const CELL_GROUPS_CATEGORY_KEY = 'Cell Groups'

/** Returns a list of data categories you can use for comparison
 * This list includes all of the categorical data fields that are present in the dataset, plus 
 * you can compare Cell Groups 
 */
function useDataCategory(): {
    dataCategoryKeys: string[], 
    getDataCategoryAttribute: (dataCategoryKey: string) => keyof CellInfo
} {
    const { categoricalDataFields } = useDataFieldsInDataset()
    const dataCategoryKeys = [ CELL_GROUPS_CATEGORY_KEY, ...categoricalDataFields.map((field) => field.label)]

    function getDataCategoryAttribute(dataCategoryKey: string): keyof CellInfo {
        if(dataCategoryKey === CELL_GROUPS_CATEGORY_KEY) return 'CELL_ID'
        return categoricalDataFields.find((field) => field.label === dataCategoryKey)?.attribute as keyof CellInfo 
    }

    return {
        dataCategoryKeys,
        getDataCategoryAttribute
    }
}

export default useDataCategory