import { PlotRelayoutEvent, Shape } from 'plotly.js'
import { usePlotlySlice } from 'redux/slices/hooks'
import { useCellVisualizationsSlice } from 'redux/slices/hooks/useCellVisualizationsSlice'
import useEventsManager from 'redux/slices/hooks/useEventsManager'
import { usePlotlyModebar } from '../header/ToolButtons'

export const useRelayout = (): {
  onRelayout: (
    e: PlotRelayoutEvent & {
      selections?: Partial<Shape>[] | undefined
    }
  ) => {
    newRangeDetected: boolean
  }
} => {
  const { triggerModebarButton } = usePlotlyModebar()
  const eventsManager = useEventsManager()
  const {
    cellVisualizations: { fileName, selectedDataset },
  } = useCellVisualizationsSlice()
  const {
    setRange,
    plotly: { range },
  } = usePlotlySlice()

  const onRelayout = (e: PlotRelayoutEvent & { selections?: Partial<Shape>[] }) => {
    const {
      'xaxis.range[0]': relayoutX0,
      'xaxis.range[1]': relayoutX1,
      'yaxis.range[0]': relayoutY0,
      'yaxis.range[1]': relayoutY1,
      'xaxis.autorange': xAutorange,
      'yaxis.autorange': yAutorange,
      dragmode,
      selections,
    } = e

    const userIsClickingSelectionOption = Boolean(dragmode)
    const userIsMakingSelection = Boolean(selections && selections[0])
    const userResetedAxis = Boolean(xAutorange && yAutorange)
    const userIsSelecting = userIsMakingSelection || userIsClickingSelectionOption
    let newRangeDetected = false

    if (userResetedAxis) {
      eventsManager.sendResetAxisEvent(fileName)
    }

    // New layout is user simply making a selection, so don't update range
    if (!userIsSelecting) {
      newRangeDetected = Boolean(relayoutX0)

      if (newRangeDetected) {
        if (range) {
          const { x1, x2 } = range
          let zoomEvent: 'in' | 'out' | undefined
          if (x1 && x2 && relayoutX0 && relayoutX1) {
            if (x1 <= relayoutX0 && relayoutX1 <= x2) {
              zoomEvent = 'in'
            } else if (relayoutX0 <= x1 && x2 <= relayoutX1) {
              zoomEvent = 'out'
            }
          }
          if (zoomEvent) {
            eventsManager.sendZoomEvent(fileName || selectedDataset.label, zoomEvent)
            triggerModebarButton('Pan')
          } else {
            // pan
            eventsManager.sendPanEvent(fileName || selectedDataset.label)
          }
        }
        // New layout includes a new range (the user zoomed, panned, etc...)
        setRange({
          x1: relayoutX0,
          x2: relayoutX1,
          y1: relayoutY0,
          y2: relayoutY1,
        })
      }
    }

    return { newRangeDetected }
  }

  return { onRelayout }
}

export default useRelayout
