import {
  QueryParamConfig,
  encodeNumber,
  decodeNumber,
  encodeDelimitedArray,
  decodeDelimitedArray,
  decodeString,
  encodeString,
} from 'use-query-params'

import { CellId } from '@deepcell/dc_core_proto/deepcell_schema2_pb'
import {
  CellClassEncoderDecoder,
  SampleTypeEncoderDecoder,
  EnumEncoderDecoder,
} from './proto-utils'

// Custom query parameters

/** Custom query string parameter that handles cell classes by name instead of number */
function generateEnumClassArrayParam<T extends EnumEncoderDecoder>(
  encoderDecoder: T
): QueryParamConfig<number[] | null | undefined> {
  return {
    encode(array: number[] | null | undefined): string | null | undefined {
      if (array == null) return undefined
      return encodeDelimitedArray(
        array.map((x) => encoderDecoder.convertToString(x)),
        ','
      )
    },

    decode(arrayStr: string | (string | null)[] | null | undefined): number[] | undefined {
      const decodedArray = decodeDelimitedArray(arrayStr, ',')
      if (decodedArray === null || decodedArray === undefined) {
        return undefined
      }
      return (decodedArray as string[]).map((x: string) => encoderDecoder.convertFromString(x))
    },
  }
}
/** Custom query string parameter that handles cell classes by name instead of number */
function generateEnumClassParam<T extends EnumEncoderDecoder>(
  encoderDecoder: T
): QueryParamConfig<number | null | undefined> {
  return {
    encode(value: number | null | undefined): string | null | undefined {
      if (value == null) return undefined
      return encoderDecoder.convertToString(value)
    },

    decode(key: string | (string | null)[] | null | undefined): number | undefined {
      const decodedValue = decodeString(key)
      if (decodedValue == null) {
        return undefined
      }
      return encoderDecoder.convertFromString(decodedValue)
    },
  }
}

/** Custom query string parameters that encode an array of values to human readable form */
export const CellClassCommaArrayParam = generateEnumClassArrayParam(CellClassEncoderDecoder)
/** Custom query string parameter that encodes a single value to a human readable string */
export const SampleTypeParam = generateEnumClassParam(SampleTypeEncoderDecoder)

/** Custom query parameter that handles Date objects encoded as timestamps in seconds */
export const TimestampParam: QueryParamConfig<Date | null | undefined> = {
  encode(value: Date | null | undefined): string | null | undefined {
    if (value == null) return undefined
    return encodeNumber(value.getTime())
  },

  decode(valueStr: string | (string | null)[] | null | undefined): Date | undefined {
    const value = decodeNumber(valueStr)
    if (value == null) {
      return undefined
    }
    return new Date(value)
  },
}

/** Custom query that handles CellId values */
export const CellIdParam: QueryParamConfig<CellId | null | undefined> = {
  encode(cellId: CellId | null | undefined): string | null | undefined {
    if (cellId == null) return undefined
    return encodeString(
      `${cellId.getTime()}:${cellId.getNumber()}${cellId.hasInstrumentId() ? `:${cellId.getInstrumentId()}` : ''
      }`
    )
  },

  decode(valueStr: string | (string | null)[] | null | undefined): CellId | undefined {
    const cellIdStr = decodeString(valueStr)
    if (cellIdStr == null) {
      return undefined
    }
    const [time, number, instrumentId] = cellIdStr?.split(':')
    const cellId = new CellId()
    cellId.setTime(parseInt(time, 10))
    cellId.setNumber(parseInt(number, 10))
    if (instrumentId !== undefined) {
      cellId.setInstrumentId(parseInt(instrumentId, 10))
    }
    return cellId
  },
}

/** Custom query string parameter that handles arrays of strings */
export const StringArrayParam: QueryParamConfig<string[] | null | undefined> = {
  encode(array: string[] | null | undefined): string | null | undefined {
    if (array == null) return undefined
    return encodeDelimitedArray(array, ',')
  },

  decode(arrayStr: string | (string | null)[] | null | undefined): string[] | undefined {
    const decodedArray = decodeDelimitedArray(arrayStr, ',')
    if (decodedArray === null || decodedArray === undefined) {
      return undefined
    }
    return decodedArray.filter((value) => value !== null) as string[]
  },
}

/**
 * Wrap a query param so that if it is blank (null, undefined, "")
 * it's encoded and decoded as undefined.  This changes the default behaviour from use-query-params
 * https://github.com/pbeshai/use-query-params#param-types
 *
 * @param param QueryParamConfig - query param to wrap
 */
export function withIgnoreBlanks<D, D2 = D>(
  param: QueryParamConfig<D, D2>
): QueryParamConfig<D, NonNullable<D2> | undefined> {
  return {
    encode(value: D): string | (string | null)[] | undefined {
      const result = param.encode(value)
      if (result == null) return undefined
      if (typeof result === 'string' && result === '') return undefined
      return result
    },

    decode(input: string | (string | null)[] | null | undefined): NonNullable<D2> | undefined {
      const result = param.decode(input)
      if (result == null) return undefined
      if (typeof result === 'string' && result === '') return undefined
      // @ts-ignore: Typescript is not figuring out the proper type here
      return result
    },
  }
}
