import BarChartIcon from '@mui/icons-material/BarChart'
import ScatterPlotIcon from '@mui/icons-material/ScatterPlot'
import { Stack } from '@mui/material'
import { useCellVisualizationsSlice } from 'redux/slices/hooks/useCellVisualizationsSlice'
import SelectableButton from './SelectableButton'

export const dataVizCellGroupsButtonId = 'dataVizCellGroupsButtonId'
export const dataVizCompareGroupsButtonId = 'dataVizCompareGroupsButtonId'

export const CellGroupsCompareButtons = (): JSX.Element => {
  const {
    setShowCellGroups,
    setShowCompare,
    cellVisualizations: { showCellGroups, showCompare },
  } = useCellVisualizationsSlice()

  return (
    <Stack direction="row" spacing={2}>
      <SelectableButton
        id={dataVizCellGroupsButtonId}
        selected={showCellGroups}
        onClick={() => setShowCellGroups(!showCellGroups)}
        startIcon={<ScatterPlotIcon />}
      >
        Cell Groups
      </SelectableButton>
      <SelectableButton
        id={dataVizCompareGroupsButtonId}
        selected={showCompare}
        onClick={() => setShowCompare(!showCompare)}
        startIcon={<BarChartIcon />}
      >
        Compare
      </SelectableButton>
    </Stack>
  )
}
export default CellGroupsCompareButtons
