import { pickBy } from 'lodash'
import { Annotations, Shape } from 'plotly.js'
import {
  CellInfoGroups,
  CellPlotData,
  CellPlotDatum,
  PinnedCellGroup,
  ScatterPlotMetadata,
} from 'redux/slices'
import { CellInfo } from '../tsv/types'
import { BasePlotParams } from './useBaseCellPlotData'

type FixedAnnotationParams = {
  fixed?: 'x' | 'y' | 'x+y' | undefined
  text: string
  x: Annotations['x']
  y: Annotations['y']
  angle?: number
}

type BannerParams = {
  /**
   * Location of the top of the banner as a percentage between 0 and 1 (1 being at the top of the plot, 0 being at the bottom)
   */
  top: number
  /**
   * Location of the bottom of the banner as a percentage between 0 and 1 (1 being at the top of the plot, 0 being at the bottom)
   */
  bottom: number
  /**
   * Location of the left of the banner as a percentage between 0 and 1 (1 being at the right of the plot, 0 being at the left)
   */
  left: number
  /**
   * Location of the right of the banner as a percentage between 0 and 1 (1 being at the right of the plot, 0 being at the left)
   */
  right: number
  /**
   * CSS color of the banner
   */
  fillcolor: string
}

export const getCellId = (cell: CellInfo): string =>
  cell.CELL_ID || `${cell.RUN_ID}_${cell.INSTRUMENT_ID}_${cell.CELL_NUMBER}`

export const addToExistingPlot = (
  plot: CellPlotData,
  x: number,
  y: number,
  originalX: number,
  originalY: number,
  cellId: string,
  color: string
): void => {
  plot.x.push(x)
  plot.y.push(y)
  plot.originalX.push(originalX)
  plot.originalY.push(originalY)
  plot.cellIds.push(cellId)
  plot.plotDatum.push({ x, y, cellId })

  if (plot.marker) {
    const colors = plot.marker.color as string[]
    colors.push(color)
  }
}

export const addNewPlotToData = (
  data: CellPlotData[],
  basePlotFunction: (params: BasePlotParams) => CellPlotData,
  basePlotParams: BasePlotParams
): void => {
  data.push(basePlotFunction(basePlotParams))
}

/**
 * Retrieves fixed data about cellInfo that does not change. This should only need to be run once.
 */
export const getMetadata = (
  cellInfo: CellInfo[],
  exludeMorphotypes?: PinnedCellGroup[]
): ScatterPlotMetadata => {
  let minX = Infinity
  let minY = Infinity
  let maxX = -Infinity
  let maxY = -Infinity
  const allX = [] as number[]
  const allY = [] as number[]
  const allCellIds = [] as string[]
  const plotDatum = [] as CellPlotDatum[]

  let coordsToExclude

  if (exludeMorphotypes) {
    coordsToExclude = new Set<string>(
      exludeMorphotypes?.flatMap((m) => m.cells?.points?.flatMap((p) => `${p.x}${p.y}`) ?? [])
    )
  }

  for (let i = 0; i < cellInfo.length; i += 1) {
    const cell = cellInfo[i]
    const { UMAP_0, UMAP_1 } = cell

    const include = coordsToExclude ? !coordsToExclude.has(`${UMAP_0}${UMAP_1}`) : true

    if (include) {
      const cellId = getCellId(cell)
      const x = +UMAP_0
      const y = +UMAP_1

      minX = x < minX ? x : minX
      minY = y < minY ? y : minY
      maxX = x > maxX ? x : maxX
      maxY = y > maxY ? y : maxY

      const currentCellPlatDatum = { x, y, cellId }

      allX.push(x)
      allY.push(y)
      allCellIds.push(cellId)
      plotDatum.push(currentCellPlatDatum)
    }
  }

  return {
    minX,
    minY,
    maxX,
    maxY,
    x: allX,
    y: allY,
    cellIds: allCellIds,
    plotDatum,
  }
}

const defaultLineProps: Partial<Shape> = {
  type: 'line',
  line: {
    color: 'lightgrey',
    width: 2,
  },
}

type GridLine = {
  orientation: 'vertical' | 'horizontal'
  /**
   * Either x or y location, depending on orientation
   */
  location: number
}

/**
 * Returns grid line that spans the whole length of the plot, either vertically or horizontally
 */
export const getFullGridLine = ({ orientation, location }: GridLine): Partial<Shape> => {
  const isVert = orientation === 'vertical'

  return {
    ...defaultLineProps,
    name: `grid-line=${location}`,
    yref: isVert ? 'paper' : undefined,
    xref: isVert ? undefined : 'paper',
    x0: isVert ? location : 0,
    y0: isVert ? 0 : location,
    y1: isVert ? 1 : location,
    x1: isVert ? location : 1,
  } as Partial<Shape>
}

/**
 * Displays banner
 */
export const getBanner = ({
  top,
  bottom,
  left,
  right,
  fillcolor = 'lightblue',
}: BannerParams): Partial<Shape> => {
  return {
    name: 'banner',
    type: 'rect',
    xref: 'paper',
    yref: 'paper',
    x0: left,
    y0: top,
    x1: right,
    y1: bottom,
    fillcolor,
    line: { width: 0 },
    layer: 'above',
  } as Partial<Shape>
}

/**
 * Displays full width banner
 */
export const getHorizontalBanner = (
  params: Omit<BannerParams, 'left' | 'right'>
): Partial<Shape> => {
  return {
    ...getBanner({ ...params, left: 0, right: 1 }),
  }
}

/**
 * Displays full height banner
 */
export const getVerticalBanner = (params: Omit<BannerParams, 'top' | 'bottom'>): Partial<Shape> => {
  return {
    ...getBanner({ ...params, top: 1, bottom: 0 }),
  }
}

const asMultilineAnnotation = (text: string, charLimitPerLine = 30, delimeter = ' '): string => {
  let formattedText = ''

  const words = text.split(delimeter)
  let line = ''
  if (words.length > 1) {
    words.forEach((word) => {
      line += `${word} `
      if (line.length > charLimitPerLine) {
        // annotations are vertically aligned to the center, so offset any suffixed newlines with prefixed newlines
        // that little space at the beginning is needed because plotly :)
        formattedText = ` <br>${formattedText}${line}<br>`
        line = ''
      }
    })
    if (line) formattedText += line

    const trailingNewline = /<br>$/
    if (formattedText.match(trailingNewline)) {
      // if there's a trailing newline, remove it and the first newline
      // this will keep every annotation lined up
      formattedText = formattedText.replace(trailingNewline, '')
      formattedText = formattedText.replace(/<br>/, '')
    }
  } else {
    // No delimeters found
    formattedText = text
  }

  return formattedText
}

/**
 * Returns an annotation in a fixed spot
 */
export const getFixedAnnotation = ({
  fixed,
  text,
  x,
  y,
  angle,
}: FixedAnnotationParams): Partial<Annotations> => {
  const charLimitPerLine = 30
  let formattedText = text
  if (text.length > charLimitPerLine) {
    formattedText = asMultilineAnnotation(text, charLimitPerLine)
  }

  return {
    yref: fixed === 'y' || fixed === 'x+y' ? 'paper' : undefined,
    xref: fixed === 'x' || fixed === 'x+y' ? 'paper' : undefined,
    x,
    y,
    text: formattedText,
    xanchor: 'center',
    yanchor: 'middle',
    showarrow: false,
    font: { size: 15 },
    textangle: `${angle}`,
    align: 'center',
  } as Partial<Annotations>
}

/**
 * Return all non-continuous cell info groups
 */
export const nonContinuous = (cig: CellInfoGroups): CellInfoGroups =>
  pickBy(cig, (e) => !e.isContinuous)
