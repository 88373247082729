import html2canvas from 'html2canvas'

/**
 * Returns a function that retrieves a base64 string of the screenshot of the plot in its current state
 */
const useScatterPlotScreenshot = (imageWidth = 280): (() => Promise<string>) => {
    const getPlotImgSrc = async () => {
        const element = document.getElementById('scatter-plot')
        let src = ''

        if (element) {
            const canvas = await html2canvas(element)
            const adjustedHeight = (imageWidth * canvas.height) / canvas.width

            // Inspired by this code: https://github.com/vre2h/use-react-screenshot/blob/master/src/index.js
            const croppedCanvas = document.createElement('canvas')
            const croppedCanvasContext = croppedCanvas.getContext('2d')

            croppedCanvas.width = imageWidth
            croppedCanvas.height = adjustedHeight

            croppedCanvasContext?.drawImage(canvas, 0, 0, imageWidth, adjustedHeight)
            src = croppedCanvas.toDataURL()
        }

        return src
    }
    return getPlotImgSrc
}

export default useScatterPlotScreenshot
