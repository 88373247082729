import { ToggleButton, ToggleButtonProps } from '@mui/material'

export const DeepcellToggleButton = ({
    children,
    ...props
}: ToggleButtonProps): JSX.Element => {
    return (
        <ToggleButton {...props}>
            {children}
        </ToggleButton>
    )
}

export default DeepcellToggleButton
