import { createSvgIcon } from '@mui/material'

const DistributionToggleIcon = createSvgIcon(
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="31" height="31" rx="3.5" fill="white" stroke="#A4EFC0" />
    <path
      d="M10.1312 18.5191V22.5125H9.15219C9.07144 22.5125 8.99664 22.5434 8.94316 22.603C8.89077 22.6614 8.86719 22.7377 8.86719 22.8158C8.86719 22.8939 8.89077 22.9701 8.94316 23.0285C8.99664 23.0881 9.07144 23.119 9.15219 23.119H10.4002H13.0082H14.7682H17.3762H19.1362H21.7442H22.8322C22.9129 23.119 22.9877 23.0881 23.0412 23.0285C23.0936 22.9701 23.1172 22.8939 23.1172 22.8158C23.1172 22.7377 23.0936 22.6614 23.0412 22.603C22.9877 22.5434 22.9129 22.5125 22.8322 22.5125H22.0292V15.5774C22.0292 15.1292 21.6976 14.7392 21.2642 14.7392H19.6322C19.1988 14.7392 18.8672 15.1292 18.8672 15.5774V22.5125H17.6772V11.5481C17.6772 11.0999 17.3456 10.71 16.9122 10.71H15.2642C14.8308 10.71 14.4992 11.0999 14.4992 11.5481V22.5125H13.3092V18.5191H13.3093L13.3091 18.514C13.291 18.0705 12.9619 17.6809 12.5282 17.6809H10.8962C10.4628 17.6809 10.1312 18.0709 10.1312 18.5191ZM10.7012 22.5125V18.5191C10.7012 18.3789 10.8016 18.2875 10.8962 18.2875H12.5442C12.6388 18.2875 12.7392 18.3789 12.7392 18.5191V22.5125H10.7012ZM19.4372 15.5774C19.4372 15.4372 19.5376 15.3458 19.6322 15.3458H21.2802C21.3748 15.3458 21.4752 15.4372 21.4752 15.5774V22.5125H19.4372V15.5774ZM15.0692 11.5481C15.0692 11.408 15.1696 11.3165 15.2642 11.3165H16.9122C17.0068 11.3165 17.1072 11.408 17.1072 11.5481V22.5125H15.0692V11.5481Z"
      fill="#5F55D1"
      stroke="#5F55D1"
      strokeWidth="0.25"
    />
    <path
      d="M22.2227 31.5555L31.556 22.2222V28.5555C31.556 30.2124 30.2128 31.5555 28.556 31.5555H22.2227Z"
      fill="#A4EFC0"
    />
  </svg>,
  'Distribution'
)

export default DistributionToggleIcon
