import CellVisualizationDirtyPrompt from './CellVisualizationDirtyPrompt'
import { CellVisualizationScene } from './CellVisualizationScene'
import useLoadCellInfoEffect from './arrow/useLoadCellInfoEffect'
import { PlotDataContextProvider } from './plot/usePlotData'

export const CellVisualizationsPage = (): JSX.Element => {
  useLoadCellInfoEffect()
  return (
    <PlotDataContextProvider>
      <CellVisualizationScene />
      <CellVisualizationDirtyPrompt />
    </PlotDataContextProvider>
  )
}

export default CellVisualizationsPage
