import { useMemo } from 'react'
import { QueryClient, useQueryClient } from 'react-query'
import {
  BooleanParam,
  DecodedValueMap,
  NumberParam,
  QueryParamConfigMap,
  SetQuery,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params'
import {
  CellClassCommaArrayParam,
  SampleTypeParam,
  StringArrayParam,
  TimestampParam,
  withIgnoreBlanks,
} from '../../utils/custom-query-params'
import { CellDisplayOptions } from '../shared/CellImageControl'
import { QueryState } from './cellSearchHelpers'

export const useCellBrowsingQueryParams = (): {
  query: DecodedValueMap<QueryParamConfigMap>
  setQuery: SetQuery<QueryParamConfigMap>
  search: QueryState
  displayOptions: CellDisplayOptions
  queryClient: QueryClient
  searchingAcrossCorpus: boolean
} => {
  /* This component stores state in the query, except for caching cell counts */
  const [query, setQuery] = useQueryParams({
    predictedClasses: withIgnoreBlanks(CellClassCommaArrayParam),
    predictedProbabilityGreaterThan: withIgnoreBlanks(StringParam),
    sampleType: withIgnoreBlanks(SampleTypeParam),
    sampleId: withIgnoreBlanks(StringParam),
    mixedSampleId: withIgnoreBlanks(StringParam),
    cellId: withIgnoreBlanks(StringParam),
    cellNumber: withIgnoreBlanks(StringParam),
    cellTime: withIgnoreBlanks(StringParam),
    before: withIgnoreBlanks(TimestampParam),
    after: withIgnoreBlanks(TimestampParam),
    morphologyClassifiers: withDefault(StringArrayParam, []),
    // @TODO: Remove old RunId parameter when no longer needed.  It used to search on only one RunId at a time.
    runId: withIgnoreBlanks(StringParam),
    runIds: withDefault(withIgnoreBlanks(StringArrayParam), []),
    sortOrderKey: withDefault(withIgnoreBlanks(StringParam), 'TIME_ASC'),
    page: withDefault(withIgnoreBlanks(NumberParam), 0),
    quadrant: withDefault(withIgnoreBlanks(NumberParam), 1),
    sharpen: withDefault(withIgnoreBlanks(BooleanParam), false),
    centerCrop: withDefault(withIgnoreBlanks(BooleanParam), false),
  })
  const search: QueryState = query as QueryState
  const displayOptions: CellDisplayOptions = useMemo(() => {
    return {
      sharpen: search.sharpen,
      centerCrop: search.centerCrop,
    }
  }, [search])

  const queryClient = useQueryClient()

  const filterNotProvided =
    !query.runId &&
    !(query.runIds === undefined || query.runIds === null || query.runIds.length === 0)

  return {
    query,
    setQuery,
    search,
    displayOptions,
    queryClient,
    searchingAcrossCorpus: !filterNotProvided,
  }
}

export default useCellBrowsingQueryParams
