import useCellVisualizationUrlParams from 'components/cell-visualizations/useCellVisualizationUrlParams'
import { useEffect, useRef } from 'react'
import { CellVisualizationsState } from 'redux/slices'
import { useCellVisualizationsSlice } from 'redux/slices/hooks/useCellVisualizationsSlice'
import { datasetItems } from 'redux/types'
import { useAppIsReady } from 'utils/useAppIsReady'
import { CELL_IMAGE_NORMAL_SIZE, CELL_IMAGE_NORMAL_SPACING } from '../shared'
import { getSessionData } from './getSessionData'
import { getCellInfoToGroups } from '../utils'

export const useLoadCellInfoEffect = (): void => {
  const { sessionId, versionId, updateVersion, isPreFilter } = useCellVisualizationUrlParams()
  const { setStore } = useCellVisualizationsSlice()

  const loaded = useRef(false)
  const isGettingSession = useRef(false)

  const appIsReady = useAppIsReady()

  const readyToGetData = sessionId && appIsReady && !loaded.current && !isGettingSession.current

  /*
    When calling an async api in a useEffect, remember that the useEffect
    might run again before the api call is resolved. Checking isGettingSession
    prevents the api from getting called twice
    */
  useEffect(() => {
    if (!readyToGetData) return

    const getArrowTable = async () => {
      isGettingSession.current = true
      const { cellsData, sessionData } = await getSessionData({
        sessionId,
        versionId,
      })

      if (cellsData && sessionData) {
        loaded.current = true

        const { version_config: state } = sessionData
        if (state) {
          const matchingDsi = datasetItems.find((dsi) => dsi.id === `${sessionId}`)
          const extraData: Partial<CellVisualizationsState> | undefined = isPreFilter
            ? {
                selectedCellInfoGroupName: 'leiden',
                cellImagesFilter: {
                  displayImages: true,
                  imageSize: CELL_IMAGE_NORMAL_SIZE / 1.5,
                  spacingAdjust: CELL_IMAGE_NORMAL_SPACING / 1.5,
                },
              }
            : undefined
          const removedCellIds = new Set<string>(
            state.morphotypesToRemove?.flatMap(
              (m) => m.cells.points?.flatMap((p) => p.cellId ?? []) ?? []
            )
          )
          const unRemovedCellData = cellsData.filter((cd) => !removedCellIds.has(cd.CELL_ID))

          setStore({
            ...state,
            ...extraData,
            cellsData,
            cellInfoGroups: getCellInfoToGroups(unRemovedCellData),
            selectedDataset: matchingDsi ?? state.selectedDataset,
          })
        }

        if (sessionData.version_id) {
          updateVersion(sessionData.version_id, isPreFilter)
        }
      }
      isGettingSession.current = false
    }

    getArrowTable()
  }, [isPreFilter, readyToGetData, sessionId, setStore, updateVersion, versionId])

  /**
   * Resetting the loaded and isGettingSession so that the API can be called again especially when the session id has changed
   */
  useEffect(() => {
    isGettingSession.current = false
    loaded.current = false
  }, [sessionId])
}

export default useLoadCellInfoEffect
