import { Box, Stack } from '@mui/material'
import PlotImagesFilter from './plot/plotLayoutImages/PlotImagesFilter'

export const PreFilterDisplayImages = (): JSX.Element => {
  return (
    <Stack
      direction="row"
      sx={{
        position: 'absolute',
        zIndex: 98,
        justifyContent: 'space-between',
        bottom: '0px',
        width: '100%',
      }}
    >
      <div />
      <Box
        sx={{
          p: 1,
          m: 1,
          border: '1px solid #C4C6FC',
          borderRadius: 1,
          backgroundColor: 'white',
        }}
      >
        <PlotImagesFilter />
      </Box>
      <div />
    </Stack>
  )
}

export default PreFilterDisplayImages
