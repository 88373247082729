import Typography from '@mui/material/Typography'
import { CellImageAction } from 'components/shared/CellImageControl'
import ContentLoading from 'components/shared/ContentLoading'
import CellImages from './CellImages'
import { MIN_CELL_IMAGES } from '../CellSearchOptions'
import useSampleDistribution from '../useSampleDistribution'

interface Props {
  handleQueryUpdate: (action: CellImageAction) => void
}

const CellBrowsingSampleDistribution = (props: Props): JSX.Element => {
  const { handleQueryUpdate } = props

  const { data: rowData, loading: isLoading, error } = useSampleDistribution()

  const cellCount = rowData.length

  return (
    <div data-testid="cellBrowsingSampleDistribution">
      {isLoading ? <ContentLoading /> : null}
      {!isLoading && error ? (
        <Typography sx={{ textAlign: 'center', mt: 2 }}>{error}</Typography>
      ) : null}
      {!isLoading && !error && cellCount === 0 ? (
        <Typography sx={{ textAlign: 'center', mt: 2 }}>
          No cell images found for this Run Id
        </Typography>
      ) : null}
      {!isLoading &&
      rowData &&
      cellCount !== undefined &&
      cellCount > 0 &&
      cellCount <= MIN_CELL_IMAGES ? (
        <Typography sx={{ textAlign: 'center', mt: 2 }}>
          Run Id doesn&apos;t contain required minimum ({MIN_CELL_IMAGES}) cell images.
        </Typography>
      ) : null}
      {!isLoading && rowData && cellCount !== undefined && cellCount > MIN_CELL_IMAGES ? (
        <CellImages cellData={rowData} handleQueryUpdate={handleQueryUpdate} />
      ) : null}
    </div>
  )
}

export default CellBrowsingSampleDistribution
