import * as ROUTES from 'constants/routes'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { CellVisualizationUrlParams } from './types'

const useHistoryPush = () => {
  const history = useHistory()

  const historyPush = (
    sessionId?: string | number,
    versionId?: string | number,
    preFilter?: boolean
  ) => {
    let pathname = '/cell-visualizations'
    if (sessionId) {
      pathname += `/${sessionId}`

      if (versionId) {
        pathname += `/versions/${versionId}`

        if (preFilter) {
          pathname += '/prefilter'
        }
      }
    }
    history.push({ pathname })
  }

  return historyPush
}

/**
 * Hook responsible for URL parameter management for visualization sessions.
 *
 * It handles the following three cases:
 *   1) No session yet (before creating a session) /cell-visualizations
 *   2) Open session, latest version /cell-visualizations/<sessionId>
 *   3) Open a specific version /cell-visualizations/<sessionId>/version/<versionId>
 *
 * And handles the following transitions
 *   1 --> 3 Create a session (with a session id and version id)
 *   2 --> 3 Open a session (with a new version id)
 *   3 --> 3 Save a session (with new version id)
 *
 * @returns sessionId and versionId (NaN if not set)
 */
const useCellVisualizationUrlParamsMethods = () => {
  const historyPush = useHistoryPush()

  const paramsSessionId = useRouteMatch<CellVisualizationUrlParams>(
    ROUTES.CELL_VISUALIZATIONS_SESSION
  )?.params
  const paramsVersionId = useRouteMatch<CellVisualizationUrlParams>(
    ROUTES.CELL_VISUALIZATIONS_SESSION_VERSION
  )?.params
  const isPreFilter =
    useRouteMatch<CellVisualizationUrlParams>(ROUTES.CELL_VISUALIZATIONS_SESSION_VERSION_PREFILTER)
      ?.isExact === true

  const { sessionId: sessionIdRaw } = paramsSessionId ?? {}
  const { versionId: versionIdRaw } = paramsVersionId ?? {}

  const versionId = parseInt(versionIdRaw ?? '', 10)
  const sessionId = parseInt(sessionIdRaw ?? '', 10)

  const updateSession = (newSessionId: string | number) => {
    historyPush(newSessionId)
  }
  const updateVersion = (newVersionId: string | number, preFilter?: boolean) => {
    historyPush(sessionId, newVersionId, preFilter)
  }
  const updateSessionAndVersion = (
    newSessionId: string | number,
    newVersionId: string | number,
    preFilter?: boolean
  ) => {
    historyPush(newSessionId, newVersionId, preFilter)
  }
  const exitPreFilter = () => {
    historyPush(sessionId, versionId)
  }
  const enterPreFilter = () => {
    historyPush(sessionId, versionId, true)
  }

  return {
    sessionId,
    versionId,
    updateSession,
    updateVersion,
    updateSessionAndVersion,
    isPreFilter,
    exitPreFilter,
    enterPreFilter,
  }
}

export const useCellVisualizationUrlParams = (): ReturnType<
  typeof useCellVisualizationUrlParamsMethods
> => useCellVisualizationUrlParamsMethods()

export default useCellVisualizationUrlParams
