import { createContext, useContext, useState } from 'react'
import { useNotificationSlice } from 'redux/slices/hooks'
import { useCellVisualizationsSlice } from 'redux/slices/hooks/useCellVisualizationsSlice'
import {
  ScatterPlotData,
  ScatterPlotMetadata,
  defaultMetaData,
  getDefaultFlattenedPlotData,
} from 'redux/slices/types'
import getScatterPlotDataFromCellInfo from './getScatterPlotDataFromCellInfo'
import updateWithVisibleDensityColors from './updateWithVisibleDensityColors'
import { useBaseCellPlotData } from './useBaseCellPlotData'
import useCellData from './useCellData'
import { usePlotDataChangeDetector } from './usePlotDataChangeDetector'
import { getMetadata } from './utils'

// Storing the scatter plot data in redux (apparently?) doesn't work, so we have to resort to react context
// Storing and updating the scatterplot data all in one place should speed things up
// https://stackoverflow.com/questions/76126033/how-to-use-redux-with-react-plotly-js

const getDefaultScatterState = () =>
  ({
    plotData: [],
    cellInfo: [],
    cellInfoUnfiltered: [],
    scatterPlotMetaData: { ...defaultMetaData },
    scatterPlotMetaDataFiltered: { ...defaultMetaData },
    flattenedPlotData: getDefaultFlattenedPlotData(),
  } as ScatterPlotData)

export const PlotDataContext = createContext<ReturnType<typeof usePlotData>>(
  getDefaultScatterState()
)

export const PlotDataContextProvider = ({ children }: React.PropsWithChildren): JSX.Element => {
  const { clearNotification } = useNotificationSlice()

  const [scatterPlotMetaData, setScatterPlotMetaData] = useState<ScatterPlotMetadata>()

  const [scatterPlotData, setScatterPlotData] = useState<ScatterPlotData>(getDefaultScatterState())

  const {
    cellVisualizations: { cellsData, plotDisplayType, morphotypesToRemove },
    visibleSelectedComparisonDimensions,
  } = useCellVisualizationsSlice()

  const { getBaseCellPlotData: getBasePlot } = useBaseCellPlotData()
  const { getDataForCell } = useCellData()

  usePlotDataChangeDetector(() => {
    if (!cellsData) return
    // Only calculate metadata once as this shouldn't change
    let metaData = scatterPlotMetaData
    if (!metaData) {
      metaData = getMetadata(cellsData)
      setScatterPlotMetaData(metaData)
    }

    const latestPlotData = getScatterPlotDataFromCellInfo({
      scatterPlotMetaData: metaData,
      cellInfo: cellsData,
      getDataForCell,
      getBasePlot,
      numOfColumns: visibleSelectedComparisonDimensions
        ? visibleSelectedComparisonDimensions[0].values.length
        : undefined,
    })

    if (plotDisplayType === 'density') {
      const calculateSeparately = Boolean(
        visibleSelectedComparisonDimensions && visibleSelectedComparisonDimensions.length > 0
      )
      updateWithVisibleDensityColors(latestPlotData.plotData, calculateSeparately)
    }

    setScatterPlotData({
      ...latestPlotData,
      scatterPlotMetaData: metaData,
      scatterPlotMetaDataFiltered: getMetadata(cellsData, morphotypesToRemove),
    })
    clearNotification()
  })
  return <PlotDataContext.Provider value={scatterPlotData}>{children}</PlotDataContext.Provider>
}

export const usePlotData = (): ScatterPlotData => {
  const data = useContext(PlotDataContext)
  return data
}
