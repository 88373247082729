import { useAuth0 } from '@auth0/auth0-react'
import { useAuthWrapper } from 'utils/demoEnvironmentUtils'
import useAuthTokens from 'utils/useAuthTokens'

export const useAppIsReady = (): boolean => {
    const { hasTokens } = useAuthTokens()
    const { isAuthenticated } = useAuthWrapper(useAuth0)
    const isReady = hasTokens && isAuthenticated

    return isReady
}

export default useAppIsReady
