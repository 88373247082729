import * as React from 'react'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import DrawerCloseIcon from 'components/shared/icons/DrawerCloseIcon'
import DrawerOpenIcon from 'components/shared/icons/DrawerOpenIcon'

const drawerWidth = 339

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean
}>(({ theme, open }) => ({
  flexGrow: 1,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
    width: `calc(100% - ${drawerWidth}px)`,
  }),
}))

const MainContentContainer = styled(Box)({
  padding: '40px 27px 20px 24px',
  border: '1px solid #C4C6FC',
  borderRadius: '0px 4px 4px 0px',
  boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.15)',
})

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}))

const PushContent = styled('div')({
  flexShrink: 0,
  height: '48px',
  width: '100%',
})

interface SearchSidebarProps {
  children: React.ReactNode
  mainContent: JSX.Element
  open: boolean
  setOpen: (state: boolean) => void
}

const SearchSideBar = ({
  children,
  mainContent,
  open,
  setOpen,
}: SearchSidebarProps): JSX.Element => {
  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={handleDrawerOpen}
        edge="start"
        sx={{
          zIndex: '100',
          position: 'absolute',
          top: '220px',
          mr: 2,
          ...(open && { display: 'none' }),
        }}
      >
        <DrawerOpenIcon inheritViewBox sx={{ width: '47px', height: '40px' }} />
      </IconButton>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <PushContent />
        <DrawerHeader sx={{ zIndex: '100', position: 'absolute', top: '56px', right: '8px' }}>
          <IconButton onClick={handleDrawerClose}>
            <DrawerCloseIcon inheritViewBox sx={{ width: '28px', height: '28px' }} />
          </IconButton>
        </DrawerHeader>
        <MainContentContainer data-testid="main-right-content">{mainContent}</MainContentContainer>
      </Drawer>
      <Main open={open}>{children}</Main>
    </Box>
  )
}

export default SearchSideBar
