import {
    Box,
    IconButton,
    MenuItem,
    SelectChangeEvent,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
} from '@mui/material'
import { DeepcellDialog, DeepcellPrimarySelect } from 'components/shared'
import InfoIcon from 'components/shared/icons/InfoIcon'
import WarningIcon from 'components/shared/icons/WarningIcon'
import { useState } from 'react'
import { useCellVisualizationsSlice } from 'redux/slices/hooks/useCellVisualizationsSlice'
import useEventsManager from 'redux/slices/hooks/useEventsManager'
import useWalkthroughSlice from 'redux/slices/hooks/useWalkthroughSlice'
import { DatasetItem, datasetItems } from 'redux/types'
import getSessionData from '../arrow/getSessionData'
import useCellVisualizationUrlParams from '../useCellVisualizationUrlParams'

const getDatasetOptions = (handleIconClick: (dataset: string) => void) =>
    datasetItems.map(({ label, id }) => (
        <MenuItem key={label} value={id}>
            <IconButton
                onClick={(e) => {
                    e.stopPropagation()
                    handleIconClick(id)
                }}
            >
                <InfoIcon sx={{ fontSize: '1rem' }} />
            </IconButton>
            {label}
        </MenuItem>
    ))

export const DatasetSelection = (): JSX.Element => {
    const { updateSessionAndVersion, updateSession } = useCellVisualizationUrlParams()

    const { setShow } = useWalkthroughSlice()

    const {
        setStore,
        cellVisualizations: { selectedDataset },
    } = useCellVisualizationsSlice()

    const [openSelect, setOpenSelect] = useState(false)
    const [potentialDataset, setPotentialDataset] = useState<DatasetItem | null>(null)
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [showDatasetInfo, setShowDatasetInfo] = useState('')
    const [datasetInfo, setDatasetInfo] = useState<DatasetItem | null>(null)
    const eventsManager = useEventsManager()

    const reset = () => {
        setShowConfirmation(false)
        setPotentialDataset(null)
    }

    const handleDatasetChange = ({ target: { value } }: SelectChangeEvent<unknown>) => {
        setShow(false)

        const id = value as DatasetItem['id']
        const matchingDsi = datasetItems.find((dsi) => dsi.id === id)
        if (matchingDsi) {
            setPotentialDataset(matchingDsi)
        }

        setShowConfirmation(true)
    }

    const handleConfirmDatasetChange = async () => {
        if (potentialDataset) {
            // cache the potentialDataset here so we can reset without losing it
            const finalDataset = potentialDataset
            reset()

            updateSession(finalDataset.id)
            setStore({})

            const { cellsData, sessionData } = await getSessionData({
                sessionId: +finalDataset.id,
            })

            // analytics
            eventsManager.sendDemoDatasetEvent(finalDataset.label)

            // use setStore instead of setSelectedDataset here because we also want to reset the rest of the store
            setStore({ selectedDataset: finalDataset, cellsData })

            if (sessionData) {
                updateSessionAndVersion(finalDataset.id, sessionData.version_id)
            }
        }
    }

    const handleIconClick = (ds: string) => {
        setShowDatasetInfo(ds)
        const matchingDsi = datasetItems.find((dsi) => dsi.id === ds)
        if (matchingDsi) setDatasetInfo(matchingDsi)
    }

    return (
        <>
            <DeepcellPrimarySelect
                items={getDatasetOptions(handleIconClick)}
                value={selectedDataset.id}
                open={openSelect}
                SelectDisplayProps={{ style: { display: 'flex', height: '20px' } }}
                onOpen={(e) => {
                    // Hack to figure out if user clicked on icon button while dropdown was closed
                    // Button.className is a string but svg.className is an object
                    const target = (
                        e.target as unknown as {
                            className: string | { baseVal: string | undefined }
                        }
                    ).className
                    const className = typeof target === 'string' ? target : target.baseVal
                    const isButton = Boolean(['Svg', 'Button'].find((x) => className?.includes(x)))

                    if (isButton) {
                        handleIconClick(selectedDataset.id)
                    } else {
                        setOpenSelect(true)
                    }
                }}
                onClose={() => setOpenSelect(false)}
                renderValue={(x) => {
                    const id = x as DatasetItem['id']
                    return (
                        <MenuItem sx={{ p: 0 }}>
                            <IconButton
                                onClick={(e) => {
                                    e.stopPropagation()
                                    handleIconClick(id)
                                }}
                            >
                                <InfoIcon sx={{ fontSize: '1rem' }} />
                            </IconButton>
                            <Typography>
                                {datasetItems.find((dsi) => dsi.id === id)?.label ?? ''}
                            </Typography>
                        </MenuItem>
                    )
                }}
                onChange={handleDatasetChange}
            />
            <DeepcellDialog
                maxWidth="xs"
                handleCancel={reset}
                handleConfirm={handleConfirmDatasetChange}
                open={showConfirmation}
                okLabel="Switch"
            >
                <Stack spacing={2}>
                    <WarningIcon sx={{ fontSize: '3.5rem', alignSelf: 'center' }} />
                    <Typography>
                        Any progress with the current dataset will be lost upon switching. Do you
                        still wish to continue?
                    </Typography>
                </Stack>
            </DeepcellDialog>
            <DeepcellDialog
                maxWidth="xs"
                handleCancel={() => setShowDatasetInfo('')}
                handleConfirm={() => setShowDatasetInfo('')}
                open={Boolean(showDatasetInfo)}
                okLabel="Okay"
                showCancelButton={false}
            >
                <Stack spacing={2}>
                    {datasetInfo && (
                        <>
                            <Box alignSelf="center" component="h2">
                                {datasetInfo.label}
                            </Box>
                            <Typography>{datasetInfo.description}</Typography>
                            <TableContainer>
                                <Table size="small">
                                    <TableBody>
                                        <TableRow key="species">
                                            <TableCell
                                                sx={{
                                                    border: '1px solid rgba(224, 224, 224, 1)',
                                                }}
                                            >
                                                Species
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    border: '1px solid rgba(224, 224, 224, 1)',
                                                }}
                                            >
                                                {datasetInfo.species}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow key="tissueType">
                                            <TableCell
                                                sx={{
                                                    border: '1px solid rgba(224, 224, 224, 1)',
                                                }}
                                            >
                                                Tissue Type
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    border: '1px solid rgba(224, 224, 224, 1)',
                                                }}
                                            >
                                                {datasetInfo.tissueType}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow key="sampleTypes">
                                            <TableCell
                                                sx={{
                                                    border: '1px solid rgba(224, 224, 224, 1)',
                                                }}
                                            >
                                                Sample Types
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    border: '1px solid rgba(224, 224, 224, 1)',
                                                }}
                                            >
                                                {datasetInfo.sampleTypes}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow key="numberOfSamples">
                                            <TableCell
                                                sx={{
                                                    border: '1px solid rgba(224, 224, 224, 1)',
                                                }}
                                            >
                                                Number Of Samples
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    border: '1px solid rgba(224, 224, 224, 1)',
                                                }}
                                            >
                                                {datasetInfo.numberOfSamples}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow key="totalCells">
                                            <TableCell
                                                sx={{
                                                    border: '1px solid rgba(224, 224, 224, 1)',
                                                }}
                                            >
                                                Total Cells
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    border: '1px solid rgba(224, 224, 224, 1)',
                                                }}
                                            >
                                                {datasetInfo.totalCells}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow key="model">
                                            <TableCell
                                                sx={{
                                                    border: '1px solid rgba(224, 224, 224, 1)',
                                                }}
                                            >
                                                Model
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    border: '1px solid rgba(224, 224, 224, 1)',
                                                }}
                                            >
                                                {datasetInfo.model}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow key="projectionType">
                                            <TableCell
                                                sx={{
                                                    border: '1px solid rgba(224, 224, 224, 1)',
                                                }}
                                            >
                                                Projection Type
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    border: '1px solid rgba(224, 224, 224, 1)',
                                                }}
                                            >
                                                {datasetInfo.projectionType}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow key="warning">
                                            <TableCell
                                                sx={{
                                                    border: '1px solid rgba(224, 224, 224, 1)',
                                                }}
                                            >
                                                Quality Control
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    border: '1px solid rgba(224, 224, 224, 1)',
                                                }}
                                            >
                                                {datasetInfo.warning}
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </>
                    )}
                </Stack>
            </DeepcellDialog>
        </>
    )
}

export default DatasetSelection
