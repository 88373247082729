import { Box } from '@mui/material'
import { QueryStatus } from 'react-query'
import { CellPlotDatum } from 'redux/slices/types'
import PlotCellImage from '../PlotCellImage'

const PlotCellImageWrapper = (
  props: { cell: CellPlotDatum } & Omit<Parameters<typeof PlotCellImage>[0], 'cellId'>
) => {
  const {
    cell: { cellId },
  } = props
  return <PlotCellImage {...props} cellId={cellId ?? ''} />
}

interface PlotCellImagesProps {
  cells?: CellPlotDatum[]
  imgProps?: Parameters<typeof PlotCellImage>[0]['imgProps']
  handleCellStatusUpdate?(cell: CellPlotDatum, status?: QueryStatus): void
}
export const PlotCellImages = ({
  cells,
  imgProps,
  handleCellStatusUpdate,
}: PlotCellImagesProps): JSX.Element => {
  const handleCellStatusChange = (cell: CellPlotDatum) => (status?: QueryStatus) => {
    if (handleCellStatusUpdate) handleCellStatusUpdate(cell, status)
  }
  return (
    <Box sx={{ textAlign: 'center' }}>
      {cells?.map((cell, i) => {
        const key = `key${i}`
        return (
          <PlotCellImageWrapper
            key={key}
            cell={cell}
            handleStatusChange={handleCellStatusChange(cell)}
            imgProps={{ ...imgProps, style: { borderRadius: '10px', ...imgProps?.style } }}
          />
        )
      })}
    </Box>
  )
}

export default PlotCellImages
