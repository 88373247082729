import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface WalkthroughState {
    show?: boolean
}

const initialState: WalkthroughState = {}

export const walkthroughSlice = createSlice({
    name: 'walkthrough',
    initialState,
    reducers: {
        setShow: (state, action: PayloadAction<WalkthroughState['show']>) => {
            state.show = action.payload
        },
    },
})

export const WalkthroughActions = walkthroughSlice.actions
export const WalkthroughReducer = walkthroughSlice.reducer
