import { plotlySlice } from '../PlotlySlice'
import useSliceWrapper from './useSliceWrapper'

const useGetMethodsAndData = () => {
  const methodsAndData = useSliceWrapper('plotly', plotlySlice)
  const { setRange } = methodsAndData

  const helperMethods = {
    /**
     * SHOULD set the range to be the extrema of the points of the plot. But who knows what plotly is doing under the hood...
     */
    resetRange: () => {
      setRange({ x1: undefined, x2: undefined, y1: undefined, y2: undefined })
    },
  }

  return { ...methodsAndData, ...helperMethods }
}

export const usePlotlySlice = (): ReturnType<typeof useGetMethodsAndData> => useGetMethodsAndData()

export default usePlotlySlice
