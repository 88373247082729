import { GridSizeChangedEvent } from 'ag-grid-community'
import { ReactElement, useEffect, useState } from 'react'
import { Run, WellConfiguration, WellCount, WellMetric } from '../../utils/api'
import { getSortByWellColumnDefs } from './metadata'
import AgGridRunDetailsTable from '../shared/AgGridRunDetailsTable'

interface IWellResultsGridProps {
  run: Run
}

type Well = WellConfiguration & WellCount & WellMetric

const WellResultsGrid = ({ run }: IWellResultsGridProps): ReactElement => {
  const [wells, setWells] = useState<Well[]>([])

  useEffect(() => {
    const configurations = run.well_sorting_configurations ?? []
    const counts = run.well_counts ?? []
    const metrics = run.well_metrics ?? []

    const countByWellId = counts.reduce((obj: Record<number, WellCount>, item: WellCount) => {
      // eslint-disable-next-line no-param-reassign
      obj[item.well] = item
      return obj
    }, {})

    const metricsByWellId = metrics.reduce((obj: Record<number, WellMetric>, item: WellMetric) => {
      // eslint-disable-next-line no-param-reassign
      obj[item.well] = item
      return obj
    }, {})

    const data = configurations.map((config: WellConfiguration) => ({
      ...config,
      ...(countByWellId[config.well] ?? {}),
      ...(metricsByWellId[config.well] ?? {}),
    }))
    setWells(data)
  }, [run])

  const onGridSizeChange = (params: GridSizeChangedEvent) => {
    params.api.sizeColumnsToFit()
  }

  return (
    <AgGridRunDetailsTable
      customClass="context-menu-ag-grid"
      rowData={wells
        .filter((wellMetric) => wellMetric.well > 0)
        .sort((well1, well2) => well1.well - well2.well)}
      columnDefs={getSortByWellColumnDefs(run?.stopped)}
      onGridSizeChanged={onGridSizeChange}
      testId="well-result-grid"
      headerHeight={32}
    />
  )
}

export default WellResultsGrid
