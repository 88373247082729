import { DialogContentText, Typography } from '@mui/material'
import { DeepcellDialog } from 'components/shared'
import { useLocalStorage } from 'react-use'

export const PreFilterIntroDialog = (): JSX.Element => {
  const [show, setShow] = useLocalStorage('show-prefilter-prompt', true)

  const handleClose = () => setShow(false)

  return (
    <DeepcellDialog
      PaperProps={{ sx: { width: 366 } }}
      open={show || show === undefined}
      showCancelButton={false}
      titleLabel="Pre-Filter Cells"
      okLabel="OK"
      contentProps={{ sx: { textAlign: 'center' } }}
      handleConfirm={handleClose}
      onClose={handleClose}
    >
      <DialogContentText mb={2}>
        <Typography>
          In this step, you can choose to remove cells that are not relevant (e.g. partial cells,
          debris, multiple cell aggregates).
        </Typography>
      </DialogContentText>
      <DialogContentText>
        <Typography>
          The cells you remove will be excluded from analysis, and also from any morphotype
          classifiers you create for sorting on instrument.
        </Typography>
      </DialogContentText>
    </DeepcellDialog>
  )
}

export default PreFilterIntroDialog
