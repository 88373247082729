import _ from 'lodash'
import {
  CellPlotDatum,
  FlattenedCellPlotData,
  getDefaultFlattenedPlotData,
} from 'redux/slices/types'
import { PlotRange } from '../../tsv/types'

export interface PlotDataRange {
  plotData?: FlattenedCellPlotData
  range?: PlotRange
}

export const coordsAreInRange = (x: number, y: number, range: PlotRange): boolean => {
  if (!x || !y) return false

  const rangeX1 = range?.x1 ?? -Infinity
  const rangeX2 = range?.x2 ?? Infinity
  const rangeY1 = range?.y1 ?? -Infinity
  const rangeY2 = range?.y2 ?? Infinity

  return x >= rangeX1 && x <= rangeX2 && y >= rangeY1 && y <= rangeY2
}

export const convertToDeepcellPlotDatum = (plotData?: FlattenedCellPlotData): CellPlotDatum[] => {
  if (!plotData) return []

  const { x, y, cellIds } = plotData

  return x.map((n, i) => ({
    x: n,
    y: y[i],
    cellId: cellIds[i],
  }))
}

export const getVisiblePointsFromPoints = (
  points: FlattenedCellPlotData,
  range: PlotRange
): FlattenedCellPlotData => {
  const inRangePoints = getDefaultFlattenedPlotData()

  for (let i = 0; i < points.x.length; i += 1) {
    if (coordsAreInRange(points.x[i], points.y[i], range)) {
      _.keys(points).forEach((k) => {
        const key = k as keyof typeof points
        const val = points[key][i]

        const inRangePointsVals = inRangePoints[key]
        type InRangeType = (typeof inRangePointsVals)[number]
        ;(inRangePointsVals as InRangeType[]).push(val)
      })
    }
  }

  return inRangePoints
}
