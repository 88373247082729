import { createSvgIcon } from '@mui/material'

const DownloadToggleIcon = createSvgIcon(
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="31" height="31" rx="3.5" fill="white" stroke="#A4EFC0" />
    <path
      d="M10.2852 21.9978C10.5852 22.2802 10.9922 22.4388 11.4165 22.4388H21.0165C21.4409 22.4388 21.8478 22.2802 22.1479 21.9978M16.2174 9.57715V18.1105M16.2174 18.1105L19.8746 14.8499M16.2174 18.1105L12.5603 14.8499"
      stroke="#5F55D1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.2227 31.5555L31.556 22.2222V28.5555C31.556 30.2124 30.2128 31.5555 28.556 31.5555H22.2227Z"
      fill="#A4EFC0"
    />
  </svg>,
  'Download'
)

export default DownloadToggleIcon
