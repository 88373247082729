import { CellInfo } from 'components/cell-visualizations/tsv/types'
import _ from 'lodash'
import { PlotData, SelectionRange, Shape } from 'plotly.js'
import { FEATURES, FeatureDefinition, FeatureGroup } from 'utils/constants'

/**
 * Put string in format that looks good
 */
export const labelize = (s: string): string => _.startCase(s.toLowerCase())

export type CellInfoDimension = { field: keyof CellInfo; values: string[] }

/**
 * Metadata is fixed data about the plot derived from the arrow file that should not change after initialization
 */
export type ScatterPlotMetadata = {
  /**
   * The smallest x value
   */
  minX: number
  /**
   * The smallest y value
   */
  minY: number
  /**
   * The largest x value
   */
  maxX: number
  /**
   * The largest y value
   */
  maxY: number
  /**
   * All original x coordinates
   */
  x: number[]
  /**
   * All original y coordinates
   */
  y: number[]
  /**
   * All cellIds
   */
  cellIds: string[]
  /**
   * x and y data but in {x,y}[] form
   */
  plotDatum: CellPlotDatum[]
}

export const defaultMetaData = {
  maxX: -Infinity,
  maxY: -Infinity,
  minX: Infinity,
  minY: Infinity,
  x: [],
  y: [],
  cellIds: [],
  plotDatum: [],
} as ScatterPlotMetadata

export interface ScatterPlotData {
  /**
   * Data to be used in ScatterPlot to show the user. Hidden values are removed by default
   */
  plotData: CellPlotData[]
  /**
   * Hidden values are removed by default
   */
  flattenedPlotData: FlattenedCellPlotData
  /**
   * Hidden values are removed by default
   */
  cellInfo: CellInfo[]
  /**
   * Hidden values retained. Removed values are still removed, though
   */
  cellInfoUnfiltered: CellInfo[]
  /**
   * Basic information about the plot data that's based on the arrow file and should not change
   */
  scatterPlotMetaData: ScatterPlotMetadata
  /**
   * Same information as scatterPlotMetaData, but with hidden values excluded
   */
  scatterPlotMetaDataFiltered: ScatterPlotMetadata
}

export type FlattenedCellPlotData = {
  /**
   * The x coordinates used for displaying the point on the plotly scatterplot
   */
  x: number[]
  /**
   * The y coordinates used for displaying the point on the plotly scatterplot
   */
  y: number[]
  /**
   * The original UMAP_0 coordinates that FlattenedCellPlotData.x is derived from
   */
  originalX: number[]
  /**
   * The original UMAP_1 coordinates that FlattenedCellPlotData.y is derived from
   */
  originalY: number[]
  cellIds: string[]
  /**
   * The same data as above but in this format
   */
  plotDatum: CellPlotDatum[]
}

export const getDefaultFlattenedPlotData = (): FlattenedCellPlotData => ({
  x: [],
  y: [],
  originalX: [],
  originalY: [],
  cellIds: [],
  plotDatum: [],
})

export type CellPlotData = Omit<Partial<PlotData>, keyof FlattenedCellPlotData> &
  FlattenedCellPlotData

/** Defines the specific subset of Plotly's PlotDatum that we currently use
 */
export type CellPlotDatum = {
  x: number
  y: number
  cellId?: string
  originalX?: number
  originalY?: number
}
export type Cells = CellPlotDatum[] | undefined
export type PaginationParams = { page?: number; itemsPerPage?: number }

// Feature groups used for non-morphometric features
const GROUP_NONE = ''
export const GROUP_RUN_INFORMATION = 'Run Information'
const GROUP_AI_FEATURES = 'AI Features'

export interface CellDataField {
  label: string // human readable label to display
  description?: string // human readable description to display
  attribute: keyof CellInfo | '' | string // unique string corresponding to the data field to use (or '' for None)
  category: string // grouping to categorize the fields under
  isContinuous: boolean
  isInternalOnly?: boolean // Set to true if this field should only be available to internal users
}

export interface LabelColor {
  name: string
  color: string
  isHidden?: boolean
}

/** Represents information about a data field.
 *
 * Categorical fields (isContinous = false) maintain a list of values
 * and their corresponding colors in alphabetical order in the data field.
 *
 * For continuous fields, the data field is left empty.
 */
export type CellInfoGroup = {
  label: string
  category: string
  isContinuous: boolean

  // Whether this data field has values in the dataset
  hasData: boolean

  // List of values and their corresponding colors
  // Only used for categorical fields
  data: { value: string; color: string; isHidden: boolean }[]
}

export const CellInfoGroupNone = {
  label: 'None',
  category: '',
  isContinuous: false,
  hasData: false,
  data: [] as CellInfoGroup['data'],
} as const

export type CellInfoGroups = Partial<{
  [K in keyof CellInfo | '']: K extends '' ? typeof CellInfoGroupNone : CellInfoGroup
}>

export type PlotSelectionState = {
  points?: CellPlotDatum[]
  range?: SelectionRange
  lassoPoints?: SelectionRange
  selections?: Partial<Shape>[]
}

export type PinnedCellGroup = {
  id: number
  active?: boolean
  name: string
  /**
   * Selection relative to the original plot. Even if a user makes a selection in multiplot mode on a faraway copy of the plot, these cells should contain all
   * original coordinates that would match what was given in the arrow file
   */
  cells: PlotSelectionState
  /**
   * Actualy selections the user draws on the plot that may or may not be offset from the original cells
   */
  transposedCells?: PlotSelectionState
  pagination?: PaginationParams
  isHighlighted?: boolean
  isInSelectedGroup?: boolean
  cellInfoGroupsSnapshot?: CellInfoGroups
  comparisonDimensionsSnapshot?: CellInfoDimension[]
}

export type MergedCellGroup = Pick<PinnedCellGroup, 'id' | 'cells'>

// export enum ActiveCellVisualizationsTab {
//   CellGroup = 'CELL_GROUP',
//   Compare = 'COMPARE',
// }

export type CellImagesFilter = {
  /**
   * Boolean flag to turn images on and off
   */
  displayImages: boolean
  /**
   * Desired image size in screen pixels
   */
  imageSize: number

  /**
   * Adjusts distance between sampled points.
   *
   * 1 = prevent any overlap of the bounding boxes
   *
   * Set to a lower value to have denser images, with potential overlap.
   * Set to a higher value to have less dense images.
   */
  spacingAdjust: number
}

export type ZoomThreshold = {
  /**
   * Maximum pixel size in plot coordinates that this zoom threshold should be used for
   */
  pixelWidthInPlotCoords: number

  /**
   * Points to load images when the current pixel size in the plot is less than pixelWidthInPlotCoords
   */
  points?: FlattenedCellPlotData
}

export const CELL_DATA_FIELD_NONE: CellDataField = {
  label: 'None',
  attribute: '',
  category: GROUP_NONE,
  isContinuous: false,
}
export const CELL_DATA_FIELD_PRE_FILTER: CellDataField = {
  attribute: 'density',
  label: 'Density',
  category: 'PreFilters',
  isContinuous: false
}

function featureDefinitionToCellDataField(
  feature: FeatureDefinition,
  group: string
): CellDataField {
  return {
    label: feature.label ? feature.label : labelize(feature.field),
    description: feature.description,
    attribute: feature.field,
    category: group,
    isContinuous: true,
    isInternalOnly: feature.isInternalOnly,
  }
}

export type DataFieldsOptions = {
  showOnlyFeaturedMorphometrics?: boolean
}

const baseFields: CellDataField[] = [
  { label: 'Run ID', attribute: 'RUN_ID', category: GROUP_RUN_INFORMATION, isContinuous: false },
  {
    label: 'Ground Truth Label',
    attribute: 'GROUND_TRUTH_LABEL',
    category: GROUP_RUN_INFORMATION,
    isContinuous: false,
  },
  {
    label: 'Sample ID',
    attribute: 'SAMPLE_ID',
    category: GROUP_RUN_INFORMATION,
    isContinuous: false,
  },
  {
    label: 'Sample Type',
    attribute: 'SAMPLE_TYPE',
    category: GROUP_RUN_INFORMATION,
    isContinuous: false,
  },
  {
    label: 'Instrument',
    attribute: 'INSTRUMENT_NAME',
    category: GROUP_RUN_INFORMATION,
    isContinuous: false,
  },
  {
    label: 'Prediction',
    attribute: 'LABEL_NAME',
    category: GROUP_AI_FEATURES,
    isContinuous: false,
  },
  {
    label: 'Cluster',
    attribute: 'leiden',
    category: GROUP_AI_FEATURES,
    isContinuous: false,
  },

  // Support Sample Quality Class output from the Embedding Analysis Tool
  {
    label: 'Sample Quality Class',
    attribute: 'SAMPLE_QUALITY_CLASS',
    category: GROUP_AI_FEATURES,
    isContinuous: false,
  },
]

export const baseAttributes = baseFields.map((x) => x.attribute)

/** Returns the data fields to make available for selection based on feature flags */
export function getDataFields(params?: DataFieldsOptions): CellDataField[] {
  const { showOnlyFeaturedMorphometrics } = params ?? {}

  const topFeatures = FEATURES.filter((feature) => feature.groupPriority !== undefined)
  const visibleFeatures = showOnlyFeaturedMorphometrics ? topFeatures : FEATURES

  let result = [...baseFields]

  const featureCategoryOrder = [
    FeatureGroup.CUSTOM,
    FeatureGroup.CELL_SHAPE_FEATURES,
    FeatureGroup.PIXEL_INTENSITY_FEATURES,
    FeatureGroup.TEXTURE_FEATURES,
    FeatureGroup.POSITION_FEATURES,
    FeatureGroup.FOCUS_FEATURES,
    FeatureGroup.DEEP_LEARNING_FEATURES,
  ]

  featureCategoryOrder.forEach((featureCategory) => {
    const features = _.orderBy(
      visibleFeatures.filter((feature) => feature.group === featureCategory),
      'featurePriority'
    ).map((feature) =>
      featureDefinitionToCellDataField(feature, labelize(feature.group.toLowerCase()))
    )

    result = [...result, ...features]
  })
  return result
}
