import CircularProgress from '@mui/material/CircularProgress'
import { DialogActions, DialogContent, DialogTitle, styled } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import { Message } from 'google-protobuf'
import React, { FormEvent, FunctionComponent, useState } from 'react'
import { MutationFunction, useMutation } from 'react-query'
import { ClientError } from 'utils/api'
import DeepcellPrimaryButton from './DeepcellPrimaryButton'

const OkCancelFormControl = styled(FormControl)(({ theme }) => ({
  width: '100%',
  minWidth: '300px',
  marginTop: theme.spacing(2),
}))

const StyledContainer = styled('div')(({ theme }) => ({
  background: theme.palette.background.default,
}))

const StyledDialogContent = styled(DialogContent)({
  padding: '0px !important',
})

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  textAlign: 'center',
  color: theme.palette.text.primary,
  padding: '0px !important',
}))

const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  padding: '0px !important',
  marginTop: theme.spacing(2),
  justifyContent: 'center',
  gap: theme.spacing(1),
}))

interface Props {
  pending?: boolean
  handleButtonClick: (cancelled: boolean) => void
  children?: React.ReactNode
  okLabel?: string
  cancelLabel?: string
  titleLabel?: string
}

/**
 * Basic dialog body with custom body and OK + Cancel buttons (with a loading spinner on OK)
 * You can use this within various elements, like Material-UI Dialog element or Popover element
 *
 * @param pending whether the spinner should show on the OK button
 * @param handleButtonClick handles button click (with a boolean for whether the dialog was cancelled
 * @param cancelLabel label to use on the Cancel button (default 'Cancel').  Omit to hide this button.
 * @param okLabel label to use on the OK button (default 'OK').  Omit to hide this button.
 * @constructor
 */
const OkCancelDialogBody: FunctionComponent<Props> = ({
  pending = false,
  handleButtonClick,
  cancelLabel = 'Cancel',
  okLabel = 'OK',
  titleLabel = '',
  children,
}: Props): JSX.Element => (
  <StyledContainer>
    <form
      onSubmit={(event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        event.stopPropagation()
        handleButtonClick(false)
      }}
    >
      {titleLabel && <StyledDialogTitle id="ok-cancel-title">{titleLabel}</StyledDialogTitle>}
      <StyledDialogContent>
        <OkCancelFormControl margin="dense">{children}</OkCancelFormControl>
      </StyledDialogContent>
      <StyledDialogActions>
        {cancelLabel && (
          <DeepcellPrimaryButton
            onClick={(_event: React.MouseEvent) => handleButtonClick(true)}
            outlined
          >
            {cancelLabel}
          </DeepcellPrimaryButton>
        )}
        {okLabel && (
          <DeepcellPrimaryButton
            endIcon={
              pending && (
                <CircularProgress data-testid="pending-circular-icon" color="secondary" size={16} />
              )
            }
            onClick={(_event: React.MouseEvent) => handleButtonClick(false)}
            contained
          >
            {okLabel}
          </DeepcellPrimaryButton>
        )}
      </StyledDialogActions>
    </form>
  </StyledContainer>
)

/** Handles tracking task update status and doing batch task updates
 *
 * @param items Reference to a list of items to update
 * @param updateFunc A function that updates an individual task
 * @param mutationFunc A react-query mutation function that executes the mutation
 * @param handleDone Function to call when done, which receives an empty list if there was no update
 * @param handleError Function tc call if an error occurs on any update
 *
 * @returns [ updating: boolean, handleBatchUpdate: (items: TData[]) => Promise<void>
 */
export function useBatchUpdate<TData extends Message, TResult>(
  items: TData[],
  updateFunc: (item: TData) => void,
  mutationFunc: MutationFunction<TResult, TData>,
  handleDone: (resultItems?: TResult[]) => void,
  handleError?: (err: string) => void
): [boolean, (canceled: boolean) => Promise<void>] {
  const [updating, setUpdating] = useState<boolean>(false)
  const mutation = useMutation(mutationFunc)

  return [
    updating,
    async (canceled: boolean): Promise<void> => {
      if (canceled) {
        handleDone()
        return
      }

      setUpdating(true)
      const promises = items.map((item) => {
        // don't mutate the property
        const itemCopy = item.clone()
        updateFunc(itemCopy)
        // @TODO Handle errors better
        return mutation.mutateAsync(itemCopy)
      })

      try {
        const result = await Promise.all(promises)
        handleDone(result)
      } catch (e) {
        if (e instanceof ClientError && handleError) {
          handleError(e.message)
        } else {
          console.error('SetAssigneePopover unhandled error', e)
        }
      } finally {
        setUpdating(false)
      }
    },
  ]
}

export default OkCancelDialogBody
