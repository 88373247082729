import { createSvgIcon } from '@mui/material'

const DropdownArrowIcon = createSvgIcon(
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 5C11.72 5 11.47 5.11 11.29 5.29L8 8.59L4.71 5.29C4.53 5.11 4.28 5 4 5C3.45 5 3 5.45 3 6C3 6.28 3.11 6.53 3.29 6.71L7.29 10.71C7.47 10.89 7.72 11 8 11C8.28 11 8.53 10.89 8.71 10.71L12.71 6.71C12.89 6.53 13 6.28 13 6C13 5.45 12.55 5 12 5Z"
      fill="black"
    />
  </svg>,
  'DropdownArrow'
)

export default DropdownArrowIcon
