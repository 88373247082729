import { Box, Typography } from '@mui/material'
import CellImageControl, { CellImageAction } from 'components/shared/CellImageControl'
import { useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'
import { useNotificationSlice } from 'redux/slices/hooks'
import useCellBrowsingSlice from 'redux/slices/hooks/useCellBrowsingSlice'
import { getCells } from 'utils/api'
import ms from 'ms'
import { messagesEqual } from 'utils/proto-utils'
import { QueryState } from '../cellSearchHelpers'
import { IMAGE_SIZE } from '../constants'

interface Props {
  searchOptions: QueryState
  cellId: string
  handleQueryUpdate: (action: CellImageAction) => void
}

export const isCellIdFormatValid = (cellIdValue: string): boolean => {
  const regex = /^\d+\s#\d+\s-?\d+$/
  return regex.test(cellIdValue)
}

const CellBrowsingByCellId = ({ searchOptions, cellId, handleQueryUpdate }: Props): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(true)
  const [errorMessage, setErrorMessage] = useState('')
  const { showError } = useNotificationSlice()
  const queryClient = useQueryClient()

  const {
    cellBrowsing: { rowData, selectedCell },
    setRowData,
  } = useCellBrowsingSlice()

  useEffect(() => {
    if (!isCellIdFormatValid(cellId)) {
      setErrorMessage('Invalid Cell Id format')
      setLoading(false)
      return
    }

    const [cellTime, cellNumber, instrumentId] = cellId.replace('#', '').split(' ').map(Number)

    const defaultParams = { number: cellNumber, time: cellTime, instrumentId }
    const cellDataById = { ...defaultParams, limit: 1 }
    ;(async () => {
      try {
        setErrorMessage('')
        setLoading(true)
        const responseCellIdData = await queryClient.fetchQuery(
          ['getCellDataById', cellDataById],
          getCells,
          {
            staleTime: ms('10 minutes'),
          }
        )
        setRowData(responseCellIdData.cells)
        handleQueryUpdate({ type: 'cellId' })
      } catch (err) {
        showError('No cells matched your query')
        setErrorMessage('No cells matched your query')
      } finally {
        setLoading(false)
      }
    })()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cellId])

  const highlightCell = messagesEqual(rowData[0]?.cell.getCellId(), selectedCell?.cell.getCellId())

  return (
    <Box data-testid="cellBrowsingCellId">
      {loading || !errorMessage ? (
        <CellImageControl
          width={IMAGE_SIZE}
          cell={rowData[0]}
          onCellImageAction={handleQueryUpdate}
          displayOptions={searchOptions}
          highlighted={highlightCell}
        />
      ) : (
        <Typography sx={{ textAlign: 'center', mt: 2 }}>{errorMessage}</Typography>
      )}
    </Box>
  )
}

export default CellBrowsingByCellId
