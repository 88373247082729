import { Box, SelectChangeEvent, Stack, Typography } from '@mui/material'
import DeepcellDatePicker from 'components/shared/DeepcellDatePicker'
import DeepcellPrimarySelect from 'components/shared/DeepcellPrimarySelect'
import useRunSearchFilterSlice from 'redux/slices/hooks/useRunSearchFilterSlice'
import { DateOperator, RunSearchFilterState } from 'redux/slices/RunSearchFilterSlice'
import { enumToKeys } from 'utils/helpers'

export const RunSearchDateSelector = (): JSX.Element => {
    const { runSearchFilter, update } = useRunSearchFilterSlice()

    const selectHandleChange = (e: SelectChangeEvent<unknown>) => {
        const val = e.target.value as unknown

        const newRunSearchFilter = {
            ...runSearchFilter,
            runDateOperator: val === '' ? undefined : val,
        } as RunSearchFilterState

        if (!val) {
            newRunSearchFilter.runDateStart = undefined
            newRunSearchFilter.runDateEnd = undefined
        }
        update(newRunSearchFilter)
    }

    const dateHandleChange = (name: keyof RunSearchFilterState) => (date: Date) => {
        const newRunSearchFilter = {
            ...runSearchFilter,
            [name]: date.toISOString().slice(0, 10),
        } as RunSearchFilterState

        update(newRunSearchFilter)
    }

    const runDateOperatorKeys = enumToKeys(DateOperator)

    const dateOperatorIs = {
        between: `${runSearchFilter.runDateOperator}` === `${DateOperator.Between}`,
        before: `${runSearchFilter.runDateOperator}` === `${DateOperator.Before}`,
        on: `${runSearchFilter.runDateOperator}` === `${DateOperator.On}`,
    }

    const isDatePickerDisabled =
        !runSearchFilter.runDateOperator || !(runSearchFilter.runDateOperator >= 0)

    const RunSearchDateField = ({ name }: { name: keyof RunSearchFilterState }) => (
        <DeepcellDatePicker
            dataTestId="runSearchDateField"
            label=""
            value={runSearchFilter[name] as Date}
            onChange={dateHandleChange(name)}
            name={name}
            disabled={isDatePickerDisabled}
        />
    )

    return (
        <Stack spacing={2} direction="row" sx={{ alignItems: 'center' }}>
            <Box width="10rem">
                <DeepcellPrimarySelect
                    label="Run Date"
                    items={[
                        { value: '', output: '--' },
                        ...runDateOperatorKeys.map((s) => ({
                            key: s,
                            value: s,
                            output: DateOperator[+s],
                        })),
                    ]}
                    value={(runSearchFilter.runDateOperator as unknown as string) ?? ''}
                    onChange={selectHandleChange}
                    data-testid="runDateSelector"
                />
            </Box>
            {dateOperatorIs.before && <RunSearchDateField name="runDateEnd" />}
            {!dateOperatorIs.before && !dateOperatorIs.between && (
                <RunSearchDateField name="runDateStart" />
            )}
            {dateOperatorIs.between && (
                <>
                    <RunSearchDateField name="runDateStart" />
                    <Typography> and </Typography>
                    <RunSearchDateField name="runDateEnd" />
                </>
            )}
        </Stack>
    )
}

export default RunSearchDateSelector
