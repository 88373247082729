import { SAMPLE_DISTRIBUTION } from 'utils/constants'
import { GridApi } from 'ag-grid-community'
import { CellResponse } from 'utils/api'
import ScrollTop from 'components/shared/ScrollTop'
import useCellBrowsingQueryParams from '../useCellBrowsingQueryParams'
import CellBrowserTable from './CellBrowserTable'
import SampleDistributionTable from './SampleDistributionTable'

interface CellBrowsingTableViewProps {
  tableGridApi: GridApi | null
  setTableGridApi: React.Dispatch<React.SetStateAction<GridApi | null>>
  selectedCells: CellResponse[]
}

const CellBrowsingTableView = ({
  tableGridApi,
  setTableGridApi,
  selectedCells,
}: CellBrowsingTableViewProps): JSX.Element => {
  const { search } = useCellBrowsingQueryParams()

  return (
    <>
      <ScrollTop tableGridApi={tableGridApi} />
      {search.sortOrderKey !== SAMPLE_DISTRIBUTION ? (
        <CellBrowserTable
          searchOptions={search}
          setTableGridApi={setTableGridApi}
          selectedCells={selectedCells}
        />
      ) : null}
      {search.sortOrderKey === SAMPLE_DISTRIBUTION ? (
        <SampleDistributionTable setTableGridApi={setTableGridApi} selectedCells={selectedCells} />
      ) : null}
    </>
  )
}

export default CellBrowsingTableView
