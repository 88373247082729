import _ from 'lodash'
import RBush, { BBox } from 'rbush'
import {
  CellPlotDatum,
  FlattenedCellPlotData,
  ZoomThreshold,
  getDefaultFlattenedPlotData,
} from 'redux/slices'

// For more details about the development of this algorithm, please see this doc:
// https://deepcellbio.atlassian.net/l/cp/nDD1Vfou
//
// This algorithm provides a way to show representative images on the scatterplot
// in a way that tries to cover the space, without too much overlap
// And also to remain stable as the user zooms in and out
// i.e. the same points shown when you're zoomed out remain visible as you zoom in
// and additional points show up to fill in the space that opens up as you zoom in
//
// This file contains the precomputation step of the algorithm
// See the useZoomThresholdSampleImages hook for how this gets used at runtime to
// lookup the actual points to show as a user interacts with the plot.

// Parameters for setting up zoom levels
// Each zoom level has a pixel ratio that's ZOOM_THRESHOLD_RATIO of the previous level
const ZOOM_THRESHOLD_RATIO = 1 / Math.sqrt(2)
// We'll precompute this many zoom levels
const NUM_THRESHOLDS = 14
// Minimum plot pixel width to compute thresholds for
const MIN_PLOT_WIDTH_IN_PIXELS = 200
/** This function precomputes the images to show at each zoom threshold.
 *
 * Each zoom threshold is for a specific pixel ratio.  And we keep an array
 * e.g. The array could be for pixel ratios of 1, 1 / sqrt(2), 0.5, ...
 *
 * When we display images, we use the zoom threshold that has a pixel ratio that is
 * greater than the current pixel ratio but as close as possible.
 *
 * @TODO consider if it might help to not make this algorithm depend on plotPixelWidth
 */
export function precomputeZoomThresholds(
  allPoints: CellPlotDatum[],
  dataWidth: number, // Width of points on the x-axis in UMAP coordinates
  imageSize: number, // Desired image size in screen pixels
  spacingAdjust: number // Scaling factor to adjust spacing.  Higher means more spacing. 1 means lay out images so they are imageSize pixels apart.
): ZoomThreshold[] {
  // Get maximum pixel size to consider = (plot width in UMAP coordinates) / (screen width in pixels)
  const maxPixelWidthInPlotCoords = dataWidth / MIN_PLOT_WIDTH_IN_PIXELS

  // Compute thresholds!
  const prevSampledPoints = new RBush<BBox>()
  const thresholds = [...Array(NUM_THRESHOLDS)]
    .map(
      (__, i) =>
        ({
          pixelWidthInPlotCoords: maxPixelWidthInPlotCoords * ZOOM_THRESHOLD_RATIO ** i,
        } as ZoomThreshold)
    )
    .reduce((prevZoomThresholds: ZoomThreshold[], curZoomThreshold: ZoomThreshold) => {
      const points = getDefaultFlattenedPlotData()

      const { pixelWidthInPlotCoords } = curZoomThreshold

      // Minimum distance between points to include, in UMAP coordinate space
      const minDist = pixelWidthInPlotCoords * imageSize * spacingAdjust

      // Add all previous points
      if (prevZoomThresholds.length > 0) {
        const lastPoints = prevZoomThresholds[prevZoomThresholds.length - 1].points
        if (lastPoints !== undefined) {
          ;(Object.keys(lastPoints) as Array<keyof FlattenedCellPlotData>).forEach((key) => {
            // @ts-ignore - TS doesn't know that lastPoints[key] is the same type as points[key]
            points[key] = [...lastPoints[key]]
          })
        }
      }

      // Go through points in random order
      // Note that we go through points that have already been added at the moment
      // But they'll fail the hasOverlap check pretty quickly
      const shuffledPoints = _.shuffle(allPoints)

      shuffledPoints.forEach((p) => {
        const { x, y, cellId } = p

        // If there's bad data, it breaks RBush's collission algorithm
        // and RBush.collides starts returning false all the time...so let's filter this out
        if (Number.isNaN(x) || Number.isNaN(y)) return

        // Check if any of the previously sampled points is within minDist
        // of point p in any direction.  If so, we have an overlap!
        const pBoundingBox = {
          minX: x - minDist,
          maxX: x + minDist,
          minY: y - minDist,
          maxY: y + minDist,
        }
        const hasOverlap = prevSampledPoints.collides(pBoundingBox)

        // If we don't have an overlap, show the point!
        if (!hasOverlap) {
          if (points && cellId) {
            points.x.push(x)
            points.y.push(y)
            points.cellIds.push(`${cellId}`)
          }
          prevSampledPoints.insert({
            minX: x,
            minY: y,
            maxX: x,
            maxY: y,
          })
        }
      })

      return [...prevZoomThresholds, { ...curZoomThreshold, points } as ZoomThreshold]
    }, [] as ZoomThreshold[])

  return thresholds
}

export default precomputeZoomThresholds
