import * as ENVIRONMENTS from 'constants/environments'
import { Box, Typography, useTheme } from '@mui/material'
import { TopNavMenu } from 'components/navigationTopBar'
import useFlagCondition from 'components/shared/useFlagCondition'
import { isExternalDemo } from 'config'
import { useCellVisualizationsSlice } from 'redux/slices/hooks/useCellVisualizationsSlice'
import useCellVisualizationUrlParams from '../useCellVisualizationUrlParams'
import { NavTopBarRightSideButtons } from './NavTopBarRightSideButtons'
import TopBarBase from './TopBarBase'

const ToolbarBox = ({ children }: React.PropsWithChildren<unknown>) => (
  <Box sx={{ display: 'flex', alignItems: 'center' }}>{children}</Box>
)

export const CellVisualizationNavTopBar = (): JSX.Element => {
  const demoEnabled = useFlagCondition('demoEnabled')
  const { sessionId, isPreFilter } = useCellVisualizationUrlParams()
  const theme = useTheme()

  let appTitle: string
  let backgroundColor: string

  if (process.env.REACT_APP_ENV === ENVIRONMENTS.PRODUCTION) {
    appTitle = 'Axon Data Suite'
    backgroundColor = theme.palette.text.primary
  } else if (process.env.REACT_APP_ENV === ENVIRONMENTS.STAGING) {
    appTitle = 'Axon Data Suite - Staging'
    backgroundColor = theme.palette.secondary.main
  } else {
    let suffix = 'Local'
    if (process.env.REACT_APP_ENV) {
      suffix = `${process.env.REACT_APP_ENV}`
    }
    appTitle = `Axon Data Suite - Development - ${suffix}`
    backgroundColor = theme.palette.info.main
  }

  let mainText = 'Cell Visualization'
  if (isExternalDemo) {
    mainText = ''
  } else if (!sessionId && !demoEnabled) {
    mainText = 'Cell Visualization Saved Sessions'
  }

  const {
    cellVisualizations: { name },
  } = useCellVisualizationsSlice()

  return (
    <TopBarBase
      backgroundColor={backgroundColor}
      toolbarProps={{ sx: { justifyContent: 'space-between' } }}
    >
      <ToolbarBox>
        {!isExternalDemo && <TopNavMenu />}
        <img
          alt=""
          src="/images/Deepcell-Icon-White.png"
          width="15px"
          style={{ marginRight: '15px' }}
        />
        <Typography variant="h6" noWrap width="100%">
          {appTitle} / {mainText}
        </Typography>
      </ToolbarBox>
      {sessionId && !demoEnabled ? (
        <Typography variant="h6" noWrap>
          {name ?? 'Untitled Session'}
        </Typography>
      ) : null}
      {isPreFilter ? null : <NavTopBarRightSideButtons />}
    </TopBarBase>
  )
}

export default CellVisualizationNavTopBar
