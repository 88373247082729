import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'
import { RootState } from 'redux/store'
import { CellData } from '../../utils/hdf5Utils'

const visualizationAdapter = createEntityAdapter()

const initialState = visualizationAdapter.getInitialState()

const visualizationSlice = createSlice({
    name: 'visualization',
    initialState,
    reducers: {},
})

// Selectors
const selectors = visualizationAdapter.getSelectors((state: RootState) => state.visualization)
export const { selectById: selectCellById, selectTotal: selectTotalCells } = selectors
export const selectAllCells = (state: RootState): CellData => {
    const cells = selectors.selectAll(state) as Record<string, unknown>[]
    return new CellData(cells)
}

// Action creators
export const VisualizationActions = visualizationSlice.actions

// Reducers
export const VisualizationReducer = visualizationSlice.reducer
