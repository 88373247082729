import { PinnedCellGroup } from 'redux/slices'

export const translatePinnedCellGroupSelection = (pcg: PinnedCellGroup): PinnedCellGroup => {
  const { points } = pcg.cells

  if (points) {
    const { x, originalX, y, originalY } = points[0]

    if (
      typeof originalX === 'number' &&
      typeof originalY === 'number' &&
      typeof x === 'number' &&
      typeof y === 'number'
    ) {
      const xOffset = x - originalX
      const yOffset = originalY - y

      const { lassoPoints, range } = pcg.cells
      const selection = lassoPoints ?? range ?? { x: [], y: [] }
      let selectionName: 'lassoPoints' | 'range' | '' = ''
      if (lassoPoints) selectionName = 'lassoPoints'
      if (range) selectionName = 'range'

      if (selectionName)
        return {
          ...pcg,
          cells: {
            ...pcg.cells,
            [selectionName]: {
              x: selection.x.map((coord) => coord - xOffset),
              y: selection.y.map((coord) => coord + yOffset),
            },
          },
        } as PinnedCellGroup
    }
  }

  return pcg
}

export default translatePinnedCellGroupSelection
