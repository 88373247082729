import { isExternalDemo, isProduction } from 'config'

// Sesssion ids currently in use for the four datasets on external demo
const externalDemoSessionIds = [ '17', '18', '9', '276', '639'  ]

// Session ids for the four datasets on staging and local development
const nonDemoSessionIds = [ '802', '988', '1200', '1467', '1545' ]

// Any historical session ids that may still be in use in the demo and should be marked read-only
// (e.g. due to having different deployment times)
const historicalDemoSessionIds = [ '12', '13' ]

// On production, this list is used to prevent accidentally overwriting the demo dataset
// @TODO Have a more robust & usable way to handle this workflow later on (e.g. locking sessions, e.g. more types of sharing)
export const readOnlySessionIds = (isProduction || isExternalDemo) ? 
    [ ...externalDemoSessionIds, ...historicalDemoSessionIds] : nonDemoSessionIds

export const datasetItems = [
    {
        label: 'Identification of cancer cells',
        id: isExternalDemo ? externalDemoSessionIds[0] : nonDemoSessionIds[0],
        description:
            'Using human melanoma, this dataset highlights the REM-I’s platform capability to identify distinct cell morphologies among cells from cell lines and primary tumor samples, revealing its potential to unlock a unique label-free, morphology-based approach to cancer cell identification.',
        species: 'Human',
        tissueType: 'Lymph Node (Metastasis)',
        sampleTypes: 'Dissociated Biopsy, Cell Lines (SK-MEL-1, SK-MEL-2, MNT-1)',
        numberOfSamples: '28',
        numberOfRuns: '81',
        totalCells:
            '2.12 million total cells analyzed / 28232 displayed for ease of visualization purposes',
        model: 'Deepcell Human Foundation Model',
        projectionType: 'Uniform Manifold Approximation and Projection (UMAP)',
        warning: 'Data was controlled for quality by removing debris and dead cells.',
        videoUrl: 'https://www.youtube.com/embed/LnFJmoRciHA?utm_campaign=Software%20Demo%201Q23&utm_source=Direct%20demo&utm_content=data%20set%201%20walk-through',    },
    {
        label: 'Exploration of cancer cell subpopulations',
        id: isExternalDemo ? externalDemoSessionIds[1] : nonDemoSessionIds[1],
        description:
            'Using the REM-I platform to isolate melanoma cells present in journey 1, this dataset reveals the heterogeneity within these cells based on subtle morphological distinctions, including pigmentation which can be difficult to identify using conventional methods.',
        species: 'Human',
        tissueType: 'Lymph Node (Metastasis)',
        sampleTypes: 'Dissociated Biopsy, Cell Lines',
        numberOfSamples: '8',
        numberOfRuns: '27',
        totalCells:
            '350,000 total cells analyzed / 18425 displayed for ease of visualization purposes',
        model: 'Deepcell Human Foundation Model',
        projectionType: 'Uniform Manifold Approximation and Projection (UMAP)',
        warning: 'Data was controlled for quality by removing debris and dead cells.',
        videoUrl: 'https://www.youtube.com/embed/ifebTTD9S4k?utm_campaign=Software%20Demo%201Q23&utm_source=Direct%20demo&utm_content=data%20set%202%20walk-through',
    },
    {
        label: 'Investigation of immune cell diversity in tumor microenvironment',
        id: isExternalDemo ? externalDemoSessionIds[2] : nonDemoSessionIds[2],
        description:
            'This dataset allows you to explore the morphological diversity of immune cell populations in the lung tumor microenvironment from a variety of human dissociated tumor cell (DTC) samples.',
        species: 'Human',
        tissueType: 'Lung',
        sampleTypes: 'Dissociated Biopsy, Dissociated Healthy Tissue',
        numberOfSamples: '11',
        numberOfRuns: '32',
        totalCells:
            '1.1 million total cells analyzed / 17203 displayed for ease of visualization purposes',
        model: 'Deepcell Human Foundation Model',
        projectionType: 'Uniform Manifold Approximation and Projection (UMAP)',
        warning: 'Data was controlled for quality by removing debris and dead cells.',
        videoUrl: 'https://www.youtube.com/embed/ugNncd7lGlA?utm_campaign=Software%20Demo%201Q23&utm_source=Direct%20demo&utm_content=data%20set%203%20walk-through',
    },
    {
        label: 'Imaging of cell death pathways in Jurkat cells',
        id: isExternalDemo ? externalDemoSessionIds[3] : nonDemoSessionIds[3],
        description:
            'This dataset illustrates the morphological changes occurring along different cell death pathways in Jurkat E6-1 cells (T Lymphoblast) treated with various cytotoxic methods (Camptothecin, Staurosporine, Heat).',
        species: 'Human',
        tissueType: 'Lymphoblast',
        sampleTypes: 'Cell Lines - Jurkat E6-1',
        numberOfSamples: '11',
        numberOfRuns: '4',
        totalCells:
            '~1 million total cells analyzed / 24902 displayed for ease of visualization purposes',
        model: 'Deepcell Human Foundation Model',
        projectionType: 'Uniform Manifold Approximation and Projection (UMAP)',
        warning: 'Data was controlled for quality by removing debris and dead cells.',
        videoUrl: 'https://www.youtube.com/embed/ZDbsRVzQ1gQ?utm_campaign=Software%20Demo%201Q23&utm_source=Direct%20demo&utm_content=data%20set%204%20walk-through',
    },
    {
        label: 'Morphological profiling of Human Adult Stem Cells',
        id: isExternalDemo ? externalDemoSessionIds[4] : nonDemoSessionIds[4],
        description:
            'This dataset explores the morphologies of different types of adult stem/progenitor cells derived from various tissues of origin (adipose tissue, bone marrow, and cord blood.)',
        species: 'Human',
        tissueType: 'Bone Marrow, Cord Blood, Lipoaspirates',
        sampleTypes: 'Isolated stem cells from tissue',
        numberOfSamples: '5',
        numberOfRuns: '33',
        totalCells:
            '1.58 million total cells analyzed / 19,118 displayed for ease of visualization purposes',
        model: 'Deepcell Human Foundation Model',
        projectionType: 'Uniform Manifold Approximation and Projection (UMAP)',
        warning: 'Data was controlled for quality by removing debris and dead cells.',
        videoUrl: '',
    },
] as const
export type DatasetItem = typeof datasetItems[number]
