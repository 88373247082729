import { Typography, styled } from '@mui/material'
import {
  GridApi,
  GridReadyEvent,
  GridSizeChangedEvent,
  RowClassParams,
  SelectionChangedEvent,
} from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { useCallback } from 'react'
import { messagesEqual } from 'utils/proto-utils'
import useCellBrowsingSlice from 'redux/slices/hooks/useCellBrowsingSlice'
import { CellResponse } from 'utils/api'
import { getCellTableColumnDefs, getCellTableRowData } from './metadata'
import useSampleDistribution from '../useSampleDistribution'

const TableContainer = styled('div')({
  width: '100%',
  height: '100%',
})

const headerHeight = 40
const rowGap = 10
const rowHeight = 50

const rowStyle = {
  boxSizing: 'border-box',
  padding: '0 15px',
  borderRadius: '4px',
  background: '#FFF',
  boxShadow:
    '0px 1px 12px 0px rgba(0, 0, 0, 0.12), 0px 4px 8px 0px rgba(0, 0, 0, 0.02), 0px 1px 4px 0px rgba(0, 0, 0, 0.01)',
}

interface Props {
  selectedCells: CellResponse[]
  setTableGridApi: React.Dispatch<React.SetStateAction<GridApi | null>>
}

const SampleDistributionTable = ({ selectedCells, setTableGridApi }: Props): JSX.Element => {
  const { setSelectedCells } = useCellBrowsingSlice()

  const { data: rowData } = useSampleDistribution()

  const rowLength = rowData?.length
  const containerHeight = rowLength * rowHeight + (rowLength - 1) * rowGap + 20 + headerHeight + 24

  const onGridReady = (params: GridReadyEvent) => {
    params.api.sizeColumnsToFit()
    if (params.api) {
      setTableGridApi(params.api)
    }

    if (selectedCells.length) {
      window.setTimeout(() => {
        params.api.forEachNode((node) => {
          const isSelected =
            selectedCells.findIndex((cell) => {
              const cellId = cell.cell.getCellId()
              return messagesEqual(cellId, node.data.cell_proto.cell.getCellId())
            }) > -1
          node.setSelected(isSelected)
        })
      }, 1000)
    }
  }

  const onSelectionChanged = useCallback(
    (event: SelectionChangedEvent) => {
      const rowsNode = event.api.getSelectedNodes()
      const rowSelected = rowsNode.map((rowNode) => rowNode.data.cell_proto)
      setSelectedCells(rowSelected)
    },
    [setSelectedCells]
  )

  return (
    <>
      {rowData.length === 0 ? (
        <Typography sx={{ textAlign: 'center', mt: 2 }}>No cells matched your query</Typography>
      ) : (
        <TableContainer
          className={`ag-theme-v3-browsing-table context-menu-ag-grid ${
            selectedCells.length ? 'no-root-padding' : ''
          }`}
          style={{ height: `${containerHeight}px`, width: '100%' }}
          data-testid="sample-distribution-table"
        >
          <AgGridReact
            onGridSizeChanged={(params: GridSizeChangedEvent) => params.api.sizeColumnsToFit()}
            rowStyle={rowStyle}
            rowSelection="multiple"
            headerHeight={headerHeight}
            onGridReady={onGridReady}
            rowData={getCellTableRowData(rowData)}
            columnDefs={getCellTableColumnDefs}
            cacheBlockSize={50}
            blockLoadDebounceMillis={3000}
            suppressDragLeaveHidesColumns
            onSelectionChanged={onSelectionChanged}
            getRowStyle={(params: RowClassParams) => {
              const { rowIndex } = params.node
              return {
                marginTop:
                  rowIndex === null || rowIndex === undefined ? '0' : `${10 * (rowIndex + 1)}px`,
                marginLeft: '10px',
              }
            }}
          />
        </TableContainer>
      )}
    </>
  )
}

export default SampleDistributionTable
