import SellIcon from '@mui/icons-material/Sell'
import { Box, styled } from '@mui/material'
import { ICellRendererParams } from 'ag-grid-community'

type BorderCellComponentProps = {
  rightBorder?: boolean
  leftBorder?: boolean
}

const StyledCustomCellComponent = styled(Box, {
  shouldForwardProp: (props) => props !== 'rightBorder' && props !== 'leftBorder',
})<BorderCellComponentProps>(({ rightBorder, leftBorder }) => ({
  height: '20px',
  ...(rightBorder && {
    borderRight: '1px solid rgba(0, 0, 0, 0.1)',
  }),
  ...(leftBorder && {
    borderLeft: '1px solid rgba(0, 0, 0, 0.1)',
  }),
}))

const StatusCellComponent = (props: Partial<ICellRendererParams>): JSX.Element => {
  const {
    valueFormatted,
    colDef: { cellRendererParams: { rightBorder = false, leftBorder = false } = {} } = {},
  } = props

  if (!valueFormatted) {
    return (
      <StyledCustomCellComponent rightBorder={rightBorder} leftBorder={leftBorder}>
        <></>
      </StyledCustomCellComponent>
    )
  }

  const iconColor = {
    Good: '#14D7275E',
    Fair: '#FDB807BF',
    Poor: '#D7143780',
  }
  const cellValue = valueFormatted * 100
  let status: string
  if (cellValue >= 0 && cellValue <= 20) status = 'Good'
  else if (cellValue >= 21 && cellValue <= 50) status = 'Fair'
  else status = 'Poor'
  return (
    <StyledCustomCellComponent rightBorder={rightBorder} leftBorder={leftBorder}>
      <span style={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <SellIcon sx={{ color: iconColor[status as keyof typeof iconColor] }} fontSize="small" />{' '}
        {status}
      </span>
    </StyledCustomCellComponent>
  )
}
export default StatusCellComponent
