import { useWindowSize } from 'react-use'
import { useElementById } from 'utils/useElementById'
import useFooterHeight from 'utils/useFooterHeight'
import { cellVisualizationsHeaderId } from '../header'

/**
 * Returns dimensions for panels that are to be overlayed over scatterplot
 * @param marginSpace The gap between the top and bottom of the panel and the head and footer. Defaults to 10px
 * @returns
 */
const usePanelHeightLogic = (marginSpace = 10) => {
  const { rect: cellVizHeader } = useElementById(cellVisualizationsHeaderId)
  const { height: windowHeight } = useWindowSize()
  const headerHeight = (cellVizHeader?.bottom ?? 0) + marginSpace
  const footerHeight = useFooterHeight() + marginSpace

  const height = windowHeight - headerHeight - footerHeight
  const heightCSS = `${height}px`
  const top = headerHeight
  const topCSS = `${top}px`

  return {
    /**
     * Height as a number in pixels
     */
    height,
    /**
     * The `height` property value but with `px` appended at the end
     */
    heightCSS,
    /**
     * The top of the panel as determined by 10 pixels below the cellViz header
     */
    top,
    /**
     * The `top` property value but with `px` appended at the end
     */
    topCSS,
  }
}

/**
 * Returns the height and top of the sliding panels
 */
export const usePanelHeight = (marginSpace = 10): ReturnType<typeof usePanelHeightLogic> => {
  return usePanelHeightLogic(marginSpace)
}

export default usePanelHeight
