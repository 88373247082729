import { SimpleSelectItem } from 'components/shared'
import { Run } from 'utils/api'

// Image sizes in pixels on screen
export const SAMPLE_SIZE_OPTIONS: SimpleSelectItem[] = [
  { output: '10,000', value: 10000 },
  { output: '25,000', value: 25000 },
  { output: '50,000', value: 50000 },
  { output: '100,000', value: 100000 },
]

export const DEFAULT_SAMPLE_SIZE = 25000

/**
 * Returns total cell count from a list of runs
 * @TODO This may not always be correct, since the Run Report is sometimes wrong
 *
 * @param runs A list of runs to be used to start the session
 * @returns Total cell count in the runs (at least as reported by the Run Report)
 */
export const totalCellCountInRuns = (runs: Run[]): number => {
  return runs.reduce((acc, run) => acc + run.total_cell_count || 0, 0)
}

export const getAvailableSampleSizeOptions = (totalCellCount: number): SimpleSelectItem[] => {
  const availableSampleSizes = SAMPLE_SIZE_OPTIONS.filter(
    (option) => (option.value as number) < totalCellCount
  )
  if (totalCellCount > 100000) {
    return availableSampleSizes
  }
  // Add the total cell count as an option and then sort the options by value
  return [
    { output: `${totalCellCount.toLocaleString()} (keep all cells)`, value: totalCellCount },
    ...availableSampleSizes,
  ].sort((a, b) => (a.value as number) - (b.value as number))
}

/**
 * Gets the option closest to the DEFAULT_SAMPLE_SIZE but not over
 */
export const getDefaultOption = (sampleSizeOptions: SimpleSelectItem[]): SimpleSelectItem => {
  const validOptions = sampleSizeOptions.filter(
    (option) => (option.value as number) <= DEFAULT_SAMPLE_SIZE
  )
  return validOptions[validOptions.length - 1]
}

/**
 * A crude estimate for session creation time based on load testing restuls
 * https://deepcellbio.atlassian.net/l/cp/Jg80Rjq6
 *
 * Update this estimate if the session creation time changes significantly
 *
 * @param runs A list of runs to be used to start the session
 * @param sampleSize The number of cells to be used in the session
 * @param creationTimeCoefficients Contains coefficients a,b,c,d in a comma-separated string
 *   for the equation a * total_cell_count + b + c * sample_size + d * sample_size^2 = estimated creation time
 */
export const estimateCreationTime = (
  totalCellCount: number,
  sampleSize: number,
  creationTimeCoefficients: string
): string => {
  const coefficients = creationTimeCoefficients.split(',').map((x) => parseFloat(x))
  const actualSampleSize = Math.min(totalCellCount, sampleSize)

  // The datatable load time is roughly linear
  //
  // And the time seems to grow nonlinearly for projection model training.
  // Use the best fit quadratic from Google Spreadsheets for now
  const totalTime =
    totalCellCount * coefficients[0] +
    coefficients[1] +
    coefficients[2] * actualSampleSize +
    coefficients[3] * actualSampleSize ** 2

  // Approximate the output
  // (Also considered using packages like humanize-duration or pretty-ms, but it ssems like a waste
  // to pull in a whole package for this one use case)
  //
  // Will just make something up for now -- with the goal of getting the estimate close
  // But usually giving the user a slightly largeer estimate than the actual session start time
  if (totalTime < 50) {
    // return closest 10s of seconds that's more than totalTime
    return `${Math.ceil(totalTime / 10) * 10} seconds`
  }
  // return closest half a minute that's more than totalTime
  const timeInMinutes = Math.ceil(totalTime / 30) / 2
  return `${timeInMinutes} minute${timeInMinutes > 1 ? 's' : ''}`
}
