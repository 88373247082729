import { Box, Stack, ToggleButtonGroup, Tooltip } from '@mui/material'
import BoxSelectIcon from 'components/shared/icons/BoxSelectIcon'
import DeepcellPrimaryIconButton from 'components/shared/DeepcellPrimaryIconButton'
import DeepcellToggleButton from 'components/shared/DeepcellToggleButton'
import CameraIcon from 'components/shared/icons/CameraIcon'
import LassoIcon from 'components/shared/icons/LassoIcon'
import PanIcon from 'components/shared/icons/PanIcon'
import ScaleIcon from 'components/shared/icons/ScaleIcon'
import { useHotkeys } from 'react-hotkeys-hook'
import { ModebarAction, ModebarDragAction, modebarDragActions } from 'redux/slices'
import { usePlotlySlice } from 'redux/slices/hooks'
import { useCellVisualizationsSlice } from 'redux/slices/hooks/useCellVisualizationsSlice'

export const usePlotlyModebar = (): {
  dragMode: ModebarDragAction
  triggerModebarButton: (action: ModebarAction) => void
} => {
  const {
    setDragMode,
    plotly: { dragMode },
  } = usePlotlySlice()

  const triggerModebarButton = (action: ModebarAction) => {
    if (!action) return

    const anchorElement = document.querySelector(`[data-title="${action}"]`) as HTMLAnchorElement
    anchorElement?.click()

    const dragAction = action as ModebarDragAction
    if (modebarDragActions.includes(dragAction)) {
      setDragMode(dragAction)
    }
  }

  return { dragMode, triggerModebarButton }
}

export function ToolButtons(): JSX.Element {
  const {
    cellVisualizations: { cellsData },
  } = useCellVisualizationsSlice()

  const { dragMode, triggerModebarButton } = usePlotlyModebar()

  useHotkeys('shift+B, b', () => triggerModebarButton('Box Select'))
  useHotkeys('shift+L, l', () => triggerModebarButton('Lasso Select'))
  useHotkeys('shift+P, p', () => triggerModebarButton('Pan'))
  useHotkeys('shift+R, r', () => triggerModebarButton('Autoscale'))

  return (
    <Box>
      <Stack id="dataVizToolButtons" direction="row" spacing={1}>
        <ToggleButtonGroup
          exclusive
          value={dragMode}
          disabled={cellsData === undefined}
          onChange={(_event, value: ModebarDragAction) => triggerModebarButton(value)}
          sx={{ border: '1px solid rgba(95, 85, 209, 0.5)' }}
        >
          <DeepcellToggleButton
            className="dataVizCellSelection"
            size="small"
            value="Box Select"
            data-testid="box-select-icon"
          >
            <Tooltip title="Box Selection (B)">
              <BoxSelectIcon />
            </Tooltip>
          </DeepcellToggleButton>
          <DeepcellToggleButton className="dataVizCellSelection" size="small" value="Lasso Select">
            <Tooltip title="Lasso Selection (L)">
              <LassoIcon />
            </Tooltip>
          </DeepcellToggleButton>
          <DeepcellToggleButton size="small" value="Pan">
            <Tooltip title="Pan (P)">
              <PanIcon />
            </Tooltip>
          </DeepcellToggleButton>
        </ToggleButtonGroup>
        <DeepcellPrimaryIconButton
          id="dataVizResetView"
          onClick={() => triggerModebarButton('Autoscale')}
          size="small"
          outlined
          icon={
            <Tooltip title="Reset View (R)">
              <ScaleIcon />
            </Tooltip>
          }
          disabled={cellsData === undefined}
        />
        <DeepcellPrimaryIconButton
          id="dataVizSnapshot"
          onClick={() => triggerModebarButton('Download plot as a png')}
          size="small"
          outlined
          icon={
            <Tooltip title="Save Image">
              <CameraIcon />
            </Tooltip>
          }
          disabled={cellsData === undefined}
        />
      </Stack>
    </Box>
  )
}

export default ToolButtons
