import { CellId } from '@deepcell/dc_core_proto/deepcell_schema2_pb'
import { Skeleton, Stack } from '@mui/material'
import { ICellRendererParams } from 'ag-grid-community'
import { ReactElement } from 'react'
import useCellImage from 'utils/useCellImage'
import { getFilters } from 'components/shared/CellImageControl'
import useCellBrowsingQueryParams from '../useCellBrowsingQueryParams'

const CellImageComponent = (props: Partial<ICellRendererParams>): ReactElement => {
  const { value: cellProto } = props
  const { displayOptions } = useCellBrowsingQueryParams()

  const cellId = cellProto?.cell?.getCellId() || new CellId()
  const { data: imageDataUrl } = useCellImage({
    cellId,
    frame: 0,
    width: 40,
    filters: getFilters(displayOptions),
  })

  return (
    <Stack direction="row" alignItems="center">
      {imageDataUrl ? (
        <img width="40px" height="40px" src={imageDataUrl} alt={cellId.toString()} />
      ) : (
        <Skeleton variant="rectangular" width={40} height={40} style={{ borderRadius: '3.5px' }} />
      )}
    </Stack>
  )
}

export default CellImageComponent
