import { Box, IconButton, Stack, styled, Switch, Tooltip, Typography } from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel'
import React from 'react'
import GridViewIcon from 'components/shared/icons/GridViewIcon'
import TableViewIcon from 'components/shared/icons/TableViewIcon'
import { DeepcellPrimarySelect } from 'components/shared'
import useCellBrowsingSlice from 'redux/slices/hooks/useCellBrowsingSlice'
import PlusIcon from 'components/shared/icons/PlusIcon'
import MinusIcon from 'components/shared/icons/MinusIcon'
import { CellDisplayOptions } from '../shared/CellImageControl'
import { SortOrderKey, SortOrders } from './sort-orders'
import QuadrantSlider from '../shared/QuadrantSlide'
import { SearchDispatchAction, SearchOptions, ViewModeType } from './types'

const SearchOptionsForm = styled('form')({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  justifyContent: 'space-between',
})

const IconSx = (active: boolean) => ({
  width: '36px',
  height: '36px',
  cursor: 'pointer',
  rect: { fill: active ? '#a1eabc' : 'inherit' },
  '&:hover > rect': { fill: '#a1eabc' },
})

interface Props {
  searchOptions: SearchOptions
  displayOptions: CellDisplayOptions
  handleSearchOptionAction: (action: SearchDispatchAction) => void
  viewMode: ViewModeType
  setViewMode: (value: ViewModeType) => void
  handleQueryUpdate: (action: SearchDispatchAction) => void
}

export const MIN_CELL_IMAGES = 50

const StyledSwitch = styled(Switch)({
  padding: 8,
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    '&::before, &::after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2,
  },
})

/** Component that manages cell search options for the parent CellBrowsingPage */
function CellSearchOptions(props: Props): JSX.Element {
  const {
    searchOptions,
    displayOptions,
    handleSearchOptionAction,
    viewMode,
    setViewMode,
    handleQueryUpdate,
  } = props

  const {
    cellBrowsing: { rowData },
  } = useCellBrowsingSlice()

  const cellCount = rowData.length

  const showPagination = !!(cellCount && cellCount >= MIN_CELL_IMAGES)

  return (
    <Box
      sx={{
        width: '100%',
        height: '56px',
        padding: '10px 30px 10px 24px',
        borderBottom: '1px solid #DEDEDE',
      }}
    >
      <SearchOptionsForm data-testid="search-options-form">
        <Stack direction="row" gap="16px" alignItems="center">
          <DeepcellPrimarySelect
            data-testid="sort-by"
            items={Object.entries(SortOrders).map((e) => ({
              key: e[0],
              value: e[0],
              output: e[1].label,
            }))}
            renderValue={(value) => {
              const optionSelected =
                Object.entries(SortOrders).find((item) => {
                  return item[0] === value
                }) || ''
              if (optionSelected) return `Sort By: ${optionSelected[1].label}`
              return ''
            }}
            formControlProps={{ sx: { width: '250px' } }}
            name="sort-order-key"
            value={searchOptions.sortOrderKey}
            onChange={(event) =>
              handleSearchOptionAction({
                type: 'sortOrderKey',
                value: event.target.value as SortOrderKey,
              })
            }
          />
          {showPagination && (
            <Tooltip title="Sharpen Images (X)" placement='top'>
              <FormControlLabel
                control={
                  <StyledSwitch
                    data-testid="cellSearchOptionsSharpen"
                    checked={displayOptions?.sharpen}
                    onChange={(_event: React.ChangeEvent<HTMLInputElement>, _checked: boolean) =>
                      handleSearchOptionAction({
                        type: 'toggleSharpen',
                      })
                    }
                  />
                }
                sx={{ color: 'black', fontSize: '0.875rem', flexShrink: 0 }}
                label="Sharpen Images (X)"
                labelPlacement="start"
              />
            </Tooltip>
          )}
          <Stack direction="row" gap="10px" justifyContent="center" alignItems="center">
            <Stack>Zoom(Z)</Stack>
            <IconButton
              sx={{ p: 0 }}
              onClick={() => {
                handleQueryUpdate({
                  type: 'toggleCenterCrop',
                  value: true,
                })
              }}
            >
              <PlusIcon inheritViewBox sx={{ width: '36px', height: '36px' }} />
            </IconButton>
            <IconButton
              sx={{ p: 0 }}
              onClick={() => {
                handleQueryUpdate({
                  type: 'toggleCenterCrop',
                  value: false,
                })
              }}
            >
              <MinusIcon inheritViewBox sx={{ width: '36px', height: '36px' }} />
            </IconButton>
          </Stack>
          {searchOptions.sortOrderKey === 'SAMPLE_DISTRIBUTION' ? (
            <Stack>
              <QuadrantSlider searchOptions={searchOptions} handleQueryUpdate={handleQueryUpdate} />
            </Stack>
          ) : null}
        </Stack>
        <Stack alignItems="center" direction="row" gap="10px">
          {rowData.length ? (
            <Typography>
              {rowData.length} out of many cells
            </Typography>
          ) : null}
          <IconButton
            onClick={() => {
              setViewMode({ table: false, grid: true })
            }}
            sx={{ p: 0 }}
          >
            <GridViewIcon sx={IconSx(viewMode.grid)} />
          </IconButton>
          <IconButton
            onClick={() => {
              setViewMode({ grid: false, table: true })
            }}
            sx={{ p: 0 }}
          >
            <TableViewIcon sx={IconSx(viewMode.table)} />
          </IconButton>
        </Stack>
      </SearchOptionsForm>
    </Box>
  )
}

export default CellSearchOptions
