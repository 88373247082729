import React, { useState } from 'react'
import { DeepcellPrimaryButton } from 'components/shared'
import TrainClassifierDialog, { TrainClassifierDialogProps } from './TrainClassifierDialog'

export const TrainClassifierButton = (): JSX.Element => {
  const [trainClassifierProps, setTrainClassifierProps] = useState<TrainClassifierDialogProps>()

  const handleTrainClassifer = () => {
    setTrainClassifierProps({})
  }

  return (
    <>
      <DeepcellPrimaryButton outlined sx={{ width: '66%' }} onClick={handleTrainClassifer}>
        Train Classifier
      </DeepcellPrimaryButton>
      <TrainClassifierDialog
        {...trainClassifierProps}
        open={Boolean(trainClassifierProps)}
        onClose={() => setTrainClassifierProps(undefined)}
      />
    </>
  )
}

export default TrainClassifierButton
