import { DeepcellAutocomplete } from 'components/shared'
import { useEffect, useMemo, useState } from 'react'
import { CELL_DATA_FIELD_NONE, CELL_DATA_FIELD_PRE_FILTER, CellInfoGroupNone } from 'redux/slices'
import { useCellVisualizationsSlice } from 'redux/slices/hooks/useCellVisualizationsSlice'
import { DENSITY_ATTRIBUTE } from 'utils/constants'
import usePanelHeight from '../cellSelectionDrawer/usePanelHeight'
import useDataFieldsInDataset from '../useDataFieldsInDataset'

export const ColorByDropdown = ({ showPreFilter }: { showPreFilter?: boolean }): JSX.Element => {
  const { height: panelHeight } = usePanelHeight()

  const [open, setOpen] = useState(false)

  const {
    setShowColorBy,
    setSelectedCellInfoGroupName,
    setPlotDisplayType,
    cellVisualizations: {
      cellInfoGroups,
      selectedCellInfoGroupName,
      showColorBy,
      plotDisplayType,
      selectedComparisonDimensions,
    },
    hiddenCellInfoGroupValues,
  } = useCellVisualizationsSlice()
  const disableContinuous = plotDisplayType === DENSITY_ATTRIBUTE || selectedComparisonDimensions
  const showDisabledLabels = (plotDisplayType === DENSITY_ATTRIBUTE && showPreFilter) ? false : disableContinuous
  type GroupKey = keyof typeof cellInfoGroups

  const { dataFields } = useDataFieldsInDataset()

  const options = useMemo(() => {
    if (showPreFilter) {
      return [
        CELL_DATA_FIELD_NONE,
        CELL_DATA_FIELD_PRE_FILTER,
        ...dataFields
      ]
    }
    return [
      CELL_DATA_FIELD_NONE,
      ...dataFields
    ]

  }, [dataFields, showPreFilter])

  useEffect(() => {
    if (plotDisplayType === DENSITY_ATTRIBUTE && showPreFilter && showColorBy !== undefined) {
      setOpen(false)
      setShowColorBy(undefined)
      return
    }
    if (showColorBy !== undefined) {
      if (!showColorBy) {
        setOpen(false)
      } else if (!selectedCellInfoGroupName) {
        setOpen(true)
        document.getElementById('color-by')?.focus()
      }
      setShowColorBy(undefined)
    }
  }, [plotDisplayType, selectedCellInfoGroupName, setShowColorBy, showColorBy, showPreFilter])

  const byOptionAttribute = (attribute: string) => {
    return cellInfoGroups[attribute as GroupKey] ?? CellInfoGroupNone
  }

  const handleChange = (__: unknown, val: string) => {
    if (val === DENSITY_ATTRIBUTE && showPreFilter) {
      setPlotDisplayType(DENSITY_ATTRIBUTE)
      setSelectedCellInfoGroupName('')
      return;
    }
    if (showPreFilter && val !== DENSITY_ATTRIBUTE) {
      const group = val as keyof typeof cellInfoGroups
      setSelectedCellInfoGroupName(group)
      setPlotDisplayType('scatter')
      return;
    }
    const group = val as keyof typeof cellInfoGroups
    setSelectedCellInfoGroupName(group)
  }

  const getOptionLabel = (v: string) => {
    if (v === DENSITY_ATTRIBUTE && showPreFilter) {
      return 'Density'
    }
    const att = v as keyof typeof cellInfoGroups
    const { label } = byOptionAttribute(att)
    const numOfFilters = hiddenCellInfoGroupValues[att]?.length
    const suffix = numOfFilters ? ` (${numOfFilters})` : ''
    return `${label}${suffix}`
  }

  const attributes = options
    .map((x) => x.attribute)
    .filter((a) => a === '' || !!cellInfoGroups[a as keyof typeof cellInfoGroups] || (a === DENSITY_ATTRIBUTE && showPreFilter))
  const continuousAttributes = options.filter((x) => x.isContinuous).map((x) => x.attribute)

  useEffect(() => {
    // if selected filter is continuous then they change the plot type to "density", change the selected filter to "None"
    if (disableContinuous && continuousAttributes.includes(selectedCellInfoGroupName)) {
      setSelectedCellInfoGroupName('')
    }
  }, [
    continuousAttributes,
    disableContinuous,
    selectedCellInfoGroupName,
    setSelectedCellInfoGroupName,
  ])

  return (
    <DeepcellAutocomplete
      sx={{ width: '100%' }}
      inputProps={{ InputLabelProps: { shrink: true }, placeholder: 'Search for field' }}
      ListboxProps={{ sx: { maxHeight: `${panelHeight - 130}px` } }}
      disableClearable
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      label="Color By"
      id="color-by"
      options={attributes}
      // this usage of getOptionLabel and renderOption allows us to display the placeholder when the option is "" while also displaying "None" in the list
      getOptionLabel={(o) => (o ? getOptionLabel(o) : '')}
      renderOption={(props, o) => {
        return <li {...props}>{getOptionLabel(o)}</li>
      }}
      getOptionDisabled={(o) => (showDisabledLabels ? continuousAttributes.includes(o) : false)}
      groupBy={(o) => byOptionAttribute(o).category}
      value={(plotDisplayType === DENSITY_ATTRIBUTE && showPreFilter) ? DENSITY_ATTRIBUTE : selectedCellInfoGroupName}
      onChange={handleChange}
      multiple={false}
      blurOnSelect
      disabled={options.length === 0}
    />
  )
}

export default ColorByDropdown
