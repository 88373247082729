// Copy and paste this into your JavaScript code to initialize the Firebase SDK.
// You will also need to load the Firebase SDK.
// See https://firebase.google.com/docs/web/setup for more details.

const FIREBASE_CONFIG_DEV = {
    projectId: 'monocle-cloudfactory',
    appId: '1:976431089398:web:0755265999482ff2728ed7',
    storageBucket: 'monocle-cloudfactory.appspot.com',
    apiKey: 'AIzaSyBm1uTp8TS1n9HlabfUhhv0Sy8w_VDL7DA',
    authDomain: 'monocle-cloudfactory.firebaseapp.com',
    messagingSenderId: '976431089398',
}

export const DEV_BASE_URL =
    'https://api-cloudfactory-dot-dc-classifier.appspot.com'
export const AUTH0_CONFIG_CLOUD_DEV = {
    domain: 'deepcell-dev.us.auth0.com',
    clientId: '0tjOdWXvmIjvZcZYPlto7564TuALG9Yo',
}
export default FIREBASE_CONFIG_DEV
