import { createSvgIcon } from '@mui/material'

const CloseIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
    <path
      d="M14.25 3.75L3.75 14.25M14.25 14.25L3.75 3.75"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>,
  'Close'
)

export default CloseIcon
