import { PlotDatum, PlotMouseEvent, PlotSelectionEvent } from 'plotly.js'
import { CellPlotDatum } from 'redux/slices'
import { CellPlotData } from 'redux/slices/types'

type PlotlyPoints = { points: PlotDatum[] }
type CellPoints = { points: CellPlotDatum[] }

export type WithCellPoints<E> = Omit<E, 'points'> & CellPoints

/**
 * Takes the PlotEvent object and adds custom fields to the first point object.
 */
export const convertToDeepcellEvent = <E extends PlotlyPoints | undefined>(
  e: E
): WithCellPoints<E> => {
  return {
    ...e,
    points: e?.points.map((p) => {
      const data = p.data as CellPlotData
      const cellId = data.cellIds[p.pointIndex]
      const originalX = data.originalX[p.pointIndex]
      const originalY = data.originalY[p.pointIndex]
      return { ...p, x: Number(p.x), y: Number(p.y), cellId, originalX, originalY }
    }),
  }
}

export const convertToDeepcellPlotMouseEvent = (
  e: PlotMouseEvent
): WithCellPoints<PlotMouseEvent> => {
  return convertToDeepcellEvent(e)
}

export const convertToDeepcellPlotSelectionEvent = (
  e: PlotSelectionEvent
): WithCellPoints<PlotSelectionEvent> => {
  return convertToDeepcellEvent(e)
}

export default convertToDeepcellEvent
