import { Box } from '@mui/material'
import { QueryStatus } from 'react-query'
import { CellPlotDatum } from 'redux/slices/types'
import ActionPlotCellImage from '../ActionPlotCellImage'

interface ActionPlotCellImageWrapperProps
  extends Omit<Parameters<typeof ActionPlotCellImage>[0], 'cellId'> {
  cell: CellPlotDatum
  pinnedGroupId: number
}

const PlotCellImageWrapper = (props: ActionPlotCellImageWrapperProps) => {
  const {
    cell: { cellId },
  } = props
  return <ActionPlotCellImage {...props} cellId={cellId ?? ''} />
}

interface ActionPlotCellImagesProps {
  pinnedGroupId?: number
  cells?: CellPlotDatum[]
  imgProps?: Parameters<typeof ActionPlotCellImage>[0]['imgProps']
  handleCellStatusUpdate?(cell: CellPlotDatum, status?: QueryStatus): void
}
export const ActionPlotCellImages = ({
  pinnedGroupId = -1,
  cells,
  imgProps,
  handleCellStatusUpdate,
}: ActionPlotCellImagesProps): JSX.Element => {
  const handleCellStatusChange = (cell: CellPlotDatum) => (status?: QueryStatus) => {
    if (handleCellStatusUpdate) handleCellStatusUpdate(cell, status)
  }
  return (
    <Box sx={{ textAlign: 'center' }}>
      {cells?.map((cell, i) => {
        const key = `key${i}`
        return (
          <PlotCellImageWrapper
            pinnedGroupId={pinnedGroupId}
            key={key}
            cell={cell}
            handleStatusChange={handleCellStatusChange(cell)}
            imgProps={{ ...imgProps, style: { borderRadius: '10px', ...imgProps?.style } }}
          />
        )
      })}
    </Box>
  )
}

export default ActionPlotCellImages
