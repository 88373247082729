import { Close } from '@mui/icons-material'
import {
  Box,
  Checkbox,
  DialogContentText,
  Divider,
  FormControlLabel,
  FormGroup,
  IconButton,
  Stack,
  Typography,
} from '@mui/material'
import { CellVisualizationsSectionHeader } from 'components/cell-visualizations/shared'
import { DeepcellPrimaryButton } from 'components/shared'
import DeepcellDialog from 'components/shared/DeepcellDialog'
import _ from 'lodash'
import { useState } from 'react'
import { PinnedCellGroup } from 'redux/slices'
import { useCellVisualizationsSlice } from 'redux/slices/hooks/useCellVisualizationsSlice'
import useEventsManager from 'redux/slices/hooks/useEventsManager'

interface MergePinnedProps {
  onCloseClick: () => void
  defaultSelected?: PinnedCellGroup
}

export const MergePinned = ({ onCloseClick, defaultSelected }: MergePinnedProps): JSX.Element => {
  const {
    setPinnedCells,
    setMergedPinnedCells,
    setPinnedGroupHighlighted,
    cellVisualizations: { pinnedCells, mergedPinnedCells },
  } = useCellVisualizationsSlice()

  const [pinnedCellGroupsToMerge, setPinnedCellGroupsToMerge] = useState<PinnedCellGroup[]>(
    defaultSelected ? [defaultSelected] : []
  )
  const [openConfirmMerge, setOpenConfirmMerge] = useState(false)
  const eventsManager = useEventsManager()

  const close = () => {
    if (pinnedCells) {
      pinnedCells.forEach((pc) =>
        setPinnedGroupHighlighted({
          pinnedGroupId: pc.id,
          isHighlighted: false,
        })
      )
    }
    onCloseClick()
  }

  const handleCheckboxClicked =
    (pinnedCellGroup: PinnedCellGroup) => (__: unknown, checked: boolean) => {
      if (checked) {
        setPinnedGroupHighlighted({ pinnedGroupId: pinnedCellGroup.id })
        setPinnedCellGroupsToMerge([...pinnedCellGroupsToMerge, pinnedCellGroup])
      } else {
        setPinnedGroupHighlighted({
          pinnedGroupId: pinnedCellGroup.id,
          isHighlighted: false,
        })
        setPinnedCellGroupsToMerge([
          ...pinnedCellGroupsToMerge.filter((p) => p.id !== pinnedCellGroup.id),
        ])
      }
    }

  const handleMerge = () => {
    const idsToMerge = pinnedCellGroupsToMerge.map((p) => p.id)
    const name = pinnedCellGroupsToMerge.map((p) => p.name).join(' & ')

    const newPinnedCellGroups =
      pinnedCells?.map((pinnedCellGroup) => {
        if (!idsToMerge.includes(pinnedCellGroup.id)) return pinnedCellGroup

        // give merged cells the same id and name
        return { ...pinnedCellGroup, id: idsToMerge[0], name }
      }) ?? []

    setPinnedCells({ pinnedCells: newPinnedCellGroups, eventsManager })

    idsToMerge.forEach((pinnedGroupId) => {
      setPinnedGroupHighlighted({
        pinnedGroupId,
        isHighlighted: false,
      })
    })

    const mergedPoints = _.shuffle(
      _.uniqBy(
        newPinnedCellGroups
          ?.filter((x) => idsToMerge.includes(x.id))
          .flatMap((x) => x.cells.points ?? []),
        (p) => `${p.x}${p.y}`
      )
    )

    setMergedPinnedCells({
      pinnedCells: [
        ...(mergedPinnedCells?.filter((x) => {
          const differsFromNewGroup = x.id !== idsToMerge[0]
          const idExists = newPinnedCellGroups.map((y) => y.id).includes(x.id)

          return differsFromNewGroup && idExists
        }) ?? []),
        { id: idsToMerge[0], cells: { points: mergedPoints } },
      ],
      eventsManager,
    })

    setPinnedCellGroupsToMerge([])
    setOpenConfirmMerge(false)
    close()
  }

  // Only return the first of a pinnedCellGroup if there are multiples
  const mergedPinnedCellGroups = pinnedCells?.filter(
    (pcg, i) => pinnedCells.findIndex((x) => x.id === pcg.id) === i
  )

  return (
    <Stack spacing={2}>
      <Stack mx={1} direction="row" alignItems="center" justifyContent="space-between">
        <CellVisualizationsSectionHeader>Merge Pinned Images</CellVisualizationsSectionHeader>
        <DeepcellPrimaryButton
          small
          onClick={() => setOpenConfirmMerge(true)}
          disabled={pinnedCellGroupsToMerge.length < 2}
        >
          Merge
        </DeepcellPrimaryButton>
        <IconButton data-testid="mergedPinnedCloseButton" onClick={close}>
          <Close />
        </IconButton>
      </Stack>
      <Divider />
      <Box sx={{ flexGrow: 1, display: 'flex' }} p={1}>
        <Stack width="100%">
          {mergedPinnedCellGroups?.map((pinnedCellGroup) => {
            return (
              <FormGroup key={pinnedCellGroup.id}>
                <Stack key={pinnedCellGroup.id} direction="row" justifyContent="space-between">
                  <FormControlLabel
                    checked={pinnedCellGroupsToMerge.map((x) => x.id).includes(pinnedCellGroup.id)}
                    control={
                      <Checkbox
                        data-testid="pinnedImagesCheckbox"
                        onChange={handleCheckboxClicked(pinnedCellGroup)}
                      />
                    }
                    label={pinnedCellGroup.name}
                  />
                </Stack>
              </FormGroup>
            )
          })}
        </Stack>
      </Box>
      <DeepcellDialog
        data-testid={`mergedPinnedDialog_${openConfirmMerge ? 'true' : 'false'}`}
        open={openConfirmMerge}
        handleConfirm={handleMerge}
        handleCancel={() => setOpenConfirmMerge(false)}
        okLabel="Yes"
        cancelLabel="No"
        titleLabel="Confirm Merge"
      >
        <DialogContentText>
          <Typography mb={2}>
            Are you sure you want to merge the following pinned groups?
          </Typography>
          <Stack>
            {pinnedCellGroupsToMerge.map((p) => (
              <Typography key={p.id}>{`• ${p.name}`}</Typography>
            ))}
          </Stack>
        </DialogContentText>
      </DeepcellDialog>
    </Stack>
  )
}

export default MergePinned
