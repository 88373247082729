import { Figure } from 'react-plotly.js'
import { PlotRange } from '../tsv/types'

export const onPlotUpdate =
  (range: PlotRange, setRange: (payload?: PlotRange) => void) =>
  (figure: Readonly<Figure>, _graphDiv: Readonly<HTMLElement>): void => {
    /** This is necessary to setRange initially, once we have data and chose the initial axes ranges.
     * After this, we depedn on the relayout handler above to continue updating the range
     *
     * @TODO Figure out if there's also an updated viewable range in this event -- I couldn't find it.
     * If so, we could make some of transitions more smoothly in the middle of a zoom or pan interaction
     * (for example to resize images more frequently to keep them the same size in screen coordinate space)
     *
     * onRelayout only fires at the end of a zoom or pan. */
    if (range?.x1 !== undefined) {
      return
    }

    const xAxisRange = figure?.layout?.xaxis?.range
    const yAxisRange = figure?.layout?.yaxis?.range
    if (xAxisRange && yAxisRange && figure.data?.length > 0) {
      setRange({
        x1: xAxisRange[0],
        x2: xAxisRange[1],
        y1: yAxisRange[0],
        y2: yAxisRange[1],
      })
    }
  }

export default onPlotUpdate
