import { getNewUniqueName, getRandomId } from 'utils/helpers'
import { defaultPagination } from '../../../redux/slices/CellVisualizationsSlice'
import { useCellVisualizationsSlice } from '../../../redux/slices/hooks/useCellVisualizationsSlice'
import useEventsManager from '../../../redux/slices/hooks/useEventsManager'
import { PinnedCellGroup, PlotSelectionState } from '../../../redux/slices/types'

const useMethodsAndData = () => {
  const { addPrefilterRegion } = useEventsManager()
  const {
    cellVisualizations: { fileName, morphotypesToRemove },
    setMorphotypeToRemoveHighlight,
    setMorphotypesToRemove,
  } = useCellVisualizationsSlice()

  const onPrefilterSelected = (currentSelection?: PlotSelectionState) => {
    if (currentSelection) {
      /*
                 there's a bug?/feature? in plotly where if the layout.shapes
                 and/or layout.annotations are updated then the onSelected gets
                 triggered with e.points and e.selections as empty arrays
                */
      const { points, selections } = currentSelection
      if (points && points.length > 0 && selections && selections.length > 0) {
        addPrefilterRegion(fileName as string, points.length)
        const cells: PlotSelectionState = {
          ...currentSelection,
          // @TODO Figure out why we need to make a copy of the points.  It doesn't work otherwise?
          points: points?.map((point) => ({
            x: point.x,
            y: point.y,
            originalX: point.originalX,
            originalY: point.originalY,
            cellId: point.cellId,
          })),
          selections: selections?.map((selection) => ({
            type: selection.type,
            x0: selection.x0,
            y0: selection.y0,
            x1: selection.x1,
            y1: selection.y1,
            path: selection.path,
          })),
        }

        const newMorphotypesToRemove = [
          // Place the new selection first, so that the user is more likely to see it
          // @TODO Scroll to the new selection in the future
          {
            // default is 1000 random ids, which should be enough...
            id: getRandomId({ exclude: morphotypesToRemove?.map((x) => x.id) }),
            name: getNewUniqueName(morphotypesToRemove),
            cells,
            active: true,
            pagination: defaultPagination,
          } as PinnedCellGroup,
          ...(morphotypesToRemove ?? []),
        ]
        setMorphotypesToRemove(newMorphotypesToRemove)
      }
    }
  }

  return {
    onPrefilterSelected,
    morphotypesToRemove,
    setMorphotypesToRemove,
    setMorphotypeToRemoveHighlight,
  }
}

export const usePreFilter = (): ReturnType<typeof useMethodsAndData> => useMethodsAndData()

export default usePreFilter
