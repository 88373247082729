import { Box, Stack, Typography } from '@mui/material'
import _ from 'lodash'
import { CellInfoGroups } from 'redux/slices'
import LegendItem from '../colorBy/LegendItem'

export const FiltersAppliedCellInfoGroupsSection = ({
  cellInfoGroups,
  maxHeight,
}: {
  cellInfoGroups: CellInfoGroups
  maxHeight: string
}): JSX.Element => {
  const keys = _.keys(cellInfoGroups) as (keyof CellInfoGroups)[]
  return (
    <>
      <Stack spacing={2}>
        <h1>Filters Applied</h1>
      </Stack>
      <Box sx={{ maxHeight, overflow: 'auto', px: 1 }}>
        {cellInfoGroups
          ? keys.map((key) => {
              const { label, data } = cellInfoGroups[key] ?? {}
              return (
                <Stack key={label} spacing={1} mb={2}>
                  <Typography>{label}</Typography>
                  {data?.map(({ value, color, isHidden }) => (
                    <LegendItem
                      readOnly
                      key={value}
                      bgColor={color}
                      label={value}
                      dataLabel={value}
                      isHidden={isHidden}
                    />
                  ))}
                </Stack>
              )
            })
          : null}
      </Box>
    </>
  )
}

export default FiltersAppliedCellInfoGroupsSection
