import { RemoteConfigSettings, AccessLevelSetting } from 'config'
import useAuthTokens from 'utils/useAuthTokens'
import useFlags from './useFlags'

export type YesNoRemoteConfigKey = {
  [K in keyof RemoteConfigSettings]: RemoteConfigSettings[K] extends AccessLevelSetting ? K : never
}[keyof RemoteConfigSettings]

/**
 * Given a flag, return the first value if 'yes', otherwise return the 2nd value.
 * Alternately, return just the key to return true if 'yes' and false otherwise.
 * @param key A key from RemoteConfigSettings that is of type 'AccessLevelSetting'
 * @param valueIfTrue
 * @param valueIfFalse
 * @returns
 */
export function useFlagCondition(key: YesNoRemoteConfigKey): boolean
export function useFlagCondition<TrueType, FalseType>(
  key: YesNoRemoteConfigKey,
  valueIfTrue: TrueType,
  valueIfFalse: FalseType
): TrueType | FalseType
export function useFlagCondition<TrueType, FalseType>(
  key: YesNoRemoteConfigKey,
  valueIfTrue?: TrueType,
  valueIfFalse?: FalseType
): TrueType | FalseType | boolean {
  const flags = useFlags()
  const val = flags[key]
  const { isInternalUser } = useAuthTokens()

  if (valueIfTrue !== undefined && valueIfFalse !== undefined) {
    return val === 'yes' || (val === 'internal' && isInternalUser) ? valueIfTrue : valueIfFalse
  }

  return val === 'yes' || (val === 'internal' && isInternalUser)
}

export default useFlagCondition
