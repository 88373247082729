import JSZip from 'jszip'
import { saveAs } from 'file-saver'
import { useState } from 'react'
import { QueryClient } from 'react-query'
import { getCellImage } from 'utils/api'
import { useLocalStorage } from 'react-use'
import { useNotificationSlice } from 'redux/slices/hooks'
import convertToTiff from './utils'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
    },
  },
})
type FileMetadata = {
  fileName: string
  base64Image: string
}
interface CellIdsDownloaderConfigProps {
  type?: 'png' | 'tiff'
  cells: string[]
  zippedFileName?: string
  maxCellIds: number
  downloadLimit: number
  timeFrame: number
}
interface CellIdsDownloaderReturn {
  handleDownloadZip: () => Promise<void>
  loading: boolean
}
const getCellIdImage = async (cellId: Record<string, number>): Promise<string> => {
  const { cellNumber, cellTime, instrumentId } = cellId
  const defaultParams = { number: cellNumber, time: cellTime, instrumentId }
  const cellIdParams = { ...defaultParams, frame: 0 }
  const responseCellImage = await queryClient.fetchQuery(
    ['getCellImageById', cellIdParams],
    getCellImage,
    {
      staleTime: 0,
    }
  )
  return responseCellImage
}

const useCellIdsDownloader = (
  key: string,
  configOptions: CellIdsDownloaderConfigProps
): CellIdsDownloaderReturn => {
  const zip = new JSZip()
  const [loading, setLoading] = useState<boolean>(false)
  const [spamDetection, setSpamDetection] = useLocalStorage(key, {
    count: 0,
    started_at: 0,
  })
  const {
    type = 'png',
    cells,
    zippedFileName = '',
    downloadLimit,
    timeFrame, // In-minutes
  } = configOptions
  const [timeLimit, setTimeLimit] = useState(0)
  const { showError } = useNotificationSlice()
  async function downloadImages() {
    const images = cells.map(async (cellId) => {
      const [cellTime, cellNumber, instrumentId] = cellId.replace('#', '').split(' ').map(Number)
      const cellIdMeta = {
        cellTime,
        cellNumber,
        instrumentId,
      }
      return {
        fileName: `${cellTime} ${cellNumber} ${instrumentId}`,
        base64Image: (await getCellIdImage(cellIdMeta)) || '',
      }
    })
    const data: FileMetadata[] = await Promise.all(images)
    data.forEach(({ fileName, base64Image }) => {
      const base64String = base64Image.split(',')[1]
      if (type === 'png') {
        zip.file(`${fileName}.${type}`, base64String, { base64: true })
      } else {
        zip.file(`${fileName}.${type}`, convertToTiff(base64String))
      }
    })
    zip.generateAsync({ type: 'blob' }).then((blob) => {
      const timeStamp = Date.now().toString()
      const fileName = zippedFileName ? `${zippedFileName}-${timeStamp}` : `${timeStamp}`
      saveAs(blob, `${fileName}.zip`)
    })
  }
  const handleDownloadZip = async () => {
    setLoading(true)
    try {
      if (!spamDetection) {
        return
      }
      const startTime = Date.now()
      const validRequest =
        spamDetection.count < downloadLimit && spamDetection.started_at < timeLimit
      const maxRequestExceeded =
        spamDetection.count >= downloadLimit && spamDetection.started_at < timeLimit
      const maxTimeExceeded = spamDetection.started_at > timeLimit
      if (!spamDetection?.count) {
        setTimeLimit(startTime + timeFrame * 60 * 1_000)
        await downloadImages()
        setSpamDetection({
          count: spamDetection.count + 1,
          started_at: startTime,
        })
      } else if (validRequest) {
        await downloadImages()
        setSpamDetection({
          count: spamDetection.count + 1,
          started_at: startTime,
        })
      } else if (maxRequestExceeded) {
        throw new Error('Maximum download exceeded')
      } else if (maxTimeExceeded) {
        setTimeLimit(startTime + timeFrame * 60 * 1_000)
        await downloadImages()
        setSpamDetection({
          count: 1,
          started_at: startTime,
        })
      }
    } catch (err) {
      if (err instanceof Error) {
        showError(`Error while downloading/archiving images: ${err.message}`)
      }
    } finally {
      setLoading(false)
    }
  }
  return { handleDownloadZip, loading }
}
export default useCellIdsDownloader
