import { density2d } from '@uwdata/kde'
import { kdTree as KdTree } from 'kd-tree-javascript'
import { useState } from 'react'

// TODO: We're using the @uwdata/kde module because it's ESM compliant, but the fast-kde module is better, but it's NOT ESM compliant. Figure out a way to make it compliant.

type Point = {
  x: number
  y: number
}

export type DensityPoint = {
  x: number
  y: number
  z: number
}

const distance = (a: Point, b: Point) => {
  return (a.x - b.x) ** 2 + (a.y - b.y) ** 2
}

export const createDensityTree = <T extends Record<string, unknown>>(
  data: T[] | undefined,
  xName: keyof T,
  yName: keyof T,
  bins = 50
): KdTree<DensityPoint> | undefined => {
  if (data) {
    const dataAsArray = [] as number[][]
    for (let i = 0; i < data.length; i += 1) {
      dataAsArray.push([+data[i][xName], +data[i][yName]])
    }
    const d = [
      ...density2d(dataAsArray, {
        bandwidth: 1,
        bins: [bins, bins],
      }),
    ] as DensityPoint[]
    const t = new KdTree(d, distance, ['x', 'y'])
    return t
  }

  return undefined
}

export const getNearestDensityValueFromTree = (
  tree: KdTree<DensityPoint> | undefined,
  x: number,
  y: number
): number | null => {
  if (tree) {
    const nearest = tree.nearest({ x, y, z: -1 }, 1)

    if (nearest?.length && nearest[0] && nearest[0][0]) {
      return nearest[0][0].z
    }
  }

  return null
}

/**
 * A convenience hook incase you want the tree to be part of state
 */
export const useDensityTree = <T extends Record<string, unknown>>(
  data: T[] | undefined,
  xName: keyof T,
  yName: keyof T
): { getNearestDensityValue: (x: number, y: number) => number | null } => {
  const [tree, setTree] = useState<
    KdTree<{
      x: number
      y: number
      z: number
    }>
  >()

  const getNearestDensityValue = (x: number, y: number) => {
    if (!tree) {
      const t = createDensityTree(data, xName, yName)
      if (t) {
        setTree(t)
        return getNearestDensityValueFromTree(t, x, y)
      }
    } else {
      return getNearestDensityValueFromTree(tree, x, y)
    }

    return null
  }

  return { getNearestDensityValue }
}

export default useDensityTree
