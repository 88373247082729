import { Card, CardContent, Stack } from '@mui/material'
import { useWindowSize } from 'react-use'
import usePreFilter from 'components/cell-visualizations/prefilter/usePreFilter'
import usePanelHeight from '../cellSelectionDrawer/usePanelHeight'
import { MorphotypeHeader } from './MorphotypeHeader'
import { MorphotypeSelectionCard } from './MorphotypeSelectionCard'

export const morphotypePanelWidth = 315

export const MorphotypesToRemove = (): JSX.Element => {
  const { width } = useWindowSize()
  const { top, height } = usePanelHeight(0)
  const { morphotypesToRemove, setMorphotypesToRemove } = usePreFilter()

  const handleDelete = (id: number) => () => {
    const newMorphotypes = morphotypesToRemove?.filter((m) => m.id !== id) ?? []
    setMorphotypesToRemove(newMorphotypes)
  }

  return (
    <Card
      sx={{
        borderRadius: 0,
        pt: 1,
        width: morphotypePanelWidth,
        position: 'absolute',
        left: width - morphotypePanelWidth,
        borderLeft: '1px solid rgba(196, 198, 252, 1)',
        boxShadow: '0px 10px 10px 0px rgba(0, 0, 0, 0.15)',
        zIndex: 1,
        top,
        height,
      }}
    >
      <CardContent sx={{ height: height - 8, overflowY: 'auto', scrollbarWidth: 'thin', mb: 1 }}>
        <MorphotypeHeader />
        <Stack spacing={1} alignItems="center">
          {morphotypesToRemove?.map((m) => (
            <MorphotypeSelectionCard
              key={m.id}
              morphotype={m}
              active={!!m.active}
              onDelete={handleDelete(m.id)}
            />
          ))}
        </Stack>
      </CardContent>
    </Card>
  )
}

export default MorphotypesToRemove
