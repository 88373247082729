import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import { SessionMetaData } from 'utils/api'
import useSessionApi from '../../useSessionApi'

export const useLoadedSessions = (
  shouldLoad = true
): {
  sessions: SessionMetaData[] | undefined
  setSessions: Dispatch<SetStateAction<SessionMetaData[] | undefined>>
} => {
  const { getSessions } = useSessionApi()
  const [sessions, setSessions] = useState<SessionMetaData[]>()

  const isLoading = useRef(false)

  const beginLoad = shouldLoad && !isLoading.current && !sessions

  useEffect(() => {
    if (!beginLoad) return

    const loadSessions = async () => {
      isLoading.current = true
      const sessionsFromBackend = await getSessions({
        version_config_fields: ['plotImgSrc', 'name', 'projectCode'],
        limit: 200,
      })
      setSessions(sessionsFromBackend)
      isLoading.current = false
    }

    loadSessions()
  }, [beginLoad, getSessions])

  return { sessions, setSessions }
}

export default useLoadedSessions
