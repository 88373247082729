import { ColDef, SortModelItem, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community'
import {
  formatTimestampToHumanReadable,
  INSTRUMENT_REPORTED_TIMEFORMAT,
} from 'components/shared/date-utils'
import HyperlinkCellComponent from 'components/runs/HyperlinkCellComponent'
import {
  CellClass,
} from '@deepcell/dc_core_proto/deepcell_schema2_pb'
import { startCase } from 'lodash'
import ProgressCellComponent from './ProgressCellComponent'
import * as ROUTES from '../../constants/routes'
import StatusCellComponent from './StatusCellComponent'
import CustomCellComponent from './CustomCellComponent'
import CustomCheckboxCellComponent from './CustomCheckboxCellComponent'

const headerTemplate =
  '<div class="ag-cell-label-container" role="presentation">' +
  '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
  '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
  '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
  '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
  '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
  '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
  '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
  '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
  '  </div>' +
  '</div>'

/**
 * Aggregate the sum of a column
 * And it will return a number that will be equivalent to sum of all rows for a particular column.
 * @function aggSumFromString
 * @param field string.
 * @param api ag-grid API.
 */

export const enum RunsHeaderName {
  RunId = 'Run Id',
  RunType = 'Run Type',
  RunDescription = 'Run Description',
  Project = 'Project',
  StartTime = 'Start Time',
  Status = 'Status',
  Operator = 'Operator',
  TotalCellCount = 'Total Cell Count',
  ExperimentCode = 'Experiment Code',
  QualityReview = 'Quality Review',
}

export const columnDefs = (): ColDef[] => {
  return [
    {
      headerName: '',
      field: 'run_id',
      checkboxSelection: true,
      width: 50,
      pinned: 'left',
      lockPinned: true,
      cellRendererFramework: CustomCheckboxCellComponent
    },
    {
      headerName: RunsHeaderName.RunId,
      field: 'run_id',
      width: 200,
      cellStyle: {
        color: '#5F55D1',
      },
      pinned: 'left',
      lockPinned: true,
      cellRendererFramework: CustomCellComponent
    },
    {
      headerName: RunsHeaderName.RunType,
      field: 'well_sorting_configurations',
      width: 150,
      valueFormatter: (params) => (params.value?.length > 0 ? 'Sorting' : 'Imaging'),
      cellRendererFramework: CustomCellComponent
    },
    {
      headerName: RunsHeaderName.QualityReview,
      field: 'run_quality_score',
      width: 140,
      cellRendererFramework: StatusCellComponent,
      valueFormatter: (params) => params.data?.run_quality_score
    },
    {
      headerName: RunsHeaderName.RunDescription,
      field: 'description',
      minWidth: 300,
      flex: 1,
      cellRendererFramework: CustomCellComponent,
      cellRendererParams: {
        ellipsis: true,
      },
    },
    {
      headerName: RunsHeaderName.Operator,
      field: 'user_email',
      cellRendererFramework: CustomCellComponent,
      cellRendererParams: {
        ellipsis: true,
      },
      sortable: true,
    },
    {
      headerName: RunsHeaderName.StartTime,
      field: 'start_time',
      width: 200,
      valueFormatter: (params) => formatTimestampToHumanReadable(params.value, INSTRUMENT_REPORTED_TIMEFORMAT) || '',
      cellRendererFramework: CustomCellComponent,
      cellRendererParams: {
        ellipsis: true
      },
    },
    {
      headerName: RunsHeaderName.Status,
      field: 'stopped',
      width: 160,
      sortable: true,
      valueFormatter: (params) =>
        (params.value as boolean) ? startCase(params.data.stop_reason) || 'Complete' : 'In Progress',
    },
    {
      headerName: RunsHeaderName.TotalCellCount,
      field: 'total_cell_count',
      width: 140,
      cellClass: 'ag-right-aligned-cell',
      valueFormatter: (params) => {
        const value = params.value as number || 0;
        if (value >= 1000000) {
          return `${(value / 1000000).toFixed(2)  }M`;
        } if (value >= 1000) {
          return `${(value / 1000).toFixed(2)  }K`;
        } 
        return value.toString();
        
      },
    },
  ]
}

export const detailColumnDefs: ColDef[] = [
  {
    headerName: 'Run Id',
    field: 'run_id',
    width: 190,
    sort: 'desc',
    sortable: true,
  },
  {
    headerName: 'Sample Id',
    field: 'run_id',
    width: 190,
    sort: 'desc',
    sortable: true,
  },
  {
    headerName: 'Project',
    field: 'project_code',
    width: 200,
    sortable: true,
  },
  {
    headerName: 'Experiment Code',
    field: 'experiment_code',
    width: 200,
    sortable: true,
  },
  {
    headerName: 'Run Description',
    field: 'description',
    width: 200,
    sortable: true,
  },
  {
    headerName: 'Run Notes',
    field: 'experiment_code',
    width: 200,
    sortable: true,
  },
  {
    headerName: 'Target Morphotypes',
    field: 'target_cell_classes',
    width: 200,
    sortable: true,
  },
]

export const getSortByWellColumnDefs = (hasRunStopped?: boolean, isInternalUser?: boolean): ColDef[] => {
  return [
    {
      headerName: 'Well',
      field: 'well',
      minWidth: 50,
    },
    {
      headerName: 'Morphotypes',
      field: 'cell_types',
      resizable: true,
      minWidth: 80,
    },
    {
      headerName: 'Threshold',
      field: 'threshold',
      resizable: true,
      minWidth: 80,
    },
    {
      headerName: 'Stop Count',
      field: 'stop_count',
      resizable: true,
      minWidth: 85,
      headerComponentParams: {
        template: headerTemplate,
      },
    },
    {
      headerName: 'Sorted cells count',
      field: 'count',
      minWidth: 120,
      resizable: true,
      cellRendererFramework: ProgressCellComponent,
      valueFormatter: ({ value }) => value ?? 0,
      cellRendererParams: {
        showCount: true,
      },
      valueGetter: (params) => {
        return (params.data.count / params.data.stop_count) * 100
      },
      headerComponentParams: {
        template: headerTemplate,
      },
    },
    {
      headerName: 'Yield',
      field: 'true_positive_rate',
      minWidth: 130,
      cellRendererFramework: ProgressCellComponent,
      valueFormatter: (params) => {
        return params.value ?? 0
      },
      hide: !hasRunStopped || !isInternalUser,
      resizable: true,
    },
    {
      headerName: 'Purity',
      field: 'purity',
      minWidth: 150,
      cellRendererFramework: ProgressCellComponent,
      valueFormatter: ({ value }) => value ?? 0,
      hide: !hasRunStopped || !isInternalUser,
      resizable: true,
    },
  ]
}

export const getQCMetricsColumnDefs = (runId: string, quadrants: string[]): ColDef[] => [
  {
    headerName: 'Run %',
    field: 'run_percent',
    pinned: 'left',
    width: 80,
    cellStyle: { 'font-weight': 'bold' },
  },
  {
    headerName: 'Browse Cells',
    pinned: 'left',
    width: 100,
    headerComponentParams: {
      template: headerTemplate,
    },
    valueGetter: (params: ValueGetterParams) => {
      const page = quadrants.findIndex((quadrant) => quadrant === params.data.run_percent)
      return `${ROUTES.CELL_BROWSING}?activeTab=search&centerCrop=0&page=${page}&runIds=${runId}&sharpen=0&showSortFields=1&sortOrderKey=SAMPLE_DISTRIBUTION`
    },
    cellRendererFramework: HyperlinkCellComponent,
  },
  {
    headerName: 'Partial/ Cut off Images',
    field: 'cutoff_image',
    width: 120,
    headerComponentParams: {
      template: headerTemplate,
    },
    editable: true,
  },
  {
    headerName: 'Contamination Covering Images',
    field: 'contamination_covering_image',
    width: 150,
    headerComponentParams: {
      template: headerTemplate,
    },
    editable: true,
  },
  {
    headerName: 'Chip Covering Blemish Images',
    field: 'chip_blemish_covering_image',
    width: 150,
    headerComponentParams: {
      template: headerTemplate,
    },
    editable: true,
  },
  {
    headerName: 'Blebbing Images',
    field: 'blebbing_image',
    width: 100,
    headerComponentParams: {
      template: headerTemplate,
    },
    editable: true,
  },
  {
    headerName: 'Cell Debris Images',
    field: 'cell_debris',
    minWidth: 120,
    flex: 1,
    headerComponentParams: {
      template: headerTemplate,
    },
    editable: true,
  },
]

export const getCellTypeColumnDefs = (runId: string, total_cell_count: number, model: string): ColDef[] => [
  {
    headerName: 'Morphotypes',
    field: 'cell_type',
    width: 180,
    valueFormatter: (params: ValueFormatterParams) => {
      if (params.value?.includes('Class')) {
        return params.value.replace('Class', '').toUpperCase()
      }
      return params.value
    },
  },
  {
    headerName: 'Sorted cells',
    field: 'count',
    minWidth: 120,
    resizable: true,
    cellRendererFramework: ProgressCellComponent,
    cellRendererParams: {
      showCount: true,
    },
    valueFormatter: (params: ValueFormatterParams) => {
      return `${(params.data.count * 100) / total_cell_count}`
    },
  },
  {
    headerName: '% in Sample',
    field: 'count',
    width: 120,
    valueFormatter: (params: ValueFormatterParams) => {
      return `${((params.value / total_cell_count) * 100 ).toFixed(2)}%`
    },
    cellStyle: { textAlign: 'left' },
  },
  {
    headerName: 'Browse Details',
    field: 'cell_type',
    minWidth: 120,
    flex: 1,
    valueGetter: (params: ValueGetterParams) => {
      let cellType: string = params.data?.cell_type
      let predictedClass = ''
      if (cellType?.includes('CellType.')) {
        predictedClass = cellType.replace('CellType.', '')
      } else if (cellType?.includes('Class')) {
        predictedClass = cellType.replace('Class', '').toUpperCase()
      }
      predictedClass = ['CLASS_', predictedClass].join('')
      if (Object.keys(CellClass).includes(predictedClass)) { // Check if predicted types in enum
        cellType = predictedClass
        return `${ROUTES.CELL_BROWSING
          }?sortOrderKey=RUN_ID_ASC&runIds=${runId}&predictedClasses=${String(predictedClass)}`
      }
      // Not in CellClass enum, assume morphology classifier
      const modelParse = model.split(' v')
      const modelVersion = modelParse[modelParse.length - 1]
      const modelName = modelParse.slice(0, modelParse.length - 1).join('')
      const classifier = `${cellType}:v${modelVersion}:${modelName}`
      const encodedClassifier = encodeURIComponent(classifier);

      return `${ROUTES.CELL_BROWSING
        }?sortOrderKey=RUN_ID_ASC&runIds=${runId}&morphologyClassifiers=${encodedClassifier}`
    },

    cellRendererFramework: HyperlinkCellComponent,
    cellStyle: { textAlign: 'left' },
  },
]

/**
 * Transform SortModelItem to a API "order_by" string param.
 * For example [ { colId: 'project_code', sort: 'asc' }, { colId: 'start_time', sort: 'desc' } ] will result in:
 * “order_by=project_code:asc,start_time:desc”.
 * And it will sort ascending by project code first, then descending by start time.
 * @function sortModelItemToOrderBy
 * @param sorts Array<SortModelItem> | The SortModelItem to transform.
 */
export function sortModelItemToOrderBy(sorts: Array<SortModelItem>): string | null {
  try {
    // try to reduce each sortModelItem to a plain string that will be use in
    // the API call as a "order_by" param, in the form : “order_by=sorts[0].colId:sorts[0].sort,sorts[1].colId:sorts[1].sort”
    return sorts.map((s) => `${s.colId}:${s.sort}`).join(',')
  } catch {
    return null
  }
}
