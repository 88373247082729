import { keys, sortBy, startCase, uniqBy, zip } from 'lodash'
import { SelectionRange, Shape } from 'plotly.js'
import {
  CellDataField,
  CellInfoGroup,
  CellInfoGroupNone,
  CellInfoGroups,
  CellPlotDatum,
  PlotSelectionState,
  getDataFields,
} from 'redux/slices'
import { FeatureGroup } from 'utils/constants'

import area from '@turf/area'
import * as turf from '@turf/helpers'
import unkinkPolygon from '@turf/unkink-polygon'
import { CellInfo } from './tsv/types'
import { CATEGORICAL_COLOR_PALETTE } from './shared'

const COLORS = CATEGORICAL_COLOR_PALETTE

const isCategoryField = /^CATEGORY_[0-9]_.*$/

const toLowerStart = (s: string) => startCase(s.toLowerCase())

/**
 * Given points, extract the x and y offset
 */
export const getOffsetFromPoints = (
  points: CellPlotDatum
): { xOffset: number; yOffset: number } => {
  const { x, originalX, y, originalY } = points

  let xOffset = 0
  let yOffset = 0
  if (typeof originalX === 'number' && typeof originalY === 'number') {
    xOffset = x - originalX
    yOffset = originalY - y
  }

  return { xOffset, yOffset }
}

/**
 * Return the selection but remapped with the provided x and y offset
 * * This is used when taking a selection that the user drew in multiplot mode and
 *   figuring out where that selection WOULD have been in single plot mode
 */
export const getOriginalSelectionRange = (
  selectionRange: SelectionRange | undefined,
  xOffset: number,
  yOffset: number
): SelectionRange | undefined => {
  let offsetSelectionRange = selectionRange

  if (selectionRange) {
    offsetSelectionRange = {
      x: selectionRange.x.map((x) => x - xOffset),
      y: selectionRange.y.map((y) => y + yOffset),
    }
  }

  return offsetSelectionRange
}

/**
 * Given a plotly lasso path string, return the string with the x and y coords offset
 * @param `path` is always a string with counter clockwise coordinates in the form _`M{x coord},{y coord}L{x coord},{y coord}L ... {x coord},{y coord}Z`_
 * @example _`"M82.88815309825493,19.039055600379367L80.37256315136571,16.523465653490142L79.86944516198787,10.234490786267077L82.88815309825493,8.222018828755697L85.15218405045523,8.47357782344462L85.15218405045523,8.976695812822465Z"`_
 */
export const getOriginalPath = (path: string, xOffset: number, yOffset: number): string => {
  const coords = path.match(/[\d.]+/g)
  const newCoords: string[] = []

  if (coords) {
    for (let i = 0; i < coords?.length; i += 2) {
      const x = parseFloat(coords[i]) - xOffset
      const y = parseFloat(coords[i + 1]) + yOffset
      newCoords.push(`${x},${y}`)
    }
  }

  return `M${newCoords?.join('L')}Z`
}

/**
 * Given a plotly shape, return a new shape but with the x and y coordinates of all defined properties shifted based on the provided x and y offset
 * @param shape
 * @param xOffset
 * @param yOffset
 * @returns
 */
export const getOriginalShape = (
  shape: Partial<Shape>,
  xOffset: number,
  yOffset: number
): Partial<Shape> => {
  const { x0: oldx0, y0: oldy0, x1: oldx1, y1: oldy1, path: oldPath } = shape

  let x0 = oldx0
  let y0 = oldy0
  let x1 = oldx1
  let y1 = oldy1
  let path = oldPath

  // it's a range shape
  if (
    typeof oldx0 === 'number' &&
    typeof oldx1 === 'number' &&
    typeof oldy0 === 'number' &&
    typeof oldy1 === 'number'
  ) {
    x0 = oldx0 - xOffset
    y0 = oldy0 + yOffset
    x1 = oldx1 - xOffset
    y1 = oldy1 + yOffset
  }

  // it's a lasso shape
  if (oldPath) {
    path = getOriginalPath(oldPath, xOffset, yOffset)
  }

  return { type: shape.type, x0, y0, x1, y1, path }
}

/**
 * Given a PlotSelectionState, return a new PlotSelectionState with what the coordinates would be if the selection was made in single plot mode
 */
export const getOriginalPlotSelectionState = (
  selection: PlotSelectionState
): PlotSelectionState => {
  if (selection.points) {
    const { xOffset, yOffset } = getOffsetFromPoints(selection.points[0])
    const originalLassoPoints = getOriginalSelectionRange(selection.lassoPoints, xOffset, yOffset)
    const originalRange = getOriginalSelectionRange(selection.range, xOffset, yOffset)

    return {
      ...selection,
      lassoPoints: originalLassoPoints,
      range: originalRange,
      points: selection.points?.map((point) => ({
        x: point.originalX ?? 0,
        y: point.originalY ?? 0,
        cellId: point.cellId,
      })),
      selections: selection.selections?.map((s) => getOriginalShape(s, xOffset, yOffset)),
    }
  }

  return selection
}

/**
 * Give a list of column names, return the custom fields as a list of CellDataFields
 */
export const getCustomFields = (fields: string[]): CellDataField[] =>
  fields.flatMap((field) =>
    isCategoryField.test(field)
      ? ({
          isContinuous: false,
          label: toLowerStart(field.replace(/CATEGORY_._/, '').replace('_', ' ')),
          attribute: field,
          category: toLowerStart(FeatureGroup.CUSTOM),
        } as CellDataField)
      : []
  )

export const getHiddenCellInfoGroupValues = (
  cellInfoGroups: CellInfoGroups
): { [K in keyof CellInfoGroups]: string[] } => {
  return keys(cellInfoGroups).reduce((acc, cigk) => {
    const attribute = cigk as keyof typeof cellInfoGroups

    if (!attribute) return acc

    const { isContinuous, data } = cellInfoGroups[attribute] ?? {}

    if (isContinuous || !data) return acc

    const hiddenColValues = data.flatMap((x) => (x.isHidden ? x.value : []))

    if (hiddenColValues.length) {
      return { ...acc, [attribute]: hiddenColValues }
    }

    return acc
  }, {} as { [K in keyof typeof cellInfoGroups]: string[] })
}

export const removeDuplicatesAndSort = (cellInfo: CellInfoGroups): CellInfoGroups => {
  const cellInfoData = cellInfo
  keys(cellInfoData).forEach((_key) => {
    const key = _key as keyof CellInfoGroups
    if (cellInfo[key]) {
      const removeDuplicates = uniqBy(cellInfo[key]?.data, 'value')
      // eslint-disable-next-line no-param-reassign
      cellInfoData[key] = {
        ...cellInfoData[key],
        data: sortBy(removeDuplicates, ['value']),
      } as CellInfoGroup & typeof CellInfoGroupNone
    }
  })

  return cellInfoData
}

export const updateColors = (cellInfo: CellInfoGroups): CellInfoGroups => {
  keys(cellInfo).forEach((_key) => {
    const key = _key as keyof CellInfoGroups
    if (cellInfo[key]) {
      const data = cellInfo[key]?.data
      const updatedData = []
      if (data) {
        for (let i = 0; i < data.length; i += 1) {
          const modifiedCOlor = COLORS[i % COLORS.length]
          updatedData.push({
            ...data[i],
            color: modifiedCOlor,
          })
        }
      }
      // eslint-disable-next-line no-param-reassign
      cellInfo[key] = {
        ...cellInfo[key],
        data: updatedData,
      } as CellInfoGroup & typeof CellInfoGroupNone
    }
  })
  return cellInfo
}

export const filterObjectsWithNoData = (cellInfo: CellInfoGroups): CellInfoGroups => {
  const filteredEntries = Object.entries(cellInfo).filter(([_key, value]) => value.hasData)
  const filteredObject = Object.fromEntries(filteredEntries)

  return filteredObject
}

export const getCellInfoToGroups = (cellInfoData: CellInfo[]): CellInfoGroups => {
  const cellInfoDataLength = cellInfoData.length

  if (cellInfoDataLength < 1) return {}

  const dataFields = [...getDataFields(), ...getCustomFields(keys(cellInfoData[0]))]

  const initializeCellInfoGroups = (cellDataFields: CellDataField[]): CellInfoGroups => {
    const initialCellInfoGroup: CellInfoGroups = {}
    for (let i = 0; i < cellDataFields.length; i += 1) {
      const { attribute, ...rest } = cellDataFields[i]
      initialCellInfoGroup[attribute as keyof CellInfo] = {
        hasData: false,
        data: [],
        ...rest,
      }
    }

    return initialCellInfoGroup
  }

  const cellInfoGroups: CellInfoGroups = initializeCellInfoGroups(dataFields)

  for (let cellInfoDataIndex = 0; cellInfoDataIndex < cellInfoDataLength; cellInfoDataIndex += 1) {
    const cellInfoRecord = cellInfoData[cellInfoDataIndex]
    dataFields.reduce((cumulateCellInfoRecords, dataField) => {
      const { attribute } = dataField
      const column = attribute as keyof CellInfo
      const value = cellInfoRecord[column] ?? ''

      if (value) {
        const cellInfoGroup = cumulateCellInfoRecords[column]
        const { data } = cellInfoGroup ?? {}

        // Mark that we have data for this field
        if (cellInfoGroup) {
          cellInfoGroup.hasData = true
        }

        // Don't keep track of all the values for continuous variables
        if (dataField.isContinuous) {
          return cumulateCellInfoRecords
        }

        // Only keep track of unique data values for categorical variables
        if (data) {
          data.push({
            value,
            isHidden: false,
            color: '',
          })
        }
      }
      return cumulateCellInfoRecords
    }, cellInfoGroups)
  }

  const filterAndSortGroups = removeDuplicatesAndSort(cellInfoGroups)
  const filteredCellInfoGroups = filterObjectsWithNoData(filterAndSortGroups)
  const updatedWithColor = updateColors(filteredCellInfoGroups)

  return updatedWithColor
}

type SimpleValue = string | number | boolean | null | undefined
/**
 * Return the first Value whose test asserts true, otherwise returns undefined
 */
export const coalesce = (
  ...values: ([boolean | undefined, SimpleValue] | SimpleValue)[]
): SimpleValue => {
  for (let i = 0; i < values.length; i += 1) {
    const [test, value] = Array.isArray(values[i])
      ? (values[i] as [boolean, SimpleValue])
      : [!!values[i] || values[i] === '', values[i] as SimpleValue]

    if (test) {
      return value
    }
  }

  return undefined
}
type ObjectCoords = { x: number[]; y: number[] }
type Coord = { x: number; y: number }
/**
 * Given an object with x and y coordinate arrays, remove the coordinates that are in
 * an array that contains an object with x,y coordinate properties.
 * Should be very fast as the Set.has() function has a time complexity of nearly O(1)
 * @param objectCoords An object that can extend { x: number[]; y: number[] }
 * @param arrayOfCoords An array that contains objects that can extend { x: number; y: number }
 * @returns A new object with data from the original object but with the x and y arrays filtered
 */

export const removeArrCoordsFromObjectCoords = <OC extends ObjectCoords, C extends Coord>(
  objectCoords: OC,
  arrayOfCoords: C[]
): OC => {
  const coordSet = new Set<string>(arrayOfCoords.map((coord) => `${coord.x}${coord.y}`))

  return {
    ...objectCoords,
    x: objectCoords.x.filter((x, i) => !coordSet.has(`${x}${objectCoords.y[i]}`)),
    y: objectCoords.y.filter((y, i) => !coordSet.has(`${objectCoords.x[i]}${y}`)),
  }
}

export const removeObjectCoordsFromArrCoords = <OC extends ObjectCoords, C extends Coord>(
  objectCoords: OC,
  arrayOfCoords: C[]
): C[] => {
  const coordSet = new Set<string>(objectCoords.x.map((x, i) => `${x}${objectCoords.y[i]}`))

  return arrayOfCoords.filter((aoc) => !coordSet.has(`${aoc.x}${aoc.y}`))
}

/** Utility function to check for invalid intersections
 *
 * Due to the nature of using a mouse, we saw that in all cell vis sessions
 * created up until May 2024 that had morphotypes, roughly a quarter of those
 * sessios had morphotype polygon regions with self-intersections.
 *
 * The vast majority of those self-intersections looked like tiny blips
 * due to random mouse motion (probably often while making corners or closing
 * the polygon)
 *
 * This function detects if the selection has self-intersections, but
 * ignores cases where those intersections look relatively tiny.
 *
 * The backend will try to resolve those cases in a way that matches
 * what Plotly shows (but the areas are so tiny it probably doesn't matter)
 *
 * @param lassoPoints The lasso selection points to check and repair
 * @param maxAreaThreshold The maximum area threshold for a hole to be considered too large
 *                     taking the area of the region to remove vs. the total area selected
 *
 * @returns true if the selection if the lasso selection has no self-intersections
 *    or only "small" self-intersections
 */
export const isSelectionValid = (lassoPoints: SelectionRange, maxAreaThreshold = 1e-2): boolean => {
  const coords = zip(lassoPoints.x, lassoPoints.y) as unknown as turf.Position[]

  // turf expects the first and last point to be the same
  // so let's close the loop
  const p0 = coords[0]
  const pn = coords[coords.length - 1]
  if (p0[0] !== pn[0] || p0[1] !== pn[1]) {
    coords.push(coords[0])
  }

  // Create a GeoJSON turf polygon from the selection
  const inputPoly = turf.polygon([coords])

  // Unkinks the polygon by removing all self-intersections
  // And returning instead a list of simple polygons that may have one or more interior holes
  // https://www.npmjs.com/package/simplepolygon
  const unkinkedPoly = unkinkPolygon(inputPoly)

  // Calculate the total area of the selection
  // (turf does this in square meters...so the units are a bit funky)
  const totalArea = area(unkinkedPoly)

  // Check for any large holes or large extra polygons
  let hasLargeHoles = false
  let largePolygonCount = 0
  unkinkedPoly.features.forEach((poly: turf.Feature<turf.Polygon>) => {
    const exteriorRing = poly.geometry.coordinates[0]
    const interiorRings = poly.geometry.coordinates.slice(1)

    // Keep interior holes that are larger than the threshold
    const filteredInteriorRings = interiorRings.filter(
      (ring) => area(turf.polygon([ring])) / totalArea > maxAreaThreshold
    )

    // If we have large holes, this selection is invalid
    if (filteredInteriorRings.length > 0) {
      hasLargeHoles = true
      return
    }

    // Count how many large polygons we have after unkinking
    if (area(turf.polygon([exteriorRing])) / totalArea > maxAreaThreshold) {
      largePolygonCount += 1
    }
  })

  // If there's multiple large polygons or large holes, the selection is invalid
  return largePolygonCount === 1 && !hasLargeHoles
}
