import { styled } from '@mui/material'

interface DeepcellLabelProps {
    label: string
    id?: string
}

const StyledLabel = styled('div')(({ theme }) => ({
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '13px',
    fontFamily: theme.typography.fontFamily,
    color: '#000000',
    margin: '8px 0px',
}))

export const DeepcellLabel = ({ label, id, ...rest }: DeepcellLabelProps): JSX.Element => {
    return (
        <StyledLabel id={id || ''} {...rest}>
            {label}
        </StyledLabel>
    )
}

export default DeepcellLabel
