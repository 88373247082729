import * as ROUTES from 'constants/routes'
import { styled } from '@mui/material'
import { Redirect } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import useAuthTokens from 'utils/useAuthTokens'
import { useAuthWrapper } from 'utils/demoEnvironmentUtils'
import { isExternalDemo } from 'config'
import ContentLoading from './shared/ContentLoading'

const Root = styled('div')(({ theme }) => ({
    padding: theme.spacing(1),
    textAlign: 'center',
}))

const Home = (): JSX.Element => {
    const { isAuthenticated, error, isLoading, loginWithRedirect } = useAuthWrapper(useAuth0)
    const { permissions, resetStorage } = useAuthTokens()

    if (error && error?.message.indexOf('parameter organization is invalid') !== -1) {
        resetStorage()
        loginWithRedirect({
            appState: {
                returnTo: window.location.origin,
            },
        })
    }

    return (
        <Root>
            {(isLoading || permissions.size === 0) && <ContentLoading />}
            {!isLoading && !isAuthenticated && <Redirect to="/login" />}
            {!isLoading && isAuthenticated && isExternalDemo && <Redirect to="/register" />}
            {!isLoading && isAuthenticated && permissions.size > 0 && (
                <>
                    {permissions.has('cells:read') && <Redirect to={ROUTES.CELL_BROWSING} />}
                    {permissions.has('cells:graph_visualization:read') && (
                        <Redirect to={ROUTES.CELL_VISUALIZATIONS} />
                    )}
                    {permissions.has('runs:read') && <Redirect to={ROUTES.RUNS} />}
                </>
            )}
        </Root>
    )
}

export default Home
