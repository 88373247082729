import { ResponseComposition, rest, RestRequest } from 'msw'
import { CellVisualizationsState } from 'redux/slices'
import { datasetItems } from 'redux/types'
import {
  API_URL,
  SessionMetaData,
  SessionMetaDataResponse,
  SessionPostResponse,
} from '../../utils/api'

const getRandId = () => Math.ceil(Math.random() * 100).toString()

const CELL_VIS_API_HANDLERS = [
  rest.delete(
    `${API_URL}/v1/cell_visualizations/sessions/:sessionId`,
    (_req: unknown, res: ResponseComposition, ctx) => {
      return res(ctx.status(200))
    }
  ),
  // TODO: Find a way to get this working with `:copy` at the end
  // rest.post(
  //   `${API_URL}/v1/cell_visualizations/sessions/:sessionId/versions/:versionId\\:copy`,
  //   (_req: unknown, res: ResponseComposition<SessionPostData>, ctx) => {
  //     return res(ctx.json({ version_id: getRandId(), session_id: getRandId() }))
  //   }
  // ),
  rest.post(
    `${API_URL}/v1/cell_visualizations/sessions/:sessionId/versions/:versionId`,
    (_req: unknown, res: ResponseComposition<SessionPostResponse>, ctx) => {
      return res(ctx.json({ data: { version_id: getRandId(), session_id: getRandId() } }))
    }
  ),
  rest.post(
    `${API_URL}/v1/cell_visualizations/sessions`,
    (
      _req: RestRequest<Partial<CellVisualizationsState>>,
      res: ResponseComposition<SessionPostResponse>,
      ctx
    ) => {
      return res(ctx.json({ data: { version_id: getRandId(), session_id: getRandId() } }))
    }
  ),
  rest.get(
    `${API_URL}/v1/cell_visualizations/sessions/:sessionId/versions/:versionId`,
    (
      req: RestRequest<Partial<CellVisualizationsState>>,
      res: ResponseComposition<SessionPostResponse>,
      ctx
    ) => {
      const { versionId, sessionId } = req.params
      return res(
        ctx.json({
          data: {
            version_id: versionId,
            session_id: sessionId,
            author_email: `person${getRandId()}@email.com`,
            data_url: 'cell-visualization-cache-dev/24.arrow',
            version_config: {
              cellImagesFilter: {
                displayImages: true,
                imageSize: 50,
                spacingAdjust: 1.5,
              },
            },
          },
        })
      )
    }
  ),
  rest.get(
    `${API_URL}/v1/cell_visualizations/sessions/:sessionId`,
    (
      req: RestRequest<Partial<CellVisualizationsState>>,
      res: ResponseComposition<SessionPostResponse>,
      ctx
    ) => {
      const { sessionId } = req.params
      return res(
        ctx.json({
          data: {
            version_id: '1',
            session_id: sessionId,
            author_email: `person${getRandId()}@email.com`,
            data_url: 'cell-visualization-cache-dev/24.arrow',
            // version_config: {
            //     cellImagesFilter: {
            //         displayImages: false,
            //         imageSize: 75,
            //         spacingAdjust: 0.75,
            //     },
            // },
          },
        })
      )
    }
  ),
  rest.get(
    `${API_URL}/v1/cell_visualizations/sessions`,
    (_: RestRequest, res: ResponseComposition<SessionMetaDataResponse>, ctx) => {
      return res(
        ctx.json({
          status: 'success',
          data: datasetItems.map(
            (di) =>
              ({
                author_email: di.id,
                updated_at: new Date(),
                session_id: +di.id,
                created_at: new Date(),
                data_url: '',
                deleted_at: new Date(),
                is_latest: true,
                version_id: 123,
                version_config: { name: di.label, projectCode: di.sampleTypes },
              } as SessionMetaData)
          ),
        })
      )
    }
  ),
  rest.get(`${API_URL}/cell-visualization-cache-dev/:fileName`, async (_req, res, ctx) => {
    return res(ctx.status(200))
  }),
]

export default CELL_VIS_API_HANDLERS
