// Do not import from '@mui/styles'. Use '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material'
import { styled } from '@mui/system'
import 'ag-grid-community/dist/styles/ag-grid.css'
// Balham theme looked more dense and readable than Material by default.  Tweak later if needed
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import 'ag-grid-community/dist/styles/ag-theme-balham.css'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import '../ag-theme-deepcell.css'
import { store } from '../redux/store'
import Auth0ProviderWithHistory from './Auth0ProviderWithHistory'
import NavigationMenu from './NavigationMenu'
import { DeepcellNotification } from './shared/DeepcellNotification'

// Reference: https://mui.com/material-ui/customization/typography/#adding-amp-disabling-variants
declare module '@mui/material/styles' {
    interface TypographyVariants {
        poppins: React.CSSProperties
    }

    // allow configuration using `createTheme`
    interface TypographyVariantsOptions {
        poppins?: React.CSSProperties
    }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        poppins: true
    }
}

// Initialize a global react-query client
const queryClient = new QueryClient()

export const theme = createTheme({
    palette: {
        primary: {
            main: '#5F55D1',
            light: '#e7e7ff',
            dark: '#8b8ff1',
            contrastText: '#EEF1F0',
        },
        secondary: {
            main: '#172623',
            dark: 'rgba(34,41,53,0.76)',
            light: '#72807F',
            contrastText: '#EEF1F0',
        },
        text: {
            primary: '#1E1A45',
            secondary: '#35363F',
            disabled: '#72807F',
        },
        error: {
            main: '#FF3653',
            light: '#ff8a80',
        },
        info: {
            main: '#5F55D1',
            light: '#F6F6F6',
        },
        warning: {
            main: '#eeba34',
        },
        divider: 'rgba(246,246,246,0.98)',
        background: {
            default: '#ffffff',
        },
        success: {
            main: '#a4efc0',
            light: '#E3FCEE',
        },
    },
    typography: {
        fontFamily: 'Telegraf',
        htmlFontSize: 19,
        poppins: {
            fontFamily: 'Poppins',
        },
        h5: {
            fontFamily: 'Telegraf',
            fontSize: '1.2em',
            lineHeight: '1em',
        },
    },
    components: {
        MuiPaper: {
            variants: [
                {
                    props: { variant: 'outlined' },
                    style: {
                        padding: '10px',
                        marginBottom: '10px',
                        border: '1px solid #5F55D1',
                    },
                },
            ],
        },
    },
})

const StyleRoot = styled('div')({
    overflow: 'auto',
    height: '100%',
})

const App = (): JSX.Element => (
    <ThemeProvider theme={theme}>
        <Provider store={store}>
            <Router>
                <Auth0ProviderWithHistory>
                    <StyleRoot>
                        <QueryParamProvider ReactRouterRoute={Route}>
                            <QueryClientProvider client={queryClient}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <NavigationMenu />
                                    <DeepcellNotification />
                                </LocalizationProvider>
                            </QueryClientProvider>
                        </QueryParamProvider>
                    </StyleRoot>
                </Auth0ProviderWithHistory>
            </Router>
        </Provider>
    </ThemeProvider>
)

export default App
