import {
  ClickAwayListener,
  Paper,
  Popper,
  PopperProps,
  styled,
  ClickAwayListenerProps,
  Slide,
} from '@mui/material'

const DropdownContainer = styled(Popper)({
  borderRadius: '4px',
  overflow: 'hidden',
  background: '#FFF',
  boxShadow:
    '0px 1px 12px 0px rgba(0, 0, 0, 0.12), 0px 4px 8px 0px rgba(0, 0, 0, 0.02), 0px 1px 4px 0px rgba(0, 0, 0, 0.01)',
})

const DropdownModal = ({
  children,
  onClickAway,
  ...restProps
}: PopperProps & ClickAwayListenerProps): JSX.Element => {
  return (
    <DropdownContainer {...restProps}>
      {({ TransitionProps, placement }) => (
        <Slide
          direction="up"
          {...TransitionProps}
          style={{
            transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={onClickAway}>{children}</ClickAwayListener>
          </Paper>
        </Slide>
      )}
    </DropdownContainer>
  )
}

export default DropdownModal
