import { Box, BoxProps, Skeleton } from '@mui/material'
import { useEffect } from 'react'
import { QueryStatus } from 'react-query'
import { useCellImageUrlFromCellId } from './tsv/utils'

const imageSize = 72
interface PlotCellImageProps {
  cellId: string
  imgProps?: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>
  wrapperProps?: BoxProps
  handleStatusChange?(status?: QueryStatus): void
  /**
   * If width/height is not provided, use the default height/width of 72
   * @default true
   */
  revertToDefaultDimensions?: boolean
}

export const PlotCellImage = ({
  cellId,
  imgProps,
  wrapperProps,
  handleStatusChange,
  revertToDefaultDimensions = true,
}: PlotCellImageProps): JSX.Element => {
  const { imageDataUrl, status } = useCellImageUrlFromCellId(cellId)

  useEffect(() => {
    if (handleStatusChange) handleStatusChange(status)
  }, [handleStatusChange, status])

  const defaultImageSize = revertToDefaultDimensions ? imageSize : undefined

  return (
    <Box component="span" sx={{ display: 'inline-flex', p: '2px' }} {...wrapperProps}>
      {imageDataUrl ? (
        <img
          data-testid="plotCellImageImgElement"
          src={imageDataUrl}
          width={imgProps?.width ?? defaultImageSize}
          alt=""
          {...imgProps}
        />
      ) : (
        <Skeleton
          data-testid="plotCellImageSkeleton"
          variant="rectangular"
          width={imgProps?.width ?? defaultImageSize}
          height={imgProps?.height ?? defaultImageSize}
          style={imgProps?.style}
        />
      )}
    </Box>
  )
}

export default PlotCellImage
