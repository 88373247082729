import * as ROUTES from 'constants/routes'
import { Box, Container, Grid, Paper, Stack } from '@mui/material'
import HubspotReactForm from 'components/shared/HubspotReactForm'
import { useTrackingCode } from 'react-hubspot-tracking-code-hook'
import useEventsManager from 'redux/slices/hooks/useEventsManager'
import useIsMobile from 'utils/useIsMobile'

const DemoRegister = (): JSX.Element => {
  const eventsManager = useEventsManager()
  const isMobile = useIsMobile()
  const { setPathPageView, setTrackPageView } = useTrackingCode()
  setPathPageView(ROUTES.DEMO_REGISTER)
  setTrackPageView()

  function handleFormSubmitted(form: HTMLFormElement) {
    const emailInput: HTMLInputElement = form.querySelector(
      'input[name="email"]'
    ) as HTMLInputElement
    const email = emailInput?.value

    if (email) {
      eventsManager.setUserEmail(email)
    }
  }

  return (
    <Box
      className="deepcell-bg-logo"
      sx={{
        overflowY: 'auto',
        height: '100vh',
        m: -1,
        width: '100vw',
      }}
    >
      <Container>
        <Stack sx={{ margin: 1 }} direction={{ xs: 'column' }} spacing={{ xs: 1, sm: 2, md: 4 }}>
          <Box alignSelf="center" component="h1">
            Try Out the Axon Data Suite to See High-Dimensional Morphology Data in Action
          </Box>
          <Paper sx={{ backgroundColor: '#FFFFFF', opacity: '0.8', p: 4 }}>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <Box
                  // alignSelf="top"
                  component="h2"
                  sx={{
                    '& .MuiTextField-root': {
                      width: isMobile ? '100%' : '50%',
                    },
                    lineHeight: '42px',
                  }}
                >
                  Gain access to
                  <ul>
                    <li>Web hosted demo, no download required</li>
                    <li>Four datasets to explore: </li>
                    <ul style={{ lineHeight: '32px' }}>
                      <li>Melanoma cell identification</li>
                      <li>Heterogeneity in Melanoma</li>
                      <li>Lung cancer TME</li>
                      <li>Cell death pathways</li>
                    </ul>
                    <li>
                      Interactive morphology UMAPs, population comparative analytics, & cell image
                      visualizations
                    </li>
                    <li>Video walk-throughs of each dataset</li>
                    <li>Tutorial guide for the Axon Data Suite</li>
                  </ul>
                </Box>
              </Grid>
              <Grid item md={6}>
                <HubspotReactForm
                  portalId="22481347"
                  formId="11de4fda-935b-4d87-81e0-15b9f778f120"
                  onFormSubmitted={handleFormSubmitted}
                />
              </Grid>
            </Grid>
          </Paper>
          <Box alignSelf="center" component="h2" sx={{ mx: 10 }}>
            Check out this video to see a few of the features you’ll get access to by entering the
            demo.
          </Box>
          <Box alignSelf="center" component="h4" sx={{ mx: 10 }}>
            <iframe
              width={isMobile ? '320' : '520'}
              height={isMobile ? '200' : '315'}
              src="https://www.youtube-nocookie.com/embed/C-87bT6vvAg"
              title="Welcome to Axon Data Suite"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </Box>
        </Stack>
      </Container>
    </Box>
  )
}

export default DemoRegister
