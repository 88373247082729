import { Stack } from '@mui/material'
import useFlagCondition from 'components/shared/useFlagCondition'
import { DatasetSelection } from './DatasetSelection'
import { PreFilterTitle } from './PreFilterTitle'
import { ScatterDensitySwitchButtons } from './ScatterDensitySwitchButtons'

interface Props {
  isPreFilter?: boolean
}

export const CellVizHeaderLeftSideContent = ({ isPreFilter }: Props): JSX.Element => {
  const demoEnabled = useFlagCondition('demoEnabled')
  const showDensityButtons = useFlagCondition('enableDensityPlot')

  return (
    <Stack direction="row" spacing={2}>
      {isPreFilter && <PreFilterTitle />}
      {!isPreFilter && showDensityButtons && <ScatterDensitySwitchButtons />}
      {demoEnabled ? <DatasetSelection /> : null}
    </Stack>
  )
}

export default CellVizHeaderLeftSideContent
