import { useMediaQuery } from '@mui/material'

/**
 * Returns true if the window width is 680px or less. More performant than useWindowSize
 * TODO: Make this more robust. Some bigger phones in landscape mode can be >900px in width
 */
export const useIsMobile = (): boolean => {
    return useMediaQuery('(max-width:680px)')
}

export default useIsMobile
