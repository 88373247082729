import { Annotations, Datum, Shape } from 'plotly.js'
import { Polygon as Poly, union } from 'polygon-clipping'
import { CellInfoDimension, PinnedCellGroup } from 'redux/slices'
import { approxEquals } from 'utils/helpers'
import { LabelFont } from '../shared'

type Point2D = { x: number; y: number }
const toNumber = (d: Datum | undefined) => +(d ?? -Infinity)

const getCoordsFromShape = (s: Partial<Shape>) => {
  const { x0, x1, y0, y1 } = {
    x0: toNumber(s.x0),
    x1: toNumber(s.x1),
    y0: toNumber(s.y0),
    y1: toNumber(s.y1),
  }

  return { x0, x1, y0, y1 }
}

/*
  @TODO: Having the higlighting logic in here causes all the polygon
  logic to run just to set fillcolor, opacity, and font. Let's see if we can
  move it somewhere else.
*/
const getShapeStandardProps = (pcg: PinnedCellGroup) =>
  ({
    xref: 'x',
    yref: 'y',
    fillcolor: pcg.isHighlighted ? 'blue' : undefined,
    opacity: pcg.isHighlighted ? 0.2 : undefined,
  } as Partial<Shape>)

const getAnnotationStandardProps = (pcg: PinnedCellGroup) =>
  ({
    text: pcg.name,
    font: {
      ...LabelFont,
      size: pcg.isHighlighted ? LabelFont.size + 2 : LabelFont.size,
    },
    opacity: pcg.isHighlighted ? 1 : 0.8,
  } as Partial<Annotations>)

/**
 * Gets highest point from an SVG path.  This function is exported mainly for unit testing only.
 * @param path SVG path string
 * @returns highest point coordinate in 2D
 */
export const getHighestPointFromPath = (path?: string): Point2D => {
  let highestCoord: Point2D = { x: 0, y: Number.NEGATIVE_INFINITY }
  let secondHighestCoord: Point2D = { x: 0, y: Number.NEGATIVE_INFINITY }

  if (!path) return highestCoord

  const points = path
    .replace(/(M|Z)/g, '')
    .split('L')
    .map((coordinatePairAsString) =>
      coordinatePairAsString.split(',').map((coordinate) => +coordinate)
    )

  points?.forEach((point) => {
    /*
          using >= here instead of > because the points are
          stored counterclockwise and we want to use the leftmost coordinate
        */
    if (point[1] >= highestCoord.y) {
      secondHighestCoord = highestCoord
      highestCoord = { x: point[0], y: point[1] }
    }
  })

  if (approxEquals(highestCoord.y, secondHighestCoord.y)) {
    // the top is a flat horizontal line and we want the x coord to be in the middle
    highestCoord = { x: (highestCoord.x + secondHighestCoord.x) / 2, y: highestCoord.y }
  }

  return highestCoord
}

/**
 * Returns the highest point from a list of shapes (rectangles or polygons)
 * @param shapesInput An array of shapes or a single shape
 * @returns The highest point across all
 */
export const getHighestPointFromShapes = (
  shapesInput: Partial<Shape>[] | Partial<Shape>
): Point2D => {
  let highestCoord: Point2D = { x: 0, y: Number.NEGATIVE_INFINITY }

  const shapes = Array.isArray(shapesInput) ? shapesInput : [shapesInput]

  shapes.forEach((shape) => {
    if (shape.path) {
      // it's a polygon
      const highestFromPath = getHighestPointFromPath(shape.path)
      if (highestFromPath.y > highestCoord.y) {
        highestCoord = highestFromPath
      }
    } else {
      // it's a box
      const { x0, x1, y0, y1 } = getCoordsFromShape(shape)

      const x = (x0 + x1) / 2
      const y = Math.max(y0, y1)
      if (y > highestCoord.y) highestCoord = { x, y }
    }
  })
  return highestCoord
}
/**
 *
 * @param pcg
 * @returns
 */
const getPolygonFromPinnedCellGroup = (pcg: PinnedCellGroup): Poly => {
  // older sessions' version config won't have transposed cells so use regular cells
  const { selections, lassoPoints } = pcg.transposedCells ?? pcg.cells ?? {}

  let poly: Poly = [[[0, 0]]]

  if (lassoPoints) {
    poly = [lassoPoints.x.map((lassoPointX, i) => [lassoPointX, lassoPoints.y[i]])]
  } else if (selections) {
    const s = selections[0]
    const { x0, x1, y0, y1 } = getCoordsFromShape(s)

    // creating a box counterclockwise from lower left point
    poly = [
      [
        [x0, y0],
        [x1, y0],
        [x1, y1],
        [x0, y1],
        [x0, y0],
      ],
    ]
  }

  return poly
}

const shouldShowBasedOnComparisonDimensions = (
  pinnedCellGroup: PinnedCellGroup,
  dimensions?: CellInfoDimension[]
) => {
  const { comparisonDimensionsSnapshot: pcgDimensions } = pinnedCellGroup

  // Show if...
  // there are no comparison dimensions vars
  // OR selected comparison dimensions matches passed in comparison dimensions
  return (
    (!pcgDimensions && !dimensions) ||
    (pcgDimensions?.length === dimensions?.length &&
      pcgDimensions?.every((x, i) => dimensions && x.field === dimensions[i].field))
  )
}

export const getMorphotypeSelectionInfo = (
  morphotypes?: PinnedCellGroup[],
  visibleDimensions?: CellInfoDimension[]
): {
  /**
   * Add to Plot.layout to display the shapes that were selected
   */
  shapes: Partial<Shape>[]
  /**
   * Add to Plot.layout.annotations to show the labels of the shapes that were selected
   */
  shapeAnnotations: Partial<Annotations>[]
} => {
  if (!morphotypes)
    return {
      shapes: [],
      shapeAnnotations: [],
    }

  const selectionInfo = morphotypes.flatMap((pinnedCellGroup, currentIndex) => {
    // older sessions' version config won't have transposed cells so use regular cells
    const { selections } = pinnedCellGroup.transposedCells ?? pinnedCellGroup.cells ?? {}

    if (
      !pinnedCellGroup.active ||
      !selections ||
      !shouldShowBasedOnComparisonDimensions(pinnedCellGroup, visibleDimensions)
    )
      return []

    const shapeProps = getShapeStandardProps(pinnedCellGroup)
    let shapesToUse = selections.map(
      (x) =>
        ({
          ...x,
          ...shapeProps,
        } as Partial<Shape>)
    )

    const matchingIndexes = morphotypes.flatMap((x, i) => (x.id === pinnedCellGroup.id ? i : []))

    if (matchingIndexes.length > 1) {
      // we only need to find the union of the shapes the first time
      if (matchingIndexes[0] === currentIndex) {
        const polys: Poly[] = matchingIndexes.map((pcgIndex) =>
          getPolygonFromPinnedCellGroup(morphotypes[pcgIndex])
        )

        // union returns ALL unions separated into groups
        const allUnionPolygons = union(polys[0], polys.slice(1))

        const paths = allUnionPolygons.map((unionPolygon) => {
          let path = ''
          unionPolygon[0].forEach((pair, i) => {
            path += `${i === 0 ? 'M' : 'L'}${pair[0]},${pair[1]}`
          })
          return path
        })

        shapesToUse = paths.map(
          (path) =>
            ({
              type: 'path',
              path,
              ...shapeProps,
            } as Partial<Shape>)
        )
      } else {
        return []
      }
    }

    const highestPoints = shapesToUse.map((shape) => getHighestPointFromShapes(shape))

    return {
      shapes: shapesToUse,
      shapeAnnotations: highestPoints.map(
        (highestPoint) =>
          ({
            x: highestPoint.x,
            y: highestPoint.y,
            ...getAnnotationStandardProps(pinnedCellGroup),
          } as Partial<Annotations>)
      ),
    }
  })

  return {
    shapes: selectionInfo.flatMap((x) => x.shapes),
    shapeAnnotations: selectionInfo.flatMap((x) => x.shapeAnnotations),
  } as const
}

export default getMorphotypeSelectionInfo
