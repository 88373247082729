import { Stack } from '@mui/material'
import DeepcellPopover from 'components/shared/DeepcellPopover'
import DeepcellPrimaryButton from 'components/shared/DeepcellPrimaryButton'
import HubspotReactForm from 'components/shared/HubspotReactForm'
import useFlagCondition from 'components/shared/useFlagCondition'
import { isExternalDemo } from 'config'
import { useState } from 'react'
import useEventsManager from 'redux/slices/hooks/useEventsManager'
import useAuthTokens from 'utils/useAuthTokens'
import useCellVisualizationUrlParams from '../useCellVisualizationUrlParams'
import DataVizWalkthroughButton from '../walkthrough/DataVizWalkthroughButton'
import { SaveSessionMenu } from './SaveSessionMenu'
import UploadRunDataTsvButton from './UploadRunDataTsvButton'

import { ShareButton } from './ShareButton'
import { UploadCellDataTsvButton } from './UploadCellDataTsvButton'

export const NavTopBarRightSideButtons = (): JSX.Element => {
  const { sessionId } = useCellVisualizationUrlParams()
  const eventsManager = useEventsManager()
  const [showContactUs, setShowContactUs] = useState(false)
  const showUploadRunDataTSV = useFlagCondition('showUploadRunDataTSV')
  const { isInternalUser } = useAuthTokens()

  const buttons: JSX.Element[] = []

  if (isExternalDemo) {
    buttons.push(
      <DeepcellPrimaryButton
        key={buttons.length}
        contained
        onClick={() => {
          eventsManager.sendContactUsByEvent(new Date().getTime())
          setShowContactUs(true)
        }}
        sx={{ width: '200px', marginRight: '15px' }}
      >
        Get in touch
      </DeepcellPrimaryButton>
    )
  }

  if (sessionId && sessionId > 0) {
    buttons.push(<SaveSessionMenu key={buttons.length} />)
    buttons.push(<DataVizWalkthroughButton key={buttons.length} />)
    if (!isExternalDemo) {
      buttons.push(<ShareButton key={buttons.length} />)
    }
  } else {
    // cell viz landing page
    if (isInternalUser) {
      buttons.push(<UploadCellDataTsvButton key={buttons.length} />)
    }
    if (showUploadRunDataTSV) {
      buttons.push(<UploadRunDataTsvButton key={buttons.length} />)
    }
  }

  return (
    <Stack direction="row" spacing={1}>
      {buttons}
      <DeepcellPopover
        open={showContactUs}
        sx={{
          '& .MuiPopover-paper': {
            width: '400px',
            minHeight: '450px',
            inset: 'unset !important',
            top: '48px !important',
            right: '16px !important',
          },
        }}
        onClose={() => setShowContactUs(false)}
      >
        <HubspotReactForm
          portalId="22481347"
          formId="078978ad-3b6c-4eec-ba76-73ec4bb2849e"
          onFormSubmitted={() => {
            eventsManager.sendContactedUsSubmittedEvent('navbar')
          }}
        />
      </DeepcellPopover>
    </Stack>
  )
}

export default NavTopBarRightSideButtons
