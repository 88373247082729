import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { IconButton, Typography } from '@mui/material'
import { useTablePagination } from 'components/shared/CompactTablePagination'
import Row from 'components/shared/Row'
import { PaginationParams } from 'redux/slices'
import { getSliceFromPagination } from 'utils/helpers'
import { SmallSelect } from '../../shared/SmallSelect'

export type MorphotypePaginationProps = {
  totalCount: number
  pagination: PaginationParams
  setPagination(p: PaginationParams): void
  items?: number[]
  showSelect?: boolean
  showPagination?: boolean
}

export const MorphotypePagination = ({
  totalCount,
  pagination,
  setPagination,
  items = [100, 150, 200],
  showSelect = true,
  showPagination = true,
}: MorphotypePaginationProps): JSX.Element => {
  const { page, itemsPerPage, changePage, changeItemsPerPage } = useTablePagination({
    onPaginationChange: setPagination,
    defaultPage: 0,
    defaultItemsPerPage: 100,
  })

  const { startIndex, endIndex } = getSliceFromPagination({
    count: totalCount,
    page: pagination.page ?? 0,
    itemsPerPage: pagination.itemsPerPage ?? items[0],
  })

  const textOutput = `${startIndex + 1}-${endIndex} of ${totalCount}`

  return (
    <Row alignItems="center">
      {showSelect ? (
        <SmallSelect items={items} value={itemsPerPage} onChange={(v) => changeItemsPerPage(v)} />
      ) : null}
      {showPagination ? (
        <Row mx={1}>
          <IconButton
            sx={{ p: 0, color: 'rgba(95, 85, 209, 1)' }}
            disabled={startIndex === 0}
            onClick={() => changePage(page - 1)}
          >
            <ArrowBackIosIcon />
          </IconButton>
          <Typography width="max-content" textAlign="center">
            {textOutput}
          </Typography>
          <IconButton
            sx={{ p: 0, color: 'rgba(95, 85, 209, 1)' }}
            disabled={endIndex === totalCount}
            onClick={() => changePage(page + 1)}
          >
            <ArrowForwardIosIcon />
          </IconButton>
        </Row>
      ) : null}
    </Row>
  )
}

export default MorphotypePagination
