import { Box, styled } from '@mui/material'
import useCellBrowsingSlice from 'redux/slices/hooks/useCellBrowsingSlice'
import * as api from '../../../utils/api'
import { messagesEqual } from '../../../utils/proto-utils'
import CellImageControl, { CellImageAction } from '../../shared/CellImageControl'
import useCellBrowsingQueryParams from '../useCellBrowsingQueryParams'
import { IMAGE_SIZE } from '../constants'

const CellImagesContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  overflow: 'auto',
  lineHeight: 0,
  gap: '1px',
  flexWrap: 'wrap',
})

interface CellImagesProps {
  cellData: api.CellResponse[]
  handleQueryUpdate: (action: CellImageAction) => void
}

function CellImages({ cellData, handleQueryUpdate }: CellImagesProps): JSX.Element {
  const { displayOptions } = useCellBrowsingQueryParams()
  const {
    cellBrowsing: { selectedCell },
  } = useCellBrowsingSlice()
  return (
    <CellImagesContainer>
      {cellData.map((cell: api.CellResponse, i) => {
        const cellId = cell.cell.getCellId()
        const highlightCell = messagesEqual(cellId, selectedCell.cell.getCellId())
        const key = `${cellId?.getTime()}_${cellId?.getNumber()}_${cellId?.getInstrumentId()}`
        if (cellId) {
          return (
            <CellImageControl
              key={key || i.toString()}
              width={IMAGE_SIZE}
              cell={cell}
              onCellImageAction={handleQueryUpdate}
              highlighted={highlightCell}
              displayOptions={displayOptions}
            />
          )
        }
        return null
      })}
    </CellImagesContainer>
  )
}

export default CellImages
