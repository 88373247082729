import UploadIcon from '@mui/icons-material/Upload'
import StartAnalysisDlg from 'components/cell-visualizations/startAnalysis/StartAnalysisDlg'
import { DeepcellPrimaryButton } from 'components/shared'
import _ from 'lodash'
import { ChangeEvent, useRef, useState } from 'react'
import { useQueryClient } from 'react-query'
import { useNotificationSlice } from 'redux/slices/hooks'
import useEventsManager from 'redux/slices/hooks/useEventsManager'
import { Run, getRuns } from 'utils/api'
import useReadCSV from 'utils/useReadCSV'

const validExtensions = ['tsv', 'csv']

const getFileDataAsString = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = () => {
      resolve(reader.result as string)
    }

    reader.onerror = () => {
      reject(new Error('Error reading file.'))
    }

    reader.readAsText(file)
  })
}

export const UploadRunDataTsvButton = (): JSX.Element => {
  const { showError } = useNotificationSlice()
  const eventsManager = useEventsManager()
  const readCSV = useReadCSV()
  const uploadInputRef = useRef<HTMLInputElement>(null)
  const [startAnalysisIsOpen, setStartAnalysisIsOpen] = useState(false)
  const queryClient = useQueryClient()

  const [runs, setRuns] = useState<Run[]>([])
  /**
   * Additional fields that exist on the uploaded run(s) file
   */
  const [fields, setFields] = useState<Record<string, string>[]>([])

  const onUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return
    const file = e.target.files[0]
    if (uploadInputRef.current) {
      uploadInputRef.current.value = ''
    }

    const extension = file.name.split('.').slice(-1)[0]

    if (validExtensions.includes(extension)) {
      eventsManager.startUploadRunDataTsv(file)
      const fileAsString = await getFileDataAsString(file)

      const resultsFromCsv = (await readCSV(fileAsString)) as ({ 'Run Id'?: string } & {
        [S in string]: string
      })[]

      if (!(resultsFromCsv[0] && resultsFromCsv[0]['Run Id'])) {
        showError('"Run Id" is a required column and was not found on uploaded file')
      } else {
        try {
          const runIds = _.uniq(resultsFromCsv.flatMap((r) => r['Run Id'] ?? []))
          const runResults = await queryClient.fetchQuery(
            ['getCellRunData', { run_ids: runIds }],
            getRuns,
            {
              staleTime: 0,
            }
          )
          setRuns(runResults)

          setFields(resultsFromCsv)
          setStartAnalysisIsOpen(true)
        } catch {
          showError('Unable to retrieve run information')
        }
      }
    } else {
      showError(
        `You've uploaded a .${extension} file, but the supported extensions are ${validExtensions.join(
          ', '
        )}.`
      )
    }
  }

  const onClick = (e: ChangeEvent<HTMLInputElement>) => {
    setFields([])
    onUpload(e)
  }

  return (
    <>
      <DeepcellPrimaryButton
        sx={{ width: 230 }}
        startIcon={<UploadIcon />}
        contained
        onClick={() => uploadInputRef.current && uploadInputRef.current.click()}
      >
        Upload Run Data
        <input
          ref={uploadInputRef}
          data-testid="upload-run-data-input"
          hidden
          type="file"
          onChange={onClick}
        />
      </DeepcellPrimaryButton>
      {fields.length ? (
        <StartAnalysisDlg
          isOpen={startAnalysisIsOpen}
          handleClose={() => setStartAnalysisIsOpen(false)}
          handleStartAnalysis={() => eventsManager.confirmUploadRunDataTsv()}
          runs={runs}
          customRunData={fields}
          hideMorphometricsModel
        />
      ) : null}
    </>
  )
}

export default UploadRunDataTsvButton
