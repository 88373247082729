import { Box, IconButton, InputAdornment, InputBase, Stack, debounce, styled } from '@mui/material'
import React, { useCallback, useState } from 'react'
import useRunSearchFilterSlice from 'redux/slices/hooks/useRunSearchFilterSlice'
import { RunSearchFilterState } from 'redux/slices/RunSearchFilterSlice'
import DropdownArrowIcon from 'components/shared/icons/DropdownArrow'
import { ColumnApi } from 'ag-grid-community'
import { AdvancedRunSearchFilterPopover } from './AdvancedRunSearchFilterPopover/AdvancedRunSearchFilterPopover'
import SelectV3 from './AdvancedRunSearchFilterPopover/SelectV3'
import PrimaryButton from './PrimaryButton'

// @TODO After doing this another time or two, refactor and pull out common search panel functionality into a component

interface RunSearchFiltersProps {
  handleCancel?: (clear?: boolean) => void
  handleSearch: (searchFilters?: RunSearchFilterState) => void
  columnApi: ColumnApi | null
}

const SearchContainer = styled(Box)({
  minWidth: '893px',
  display: 'flex',
  flexGrow: 1,
  height: '32px',
  borderRadius: '5px',
})

const StyledSearchInput = styled(InputBase)({
  border: '1px solid #EDEDED',
  background: '#EDEDED',
  height: '32px',
  padding: '10px',
  borderRadius: '5px',
  marginLeft: '10px',
})

const Options = [
  { label: 'Recently Started', value: 'start_time' },
  { label: 'Run Id', value: 'run_id' },
  { label: 'Operator', value: 'user_email' },
  { label: 'Run type', value: 'well_sorting_configurations' },
]

export function RunSearchFilter({
  handleCancel: handleCancelProp,
  handleSearch: handleSearchProp,
  columnApi,
}: RunSearchFiltersProps): JSX.Element {
  const { update, runSearchFilter } = useRunSearchFilterSlice()
  const [optionSelected, setOptionSelected] = useState(Options[0].value)

  // Helps typing feel interactive while delaying more expensive search updates
  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  const debounceHandleSearch = useCallback(debounce(handleSearchProp, 250), [])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newRunSearchFilter: RunSearchFilterState = {
      ...runSearchFilter,
      keyword: e.target.value,
    }
    update(newRunSearchFilter)
    debounceHandleSearch(newRunSearchFilter)
  }

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [openPopper, setOpenPopper] = useState(false)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
    setOpenPopper(!openPopper)
  }

  const handleSearch = () => {
    handleSearchProp()
    setOpenPopper(false)
  }

  const handleCancel = (clear = true) => {
    if (handleCancelProp) handleCancelProp(clear)
    setOpenPopper(false)
  }

  const handleColumnSort = (colId: string) => {
    let columnState
    switch (colId) {
      case 'user_email':
        columnState = {
          state: [{ colId, sort: 'asc' }],
          defaultState: { sort: null },
        }
        break
      default:
        columnState = {
          state: [{ colId, sort: 'desc' }],
          defaultState: { sort: null },
        }
    }

    columnApi?.applyColumnState(columnState)
    setOptionSelected(colId)
  }

  return (
    <div>
      <Stack sx={{ height: '60px', pt: '13px', pb: '15px' }} direction="row" gap="12px">
        <SearchContainer data-testid="runs-search-bar">
          <StyledSearchInput
            placeholder="Search"
            fullWidth
            onChange={handleChange}
            value={runSearchFilter.keyword ?? ''}
            endAdornment={
              <InputAdornment position="end">
                <IconButton data-testid="search-arrow-icon" sx={{ p: 0 }} onClick={handleClick}>
                  <DropdownArrowIcon />
                </IconButton>
              </InputAdornment>
            }
          />
        </SearchContainer>
        <Box data-testid="advanced-search-btn" sx={{ width: '165px', height: '32px' }}>
          <PrimaryButton sx={{ fontSize: '12px' }} onClick={handleClick}>
            {runSearchFilter.advancedSearchEnabled ? 'Refine' : 'Advanced'} Search
          </PrimaryButton>
        </Box>
        <Box data-testid="sort-runs-table" sx={{ width: '161px', height: '32px' }}>
          <SelectV3
            size="small"
            items={Object.entries(Options).map((e) => ({
              key: e[0],
              value: e[1].value,
              output: e[1].label,
            }))}
            value={optionSelected}
            onChange={(event) => handleColumnSort(event.target.value as unknown as string)}
          />
        </Box>
      </Stack>
      <AdvancedRunSearchFilterPopover
        open={openPopper}
        anchorEl={anchorEl}
        handleCancel={handleCancel}
        handleSearch={handleSearch}
      />
    </div>
  )
}

export default RunSearchFilter
