import { createSvgIcon } from '@mui/material'

const InfoIcon = createSvgIcon(
    <svg width="24" height="24" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M7.51048 5.384C7.32448 5.384 7.16848 5.321 7.04248 5.195C6.91648 5.069 6.85348 4.913 6.85348 4.727C6.85348 4.541 6.91648 4.385 7.04248 4.259C7.16848 4.133 7.32448 4.07 7.51048 4.07C7.69048 4.07 7.84348 4.133 7.96948 4.259C8.09548 4.385 8.15848 4.541 8.15848 4.727C8.15848 4.913 8.09548 5.069 7.96948 5.195C7.84348 5.321 7.69048 5.384 7.51048 5.384ZM8.01448 6.041V11H6.98848V6.041H8.01448Z"
            fill="#5F55D1"
        />
        <circle cx="7.5" cy="7.5" r="7" stroke="#5F55D1" />
    </svg>,
    'Info'
)

export default InfoIcon
