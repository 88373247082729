import { AppBar, Toolbar } from '@mui/material'
import { usePlotlySlice } from 'redux/slices/hooks'
import { useCellVisualizationsSlice } from 'redux/slices/hooks/useCellVisualizationsSlice'
import useFlagCondition from 'components/shared/useFlagCondition'
import PlotImagesFilter from './plot/plotLayoutImages/PlotImagesFilter'
import EditPreFiltersButton from './prefilter/EditPreFiltersButton'
import useCellVisualizationUrlParams from './useCellVisualizationUrlParams'

export const CellVisualizationFooter = (): JSX.Element => {
  const {
    setSelectedComparisonDimensions,
    cellVisualizations: { selectedComparisonDimensions },
  } = useCellVisualizationsSlice()
  const { isPreFilter, enterPreFilter } = useCellVisualizationUrlParams()
  const preFilterEnabled = useFlagCondition('cellVisualizationsPrefilterEnabled')
  const showEditPreFilter = preFilterEnabled && !isPreFilter

  const { resetRange } = usePlotlySlice()

  const handleEditPreFilter = () => {
    if (selectedComparisonDimensions) {
      resetRange()
      setSelectedComparisonDimensions()
    }
    enterPreFilter()
  }

  return (
    <AppBar
      position="fixed"
      color="primary"
      sx={{ top: 'auto', bottom: 0, backgroundColor: 'white' }}
    >
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        {showEditPreFilter ? <EditPreFiltersButton onClick={handleEditPreFilter} /> : <div />}
        {selectedComparisonDimensions ? <div /> : <PlotImagesFilter />}
        <div />
      </Toolbar>
    </AppBar>
  )
}

export default CellVisualizationFooter
