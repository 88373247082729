import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import { DeepcellPrimaryButton } from 'components/shared'

interface Props {
  onClick(): void
}

export const EditPreFiltersButton = ({ onClick }: Props): JSX.Element => {
  return (
    <DeepcellPrimaryButton sx={{ paddingLeft: 2, letterSpacing: '.1em' }} text onClick={onClick}>
      <KeyboardBackspaceIcon />
      Edit pre-filters
    </DeepcellPrimaryButton>
  )
}

export default EditPreFiltersButton
