import { createSvgIcon } from '@mui/material'

const DrawerOpenIcon = createSvgIcon(
  <svg width="47" height="40" viewBox="0 0 47 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="-0.650106"
      y="0.650106"
      width="45.6998"
      height="38.6998"
      rx="4.55074"
      transform="matrix(-1 0 0 1 45.6998 0)"
      fill="white"
      stroke="#A4EFC0"
      strokeWidth="1.30021"
    />
    <path
      d="M16.81 15.7102C18.7186 16.8903 20.4281 18.2736 21.8941 19.8237C22.0783 20.0184 22.0783 20.2909 21.8941 20.4856C20.4281 22.0357 18.7186 23.419 16.81 24.5991"
      stroke="#303A47"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.1538 15.7102C26.0623 16.8903 27.7718 18.2736 29.2379 19.8237C29.422 20.0184 29.422 20.2909 29.2379 20.4856C27.7718 22.0357 26.0623 23.419 24.1538 24.5991"
      stroke="#303A47"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  'DrawerOpen'
)

export default DrawerOpenIcon
