import { styled } from '@mui/material'
import { Annotations, Image, Shape } from 'plotly.js'
import Plotly from 'plotly.js/dist/plotly-axon'
import { PlotParams } from 'react-plotly.js'
import createPlotlyComponent from 'react-plotly.js/factory'
import { usePlotlySlice } from 'redux/slices/hooks'
import { useCellVisualizationsSlice } from 'redux/slices/hooks/useCellVisualizationsSlice'
import { CellPlotData } from 'redux/slices/types'
import useFlagCondition from 'components/shared/useFlagCondition'
import usePanelHeight from '../cellSelectionDrawer/usePanelHeight'
import { onPlotUpdate } from './onPlotUpdate'
import usePlotDataWithScale from './usePlotDataWithScale'
import useCellVisualizationUrlParams from '../useCellVisualizationUrlParams'

// customizable method: use your own `Plotly` object
const Plot = createPlotlyComponent(Plotly)

export const StyledPlot = styled(Plot)({
  '& * .layer-above .imagelayer image': {
    clipPath: 'circle()',
  },
  '& * .modebar': {
    visibility: 'hidden',
  },
})

type BasePlotProps = {
  plotData?: CellPlotData[]
  shapes?: Partial<Shape>[]
  annotations?: Partial<Annotations>[]
  images?: Partial<Image>[]
  style?: React.CSSProperties
} & Pick<PlotParams, 'onRelayout' | 'onSelected' | 'onClick'>

export const BasePlot = ({
  plotData = [],
  shapes = [],
  annotations = [],
  images = [],
  style,
  onClick,
  onRelayout,
  onSelected,
}: BasePlotProps): JSX.Element => {
  const { height: plotHeight } = usePanelHeight(0)
  const {
    setRange,
    plotly: { range },
  } = usePlotlySlice()
  const {
    cellVisualizations: { dataRevision, plotDisplayType },
  } = useCellVisualizationsSlice()
  const { isPreFilter } = useCellVisualizationUrlParams()
  const preFilterEnabled = useFlagCondition('cellVisualizationsPrefilterEnabled')
  const demoEnabled = useFlagCondition('demoEnabled')

  const showPreFilter = isPreFilter && preFilterEnabled && !demoEnabled
  const plotDataWithScale = usePlotDataWithScale(plotData, plotDisplayType, showPreFilter);
  return (
    <StyledPlot
      data={[...plotDataWithScale]}
      style={{
        width: '100%',
        height: `${plotHeight}px`,
        position: 'relative',
        top: '-5px',
        ...style,
      }}
      layout={{
        margin: { t: 0, b: 0, l: 0, r: 0, pad: 0 },
        shapes,
        xaxis: {
          visible: false,
          range: [range?.x1, range?.x2],
        },
        yaxis: {
          visible: false,
          scaleanchor: 'x',
          scaleratio: 1,
          range: [range?.y1, range?.y2],
        },
        showlegend: false,
        clickmode: 'event',
        annotations,
        uirevision: dataRevision,
        images,
        // this is only default dragmode. This gets changed internally because plotly is a bad boy and doesn't play by React's rules
        dragmode: 'pan',
      }}
      onRelayout={onRelayout}
      onSelected={onSelected}
      onClick={onClick}
      onUpdate={onPlotUpdate(range, setRange)}
      useResizeHandler
      config={{
        responsive: true,
        displaylogo: false,
        displayModeBar: true,
        scrollZoom: true,
      }}
    />
  )
}

export default BasePlot
