import { createSvgIcon } from '@mui/material'

const ShowIcon = createSvgIcon(
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.00188 7.70481C7.75366 7.70481 8.39205 7.44169 8.91705 6.91544C9.44205 6.38919 9.70455 5.75018 9.70455 4.99839C9.70455 4.24661 9.44142 3.60822 8.91517 3.08322C8.38893 2.55822 7.74991 2.29572 6.99812 2.29572C6.24634 2.29572 5.60795 2.55885 5.08295 3.0851C4.55795 3.61134 4.29545 4.25035 4.29545 5.00214C4.29545 5.75392 4.55858 6.39231 5.08483 6.91731C5.61107 7.44231 6.25009 7.70481 7.00188 7.70481ZM6.99626 6.78208C6.50027 6.78208 6.07992 6.60849 5.73523 6.2613C5.39053 5.9141 5.21818 5.49251 5.21818 4.99653C5.21818 4.50054 5.39178 4.08019 5.73897 3.73549C6.08617 3.3908 6.50776 3.21845 7.00374 3.21845C7.49973 3.21845 7.92008 3.39204 8.26477 3.73923C8.60947 4.08643 8.78182 4.50802 8.78182 5.00401C8.78182 5.5 8.60822 5.92034 8.26103 6.26504C7.91383 6.60974 7.49224 6.78208 6.99626 6.78208ZM7 9.77299C5.45152 9.77299 4.05152 9.33284 2.8 8.45254C1.54848 7.57224 0.615152 6.42148 0 5.00027C0.615152 3.57905 1.54848 2.4283 2.8 1.54799C4.05152 0.667691 5.45152 0.227539 7 0.227539C8.54848 0.227539 9.94848 0.667691 11.2 1.54799C12.4515 2.4283 13.3848 3.57905 14 5.00027C13.3848 6.42148 12.4515 7.57224 11.2 8.45254C9.94848 9.33284 8.54848 9.77299 7 9.77299ZM6.99731 8.81845C8.28244 8.81845 9.46326 8.4711 10.5398 7.7764C11.6163 7.08171 12.4356 6.15633 12.9977 5.00027C12.4356 3.84421 11.6172 2.91883 10.5425 2.22413C9.46773 1.52943 8.2878 1.18208 7.00269 1.18208C5.71756 1.18208 4.53674 1.52943 3.46023 2.22413C2.38371 2.91883 1.55909 3.84421 0.986364 5.00027C1.55909 6.15633 2.38282 7.08171 3.45754 7.7764C4.53227 8.4711 5.7122 8.81845 6.99731 8.81845Z"
      fill="#DEDEDE"
    />
  </svg>,
  'Show'
)

export default ShowIcon
