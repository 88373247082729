import {
  Box,
  IconButton,
  ImageList,
  ImageListItem,
  ListItem,
  Skeleton,
  Stack,
  Typography,
  styled,
} from '@mui/material'
import CloseIcon from 'components/shared/icons/CloseIcon'
import { CellsByGroup } from 'redux/slices'
import { useCellVisualizationsSlice } from 'redux/slices/hooks/useCellVisualizationsSlice'
import { DeepcellPrimaryButton } from 'components/shared'
import useMorphotypeBatchDownloader from 'components/shared/useMorphotypeBatchDownloader'
import CellImageDownloadDialog from 'components/cell-browsing/CellImageDownloadDialog'
import { useState } from 'react'
import DropdownModal from 'components/runs/DropdownModal'
import { useCellImageUrlFromCellId } from '../tsv/utils'

const styleProps = {
  width: '43.55px',
  height: '44px',
  borderRadius: '4px',
}

const HeaderLabel = styled(Typography)({
  fontSize: '16px',
  lineHeight: '16.96px',
  letterSpacing: '0.1em',
  textAlign: 'left',
})

const MorphotypeCardContainer = styled(Box)({
  border: '0.5px solid #D1D1D1',
  width: '275px',
  minHeight: '87px',
  maxHeight: '134px',
  padding: '10px',
})

const MorphotypeGroupLabel = styled(Typography)({
  width: '100%',
  fontSize: '12px',
  lineHeight: '12.72px',
  textAlign: 'left',
  color: '#172623',
})

const StyledTypography = styled(Typography)({
  fontSize: '14px',
  lineHeight: '20px',
  letterSpacing: '0em',
  textAlign: 'center',
})

interface ProgressbarProps {
  completed: number | string
}

const ProgressContainer = styled('div')({
  height: '17px',
  width: '100%',
  padding: '1px',
  boxSizing: 'border-box',
  backgroundColor: '#EEEFFF',
  borderRadius: '10px',
  border: '1px solid #A4EFC0',
  display: 'flex',
  alignItems: 'center',
  flexShrink: 0,
})

const StyledListItem = styled(ListItem)({
  color: '#000',
  fontSize: '12px',
  fontWeight: 'bold',
  letterSpacing: '1.2px',
  textTransform: 'uppercase',
  cursor: 'pointer',
  '&:hover': {
    background: '#E6E4FF',
  },
  '&:active': {
    color: '#5F55D1',
    background: '#E6E4FF',
  },
  '& > a': {
    textDecoration: 'none',
    color: '#000',
  },
})

const ProgressBar = styled('div', {
  shouldForwardProp: (props) => props !== 'completed',
})<ProgressbarProps>(({ completed }) => {
  return {
    height: '100%',
    transition: 'width 3s ease-in-out',
    width: `${completed}%`,
    backgroundColor: '#5F55D1',
    borderRadius: completed === '100.0' ? '10px' : '10px 5px 5px 10px',
  }
})

const CellImage = ({ cell }: { cell: string }): JSX.Element => {
  const { imageDataUrl } = useCellImageUrlFromCellId(cell)

  return (
    <ImageListItem>
      {imageDataUrl ? (
        <img style={styleProps} data-testid="plotCellImageImgElement" src={imageDataUrl} alt="" />
      ) : (
        <Skeleton data-testid="plotCellImageSkeleton" variant="rectangular" sx={styleProps} />
      )}
    </ImageListItem>
  )
}

const MorphotypeCard = ({ morphoGroupData }: { morphoGroupData: CellsByGroup }): JSX.Element => {
  const { cellIds, groupName } = morphoGroupData
  return (
    <MorphotypeCardContainer>
      <Stack gap="10px">
        <MorphotypeGroupLabel>{groupName}</MorphotypeGroupLabel>
        <ImageList sx={{ maxHeight: '96px', margin: '0px' }} cols={5} rowHeight={44}>
          {cellIds.map((cell) => {
            return <CellImage key={cell} cell={cell} />
          })}
        </ImageList>
      </Stack>
    </MorphotypeCardContainer>
  )
}

const ExportMorphotypeContent = (): JSX.Element => {
  const [modalState, setModalState] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [showModal, setShowModal] = useState<boolean>(false)

  const {
    clearExportMorphotype,
    cellVisualizations: { exportCellMorphotype },
  } = useCellVisualizationsSlice()

  const downloadConfig = {
    groups: exportCellMorphotype,
    zippedFileName: '[Morphotypes]',
    downloadLimit: 10,
    timeFrame: 10,
  }

  const { handleDownloadZip: handlePng, loading: loadingPng } = useMorphotypeBatchDownloader(
    'cellVizPng',
    {
      ...downloadConfig,
      type: 'png',
    }
  )

  const { handleDownloadZip: handleTiff, loading: loadingTiff } = useMorphotypeBatchDownloader(
    'cellVizTiff',
    {
      ...downloadConfig,
      type: 'tiff',
    }
  )

  const handleExport = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget)
    setModalState(!modalState)
  }

  return (
    <>
      <Stack sx={{ height: '100%', position: 'relative' }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <HeaderLabel>Export Selections ({exportCellMorphotype.length})</HeaderLabel>
          <IconButton sx={{ p: 0 }}>
            <CloseIcon onClick={() => clearExportMorphotype()} />
          </IconButton>
        </Stack>
        <Stack sx={{ flexGrow: 1, overflowY: 'auto' }} mt="16px" gap="10px">
          {exportCellMorphotype.map((morpho) => {
            return morpho.cellIds.length ? (
              <MorphotypeCard key={morpho.groupId} morphoGroupData={morpho} />
            ) : (
              <></>
            )
          })}
        </Stack>
        <Stack sx={{ paddingY: '20px' }}>
          <DeepcellPrimaryButton
            disabled={loadingPng || loadingTiff}
            onClick={handleExport}
            contained
            type="button"
          >
            Export
          </DeepcellPrimaryButton>
        </Stack>
      </Stack>

      <DropdownModal
        placement="top-start"
        disablePortal={false}
        transition
        anchorEl={anchorEl}
        open={modalState}
        onClickAway={() => setModalState(false)}
      >
        <Box sx={{ width: anchorEl?.clientWidth || '118px' }}>
          <StyledListItem
            onClick={() => {
              setShowModal(true)
              handlePng()
            }}
          >
            PNG
          </StyledListItem>
          <StyledListItem
            onClick={() => {
              setShowModal(true)
              handleTiff()
            }}
          >
            TIFF
          </StyledListItem>
        </Box>
      </DropdownModal>

      <CellImageDownloadDialog
        id="download-modal"
        title="Exporting"
        keepMounted
        open={showModal && (loadingPng || loadingTiff)}
        onClose={() => {
          setShowModal(false)
        }}
      >
        <Stack gap="10px">
          <StyledTypography>
            Exporting the selected cell images. It may take some time.
          </StyledTypography>
          <ProgressContainer>
            <ProgressBar completed="40" />
          </ProgressContainer>
        </Stack>
      </CellImageDownloadDialog>
    </>
  )
}

export default ExportMorphotypeContent
