import {
  Card,
  CardContent,
  ClickAwayListener,
  Tooltip,
  TooltipProps,
  styled,
  tooltipClasses,
} from '@mui/material'
import { CellInfoDimension, CellInfoGroups } from 'redux/slices'
import usePanelHeight from '../cellSelectionDrawer/usePanelHeight'
import { FiltersAppliedComparisonPlotSection } from './FiltersAppliedComparisonPlotSection'
import { FiltersAppliedCellInfoGroupsSection } from './FiltersAppliedCellInfoGroupsSection'

interface FiltersAppliedTooltipProps extends Omit<TooltipProps, 'title'> {
  cellInfoGroups?: CellInfoGroups
  comparisonDimensions?: CellInfoDimension[]
}
const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.background.default,
    border: `1px solid ${theme.palette.info.dark}`,
    color: theme.palette.text.primary,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.info.dark,
  },
}))

export const FiltersAppliedTooltip = ({
  cellInfoGroups,
  comparisonDimensions,
  children,
  onClose,
  ...rest
}: FiltersAppliedTooltipProps): JSX.Element => {
  const { height } = usePanelHeight()
  const maxHeightCSS = `${height - 160}px`

  if (!rest.open) return <>{children}</>

  return (
    <ClickAwayListener
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
      onClickAway={(e) => onClose && onClose(e)}
    >
      <div>
        <StyledTooltip
          {...rest}
          arrow
          open
          PopperProps={{
            placement: 'left',
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, -10],
                },
              },
            ],
          }}
          title={
            <Card elevation={0} sx={{ width: '285px' }}>
              <CardContent sx={{ p: 1 }}>
                {comparisonDimensions ? (
                  <FiltersAppliedComparisonPlotSection
                    comparisonDimensions={comparisonDimensions}
                  />
                ) : null}
                {cellInfoGroups ? (
                  <FiltersAppliedCellInfoGroupsSection
                    cellInfoGroups={cellInfoGroups}
                    maxHeight={maxHeightCSS}
                  />
                ) : null}
              </CardContent>
            </Card>
          }
        >
          {children}
        </StyledTooltip>
      </div>
    </ClickAwayListener>
  )
}

export default FiltersAppliedTooltip
