import * as ROUTES from 'constants/routes'

import { CellVisualizationNavTopBar } from 'components/cell-visualizations/topBar'
import { NavigationTopBar } from 'components/navigationTopBar'
import { Helmet } from 'react-helmet'
import { useRouteMatch } from 'react-router-dom'
import useFlags from './useFlags'
import DeepcellDowntimeAlert from './DeepcellDowntimeAlert'

export const DeepcellHeader = (): JSX.Element => {
  const { demoEnabled } = useFlags()
  const isDemo = demoEnabled === 'yes'
  const isCellViz = useRouteMatch(ROUTES.ALL_CELL_VISUALIZATION_ROUTES)
  const isRegister = useRouteMatch(ROUTES.DEMO_REGISTER)
  const isDemoDatasets = useRouteMatch(ROUTES.DEMO_DATASETS)

  let output = <NavigationTopBar />

  if (isCellViz) {
    output = <CellVisualizationNavTopBar />
  }

  if (isRegister || isDemoDatasets) {
    output = <></>
  }

  return (
    <>
      {isDemo ? (
        <Helmet>
          <title>Axon Data Suite</title>
        </Helmet>
      ) : (
        <></>
      )}
      {output}
      <DeepcellDowntimeAlert />
    </>
  )
}

export default DeepcellHeader
