import CheckIcon from '@mui/icons-material/Check'
import { PopoverProps, ToggleButton, styled } from '@mui/material'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import { CATEGORICAL_COLOR_PALETTE } from 'components/cell-visualizations/shared'
import DeepcellPopover from 'components/shared/DeepcellPopover'
import React, { useState } from 'react'

interface StyledToggleButtonProps {
  bgcolor: string
}

export const StyledToggleButton = styled(ToggleButton)(({ bgcolor }: StyledToggleButtonProps) => ({
  borderRadius: 0,
  '&.Mui-disabled': {
    border: 0,
    cursor: 'not-allowed',
    pointerEvents: 'auto',
  },
  '&.Mui-selected, &.Mui-selected:hover, &:hover': {
    backgroundColor: bgcolor,
  },
  width: '100%',
  height: 50,
  backgroundColor: bgcolor,
  cursor: 'pointer',
}))

interface LegendColorPickerProps {
  preSelectedColors: string[]
  anchorEl: PopoverProps['anchorEl']
  onClose(selectedColor: string): void
  open?: boolean
}
export const LegendColorPicker = ({
  preSelectedColors,
  anchorEl,
  onClose,
  open = false,
}: LegendColorPickerProps): JSX.Element => {
  const [selectedColor, setSelectedColor] = useState<string>('')

  const handleColorChange = (_event: React.MouseEvent, newColor: string | null) => {
    if (newColor !== null) {
      setSelectedColor(newColor)
    }
  }

  return (
    <DeepcellPopover
      open={open}
      onClose={() => onClose(selectedColor)}
      anchorEl={anchorEl}
      data-testid="legend-color-picker"
    >
      <ToggleButtonGroup
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(5, 52px)',
          border: '1px solid black',
        }}
        value={selectedColor}
        exclusive
        onChange={handleColorChange}
        aria-label="color-picker"
      >
        {CATEGORICAL_COLOR_PALETTE.map((item: string) => (
          <StyledToggleButton
            key={item}
            style={{
              border: `${item === selectedColor ? '2px solid white' : 'none'}`,
            }}
            bgcolor={item}
            value={item}
            aria-label={item}
            disabled={preSelectedColors?.includes(item)}
          >
            {item === selectedColor ? <CheckIcon sx={{ color: 'white' }} /> : null}
          </StyledToggleButton>
        ))}
      </ToggleButtonGroup>
    </DeepcellPopover>
  )
}

export default LegendColorPicker
