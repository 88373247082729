import { useEffect, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import DeepcellDialog, { DeepcellDialogProps } from './DeepcellDialog'

interface RouterPromptProps {
  when: boolean
  dialogProps?: Omit<DeepcellDialogProps, 'open'>
  /**
   * Do not show prompt if pathname ends with any strings in ignorePaths. This applies when entering or exiting.
   */
  ignorePaths?: string | string[]
}

export const RouterPrompt = (props: RouterPromptProps): JSX.Element => {
  const { when, dialogProps, ignorePaths: ignorePathsRaw } = props
  const ignorePaths = useMemo(() => {
    if (Array.isArray(ignorePathsRaw)) {
      return ignorePathsRaw
    }
    return [ignorePathsRaw ?? '']
  }, [ignorePathsRaw])

  const history = useHistory()

  const [showPrompt, setShowPrompt] = useState(false)
  const [currentPath, setCurrentPath] = useState('')

  const prevPath = useRef('')
  const unblockRef = useRef<ReturnType<typeof history.block>>()

  function onCancel() {
    setShowPrompt(false)
  }

  useEffect(() => {
    // history.block expects no return value but es-lint expects every function to return a value, so it's necessary to disable es-lint here
    // eslint-disable-next-line consistent-return
    unblockRef.current = history.block((location) => {
      const isPathToIgnore = ignorePaths.some(
        (p) => p && (prevPath.current.endsWith(p) || location.pathname.endsWith(p))
      )

      prevPath.current = location.pathname
      if (when && !isPathToIgnore) {
        setCurrentPath(location.pathname)
        setShowPrompt(true)
        return false
      }
    })

    return () => unblockRef.current && unblockRef.current()
  }, [history, ignorePaths, when])

  function handleConfirm() {
    if (unblockRef && unblockRef.current) {
      unblockRef.current()
    }
    setShowPrompt(false)
    history.push(currentPath)
  }

  return (
    <DeepcellDialog
      open={showPrompt}
      onClose={onCancel}
      handleCancel={onCancel}
      handleConfirm={handleConfirm}
      {...dialogProps}
    />
  )
}

export default RouterPrompt
