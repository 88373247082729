import { Box, Chip, Divider, Typography, styled } from '@mui/material'
import { DeepcellPrimaryButton } from 'components/shared'
import _ from 'lodash'
import { useCellVisualizationsSlice } from 'redux/slices/hooks/useCellVisualizationsSlice'
import useEventsManager from 'redux/slices/hooks/useEventsManager'
import { getHiddenCellInfoGroupValues } from '../utils'

interface FiltersAppliedProps {
  onDetailsClick?(): void
}

const StyledChip = styled(Chip)(({ theme }) => ({
  fontSize: '.8rem',
  backgroundColor: theme.palette.info.light,
  color: theme.palette.primary.main,
  marginRight: 5,
  marginBottom: 5,
}))

export const FiltersApplied = ({ onDetailsClick }: FiltersAppliedProps): JSX.Element => {
  const { sendFilterOpenDetails, sendFilterOpenDatafield, sendFilterClearDatafield } =
    useEventsManager()

  const {
    setSelectedCellInfoGroupName,
    cellVisualizations: { cellInfoGroups },
    setCellInfoGroupData,
  } = useCellVisualizationsSlice()

  type Key = keyof typeof hiddenCellInfoGroupValues

  const hiddenCellInfoGroupValues = getHiddenCellInfoGroupValues(cellInfoGroups)
  const hiddenAttributes = _.keys(hiddenCellInfoGroupValues) as Key[]

  const handleDelete = (attribute: Key) => () => {
    sendFilterClearDatafield(attribute)
    setCellInfoGroupData(
      attribute,
      cellInfoGroups[attribute]?.data.map((x) => ({ ...x, isHidden: false })) ?? []
    )
  }

  const handleClick = (attribute: Key) => () => {
    sendFilterOpenDatafield(attribute)
    setSelectedCellInfoGroupName(attribute)
  }

  const handleDetailsClick = () => {
    sendFilterOpenDetails()
    if (onDetailsClick) onDetailsClick()
  }

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}>
        <Typography sx={{ fontSize: 11 }}>Filters Applied</Typography>
        <DeepcellPrimaryButton text size="small" onClick={handleDetailsClick}>
          Details
        </DeepcellPrimaryButton>
      </Box>
      <Box sx={{ display: 'initial' }}>
        {hiddenAttributes.map((attribute) => (
          <StyledChip
            key={attribute}
            label={`${cellInfoGroups[attribute]?.label} (${hiddenCellInfoGroupValues[attribute]?.length})`}
            variant="outlined"
            onClick={handleClick(attribute)}
            onDelete={handleDelete(attribute)}
          />
        ))}
      </Box>
      <Divider id="filters-applied-divider" />
    </>
  )
}

export default FiltersApplied
