import { useEffect, useState } from 'react'

/**
 * Hook wrapper for document.getElementById
 * @note It's up to the user to ensure this is called after the element already exists
 */
export const useElementById = (
    id: string
): { element: HTMLElement | null; rect: DOMRect | undefined } => {
    const [element, setElement] = useState<HTMLElement | null>(null)
    const rect = element?.getBoundingClientRect()

    const domEl = document.getElementById(id)

    useEffect(() => {
        if (domEl && !element) {
            setElement(document.getElementById(id))
        }
    }, [domEl, element, id])

    return { element, rect }
}

export default useElementById
