import { LoadingButton } from '@mui/lab'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentProps,
  DialogContentText,
  DialogProps,
  DialogTitle,
  Typography,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import DeepcellPrimaryButton from './DeepcellPrimaryButton'

export interface DeepcellDialogProps extends DialogProps {
  titleLabel?: string
  okLabel?: string
  okDisabled?: boolean
  cancelLabel?: string
  showCancelButton?: boolean
  description?: string
  loadingButtonState?: boolean
  id?: string
  loadingButton?: boolean
  handleCancel?: (reason?: 'backdropClick' | 'escapeKeyDown' | 'cancelButtonClick') => void
  handleConfirm?: () => void
  contentProps?: DialogContentProps
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    border: 'none',
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.25)',
    borderRadius: '4px',
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogTitle-root': {
    padding: theme.spacing(2),
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '25px',
    textAlign: 'center',
    color: theme.palette.text.primary,
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(2),
    paddingBottom: '18px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

export const DeepcellDialog = ({
  titleLabel,
  cancelLabel = 'Cancel',
  showCancelButton = true,
  okLabel = 'Ok',
  okDisabled,
  children,
  loadingButtonState,
  loadingButton,
  description,
  handleCancel = () => {},
  handleConfirm,
  contentProps,
  ...props
}: DeepcellDialogProps): JSX.Element => {
  return (
    <StyledDialog onClose={(_, r) => handleCancel(r)} {...props}>
      {titleLabel && <DialogTitle id="alert-dialog-title">{titleLabel}</DialogTitle>}
      <DialogContent {...contentProps}>
        {description && (
          <DialogContentText id="alert-dialog-description">
            <Typography component="span">{description}</Typography>
          </DialogContentText>
        )}
        {children}
      </DialogContent>
      <DialogActions>
        {showCancelButton ? (
          <DeepcellPrimaryButton outlined onClick={() => handleCancel('cancelButtonClick')}>
            {cancelLabel}
          </DeepcellPrimaryButton>
        ) : null}

        {loadingButton ? (
          <LoadingButton
            loading={loadingButtonState}
            onClick={handleConfirm}
            color="primary"
            variant="contained"
          >
            {okLabel}
          </LoadingButton>
        ) : (
          <DeepcellPrimaryButton disabled={okDisabled} contained onClick={handleConfirm}>
            {okLabel}
          </DeepcellPrimaryButton>
        )}
      </DialogActions>
    </StyledDialog>
  )
}

export default DeepcellDialog
