import { Box, Typography } from '@mui/material'
import { CellInfoDimension, CellInfoGroups } from 'redux/slices'
import { useCellVisualizationsSlice } from 'redux/slices/hooks/useCellVisualizationsSlice'

export const FiltersAppliedComparisonPlotSection = ({
  comparisonDimensions,
}: {
  comparisonDimensions: CellInfoDimension[]
}): JSX.Element => {
  const {
    cellVisualizations: { cellInfoGroups },
  } = useCellVisualizationsSlice()

  const getLabel = (name: keyof CellInfoGroups) => cellInfoGroups[name]?.label

  return (
    <>
      <h1>Comparison Plots Applied</h1>
      <Box px={1}>
        {comparisonDimensions.map((cellInfoDimension, i) => {
          return (
            <Box key={cellInfoDimension.field}>
              <h2>{i === 0 ? 'Primary' : 'Other'} Experimental Variable</h2>
              <Typography>{getLabel(cellInfoDimension.field)}</Typography>
            </Box>
          )
        })}
      </Box>
    </>
  )
}

export default FiltersAppliedComparisonPlotSection
