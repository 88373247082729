import { Box, Divider, Stack, Typography } from '@mui/material'
import HideIcon from 'components/shared/icons/Hide'
import ShowIcon from 'components/shared/icons/Show'
import { HIDDEN_COLOR } from '../shared'

const colorBoxSize = 40

export interface ILegendItemProps {
  label: string
  bgColor?: string
  onClick?: (e: React.MouseEvent) => void
  dataLabel: string
  readOnly?: boolean
  isHidden?: boolean
  onHide?(wasHidden: boolean): void
}

const LegendItem = ({
  label,
  bgColor,
  dataLabel,
  onClick,
  readOnly,
  isHidden,
  onHide,
  ...rest
}: ILegendItemProps): JSX.Element => {
  const showFilter = !readOnly

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ opacity: isHidden ? 0.4 : undefined }}
      >
        <Stack direction="row" spacing={2}>
          {bgColor ? (
            <Box
              sx={{
                minWidth: colorBoxSize,
                minHeight: colorBoxSize,
                width: colorBoxSize,
                height: colorBoxSize,
                backgroundColor: `${isHidden ? HIDDEN_COLOR : bgColor} !important`,
                cursor: onClick ? 'pointer' : '',
                borderRadius: 1,
              }}
              data-testid="legend-item"
              data-label={dataLabel}
              onClick={onClick}
              {...rest}
            />
          ) : null}
          <Typography alignSelf="center">{label}</Typography>
        </Stack>
        {showFilter ? (
          <>
            {isHidden ? (
              <ShowIcon
                fontSize="large"
                onClick={() => onHide && onHide(true)}
                sx={{ cursor: 'pointer' }}
              />
            ) : (
              <HideIcon
                fontSize="large"
                onClick={() => onHide && onHide(false)}
                sx={{ cursor: 'pointer' }}
              />
            )}
          </>
        ) : null}
      </Stack>
      <Divider />
    </>
  )
}
export default LegendItem
