import { kdTree } from 'kd-tree-javascript'
import { CellPlotData } from 'redux/slices'
import { PlotMarker } from 'plotly.js'
import { DensityPoint, createDensityTree, getNearestDensityValueFromTree } from './useDensityTree'

/**
 * Updates the marker.color values of the cell data based on density
 * * If calculateSeparate === true, a separate density tree will be created for each subplot
 * * If calculateSeparate === false, one density tree will be created for all combined subplots
 */
export const updateWithVisibleDensityColors = (
  data: CellPlotData[],
  calculateSeparate = true
): void => {
  let tree: kdTree<DensityPoint> | undefined

  if (!calculateSeparate) {
    tree = createDensityTree(
      data.flatMap((d) => d.plotDatum),
      'x',
      'y',
      50
    )
  }

  // If we're not creating separate density plots
  // Keep track of the minimum and maximum density values
  // to ensure we use the same color scale for all plots
  // (fix for SWT-228)
  let minDensity: number | null = null
  let maxDensity: number | null = null

  data.forEach((d, dataIndex) => {
    if (data[dataIndex].marker) {
      const { plotDatum } = d
      if (calculateSeparate) {
        tree = createDensityTree(plotDatum, 'x', 'y', 50)
      }

      if (tree) {
        const densityValues = plotDatum.map(({x, y}) => getNearestDensityValueFromTree(tree, x, y))
        const newColors = densityValues.map((densityValue) => `${densityValue ?? ''}`)

        if(!calculateSeparate) {
          densityValues.forEach((densityValue) => {
            if (densityValue !== null) {
              if(minDensity === null || maxDensity === null) {
                minDensity = densityValue
                maxDensity = densityValue
              } else {
                minDensity = Math.min(minDensity, densityValue)
                maxDensity = Math.max(maxDensity, densityValue)  
              }
            }
          })
        }

        const marker = data[dataIndex].marker ?? {}
        marker.color = newColors
      }
    }
  })

  // Make sure that all the plots in data use the same color scale
  // If we're not creating separate density plots
  if(!calculateSeparate) {
    data.forEach((dataItem) => {
      if (dataItem.marker && minDensity !== null && maxDensity !== null) {
        // eslint-disable-next-line no-param-reassign
        (dataItem.marker as Partial<PlotMarker>).cmin = minDensity;
        // eslint-disable-next-line no-param-reassign
        (dataItem.marker as Partial<PlotMarker>).cmax = maxDensity;
      }
    })
  }
}

export default updateWithVisibleDensityColors
