import { Box, useTheme } from '@mui/material'
import { animated, useSpring } from '@react-spring/web'
import { useEffect } from 'react'

type StepAction = 'next' | 'skip' | 'click-outside'

export interface StepUpdate {
    fromIndex: number
    toIndex?: number
    action: StepAction
    target?: string
}
export interface StepComponentProps {
    onNext: (toIndex?: number) => void
    onSkip: () => void
}

export interface Step {
    name: string
    component: (props: StepComponentProps) => JSX.Element
    target?: StepUpdate['target']
}

export interface WalkthroughProps {
    index?: number
    show?: boolean
    steps?: Step[]
    onUpdate?: (params: StepUpdate) => void
}

const AnimatedBox = animated(Box)

const Walkthrough = (props: WalkthroughProps): JSX.Element => {
    const { index, show, steps, onUpdate } = props
    const theme = useTheme()

    const [spotlightSpring, spotlightApi] = useSpring(() => ({
        from: { transform: 'scale(1)' },
    }))

    useEffect(() => {
        const interval = setInterval(() => {
            spotlightApi.start({
                to: {
                    transform:
                        spotlightSpring.transform.get() === 'scale(1)' ? 'scale(1.05)' : 'scale(1)',
                },
            })
        }, 1500)

        return () => {
            clearInterval(interval)
        }
    }, [spotlightApi, spotlightSpring.transform])

    if (!show || index === undefined || !steps || index < 0 || steps.length < 1 || !steps[index])
        return <></>

    const { component: Component, target } = steps[index]

    let targetsToHighlight: JSX.Element[] = []
    if (target) {
        const elements = target ? [...document.querySelectorAll(target)] : []
        if (elements?.length > 0) {
            targetsToHighlight = elements.flatMap((element, i) => {
                if (!element) return []

                const dims = element.getBoundingClientRect()

                const key = `key${i}`
                return (
                    <AnimatedBox
                        style={spotlightSpring}
                        key={key}
                        sx={{
                            border: `3px solid ${theme.palette.info.main}`,
                            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
                            borderRadius: '8px',
                            position: 'absolute',
                            top: dims.top - 2,
                            left: dims.left - 2,
                            width: dims.width + 4,
                            height: dims.height + 4,
                            pointerEvents: 'none',
                            zIndex: 98,
                        }}
                    />
                )
            })
        }
    }

    return (
        <>
            {targetsToHighlight}
            <Component
                onNext={(toIndex) =>
                    onUpdate && onUpdate({ fromIndex: index, action: 'next', toIndex })
                }
                onSkip={() => onUpdate && onUpdate({ fromIndex: index, action: 'skip' })}
            />
        </>
    )
}

export default Walkthrough
