import { ReactElement } from 'react'
import { Run } from '../../utils/api'
import { getCellTypeColumnDefs } from './metadata'
import AgGridRunDetailsTable from '../shared/AgGridRunDetailsTable'

interface IWellResultsGridProps {
  run: Run
}

const CellTypeGrid = ({ run }: IWellResultsGridProps): ReactElement => {
  const rowData = run.cell_counts ?? []
  const model = run.model ?? ''
  return (
    <AgGridRunDetailsTable
      testId="cell-type-grid"
      customClass="context-menu-ag-grid"
      columnDefs={getCellTypeColumnDefs(run.run_id, run.total_cell_count, model)}
      rowData={rowData}
    />
  )
}

export default CellTypeGrid
