import { Box, styled, useTheme } from '@mui/material'
import { ArrowUpward } from '@mui/icons-material'
import { useRef } from 'react'
import { GridApi } from 'ag-grid-community'

const ArrowDiv = styled(Box)(({ theme }) => ({
  width: '24px',
  height: '24px',
  borderRadius: '50%',
  cursor: 'pointer',
  backgroundColor: `${theme.palette.success.main}`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'fixed',
  bottom: '16px',
  right: '32px',
}))

interface ScrollTopProps {
  tableGridApi?: GridApi | null
}

const ScrollTop = ({ tableGridApi }: ScrollTopProps): JSX.Element => {
  const theme = useTheme()

  const scrollRef = useRef<HTMLElement>(null)
  const handleScrollUp = () => {
    if (tableGridApi) {
      tableGridApi.ensureIndexVisible(1, 'middle')
      return
    }
    if (!scrollRef.current) return
    scrollRef.current.scrollIntoView(false)
  }

  return (
    <>
      <Box ref={scrollRef} />
      <ArrowDiv onClick={handleScrollUp}>
        <ArrowUpward
          width="16"
          height="16"
          style={{
            color: `${theme.palette.info.main}`,
          }}
        />
      </ArrowDiv>
    </>
  )
}

export default ScrollTop
