import { styled } from '@mui/material'
import { ReactNode } from 'react'
import Button, { ButtonProps } from '@mui/material/Button'

interface PrimaryButtonProps extends ButtonProps {
  children: ReactNode
}

const StyledButton = styled(Button)({
  display: 'flex',
  alignItems: 'center',
  flexShrink: 0,
  gap: '10px',
  width: '100%',
  height: '32px',
  border: '1px solid #5F55D1',
  borderRadius: '3px',
  color: '#5F55D1',
  background: '#E3FCEE',
  boxShadow: '0px 0px 6px 0px rgba(0, 0, 0, 0.15)',
  textTransform: 'uppercase',
  justifyContent: 'center',
  textAlign: 'center',
  fontSize: '10px',
  fontWeight: 500,
  lineHeight: 'normal',
  letterSpacing: '1px',
  '&:hover': {
    background: '#a1eabc'
  }
})

const PrimaryButton = ({ children, ...rest }: PrimaryButtonProps): JSX.Element => {
  return <StyledButton {...rest}>{children}</StyledButton>
}

export default PrimaryButton
