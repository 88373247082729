import { styled } from '@mui/material'
import { DeepcellAutocomplete } from 'components/shared'
import { useCellVisualizationsSlice } from 'redux/slices/hooks/useCellVisualizationsSlice'
import useEventManager from 'redux/slices/hooks/useEventsManager'
import { CellDataField } from 'redux/slices/types'
import useDataFieldsInDataset from '../useDataFieldsInDataset'

const StyledDeepcellAutocomplete = styled(DeepcellAutocomplete)(({ theme }) => ({
  '& .MuiAutocomplete-inputRoot': {
    color: theme.palette.primary.main,
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
  },
}))

export const DataFieldSelect = (): JSX.Element => {
  const { dataFields } = useDataFieldsInDataset()
  const {
    cellVisualizations: { groupCompare },
    setGroupCompare,
  } = useCellVisualizationsSlice()

  const { selectedFeatures } = groupCompare
  const eventsManager = useEventManager()

  const onChange = (_e: unknown, opt: unknown) => {
    setGroupCompare({
      groupCompare: { ...groupCompare, selectedFeatures: opt as CellDataField[] },
      eventsManager,
    })
  }

  return (
    <StyledDeepcellAutocomplete
      multiple
      filterSelectedOptions
      disableClearable
      label="Choose data field(s) to plot"
      id="value-to-compare"
      options={dataFields}
      groupBy={(option) => (option as CellDataField).category}
      getOptionLabel={(opt) => (opt as CellDataField).label}
      value={selectedFeatures}
      onChange={onChange}
    />
  )
}

export default DataFieldSelect
