import styled from '@emotion/styled'
import PencilIcon from '@mui/icons-material/Create'
import { ColDef, ICellRendererParams } from 'ag-grid-community'
import {
  formatDateTime,
  formatDuration,
  formatTimestampToHumanReadable,
  INSTRUMENT_REPORTED_TIMEFORMAT,
} from 'components/shared/date-utils'
import _ from 'lodash'
import moment from 'moment'
import { Run } from 'utils/api'
import { CellClassEncoderDecoder } from 'utils/proto-utils'
import { IconButton } from '@mui/material'
import CustomCellComponent from './CustomCellComponent'

// Displayed when there are multiple runs selected and there is more than one value for a field
export const MULTIPLE_VALUES = '<Multiple Values>'

/*
 *  Data type to be dysplayed
 */
export type BasicDataType = string | boolean | number | null | string[] | number[] | undefined

export interface RowDataType {
  label: string
  values: BasicDataType
  field?: string | null
  editable?: boolean
}

/*
 * Row data type to be displayed in the table.
 */
export interface Row {
  name: string
  data: BasicDataType
  field?: string | null
  editable: boolean | undefined
}

/*
 * We know it’s imaging if the well_sorting_configurations is present
 */
function getRunType(run: Run) {
  return run?.well_sorting_configurations && run?.well_sorting_configurations.length > 0
    ? 'Sorting'
    : 'Imaging'
}

/** Extracts a field from each run in an array and turns into a comma-separated list */
export function joinValues(runs: Run[], field: keyof Run): string {
  return runs.map((r) => r[field]).join(', ')
}

/* If all runs have the same value for the property, then returns that value, otherwise returns "<Multiple Values>" */
export function mergeDisplayValue(runs: Run[], property: keyof Run): BasicDataType {
  const values = _.uniq(runs.map((run: Run) => run[property]))
  if (values.length === 1) return values[0] as BasicDataType
  return MULTIPLE_VALUES
}

/** Gets cell class strings from an array of cell class numbers */
export function getCellClassStrings(cell_classes?: number[]): string[] {
  return (
    cell_classes?.map((cellClass: number) => CellClassEncoderDecoder.convertToString(cellClass)) ||
    []
  )
}

const ChangeIcon = styled(PencilIcon)({
  cursor: 'pointer',
  width: '1rem',
  height: '0.8rem',
})
/*
 * Draw the pencil button if the row is editable
 */
const drawEditPencilButton = (
  r: RowDataType,
  onClick: (r: Row) => (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
): JSX.Element | null => {
  const { editable, label, values, field } = r
  const data: Row = {
    editable,
    name: label,
    data: values,
    field,
  }
  return data.editable ? (
    <IconButton aria-label={`${field}-edit`} onClick={onClick(data)}>
      <ChangeIcon fontSize="small" data-testid={`${field}-edit`} />
    </IconButton>
  ) : null
}

export const outputSingleRowData = (run: Run): RowDataType[] => {
  // Time display parts
  const startTime = formatDateTime(run?.start_time)
  const endTime = formatDateTime(run?.stop_time)

  const duration = run?.stop_time
    ? moment.duration(moment(run.stop_time).diff(run.start_time))
    : null
  const durationString = duration ? ` ( ${formatDuration(duration)} duration )` : ''

  // Filter out well 0 if it exists
  const filteredWellCounts = run?.well_counts?.filter((wellCount) => wellCount.well > 0)
  // Cell count display parts
  const countPerWell = filteredWellCounts?.map((item) => item.count)
  const sortedCellCount = countPerWell ? _.sum(countPerWell) : null
  const sortedCells = sortedCellCount !== null ? ` (${sortedCellCount} sorted)` : ''

  return [
    {
      label: 'Run Id',
      values: run?.run_id,
    },
    {
      label: 'Run Type',
      values: getRunType(run),
    },
    {
      label: 'Time',
      values: `${formatTimestampToHumanReadable(startTime, INSTRUMENT_REPORTED_TIMEFORMAT)}${
        endTime
          ? ` to ${formatTimestampToHumanReadable(endTime, INSTRUMENT_REPORTED_TIMEFORMAT)}`
          : ''
      }${durationString}`,
    },
    {
      label: 'Cell Count',
      values: `${run?.total_cell_count}${sortedCells}`,
    },
    {
      label: 'Sample Id',
      values: run?.sample_id || '',
      editable: true,
      field: 'sample_id',
    },
    {
      label: 'Instrument Serial Number',
      values: run?.instrument_serial_number || '',
      editable: false,
      field: 'instrument_serial_number',
    },
    {
      label: 'Chip Barcode',
      values: run?.chip_barcode || '',
      editable: false,
      field: 'chip_barcode',
    },
    {
      label: 'Project Code',
      values: run?.project_code || '',
      editable: true,
      field: 'project_code',
    },
    {
      label: 'Experiment Code',
      values: run?.experiment_code || '',
      editable: true,
      field: 'experiment_code',
    },
    {
      label: 'Model',
      values: run?.model || '',
    },
    {
      label: 'Run Description',
      values: run?.description || '',
      editable: true,
      field: 'description',
    },
    {
      label: 'Run Notes',
      values: run?.run_notes || '',
      editable: true,
      field: 'run_notes',
    },
    {
      label: 'Target Morphotypes',
      values: getCellClassStrings(run?.target_cell_classes),
      editable: true,
      field: 'target_cell_classes',
    },
    {
      label: 'User',
      values: run?.user_email,
    },
  ]
}

export const outputColumnDefs = (
  onClick: (r: Row) => (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
): ColDef[] => {
  return [
    {
      headerName: 'Label',
      field: 'label',
      width: 200,
    },
    {
      headerName: 'Values',
      field: 'values',
      flex: 1,
      cellClass: 'ag-value-bold',
      cellRendererFramework: CustomCellComponent,
      cellRendererParams: {
        ellipsis: true,
      },
    },
    {
      headerName: '',
      field: 'values',
      width: 50,
      cellRendererFramework: (params: ICellRendererParams) =>
        drawEditPencilButton(params.data, onClick),
    },
  ]
}

export const outputMultipleRowData = (runs: Run[]): RowDataType[] => {
  // Time summary
  const minTime = formatDateTime(
    moment.min.apply(
      null,
      runs.map((run) => moment(run?.start_time))
    )
  )
  const maxTime = formatDateTime(
    moment.max.apply(
      null,
      runs.map((run) => moment(run?.stop_time ? run?.stop_time : run?.start_time))
    )
  )
  // Cell count summary
  const totalCellCount = _.sum(runs.map((run) => run.total_cell_count))
  // Target Morphotypes
  const targetCellClasses = mergeDisplayValue(runs, 'target_cell_classes')
  const targetCellClassString =
    targetCellClasses === MULTIPLE_VALUES
      ? MULTIPLE_VALUES
      : getCellClassStrings(targetCellClasses as number[] | undefined)

  return [
    {
      label: 'Run Ids',
      values: joinValues(runs, 'run_id'),
    },
    {
      label: 'Run Types',
      values: MULTIPLE_VALUES,
    },
    {
      label: 'Time',
      values: `${minTime} to ${maxTime}`,
    },
    {
      label: 'Total Cell Count',
      values: totalCellCount,
    },
    {
      label: 'Sample Id',
      values: mergeDisplayValue(runs, 'sample_id') || '',
      editable: true,
      field: 'sample_id',
    },
    {
      label: 'Instrument Serial Number',
      values: mergeDisplayValue(runs, 'instrument_serial_number'),
      editable: false,
      field: 'instrument_serial_number',
    },
    {
      label: 'Chip Barcode',
      values: mergeDisplayValue(runs, 'chip_barcode'),
      editable: false,
      field: 'chip_barcode',
    },
    {
      label: 'Project Code',
      values: mergeDisplayValue(runs, 'project_code'),
      editable: true,
      field: 'project_code',
    },
    {
      label: 'Experiment Code',
      values: mergeDisplayValue(runs, 'experiment_code'),
      editable: true,
      field: 'experiment_code',
    },
    {
      label: 'Model',
      values: mergeDisplayValue(runs, 'model'),
    },
    {
      label: 'Run Description',
      values: mergeDisplayValue(runs, 'description'),
      editable: false,
      field: 'description',
    },
    {
      label: 'Run Notes',
      values: mergeDisplayValue(runs, 'run_notes'),
      editable: true,
      field: 'run_notes',
    },
    {
      label: 'Target Morphotypes',
      values: targetCellClassString,
      editable: true,
      field: 'target_cell_classes',
    },
    {
      label: 'User',
      values: mergeDisplayValue(runs, 'user_email'),
    },
  ]
}
