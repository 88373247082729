import { styled } from '@mui/material'
import Button, { ButtonProps } from '@mui/material/Button'

interface CommonProps {
    icon: React.ReactElement
}

interface OutlinedVariant {
    /**
     * The variant to use.
     *
     * Variant type: outlined
     *
     * Refer: https://mui.com/material-ui/react-button/#outlined-button
     */
    outlined: boolean
    contained?: never
}
interface ContainedVariant {
    outlined?: never
    /**
     * The variant to use.
     *
     * Variant type: contained
     *
     * Refer: https://mui.com/material-ui/react-button/#contained-button
     */
    contained: boolean
}

type DeepcellIconButtonProps = ButtonProps & CommonProps & (OutlinedVariant | ContainedVariant)

const StyledButton = styled(Button)(() => ({
    minWidth: '0px',
}))

export const DeepcellPrimaryIconButton = ({
    outlined,
    contained,
    icon,
    ...props
}: DeepcellIconButtonProps): JSX.Element => {
    const buttonVariant = (): ButtonProps['variant'] => {
        if (outlined) return 'outlined'
        if (contained) return 'contained'
        return 'outlined'
    }

    return (
        <StyledButton {...props} color="primary" variant={buttonVariant()}>
            {icon}
        </StyledButton>
    )
}

export default DeepcellPrimaryIconButton
