import { CellInfoDimension } from 'redux/slices'
import { CellInfo } from '../tsv/types'

export type Combination = { key: string; exists: boolean }

/**
 * Given a cell and different dimensions, return the different possible combinations and if that cell exists for each combination
 * @param cell 
 * @param dimensions 
 * @exampleInput
 * ```
 * [
 *  { field: 'INSTRUMENT_NAME', values: ['Aries', 'Gemini'] },
 *  { field: 'SAMPLE_TYPE', values: ['A375', 'BEAD6'] }
 * ]
 * ```
 * @exampleOutput
 * ```
    [
      {key: 'INSTRUMENT_NAME:Aries&SAMPLE_TYPE:A375', exists: false}
      {key: 'INSTRUMENT_NAME:Gemini&SAMPLE_TYPE:A375', exists: true}
      {key: 'INSTRUMENT_NAME:Aries&SAMPLE_TYPE:BEAD6', exists: false}
      {key: 'INSTRUMENT_NAME:Gemini&SAMPLE_TYPE:BEAD6', exists: false}
    ]
    ```
 */

export const generateCellInfoCombinations = (
  cell: CellInfo,
  dimensions: CellInfoDimension[]
): Combination[] => {
  const combinations: Combination[] = [{ key: '', exists: true }]

  dimensions.forEach(({ field, values }, fieldIndex) => {
    const newCombinations: Combination[] = []
    const lastField = fieldIndex === dimensions.length - 1

    values.forEach((value) => {
      combinations.forEach(({ key, exists }) => {
        const newCombination = {
          key: `${key}${field}:${value}${lastField ? '' : '&'}`,
          exists: exists && cell[field] === value,
        }

        newCombinations.push(newCombination)
      })
    })

    combinations.splice(0, combinations.length, ...newCombinations)
  })

  return combinations
}

export default generateCellInfoCombinations
