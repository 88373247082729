import { InputLabelProps, Select, styled, SelectProps, MenuItem } from '@mui/material'
import { SimpleSelectItem } from 'components/shared'
import DropdownArrowIcon from 'components/shared/icons/DropdownArrow'
import React from 'react'

const StyledSelect = styled(Select)(({ theme }) => ({
  background: theme.palette.background.default,
  border: `1px solid ${theme.palette.primary.main}`,
  fontSize: '12px',
  height: '100%',
  width: '100%',
  textAlign: 'left',
  borderRadius: '4px',
  '& .MuiSelect-icon': {
    color: theme.palette.info.main,
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
}))

export interface SelectV3Props extends SelectProps {
  label?: string
  items: (SimpleSelectItem | string | number | JSX.Element)[]
  inputLabelProps?: InputLabelProps
}

const SelectV3 = ({ label, items, ...rest }: SelectV3Props): JSX.Element => {
  return (
    <StyledSelect
      // Helps make getByLabelText work in tests, so we can try and test using what the user sees
      inputProps={{ 'aria-label': label }}
      MenuProps={{
        PaperProps: {
          sx: {
            maxHeight: '50vh',
            boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.15)',
            borderRadius: '4px',
            border: '1px solid #5F55D1',
            '& .MuiMenuItem-root': {
              fontSize: '12px',
              fontWeight: '500',
              color: '#172623',
            },
            '& .MuiMenu-list': {
              padding: '0px',
            },
          },
        },
      }}
      IconComponent={DropdownArrowIcon}
      {...rest}
    >
      {items.map((i) => {
        let item: SimpleSelectItem
        if (React.isValidElement(i)) return i
        if (typeof i === 'string' || typeof i === 'number') {
          item = { key: i, value: i, output: i }
        } else {
          item = i
        }
        const { key, value, output } = item
        return (
          <MenuItem key={key ?? value ?? -1} value={value ?? key ?? undefined}>
            {output ?? value ?? key ?? ''}
          </MenuItem>
        )
      })}
    </StyledSelect>
  )
}

export default SelectV3
