import { Box, styled } from '@mui/material'
import { ICellRendererParams } from 'ag-grid-community'

type BorderCellComponentProps = {
  rightBorder?: boolean
  leftBorder?: boolean
  ellipsis?: boolean
}

const StyledCustomCellComponent = styled(Box, {
  shouldForwardProp: (props) =>
    props !== 'rightBorder' && props !== 'leftBorder' && props !== 'ellipsis',
})<BorderCellComponentProps>(({ rightBorder, leftBorder, ellipsis }) => ({
  height: '20px',
  ...(rightBorder && {
    borderRight: '1px solid rgba(0, 0, 0, 0.1)',
  }),
  ...(leftBorder && {
    borderLeft: '1px solid rgba(0, 0, 0, 0.1)',
  }),
  ...(ellipsis && {
    paddingRight: '15px',
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    display: 'block',
  }),
}))

const CustomCheckboxCellComponent = (props: Partial<ICellRendererParams>): JSX.Element => {
  const {
    colDef: {
      cellRendererParams: { rightBorder = false, leftBorder = false, ellipsis = false } = {},
    } = {},
  } = props

  return (
    <StyledCustomCellComponent
      rightBorder={rightBorder}
      leftBorder={leftBorder}
      ellipsis={ellipsis}
    />
  )
}

export default CustomCheckboxCellComponent
