import Box from '@mui/material/Box'
import ContentLoading from 'components/shared/ContentLoading'

import useCellVisualizationUrlParams from '../useCellVisualizationUrlParams'
import ScatterPlot from './ScatterPlot'
import { usePlotData } from './usePlotData'

export const MainGraphContent = (): JSX.Element => {
  const { isPreFilter } = useCellVisualizationUrlParams()
  const allPlotData = usePlotData()
  const {
    scatterPlotMetaData: { x },
  } = allPlotData

  const dataIsReady = x.length > 0

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        paddingTop: '0.5rem',
      }}
    >
      {dataIsReady ? (
        <ScatterPlot {...allPlotData} isPreFilter={isPreFilter} />
      ) : (
        <ContentLoading />
      )}
    </Box>
  )
}

export default MainGraphContent
