/**
 * Get the zero-based indexes based on zero-based pagination info.
 * should be used with a js array slice method
 */
export const getSliceFromPagination = ({
  count,
  page,
  itemsPerPage,
}: {
  count: number
  page: number
  itemsPerPage: number
}): { startIndex: number; endIndex: number } => {
  const startIndex = Math.min(page * itemsPerPage, count)
  const endIndex = Math.min(startIndex + itemsPerPage, count)

  return { startIndex, endIndex }
}

/**
 * Get a unique id. Default returns a random number between 1 - 1000
 */
export const getRandomId = (params?: { exclude?: number[]; numOfDigits?: number }): number => {
  const { exclude, numOfDigits } = params ?? { exclude: undefined, numOfDigits: undefined }

  // the double asterisks ** is the exponent symbol
  const factor = numOfDigits ? 10 ** numOfDigits : 1000
  const getRand = () => Math.ceil(Math.random() * factor)
  let id = getRand()
  if (exclude) {
    while (exclude.includes(id)) {
      id = getRand()
    }
  }

  return id
}

/**
 * Given an optional list of names, returns a new name in the form of 'New Selection {X}' where {X} is the first non-existing number in listOfNames
 */
export const getNewUniqueName = (listOfNames?: { name: string }[]): string => {
  if (!listOfNames || !listOfNames.length) {
    return 'New Selection 1'
  }

  const namePrefix = 'New Selection '
  let numberSuffix = 2
  for (let i = 2; listOfNames.some((x) => x.name === `${namePrefix}${i}`); i += 1) {
    numberSuffix = i + 1
  }

  return `${namePrefix}${numberSuffix}`
}

/** Tests if two numbers are approximately equal, within the given tolerance */
export const approxEquals = (x: number, y: number, tolerance = 1e-6): boolean => {
  return Math.abs(x - y) < tolerance
}

// Typescript doubles the keys for some reason. We just want the keys as int indexes
/** Returns keys from an enum as an array of strings */
export const enumToKeys = <Enum extends Record<string, unknown>>(
  e: Enum,
  keysAreStrings = false
): (keyof Enum)[] =>
  (Object.entries(e) as Array<[keyof Enum, unknown]>).flatMap(([key, val]) => {
    if (keysAreStrings) {
      return val as keyof Enum
    }
    if (parseInt(key as string, 10) >= 0) {
      return key
    }
    return []
  })
