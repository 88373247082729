import { createSvgIcon } from '@mui/material'

const HideIcon = createSvgIcon(
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_5503_40835)">
      <path
        d="M11.689 9.83325C13.1773 8.505 13.9998 7 13.9998 7C13.9998 7 11.3748 2.1875 6.99984 2.1875C6.15949 2.19039 5.32864 2.3655 4.55859 2.702L5.23234 3.37662C5.79885 3.17026 6.39692 3.06397 6.99984 3.0625C8.85484 3.0625 10.394 4.0845 11.5218 5.21237C12.0647 5.7581 12.5513 6.35699 12.9743 7C12.9236 7.07612 12.8676 7.16012 12.8037 7.252C12.5106 7.672 12.0775 8.232 11.5218 8.78762C11.3775 8.932 11.227 9.07462 11.0695 9.21287L11.689 9.83325Z"
        fill="#5F55D1"
      />
      <path
        d="M9.88494 8.02907C10.0802 7.48293 10.1164 6.89257 9.98921 6.32668C9.86207 5.7608 9.57684 5.24265 9.16672 4.83253C8.75661 4.42242 8.23846 4.13719 7.67257 4.01004C7.10669 3.88289 6.51633 3.91906 5.97019 4.11432L6.69032 4.83444C7.02659 4.78631 7.36945 4.81716 7.69173 4.92453C8.01401 5.03191 8.30685 5.21287 8.54706 5.45308C8.78726 5.69328 8.96822 5.98612 9.0756 6.3084C9.18298 6.63068 9.21382 6.97355 9.16569 7.30982L9.88494 8.02907ZM7.30982 9.16569L8.02907 9.88494C7.48293 10.0802 6.89257 10.1164 6.32668 9.98921C5.7608 9.86207 5.24265 9.57684 4.83253 9.16672C4.42242 8.75661 4.13719 8.23846 4.01004 7.67257C3.88289 7.10669 3.91906 6.51633 4.11432 5.97019L4.83444 6.69032C4.78631 7.02659 4.81716 7.36945 4.92453 7.69173C5.03191 8.01401 5.21287 8.30685 5.45308 8.54706C5.69328 8.78726 5.98612 8.96822 6.3084 9.0756C6.63068 9.18298 6.97355 9.21382 7.30982 9.16569Z"
        fill="#5F55D1"
      />
      <path
        d="M2.93125 4.78643C2.77375 4.92643 2.62238 5.06818 2.478 5.21255C1.93519 5.75828 1.44858 6.35717 1.0255 7.00018L1.19613 7.25218C1.48925 7.67218 1.92238 8.23218 2.478 8.78781C3.60588 9.91568 5.14588 10.9377 7 10.9377C7.6265 10.9377 8.21625 10.8213 8.7675 10.6227L9.44125 11.2982C8.6712 11.6347 7.84035 11.8098 7 11.8127C2.625 11.8127 0 7.00018 0 7.00018C0 7.00018 0.821625 5.49431 2.31088 4.16693L2.93038 4.78731L2.93125 4.78643ZM11.9403 12.5599L1.44025 2.05993L2.05975 1.44043L12.5598 11.9404L11.9403 12.5599Z"
        fill="#5F55D1"
      />
    </g>
    <defs>
      <clipPath id="clip0_5503_40835">
        <rect width="14" height="14" fill="white" />
      </clipPath>
    </defs>
  </svg>,
  'Hide'
)

export default HideIcon
