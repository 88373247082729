import { CellInfo } from 'components/cell-visualizations/tsv/types'

export enum FeatureGroup {
  CELL_SHAPE_FEATURES = 'CELL_SHAPE_FEATURES',
  PIXEL_INTENSITY_FEATURES = 'PIXEL_INTENSITY_FEATURES',
  TEXTURE_FEATURES = 'TEXTURE_FEATURES',
  POSITION_FEATURES = 'POSITION_FEATURES',
  FOCUS_FEATURES = 'FOCUS_FEATURES',
  DEEP_LEARNING_FEATURES = 'DEEP_LEARNING_FEATURES',
  CUSTOM = 'CUSTOM',
}

export type FeatureDefinition = {
  field: keyof CellInfo
  label?: string
  description?: string
  group: FeatureGroup
  groupPriority?: number // feature priority when displayed in group
  isInternalOnly?: boolean
}

// Deep learning dimensions
const DEEP_LEARNING_FEATURES: FeatureDefinition[] = [...Array(64).keys()].map((i) => ({
  field: `${i}` as keyof CellInfo,
  label: `HFM:DL${String(i + 1).padStart(3, '0')}`,
  description:
    '64 quantitative descriptions of cell features derived from deep learning models based on neural networks',
  group: FeatureGroup.DEEP_LEARNING_FEATURES,
}))

const LBP_CENTER_FEATURES: FeatureDefinition[] = [...Array(10).keys()].map((i) => ({
  field: `LBP_CENTER_${String(i + 1).padStart(2, '0')}` as keyof CellInfo,
  label: `Local binary patterns - center ${i + 1}`,
  description:
    'The 10 Local Binary Pattern (LBP) - Center features determine the texture inside the cell and describe the appearance near the center of the cell',
  group: FeatureGroup.TEXTURE_FEATURES,
}))

const LBP_PERIPHERY_FEATURES: FeatureDefinition[] = [...Array(10).keys()].map((i) => ({
  field: `LBP_PERIPHERY_${String(i + 1).padStart(2, '0')}` as keyof CellInfo,
  label: `Local binary patterns - periphery ${i + 1}`,
  description:
    'The 10 Local Binary Pattern (LBP) - Periphery features determine the texture at the periphery of the cell and describe the appearance near the edge of the cell',
  group: FeatureGroup.TEXTURE_FEATURES,
}))

// These were replaced in v5 with HU_MOMENTS[2]
const HU_FEATURES_V1: FeatureDefinition[] = [...Array(7).keys()].map((i) => ({
  field: `HU${String(i + 1).padStart(2, '0')}` as keyof CellInfo,
  group: FeatureGroup.TEXTURE_FEATURES,
}))

// Keep this list in sync with types.ts
//
// And keep the labels and descriptions in sync with our publicly facing labels in the
// HFM Panel Sheet: https://deepcell.com/wp-content/uploads/2023/05/Deepcell-Human-Foundation-Model-Panel-Sheet.pdf
export const FEATURES: FeatureDefinition[] = [
  // Most important features comes first
  // Do not change the order of this initial section of feature names
  {
    field: 'AREA',
    label: 'Area (µm²)',
    description: 'Cell area',
    group: FeatureGroup.CELL_SHAPE_FEATURES,
  },
  {
    field: 'TOTAL_DETECTION_AREA',
    label: 'Total Detection Area',
    group: FeatureGroup.CELL_SHAPE_FEATURES,
  },
  {
    field: 'PERIMETER',
    label: 'Perimeter (µm)',
    description: 'Length of the cell outline',
    group: FeatureGroup.CELL_SHAPE_FEATURES,
  },
  {
    field: 'MAX_FERET',
    label: 'Maximum caliper distance (µm)',
    description: 'Width of widest possible box around the cell',
    group: FeatureGroup.CELL_SHAPE_FEATURES,
    groupPriority: 1,
  },
  {
    field: 'MIN_FERET',
    label: 'Minimum caliper distance (µm)',
    description: 'Width of narrowest possible box around the cell',
    group: FeatureGroup.CELL_SHAPE_FEATURES,
  },
  {
    field: 'MAX_RADIUS',
    label: 'Maximum radius (µm)',
    description: 'Largest radius from center of cell to cell border',
    group: FeatureGroup.CELL_SHAPE_FEATURES,
  },
  {
    field: 'MIN_RADIUS',
    label: 'Minimum radius (µm)',
    description: 'Shortest radius from center of cell to cell border',
    group: FeatureGroup.CELL_SHAPE_FEATURES,
  },
  {
    field: 'LONG_AXIS',
    label: 'Long ellipse axis (µm)',
    description: 'Long axis of best fit ellipse',
    group: FeatureGroup.CELL_SHAPE_FEATURES,
  },
  {
    field: 'SHORT_AXIS',
    label: 'Short ellipse axis (µm)',
    description: 'Short axis of best fit ellipse',
    group: FeatureGroup.CELL_SHAPE_FEATURES,
  },

  {
    field: 'ECCENTRICITY',
    label: 'Ellipse Elongation (unitless)',
    description:
      'Aspect ratio of best fit ellipse. Metric: 0 indicates a circle, 1 indicates a line',
    group: FeatureGroup.CELL_SHAPE_FEATURES,
    groupPriority: 1,
  },
  {
    field: 'ELLIPSE_VARIANCE',
    label: 'Ellipse similarity (unitless)',
    description:
      'Deviation from an elliptical shape. Metric: 0 indicates a perfectly elliptical shape',
    group: FeatureGroup.CELL_SHAPE_FEATURES,
  },
  {
    field: 'ROUNDNESS',
    label: 'Roundness (unitless)',
    description:
      'Roundness is a measure for circularity or compactness of the shape.  Metric: Range is 0 to 1, where 1 is a perfect circle',
    group: FeatureGroup.CELL_SHAPE_FEATURES,
  },
  {
    field: 'CIRCULARITY',
    label: 'Circle similarity (unitless)',
    description: 'Deviation from a circular shape. Metric: 0 indicates a perfectly circular shape',
    group: FeatureGroup.CELL_SHAPE_FEATURES,
  },
  {
    field: 'SOLIDITY',
    label: 'Convex shape (unitless)',
    description: 'Ratio of the area of the convex hull of the cell to the total area of the cell',
    group: FeatureGroup.CELL_SHAPE_FEATURES,
  },

  {
    field: 'MEAN',
    label: 'Mean pixel intensity (arbitrary units)',
    description:
      'Mean pixel grayscale value, refers to how much light a cell absorbs and/or scatters. Metric: Range is -1 to 1',
    group: FeatureGroup.PIXEL_INTENSITY_FEATURES,
    groupPriority: 1,
  },
  {
    field: 'STANDARD_DEVIATION',
    label: 'Standard deviation of pixel intensity',
    description:
      'Standard deviation of pixel grayscale values gives an indication of the uniformity of pixel intensity within the cell',
    group: FeatureGroup.PIXEL_INTENSITY_FEATURES,
    groupPriority: 2,
  },

  {
    field: 'POSITIVE_FRACTION',
    label: 'Positive fraction',
    description: 'Fraction of pixels with a grayscale value significantly above background',
    group: FeatureGroup.PIXEL_INTENSITY_FEATURES,
  },
  {
    field: 'NEGATIVE_FRACTION',
    label: 'Negative fraction',
    description: 'Fraction of pixels with a grayscale value significantly below background',
    group: FeatureGroup.PIXEL_INTENSITY_FEATURES,
  },
  {
    field: 'PERCENTILE_75',
    label: 'Pixel intensity 75th percentile',
    description: '75th percentile of pixel grayscale values',
    group: FeatureGroup.PIXEL_INTENSITY_FEATURES,
  },
  {
    field: 'PERCENTILE_25',
    label: 'Pixel intensity 25th percentile',
    description: '25th percentile of pixel grayscale values',
    group: FeatureGroup.PIXEL_INTENSITY_FEATURES,
  },

  // Added in v7 to normalize by cell area
  {
    field: 'LARGE_BLACK_BLOB_MEAN',
    group: FeatureGroup.TEXTURE_FEATURES,
    groupPriority: 1,
  },
  {
    field: 'LARGE_WHITE_BLOB_MEAN',
    group: FeatureGroup.TEXTURE_FEATURES,
    groupPriority: 2,
  },
  {
    field: 'SMALL_BLACK_BLOB_MEAN',
    group: FeatureGroup.TEXTURE_FEATURES,
    groupPriority: 3,
  },
  {
    field: 'SMALL_WHITE_BLOB_MEAN',
    group: FeatureGroup.TEXTURE_FEATURES,
    groupPriority: 4,
  },

  // These are only present in v6 and earlier
  {
    field: 'SMALL_WHITE_BLOB_INTEGRAL',
    label: 'Small set of connected bright pixels, integral',
    description:
      'Sum of pixel intensities in regions identified as being small bright structures. Small is described as ~8 pixels but smaller sets could be detected',
    group: FeatureGroup.TEXTURE_FEATURES,
    groupPriority: 5,
  },
  {
    field: 'SMALL_BLACK_BLOB_INTEGRAL',
    label: 'Small set of connected dark pixels, integral',
    description:
      'Sum of pixel intensities in regions identified as being small dark structures. Small is defined as ~8 pixels but smaller sets could be detected',
    group: FeatureGroup.TEXTURE_FEATURES,
    groupPriority: 6,
  },
  {
    field: 'LARGE_WHITE_BLOB_INTEGRAL',
    label: 'Large set of connected bright pixels, integral',
    description: 'Sum of pixel intensities in regions identified as being large bright structures',
    group: FeatureGroup.TEXTURE_FEATURES,
    groupPriority: 7,
  },
  {
    field: 'LARGE_BLACK_BLOB_INTEGRAL',
    label: 'Large set of connected dark pixels, integral',
    description: 'Sum of pixel intensities in regions identified as being large dark structures',
    group: FeatureGroup.TEXTURE_FEATURES,
    groupPriority: 8,
  },
  // In v7 and later, BLOB_INTEGRAL features were renamed to BLOB_SUM
  {
    field: 'SMALL_WHITE_BLOB_SUM',
    label: 'Small set of connected bright pixels, sum',
    description:
      'Sum of pixel intensities in regions identified as being small bright structures. Small is described as ~8 pixels but smaller sets could be detected',
    group: FeatureGroup.TEXTURE_FEATURES,
    groupPriority: 5,
  },
  {
    field: 'SMALL_BLACK_BLOB_SUM',
    label: 'Small set of connected dark pixels, sum',
    description:
      'Sum of pixel intensities in regions identified as being small dark structures. Small is defined as ~8 pixels but smaller sets could be detected',
    group: FeatureGroup.TEXTURE_FEATURES,
    groupPriority: 6,
  },
  {
    field: 'LARGE_WHITE_BLOB_SUM',
    label: 'Large set of connected bright pixels, sum',
    description: 'Sum of pixel intensities in regions identified as being large bright structures',
    group: FeatureGroup.TEXTURE_FEATURES,
    groupPriority: 7,
  },
  {
    field: 'LARGE_BLACK_BLOB_SUM',
    label: 'Large set of connected dark pixels, sum',
    description: 'Sum of pixel intensities in regions identified as being large dark structures',
    group: FeatureGroup.TEXTURE_FEATURES,
    groupPriority: 8,
  },

  { field: 'LARGE_BLACK_BLOBS', group: FeatureGroup.TEXTURE_FEATURES },
  { field: 'LARGE_WHITE_BLOBS', group: FeatureGroup.TEXTURE_FEATURES },
  { field: 'SMALL_BLACK_BLOBS', group: FeatureGroup.TEXTURE_FEATURES },
  { field: 'SMALL_WHITE_BLOBS', group: FeatureGroup.TEXTURE_FEATURES },

  // Starting in v5, these have been renamed
  {
    field: 'HU_MOMENTS_01',
    label: 'Image moment 1',
    description:
      'Two values that describe the weighted average or distribution of the image pixel intensities within the cell in a rotation and scale-invariant manner',
    group: FeatureGroup.TEXTURE_FEATURES,
  },
  {
    field: 'HU_MOMENTS_02',
    label: 'Image moment 2',
    description:
      'Two values that describe the weighted average or distribution of the image pixel intensities within the cell in a rotation and scale-invariant manner',

    group: FeatureGroup.TEXTURE_FEATURES,
  },

  // Focus features from Morphometrics v6 and above
  {
    field: 'Z_ESTIMATE',
    label: 'Image focus (µm)',
    description: 'Estimate of the distance of the cell to the focal plane of the microscope',
    group: FeatureGroup.FOCUS_FEATURES,
    isInternalOnly: true,
  },
  {
    field: 'SHARPNESS',
    label: 'Image sharpness (arbitrary units)',
    description:
      'A measure of how sharp or smooth the image is. Typically an out-of-focus image is less sharp than an in-focus image.',
    group: FeatureGroup.FOCUS_FEATURES,
    isInternalOnly: true,
  },
  {
    field: 'RING_WIDTH',
    label: 'Ring width (arbitrary units)',
    description:
      'Our imaging modality creates a dark or bright ring to appear around the cell, which is larger and more intense the more out of focus the cell is. This feature estimates the width of the ring',
    group: FeatureGroup.FOCUS_FEATURES,
    isInternalOnly: true,
  },
  {
    field: 'RING_INTENSITY',
    label: 'Ring intensity (arbitrary units)',
    description:
      'Our imaging modality creates a dark or bright ring to appear around the cell, which is larger and more intense the more out of focus the cell is. This feature estimates the intensity of the ring.',
    group: FeatureGroup.FOCUS_FEATURES,
    isInternalOnly: true,
  },

  // Position features
  {
    field: 'CENTROID_X',
    group: FeatureGroup.POSITION_FEATURES,
    label: 'Centroid X axis (µm)',
    description: 'X axis position of the cell relative to the camera’s field of view',
  },
  {
    field: 'CENTROID_Y',
    group: FeatureGroup.POSITION_FEATURES,
    label: 'Centroid Y axis (µm)',
    description: 'Y axis position of the cell relative to the camera’s field of view',
  },

  ...LBP_CENTER_FEATURES,
  ...LBP_PERIPHERY_FEATURES,

  // These were renamed in v5 to HU_MOMENTS
  ...HU_FEATURES_V1,

  ...DEEP_LEARNING_FEATURES,
]

export const SAMPLE_DISTRIBUTION = 'SAMPLE_DISTRIBUTION'
export const DENSITY_ATTRIBUTE = 'density'