import { SelectChangeEvent } from '@mui/material'
import { DeepcellPrimarySelect } from 'components/shared'
import { useCellVisualizationsSlice } from 'redux/slices/hooks/useCellVisualizationsSlice'
import useEventManager from 'redux/slices/hooks/useEventsManager'
import useDataCategory from './useDataCategory'

export const DataCategorySelect = (): JSX.Element => {
    const {
        cellVisualizations: { groupCompare },
        setGroupCompare,
    } = useCellVisualizationsSlice()

    const { selectedDataCategory } = groupCompare
    const { dataCategoryKeys } = useDataCategory()
    const eventsManager = useEventManager()

    const handleDataCategoryChange = (e: SelectChangeEvent<unknown>) => {
        setGroupCompare({
            groupCompare: { ...groupCompare, selectedDataCategory: e.target.value as string },
            eventsManager,
        })
    }

    return (
        <DeepcellPrimarySelect
            label="Choose Data Category To Compare"
            items={dataCategoryKeys}
            value={selectedDataCategory}
            onChange={handleDataCategoryChange}
        />
    )
}

export default DataCategorySelect
