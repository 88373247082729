import { Slice as RTKSlice } from '@reduxjs/toolkit'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/store'

type StorePropertyNames = keyof RootState

type StoreData<SPN extends StorePropertyNames> = {
    [StorePropertyName in SPN]: RootState[StorePropertyName]
}

type WrappedSliceMethods<Slice extends RTKSlice> = {
    [ActionName in keyof Slice['actions']]: (
        ...arg: Parameters<Slice['actions'][ActionName]>
    ) => void
}

/**
 * Returns an object with all slice methods and data in the store for that name
 * @param {string} storePropertyName Name of the store property ('labeling', 'runSearchFilter', etc...)
 * @param {Slice} slice Slice you want wrapped
 * @returns An object containing all methods of the slice along with all data in the store for that name
 * @example const useRunsSlice = () => useSliceWrapper('runs', runsSlice)
 * @tip Note that if you're exporting a function that returns useSliceWrapper, eslint requires you to
 * explicitly declare the return type of that export function. A way around this is to declare a local,
 * non-exported function that that wraps around useSliceWrapper, then have your exported function return the local
 * function, then have your return type of the exported function be the ReturnType<> of the local function.
 * @example
 * const useGetMethodsAndData = () => useSliceWrapper('runs', runsSlice)
 * export const useRunsSlice = (): ReturnType<typeof useGetMethodsAndData> => useGetMethodsAndData()
 * export default useRunsSlice
 */
export const useSliceWrapper = <Slice extends RTKSlice, Name extends StorePropertyNames>(
    storePropertyName: Name,
    slice: Slice
): WrappedSliceMethods<Slice> & StoreData<Name> => {
    const dispatch = useDispatch()
    const { actions } = slice

    const data = useSelector<RootState, RootState[Name]>((state) => state[storePropertyName])
    const dataOutput = { [storePropertyName]: data } as StoreData<Name>

    const methods = Object.keys(actions).reduce((acc, k) => {
        const key = k as keyof typeof actions
        type Method = Slice['actions'][typeof key]

        if (actions[key]) {
            return {
                ...acc,
                [key]: (input: Parameters<Method>) => {
                    dispatch(actions[key](input))
                },
            }
        }
        return acc
    }, {} as WrappedSliceMethods<Slice>)

    return { ...methods, ...dataOutput }
}

export default useSliceWrapper
