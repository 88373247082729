import { AppBar, styled, Toolbar, ToolbarProps } from '@mui/material'

const NavAppBar = styled(AppBar)(({ theme }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  backgroundColor: theme.palette.text.primary,
  boxShadow: '0',
}))

interface TopBarBaseProps {
  backgroundColor?: string
  toolbarProps?: ToolbarProps
}

export const TopBarBase = ({
  backgroundColor,
  children,
  toolbarProps,
}: React.PropsWithChildren<TopBarBaseProps>): JSX.Element => (
  <NavAppBar
    position="fixed"
    sx={{
      backgroundColor,
    }}
  >
    <Toolbar variant="dense" {...toolbarProps}>
      {children}
    </Toolbar>
  </NavAppBar>
)

export default TopBarBase
