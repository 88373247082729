import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Box, Tooltip, styled } from '@mui/material'
import CompactTablePagination from 'components/shared/CompactTablePagination'
import { PaginationParams } from 'redux/slices'

const zero = '0 !important'
const Wrapper = styled(Box)(() => ({
  '& .MuiTablePagination-displayedRows': {
    margin: zero,
    padding: zero,
  },
  '& .MuiTablePagination-actions': {
    margin: '.2rem !important',
    '*': { padding: '.1rem !important' },
  },
  '& [class$="MuiTablePagination-select"]': {
    margin: '0 .5rem 0 0 !important',
    '& *': {
      paddingLeft: zero,
    },
  },
  '& *': {
    border: zero,
  },
  '& .MuiTablePagination-selectLabel': {
    alignSelf: 'start',
    lineHeight: 1,
  },
  '& .MuiTablePagination-toolbar': {
    minHeight: '30px',
  },
}))

interface CellImagePaginationProps {
  count: number
  onPaginationChange: (s: PaginationParams) => void
  defaultPage?: number
  defaultItemsPerPage?: number
}

const CellsPerPageIcon = () => (
  <Tooltip title="Cells per page" placement="top-start">
    <InfoOutlinedIcon color="info" sx={{ height: '.8rem', cursor: 'pointer' }} />
  </Tooltip>
)

export const CellImagePagination = ({
  count,
  onPaginationChange,
  defaultPage,
  defaultItemsPerPage,
}: CellImagePaginationProps): JSX.Element => {
  return (
    <Wrapper>
      <CompactTablePagination
        count={count}
        onPaginationChange={onPaginationChange}
        defaultPage={defaultPage ?? 0}
        defaultItemsPerPage={defaultItemsPerPage ?? 10}
        labelRowsPerPage={<CellsPerPageIcon />}
      />
    </Wrapper>
  )
}

export default CellImagePagination
