import { styled } from '@mui/material'
import { GridApi, ProcessCellForExportParams, RowClassParams } from 'ag-grid-community'
import { AgGridReact, AgGridReactProps, ChangeDetectionStrategyType } from 'ag-grid-react'
import React, { useEffect, useMemo } from 'react'
import Moment from 'moment'
import useAuthTokens from 'utils/useAuthTokens'
import useFlagCondition from 'components/shared/useFlagCondition'
import { columnDefs, RunsHeaderName } from './metadata'

export const RUN_DATA_CACHE_BLOCK_SIZE = 100

const processCellHandler = (params: ProcessCellForExportParams) => {
  const { valueFormatter } = params.column.getColDef()
  if (valueFormatter) {
    switch (params.column.getColDef().headerName) {
      case RunsHeaderName.RunType:
        return params.value?.length > 0 ? 'Sorting' : 'Imaging'
      case RunsHeaderName.StartTime:
        return Moment(params.value).format('YYYY-MM-DD HH:mm:ss') || ''
      case RunsHeaderName.Status:
        return (params.value as boolean) ? 'Complete' : 'In Progress'
      default:
        return params.value
    }
  }
  return params.value
}

const gridOptions = {
  defaultCsvExportParams: {
    processCellCallback: processCellHandler,
  },
  defaultExcelExportParams: {
    processCellCallback: processCellHandler,
  },
  processCellForClipboard: processCellHandler,
}

const TableContainer = styled('div')({
  width: 'calc(100vw - 16px)',
  height: 'calc(100vh - 150px)',
})

interface IRunGrid extends AgGridReactProps {
  gridApi?: GridApi | null
  rowItems: number | null
  showDetailsPanel: (value: boolean) => void
}

const headerHeight = 40

function RunGrid(props: IRunGrid) {
  const { isInternalUser } = useAuthTokens()

  const { gridApi, rowItems } = props
  const manualQualityReviewEnabled = useFlagCondition('manualQualityReviewEnabled')
  const withoutQualityReviewColumn = useMemo(
    () => columnDefs().filter((item) => item.headerName !== RunsHeaderName.QualityReview),
    []
  )
  useEffect(() => {
    if (!isInternalUser) {
      gridApi?.setColumnDefs([])
      gridApi?.setColumnDefs(withoutQualityReviewColumn)
    }
  }, [gridApi, isInternalUser, withoutQualityReviewColumn, rowItems])

  const rowStyle = {
    padding: '0px 50px',
    background: '#FFF',
  }

  const getColumnDefs = () => {
    if (manualQualityReviewEnabled) {
      return columnDefs()
    }
    return withoutQualityReviewColumn
  }
  return (
    <TableContainer
      className="ag-theme-v3-runs-table context-menu-ag-grid"
      data-testid="runs-table"
    >
      <AgGridReact
        rowStyle={rowStyle}
        headerHeight={headerHeight}
        rowSelection="multiple"
        rowModelType="infinite"
        cacheBlockSize={RUN_DATA_CACHE_BLOCK_SIZE}
        columnDefs={getColumnDefs()}
        gridOptions={gridOptions}
        rowDataChangeDetectionStrategy={ChangeDetectionStrategyType.IdentityCheck}
        suppressDragLeaveHidesColumns
        getRowStyle={(params: RowClassParams) => {
          const { rowIndex } = params.node
          return {
            marginTop:
              rowIndex === null || rowIndex === undefined ? '0' : `${10 * (rowIndex + 1)}px`,
          }
        }}
        {...props}
        rowBuffer={100}
      />
    </TableContainer>
  )
}

export default React.memo(RunGrid)
