import { Box, Stack, styled } from '@mui/material'
import ToggleButton from '@mui/material/ToggleButton'
import useFlagCondition from 'components/shared/useFlagCondition'
import React, { memo, useRef, useState } from 'react'
import { useWindowSize } from 'react-use'
import { CellInfoGroup } from 'redux/slices'
import { useCellVisualizationsSlice } from 'redux/slices/hooks/useCellVisualizationsSlice'
import useEventsManager from 'redux/slices/hooks/useEventsManager'
import useFooterHeight from 'utils/useFooterHeight'
import { LegendColorPicker } from './LegendColorPicker'
import LegendItem from './LegendItem'
import { StaticPlotLegend } from './StaticPlotLegend'

interface StyledToggleButtonProps {
  bgcolor: string
}

export const StyledToggleButton = styled(ToggleButton)(({ bgcolor }: StyledToggleButtonProps) => ({
  borderRadius: 0,
  '&.Mui-disabled': {
    border: 0,
    cursor: 'not-allowed',
    pointerEvents: 'auto',
  },
  '&.Mui-selected, &.Mui-selected:hover, &:hover': {
    backgroundColor: bgcolor,
  },
  width: '100%',
  height: 50,
  backgroundColor: bgcolor,
  cursor: 'pointer',
}))

const Legend = ({ showPreFilter }: { showPreFilter?: boolean }): JSX.Element => {
  const eventsManager = useEventsManager()

  const topBoxRef = useRef<HTMLDivElement>(null)

  const {
    setSelectedCellInfoGroupData,
    selectedCellInfoGroup,
    cellVisualizations: { plotDisplayType },
  } = useCellVisualizationsSlice()
  const { data, isContinuous } = selectedCellInfoGroup
  const isDensity = plotDisplayType === 'density'

  const colorChangeCount = useRef(0)
  const [popoverAnchor, setPopoverAnchor] = useState<HTMLElement | null>(null)

  const pickerEnabled = useFlagCondition('cellVisualizationsColorPickerEnabled')
  const footerHeight = useFooterHeight()
  const { height: windowHeight } = useWindowSize()
  const innerHeight =
    windowHeight - (topBoxRef.current?.getBoundingClientRect().top ?? 0) - footerHeight - 40

  const handleOnClick = (e: React.MouseEvent) => {
    setPopoverAnchor(e.currentTarget as HTMLElement)
  }

  const onPopoverClose = (selectedColor: string) => {
    colorChangeCount.current += 1
    eventsManager.sendCustomColorEvent(colorChangeCount.current)

    const label = popoverAnchor?.getAttribute('data-label')

    setSelectedCellInfoGroupData(
      data.map((x) => (x.value === label ? { ...x, color: selectedColor } : x))
    )

    setPopoverAnchor(null)
  }

  const onHide = (item: CellInfoGroup['data'][number], wasHidden: boolean) => {
    setSelectedCellInfoGroupData(
      data.map((x) => (x.value === item.value ? { ...x, isHidden: !wasHidden } : x))
    )
  }

  return (
    <Box ref={topBoxRef}>
      <Stack spacing={2} sx={{ height: innerHeight, overflow: 'auto', px: 1 }}>
        {isContinuous || (isDensity && showPreFilter) ? (
          <StaticPlotLegend height={innerHeight} />
        ) : (
          data.map((item) => (
            <LegendItem
              key={item.value}
              bgColor={isDensity ? undefined : item.color}
              label={item.value}
              onClick={pickerEnabled ? handleOnClick : undefined}
              dataLabel={item.value}
              isHidden={item.isHidden}
              onHide={(wh) => onHide(item, wh)}
              readOnly={showPreFilter}
            />
          ))
        )}
      </Stack>
      <LegendColorPicker
        preSelectedColors={data.map((x) => x.color)}
        open={!!popoverAnchor}
        onClose={onPopoverClose}
        anchorEl={popoverAnchor}
      />
    </Box>
  )
}

export default memo(Legend)
