import * as ROUTES from 'constants/routes'
import { Box } from '@mui/material'
import CellVisualizationFooter from 'components/cell-visualizations/CellVisualizationFooter'
import { useRouteMatch } from 'react-router-dom'

export const deepcellFooterId = 'deepcellFooterId'

export const DeepcellFooter = (): JSX.Element => {
  const isCellViz = useRouteMatch([ROUTES.CELL_VISUALIZATIONS_SESSION])
  const isPreFilter = useRouteMatch(ROUTES.CELL_VISUALIZATIONS_SESSION_VERSION_PREFILTER)

  let footer = <></>

  if (isPreFilter) {
    return footer
  }

  if (isCellViz) {
    footer = <CellVisualizationFooter />
  }

  return (
    <Box
      id={deepcellFooterId}
      sx={{ top: 'auto', bottom: 0, height: '64px', position: 'fixed', zIndex: 100 }}
    >
      {footer}
    </Box>
  )
}

export default DeepcellFooter
