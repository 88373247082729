import { useCellVisualizationsSlice } from 'redux/slices/hooks/useCellVisualizationsSlice'
import { colorByButtonId } from '../colorBy/ColorByDrawer'
import { IndexedContent } from './IndexedContent'
import { Video } from './Video'
import { Step } from './Walkthrough'
import getStepComponent from './getStepComponent'

export const useWalkthroughSteps = (): Step[] => {
  const {
    cellVisualizations: { showColorBy },
  } = useCellVisualizationsSlice()

  return [
    {
      name: 'Welcome',
      component: getStepComponent({
        header: 'Welcome to the Axon Data Suite',
        content: (
          <IndexedContent>
            <p>
              This 3-minute tutorial will walk you through the capabilities of Deepcell’s cell
              visualization workflow, which enables data analysis from cell images obtained on the
              Deepcell instrument. The Deepcell AI technology extracts features from cell images
              from diverse human cells without prior knowledge of specific cell types, cell
              preparation, or other application-specific knowledge for an exploratory approach.
            </p>
          </IndexedContent>
        ),
      }),
    },
    {
      name: 'Welcome 2',
      component: getStepComponent({
        header: 'What to expect',
        content: (
          <IndexedContent>
            <div>
              <p>
                This guided walkthrough will introduce you to features specifically designed to
                explore Deepcell data using an interactive UMAP, where cells are distributed and
                clustered based on unique morphological characteristics. You will learn how to:
              </p>
              <ul>
                <li>Assess morphological diversity across the plot using multiple parameters</li>
                <li>Identify and capture cell populations for further downstream analysis</li>
                <li>Review and compare your selections</li>
              </ul>
            </div>
          </IndexedContent>
        ),
      }),
    },
    {
      name: 'UMAP Cell Point',
      component: getStepComponent({
        header: 'Assessing morphological diversity in the plot',
        content: (
          <>
            <IndexedContent>
              <p>
                Each data point on the UMAP is an individual cell that has been imaged with the
                Deepcell instrument. Click on a single data point to view what additional
                information can be obtained for a single cell image
              </p>
            </IndexedContent>
            <Video src="/images/ClickCellPoint.gif" />
          </>
        ),
        position: 'right',
      }),
    },
    {
      name: 'Images toolbar',
      target: '#plot-images-filter',
      component: getStepComponent({
        header: 'Assessing morphological diversity in the plot',
        content: (
          <>
            <IndexedContent>
              <p>
                This toolbar lets you display (or hide) cell images on top of the UMAP to get a
                sense of the various cell morphologies represented in different areas of the plot.
                This is a great way to start when exploring a dataset. You can adjust the image size
                and spacing as needed.
              </p>
            </IndexedContent>
            <Video src="/images/DisplayImages.gif" />
          </>
        ),
        position: 'right',
      }),
    },
    {
      name: 'Color by',
      target: !showColorBy ? `#${colorByButtonId}` : '',
      component: getStepComponent({
        header: 'Assessing morphological diversity in the plot',
        content: (
          <>
            <IndexedContent>
              <div>
                <p>
                  Furthermore, you might want to use the “Color By” option to analyze how different
                  cell morphologies or cell states are clustered in different areas of the UMAP.
                  This is a useful method to reveal interesting groupings based on specific
                  characteristics. Below options are available:
                </p>
                <ul>
                  <li>Run information (e.g. Ground Truth Labels)</li>
                  <li>AI features (e.g. Clusters)</li>
                  <li>
                    A list of measurable cell characteristics (e.g. related to cell size, shape, or
                    texture)
                  </li>
                </ul>
              </div>
            </IndexedContent>
            <Video src="/images/SelectColorByButtonGIF.gif" />
          </>
        ),
        position: 'right',
      }),
    },
    {
      name: 'Visualization toolbar',
      target: '#dataVizToolButtons',
      component: getStepComponent({
        header: 'How to further explore regions of the plot',
        content: (
          <>
            <IndexedContent>
              <p>
                To reveal cell images located in a given region of the UMAP, simply select specific
                areas on it using the box or lasso tools in the visualization toolbar. Click and
                hold to select areas, immediately making the relevant cell images available in the
                right panel.
              </p>
            </IndexedContent>
            <Video src="/images/LassoSelection.gif" />
          </>
        ),
        position: 'left',
      }),
    },
    {
      name: 'Cell Group Creation',
      target: '#dataVizCellGroupsButtonId',
      component: getStepComponent({
        header: 'How to further explore regions of the plot',
        content: (
          <>
            <IndexedContent>
              <p>
                Whenever you find a population of interest by selecting an area, we encourage you to
                create and save it using this button. These selections (or Cell Groups) can then be
                (re)named, merged or deleted as needed.
              </p>
            </IndexedContent>
            <Video src="/images/CellGroupsCreation.gif" />
          </>
        ),
        position: 'left',
      }),
    },
    {
      name: 'Compare Cells',
      target: '#dataVizCompareGroupsButtonId',
      component: getStepComponent({
        header: 'Comparing cell groups and saving plots',
        content: (
          <>
            <IndexedContent>
              <p>
                A ‘Compare’ section is available to create histograms or distribution plots using
                the data presented, helping you test your hypotheses. You can create charts using
                any category, including the cell groups you created. All plots generated on the
                platform can be easily downloaded using this download button.
              </p>
            </IndexedContent>
            <Video src="/images/CompareSelections.gif" />
          </>
        ),
        position: 'left',
      }),
    },
    {
      name: 'Guide',
      target: '#dataVizWalkthroughButtonId',
      component: getStepComponent({
        content: (
          <>
            <IndexedContent>
              <p>
                If you need any guidance at any point, you can click on the Guide Button here and
                access this walkthrough.
              </p>
            </IndexedContent>
            <Video src="/images/ShowGuide.gif" />
          </>
        ),
        showSkipButton: false,
        primaryButtonText: 'Finish',
        position: 'center',
      }),
    },
  ]
}

export default useWalkthroughSteps
