import { Stack } from '@mui/material'

export const Row = ({
  children,
  ...rest
}: React.PropsWithChildren<Omit<Parameters<typeof Stack>[0], 'direction'>>): JSX.Element => {
  return (
    <Stack {...rest} direction="row">
      {children}
    </Stack>
  )
}

export default Row
