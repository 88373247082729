import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'

function ContentLoading(): JSX.Element {
    return (
        <Grid container direction="column" alignItems="center" sx={{ flexGrow: 1 }}>
            <CircularProgress data-testid="circularProgress" color="secondary" />
        </Grid>
    )
}

export default ContentLoading
