import { CellId } from '@deepcell/dc_core_proto/deepcell_schema2_pb'
import { Card, Checkbox, Skeleton, styled } from '@mui/material'
import CheckedIcon from 'components/shared/icons/CheckedIcon'
import { useMemo } from 'react'
import { QueryClient } from 'react-query'
import useCellBrowsingSlice from 'redux/slices/hooks/useCellBrowsingSlice'
import { CellImageFilters, CellResponse } from 'utils/api'
import { messagesEqual } from 'utils/proto-utils'
import useCellImage, { prefetchCellImage } from 'utils/useCellImage'

const CENTER_CROP_FILTER: CellImageFilters = {
  centerCropSize: 110,
}

const SHARPEN_FILTER: CellImageFilters = {
  unsharpAmount: 2,
  unsharpRadius: 2,
}

export interface CellDisplayOptions {
  centerCrop?: boolean
  sharpen?: boolean
}

const CardRoot = styled(Card)({
  display: 'inline-flex',
  position: 'relative',
  overflow: 'visible',
  borderRadius: 0,
  boxShadow: 'none',
  flexWrap: 'wrap',
  cursor: 'pointer',
})

export type CellImageAction =
  | { type: 'clickCell'; cell: CellResponse }
  | { type: 'resetCheckboxSelection' }
  | { type: 'cell' }
  | { type: 'cellId' }

interface Props {
  key?: string
  cell?: CellResponse | undefined
  width: number // desired image display size in pixels (assuming square)
  chipLabel?: string
  displayOptions?: CellDisplayOptions
  highlighted?: boolean
  onCellImageAction?: (action: CellImageAction) => void
}

export function getFilters(displayOptions?: CellDisplayOptions): CellImageFilters {
  let filters: CellImageFilters = {}
  if (displayOptions?.centerCrop) {
    filters = CENTER_CROP_FILTER
  }
  if (displayOptions?.sharpen) {
    filters = { ...filters, ...SHARPEN_FILTER }
  }
  return filters
}

/** Converts to device pixel width for retina displays */
function getWidthForDevice(width: number): number {
  // @TODO This doesn't update if a user drags to a different monitor with a different pixel ratio
  // But this is not a high priority
  return Math.floor(width * window.devicePixelRatio)
}

/*
 * Prefetch image using the display options encoded in CellImageControl
 * Make sure you use the same QueryClient as when making the actual request.
 */
export async function prefetchForImageControl(
  queryClient: QueryClient,
  cellId: CellId,
  width: number,
  displayOptions?: CellDisplayOptions
): Promise<void> {
  await prefetchCellImage(
    queryClient,
    cellId,
    0,
    getWidthForDevice(width),
    getFilters(displayOptions)
  )
}

// This is a hack. Make it so if there's any classname then it's "enabled"
// @TODO: Figure out how to pass custom props to styled component
const HighlightedImage = styled('div')((props) =>
  props.className === 'true'
    ? {
        // Make this subtle, but enough of a difference in shape + color to be preattentive
        // So it's easy to spot highlighted cells
        border: `2px solid ${props.theme.palette.warning.main}`,
        borderRadius: props.theme.spacing(2),
        opacity: 0.7,
        filter: 'sepia(100%)',
        overflow: 'hidden',
      }
    : {}
)

/** A common control for showing a cell image at within a fixed size square */
const CellImageControl = (props: Props): JSX.Element => {
  const { cell, width, highlighted, displayOptions, onCellImageAction } = props

  const {
    cellBrowsing: { selectedCells },
  } = useCellBrowsingSlice()

  const showChecked = useMemo(() => {
    return selectedCells.find((selectedCell) =>
      messagesEqual(selectedCell.cell.getCellId(), cell?.cell.getCellId())
    )
  }, [cell, selectedCells])

  const { data: imageDataUrl } = useCellImage({
    cellId: cell?.cell.getCellId(),
    frame: 0,
    width: getWidthForDevice(width),
    filters: getFilters(displayOptions),
  })
  const sizeStyle = {
    width: `${width}px`,
    height: `${width}px`,
  }

  if (!imageDataUrl || !cell) {
    return (
      <Skeleton
        key={cell?.toString()}
        variant="rectangular"
        sx={{ borderRadius: '3.5px', ...sizeStyle }}
      />
    )
  }

  return (
    <CardRoot
      key={cell.toString()}
      style={sizeStyle}
      {...(onCellImageAction
        ? {
            onMouseDown: () => onCellImageAction({ type: 'clickCell', cell }),
          }
        : {})}
    >
      {showChecked ? (
        <Checkbox
          disableTouchRipple
          checkedIcon={<CheckedIcon inheritViewBox sx={{ width: '22px', height: '22px' }} />}
          sx={{ p: 0, position: 'absolute', zIndex: '2' }}
          checked
        />
      ) : null}
      <HighlightedImage
        data-testid="cellImageControlIMG"
        className={`${highlighted}`}
        style={sizeStyle}
      >
        <img
          alt=""
          src={imageDataUrl}
          style={{ ...sizeStyle, userSelect: 'none' }}
          draggable={false}
        />
      </HighlightedImage>
    </CardRoot>
  )
}

export default CellImageControl
