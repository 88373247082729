import { styled } from '@mui/material'
import Button, { ButtonProps } from '@mui/material/Button'

interface OutlinedVariant {
  /**
   * The variant to use.
   *
   * Variant type: outlined
   *
   * Refer: https://mui.com/material-ui/react-button/#outlined-button
   */
  outlined: boolean
  contained?: never
  small?: never
  text?: never
}
interface ContainedVariant {
  outlined?: never
  /**
   * The variant to use.
   *
   * Variant type: contained
   *
   * Refer: https://mui.com/material-ui/react-button/#contained-button
   */
  contained: boolean
  small?: never
  text?: never
}

interface SmallVariant {
  outlined?: never
  contained?: never
  /**
   * The variant to use.
   *
   * Variant type: small
   */
  small: boolean
  text?: never
}

interface TextVariant {
  outlined?: never
  contained?: never
  small?: never
  /**
   * The variant to use.
   *
   * Variant type: small
   */
  text: boolean
}

const StyledOutlineButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  color: theme.palette.primary.main,
  gap: '10px',
  padding: '10px 25px',
  background: theme.palette.background.default,
  border: `2px solid ${theme.palette.success.main}`,
  borderRadius: '4px',
  '&:hover': {
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
    border: `2px solid ${theme.palette.success.main}`,
  },
}))

const StyledContainedButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  color: theme.palette.primary.main,
  gap: '10px',
  padding: '10px 25px',
  boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.15) !important',
  background: theme.palette.success.light,
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: '3px',
  '&:hover': {
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25) !important',
    background: theme.palette.success.main,
  },
}))

const StyledSmallButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  color: theme.palette.primary.main,
  padding: '4.5px 14.5px',
  gap: '10px',
  background: theme.palette.background.default,
  border: `1px solid ${theme.palette.success.main}`,
  boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.15)',
  borderRadius: '4px',
}))

export type DeepcellPrimaryButtonProps = ButtonProps &
  (OutlinedVariant | ContainedVariant | SmallVariant | TextVariant)

export const DeepcellPrimaryButton = ({
  children,
  outlined,
  contained,
  text,
  small,
  ...props
}: DeepcellPrimaryButtonProps): JSX.Element => {
  if (outlined)
    return (
      <StyledOutlineButton disableRipple color="primary" variant="outlined" {...props}>
        {children}
      </StyledOutlineButton>
    )
  if (contained)
    return (
      <StyledContainedButton disableRipple color="primary" variant="contained" {...props}>
        {children}
      </StyledContainedButton>
    )
  if (small)
    return (
      <StyledSmallButton disableRipple color="primary" variant="outlined" size="small" {...props}>
        {children}
      </StyledSmallButton>
    )

  if (text)
    return (
      <Button disableRipple color="primary" variant="text" {...props}>
        {children}
      </Button>
    )
  return <></>
}

export default DeepcellPrimaryButton
