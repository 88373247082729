import { Image } from 'plotly.js'
import { toImage } from 'plotly.js/dist/plotly-axon'
import { useCallback, useEffect, useState } from 'react'
import { ScatterPlotMetadata } from 'redux/slices'
import { useCellVisualizationsSlice } from 'redux/slices/hooks/useCellVisualizationsSlice'
import useElementById from 'utils/useElementById'
import getMarkerSize from './getMarkerSize'
import { usePlotData } from './usePlotData'
import { HIDDEN_COLOR } from '../shared'

const buffer = 5

const getImage = (
  imgUrl: string,
  position: { x: number; y: number },
  size: { height: number; width: number }
) => {
  return {
    x: position.x,
    y: position.y,
    xref: 'x',
    yref: 'y',
    xanchor: 'center',
    yanchor: 'middle',
    sizex: size.width,
    sizey: size.height,
    sizing: 'stretch',
    source: imgUrl,
    layer: 'below',
  } as Partial<Image>
}

type GetImageUrlParams = {
  scatterPlotMetaData: ScatterPlotMetadata
  /**
   * Default to a big enough image so that when the user zooms in the image doesn't get too grainy
   */
  width?: number
  height?: number
  markerSize?: number
}

const getImageUrl = async ({
  scatterPlotMetaData,
  width = 2000,
  height = 2000,
  markerSize = 1,
}: GetImageUrlParams) => {
  const { maxX, maxY, minX, minY, x, y } = scatterPlotMetaData
  const url = await toImage(
    {
      data: [
        {
          x,
          y,
          type: 'scattergl',
          mode: 'markers',
          marker: {
            color: HIDDEN_COLOR,
            size: markerSize,
          },
        },
      ],
      layout: {
        margin: { t: 0, b: 0, l: 0, r: 0, pad: 0 },
        xaxis: {
          visible: false,
          range: [minX - buffer, maxX + buffer],
        },
        yaxis: {
          visible: false,
          range: [minY - buffer, maxY + buffer],
        },
        showlegend: false,
      },
    },
    { scale: 1, format: 'png', width, height }
  )
  return url
}

type UseHiddenBackgroundImagesParams = {
  /**
   * If true, points from morphotypesToRemove will be removed entirely instead of greyed out
   */
  removePreFilterPoints?: boolean
}

export const useHiddenBackgroundImages = (
  params?: UseHiddenBackgroundImagesParams
): Partial<Image>[] => {
  const { removePreFilterPoints: removeHiddenPoints } = params ?? {}

  const { scatterPlotMetaData, scatterPlotMetaDataFiltered } = usePlotData()
  const metaData = removeHiddenPoints ? scatterPlotMetaDataFiltered : scatterPlotMetaData

  const { rect } = useElementById('scatter-plot')

  const { visibleSelectedComparisonDimensions } = useCellVisualizationsSlice()

  const [images, setImages] = useState<Partial<Image>[]>([])

  const { maxX, minX, maxY, minY } = metaData
  const plotWidth = maxX - minX
  const plotHeight = maxY - minY
  const centerX = (maxX + minX) / 2
  const centerY = (maxY + minY) / 2
  const xOffset = plotWidth * 1.5
  const yOffset = plotHeight * 1.5

  const getImageSize = useCallback(
    () => ({ width: plotWidth + buffer * 2, height: plotHeight + buffer * 2 }),
    [plotHeight, plotWidth]
  )

  const getImages = useCallback(
    (imgUrl: string) => {
      const img = [] as Partial<Image>[]
      if (visibleSelectedComparisonDimensions) {
        const numOfColumns = visibleSelectedComparisonDimensions[0].values.length
        const totalCount =
          (visibleSelectedComparisonDimensions[1]?.values.length || 1) * numOfColumns
        for (let i = 0; i < totalCount; i += 1) {
          const col = i % numOfColumns
          const row = Math.floor(i / numOfColumns)
          img.push(
            getImage(
              imgUrl,
              { x: centerX + xOffset * col, y: centerY - yOffset * row },
              getImageSize()
            )
          )
        }
      } else {
        img.push(getImage(imgUrl, { x: centerX, y: centerY }, getImageSize()))
      }

      return img
    },
    [centerX, centerY, getImageSize, visibleSelectedComparisonDimensions, xOffset, yOffset]
  )

  useEffect(() => {
    const loadImages = async () => {
      const markerSize = getMarkerSize(metaData.x.length)
      const { height, width } = rect ?? {}
      const url = await getImageUrl({ scatterPlotMetaData: metaData, width, height, markerSize })
      if (url) {
        const img = getImages(url)
        if (img.length) {
          setImages(img)
        }
      }
    }

    loadImages()
  }, [getImages, rect, metaData])

  return images
}

export default useHiddenBackgroundImages
