import useFlagCondition from 'components/shared/useFlagCondition'
import { PreFilterIntroDialog } from './PreFilterIntroDialog'
import CellSelectionDrawer from './cellSelectionDrawer/CellSelectionDrawer'
import { ColorByDrawer } from './colorBy'
import CellVisualizationsHeader from './header'
import MainGraphContent from './plot/MainGraphContent'
import { MorphotypesToRemove } from './prefilter/MorphotypesToRemove'
import useCellVisualizationUrlParams from './useCellVisualizationUrlParams'
import { PreFilterDisplayImages } from './PreFilterDisplayImages'

export const CellVisualizationScene = (): JSX.Element => {
  const { isPreFilter } = useCellVisualizationUrlParams()
  const preFilterEnabled = useFlagCondition('cellVisualizationsPrefilterEnabled')
  const demoEnabled = useFlagCondition('demoEnabled')

  const showPreFilter = isPreFilter && preFilterEnabled && !demoEnabled

  return (
    <>
      {showPreFilter ? <PreFilterIntroDialog /> : null}
      <CellVisualizationsHeader isPreFilter={showPreFilter} />
      {showPreFilter ? (
        <>
          <ColorByDrawer showPreFilter={showPreFilter} />
          <PreFilterDisplayImages />
        </>
      ) : (
        <ColorByDrawer />
      )}

      <MainGraphContent />
      {showPreFilter ? <MorphotypesToRemove /> : <CellSelectionDrawer />}
    </>
  )
}

export default CellVisualizationScene
