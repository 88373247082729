import { createSvgIcon } from '@mui/material'

const TableViewIcon = createSvgIcon(
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="35" height="35" rx="3.5" fill="white" stroke="#A4EFC0" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.0002 12.75C14.0002 12.3358 14.336 12 14.7502 12H25.2502C25.6645 12 26.0002 12.3358 26.0002 12.75C26.0002 13.1642 25.6645 13.5 25.2502 13.5H14.7502C14.336 13.5 14.0002 13.1642 14.0002 12.75ZM14.0002 18C14.0002 17.5858 14.336 17.25 14.7502 17.25H25.2502C25.6645 17.25 26.0002 17.5858 26.0002 18C26.0002 18.4142 25.6645 18.75 25.2502 18.75H14.7502C14.336 18.75 14.0002 18.4142 14.0002 18ZM14.0002 23.25C14.0002 22.8358 14.336 22.5 14.7502 22.5H25.2502C25.6645 22.5 26.0002 22.8358 26.0002 23.25C26.0002 23.6642 25.6645 24 25.2502 24H14.7502C14.336 24 14.0002 23.6642 14.0002 23.25Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.99023 12.75C9.99023 12.1977 10.4379 11.75 10.9902 11.75H11.0002C11.5525 11.75 12.0002 12.1977 12.0002 12.75V12.76C12.0002 13.3123 11.5525 13.76 11.0002 13.76H10.9902C10.4379 13.76 9.99023 13.3123 9.99023 12.76V12.75Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.99023 23.25C9.99023 22.6977 10.4379 22.25 10.9902 22.25H11.0002C11.5525 22.25 12.0002 22.6977 12.0002 23.25V23.26C12.0002 23.8123 11.5525 24.26 11.0002 24.26H10.9902C10.4379 24.26 9.99023 23.8123 9.99023 23.26V23.25Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.99023 18C9.99023 17.4477 10.4379 17 10.9902 17H11.0002C11.5525 17 12.0002 17.4477 12.0002 18V18.01C12.0002 18.5623 11.5525 19.01 11.0002 19.01H10.9902C10.4379 19.01 9.99023 18.5623 9.99023 18.01V18Z"
      fill="black"
    />
  </svg>,
  'TableView'
)

export default TableViewIcon
