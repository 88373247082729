import { DeepcellDialog } from 'components/shared'

interface MobilePromptProps {
  open: boolean
  onContinue(): void
}
export const MobilePrompt = ({ open, onContinue }: MobilePromptProps): JSX.Element => {
  return (
    <DeepcellDialog
      open={open}
      handleConfirm={onContinue}
      showCancelButton={false}
      titleLabel="Please Note!"
      okLabel="Continue anyway"
    >
      <p>The Axon Data Suite is not optimized for mobile devices.</p>
      <p>
        For the best experience we highly recommend using the platform with a desktop or laptop.
      </p>
    </DeepcellDialog>
  )
}

export default MobilePrompt
