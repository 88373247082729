import { ReactElement, useEffect, ComponentType } from 'react'
import { Route, RouteProps } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { useAuthWrapper } from 'utils/demoEnvironmentUtils'
import ContentLoading from './ContentLoading'
import useAuthTokens from '../../utils/useAuthTokens'

interface PrivateComponentProps {
    component: ComponentType
}

// Inspired by what this does https://auth0.github.io/auth0-react/modules/with_authentication_required.html
// Adds a hasToken check in addition to auth check.
// Avoids creating a completely new component on each render call
// (which seriously messes up children and causes expensive re-rendering)
function PrivateRouteComponent(props: PrivateComponentProps): JSX.Element {
    const { component: Component } = props
    const { hasTokens } = useAuthTokens()
    const { isLoading, isAuthenticated, loginWithRedirect } = useAuthWrapper(useAuth0)
    const isReady = hasTokens && isAuthenticated
    const returnTo = `${window.location.pathname}${window.location.search}`

    useEffect(() => {
        if (isLoading || isAuthenticated) return

        loginWithRedirect({
            appState: {
                returnTo,
            },
        })
    }, [isLoading, isAuthenticated, loginWithRedirect, returnTo])

    return isReady ? <Component /> : <ContentLoading />
}

interface Props {
    exact?: RouteProps['exact']
    path: RouteProps['path']
    component: ComponentType
}

// PrivateRoute from https://auth0.com/blog/complete-guide-to-react-user-authentication/#Protecting-Routes
export const PrivateRoute = (props: Props): ReactElement => {
    const { exact, path, component } = props

    return (
        <Route
            exact={exact}
            path={path}
            render={() => <PrivateRouteComponent component={component} />}
        />
    )
}

export default PrivateRoute
