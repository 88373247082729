import { Divider, Stack, Typography } from '@mui/material'

export const MorphotypeHeader = (): JSX.Element => {
  return (
    <Stack spacing={1} mb={1}>
      <Stack direction="row" spacing={1} alignItems="center">
        <img src="/images/CellsToFilter.png" alt="" style={{ clipPath: 'circle()' }} />
        <Typography variant="h6">Cells to filter</Typography>
      </Stack>
      <Typography>
        The cells you remove (greyed out parts of the UMAP) will be excluded from analysis, and also
        from any morphotype classifiers you create for sorting on instrument.
      </Typography>
      <Divider sx={{ borderColor: 'rgba(196, 196, 196, 1)' }} />
    </Stack>
  )
}

export default MorphotypeHeader
