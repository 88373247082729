import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { DEFAULT_REMOTE_CONFIG } from 'config'

const initialState = { ...DEFAULT_REMOTE_CONFIG, isLoadedFromRemote: false }

export const remoteFlagsSlice = createSlice({
  name: 'flags',
  initialState,
  reducers: {
    set: (_, action: PayloadAction<typeof DEFAULT_REMOTE_CONFIG>) => ({
      ...action.payload,
      isLoadedFromRemote: true,
    }),
    markAsNotLoaded: (state) => ({ ...state, isLoadedFromRemote: false }),
  },
})

export const RemoteFlagsActions = remoteFlagsSlice.actions
export const RemoteFlagsReducer = remoteFlagsSlice.reducer
