import { roundToNearestIntervalMinutes, formatTimestampToHumanReadable, INSTRUMENT_REPORTED_TIMEFORMAT } from 'components/shared/date-utils'
import { Cell } from '@deepcell/dc_core_proto/deepcell_schema2_pb'
import {
  CellClassEncoderDecoder,
  getCellPrediction,
  getPredictedClass,
} from '../../utils/proto-utils'

export abstract class SortOrder {
  // private to disallow creating other instances of this type
  protected constructor(public readonly label: string, public readonly orderBy: string) {}

  abstract getLabel(cell: Cell): string | undefined
}

class ProbabilitySortOrder extends SortOrder {
  private static LABEL_FRACTION_DIGITS = 2

  constructor() {
    super('Decreasing Probability', 'predicted_probability:desc')
  }

  static getProbabilityLabel(cell: Cell): string | undefined {
    const prediction = getCellPrediction(cell)
    if (prediction === undefined) return undefined

    const cellClass = getPredictedClass(prediction)
    if (cellClass === undefined) return undefined

    const probabilityMap = prediction.getProbabilitiesMap()
    if (!probabilityMap.has(cellClass)) {
      return undefined
    }
    const probability = probabilityMap.get(cellClass)
    return Number(probability).toFixed(ProbabilitySortOrder.LABEL_FRACTION_DIGITS)
  }

  getLabel(cell: Cell): string | undefined {
    return ProbabilitySortOrder.getProbabilityLabel(cell)
  }
}


class TimeAscSortOrder extends SortOrder {
  private static ROUND_TIME_MINUTES = 60

  constructor() {
      super('Oldest first', 'time:asc')
  }

  getLabel(cell: Cell): string | undefined {
      return `${formatTimestampToHumanReadable(
          roundToNearestIntervalMinutes(
              cell.getCellId()?.getTime(),
              TimeAscSortOrder.ROUND_TIME_MINUTES,
              true
          ),
          INSTRUMENT_REPORTED_TIMEFORMAT
        )}`
  }
}

class TimeDescSortOrder extends SortOrder {
  private static ROUND_TIME_MINUTES = 60

  constructor() {
      super('Newest first', 'time:desc')
  }

  getLabel(cell: Cell): string | undefined {
      return `${formatTimestampToHumanReadable(
          roundToNearestIntervalMinutes(
              cell.getCellId()?.getTime(),
              TimeDescSortOrder.ROUND_TIME_MINUTES,
              false
          ),
          INSTRUMENT_REPORTED_TIMEFORMAT
        )}`
  }
}
class ClassSortOrder extends SortOrder {
  constructor() {
    super('Class & Probability', 'predicted_class:asc,predicted_probability:desc')
  }

  getLabel(cell: Cell): string | undefined {
    const predictions = cell.getPredictionsList()
    if (predictions && predictions.length > 0) {
      return `${CellClassEncoderDecoder.convertToString(
        getPredictedClass(predictions[0])
      )} ${ProbabilitySortOrder.getProbabilityLabel(cell)}`
    }
    return 'NO PREDICTION'
  }
}

class RunIdSortOrder extends SortOrder {
  constructor() {
    super('Run Id & Time', 'run_id:asc,time:asc')
  }

  getLabel(cell: Cell): string | undefined {
    return cell.getRunId()
  }
}

class SampleDistributionOrder extends SortOrder {
  constructor() {
    super('Sample Distribution', 'sample_distribution')
  }

  getLabel(cell: Cell): string | undefined {
    return cell.getRunId()
  }
}

export const SortOrders = {
  PROBABILITY_DESC: new ProbabilitySortOrder(),
  TIME_DESC: new TimeDescSortOrder(),
  TIME_ASC: new TimeAscSortOrder(),
  CLASS_ASC: new ClassSortOrder(),
  RUN_ID_ASC: new RunIdSortOrder(),
  SAMPLE_DISTRIBUTION: new SampleDistributionOrder(),
}

export type SortOrderKey = keyof typeof SortOrders

export function getDisplayChipLabel(
  cell: Cell,
  previousCell: Cell | undefined,
  sortOrderKey: SortOrderKey
): string | undefined {
  const sortOrder = SortOrders[sortOrderKey]
  const label = sortOrder.getLabel(cell)
  const lastLabel = previousCell && sortOrder.getLabel(previousCell)
  if (label !== lastLabel) return label
  return undefined
}
