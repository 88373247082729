import { CellClass, SampleType } from '@deepcell/dc_core_proto/deepcell_schema2_pb'
import { Box, Link, Stack, styled } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import {
  DeepcellAutocomplete,
  DeepcellMultiInput,
  DeepcellPrimaryButton,
  DeepcellTextField,
} from 'components/shared'
import DeepcellDateTimePicker from 'components/shared/DeepcellDateTimePicker'
import DeepcellPrimarySelect from 'components/shared/DeepcellPrimarySelect'
import AccordianDownIcon from 'components/shared/icons/AccordianDownIcon'
import AccordianUpIcon from 'components/shared/icons/AccordianUpIcon'
import React, { useReducer, useState, useEffect } from 'react'
import { useQueryClient } from 'react-query'
import { FindMorphologyTypesResponse, getMorphologyClassifiers } from 'utils/api'
import { CellClassEncoderDecoder } from 'utils/proto-utils'
import useAuthTokens from 'utils/useAuthTokens'
import useCellBrowsingSlice from 'redux/slices/hooks/useCellBrowsingSlice'
import { ChangeSearchFilterAction, SearchFilters, ViewModeType } from './types'

interface Props {
  className?: string
  defaultSearchFilters: SearchFilters
  handleSearch: (searchFilters: SearchFilters) => void
  handleReset: () => void
  disabledSubmit: boolean
  onError: (event: React.SyntheticEvent<HTMLFormElement, Event>) => void
  setViewMode: React.Dispatch<React.SetStateAction<ViewModeType>>
}

const emptySearchState = {
  after: undefined,
  before: undefined,
  cellId: '',
  cellNumber: undefined,
  cellTime: undefined,
  centerCrop: false,
  mixedSampleId: '',
  page: 0,
  predictedClasses: [],
  predictedProbabilityGreaterThan: undefined,
  runId: undefined,
  runIds: [],
  sampleId: '',
  sampleType: -1,
  selectedCellId: undefined,
  sharpen: false,
  sortOrderKey: 'TIME_ASC',
}

/**
 * Handles state updates for the useReducer hook
 */
export function reducer(state: SearchFilters, action: ChangeSearchFilterAction): SearchFilters {
  const result: SearchFilters = { ...state }

  switch (action.type) {
    case 'predictedClasses':
      result.predictedClasses = action.values
      break

    // @TODO: Figure out how to type this properly
    // These are grouped by type so that Typescript infers the type of action.value properly
    // Ideally all of these cases could be one
    case 'predictedProbabilityGreaterThan':
    case 'mixedSampleId':
    case 'sampleId':
      result[action.type] = action.value
      break

    case 'runIds':
      result[action.type] = action.value
      break

    case 'sampleType':
      result[action.type] = action.value
      break

    case 'before':
    case 'after':
      result[action.type] = action.value
      break

    case 'cellId':
      result[action.type] = action.value
      break

    case 'morphologyClassifiers':
      result[action.type] = action.value
      break

    case 'morphologyClassifiersProbabilityGreaterThan':
      result[action.type] = action.value
      break

    case 'reset':
      return emptySearchState

    default:
      throw Error('Unhandled action')
  }
  return result
}

function CellSearchFilters(props: Props): JSX.Element {
  const { isInternalUser } = useAuthTokens()
  const {
    className,
    defaultSearchFilters,
    handleSearch,
    handleReset,
    onError,
    disabledSubmit,
    setViewMode,
  } = props

  const [searchFilters, dispatch] = useReducer(reducer, {
    ...defaultSearchFilters,
  })
  const {
    predictedClasses,
    predictedProbabilityGreaterThan,
    sampleType,
    sampleId,
    mixedSampleId,
    cellId,
    runIds,
    before,
    after,
    morphologyClassifiers,
    morphologyClassifiersProbabilityGreaterThan,
  } = searchFilters

  const { resetCellBrowsing } = useCellBrowsingSlice()

  // Morphotypes list for displaying selection to user
  const [morphotypes, setMorphotypes] = useState<string[]>([])
  const [newRunSearch, setNewRunSearch] = useState<boolean>(true)
  const queryClient = useQueryClient()

  useEffect(() => {
    if (newRunSearch && runIds.length > 0) {
      const params = { run_ids: searchFilters.runIds }
        ; (async () => {
          try {
            const findMorphologyTypesResponse: FindMorphologyTypesResponse =
              await queryClient.fetchQuery(['RunIdParams', params], getMorphologyClassifiers)
            setMorphotypes(findMorphologyTypesResponse.morphoTypes)
          } catch (err) {
            console.error(
              `Unable to fetch the morphology classifiers for runIds: ${searchFilters.runIds} due to error ${err}`
            )
          }
          setNewRunSearch(false)
        })()
    }
  })

  function submitSearch(newSearchFilters?: Partial<SearchFilters>) {
    handleSearch({
      ...searchFilters,
      ...newSearchFilters,
      sampleType:
        searchFilters.sampleType && searchFilters.sampleType < 0
          ? undefined
          : searchFilters.sampleType,
    })
    setViewMode({
      grid: true,
      table: false,
    })
    resetCellBrowsing()
  }

  const updateAndSearch = (newRunIds: string) => {
    const runIdsArray = newRunIds.split(/\s+/)
    setNewRunSearch(true)
    dispatch({
      type: 'runIds',
      value: runIdsArray,
    })
    submitSearch({ runIds: runIdsArray })
  }

  const StyledAdvancedSearchLink = styled(Link)({
    color: '#5F55D1',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
  })

  const [isAdvancedSearchOpen, setIsAdvancedSearchOpen] = useState<boolean>(false)

  const handleResetSubmit = () => {
    handleReset()
    dispatch({
      type: 'reset',
    })
  }

  return (
    <form
      className={className}
      onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        submitSearch()
      }}
      onErrorCapture={onError}
    >
      <Stack spacing={2}>
        <DeepcellMultiInput
          label="Run Ids"
          data-testid="run-ids"
          value={runIds.length && runIds[0] ? runIds : []}
          updateValueState={updateAndSearch}
        />
        {isInternalUser ? (
          <DeepcellAutocomplete
            multiple
            label="Predicted Classes During Run"
            id="predicted-classes"
            options={Object.values(CellClass)}
            getOptionLabel={(option) =>
              option === -1 ? '' : CellClassEncoderDecoder.convertToString(option)
            }
            value={predictedClasses?.filter((val) => val !== -1) ?? []}
            filterSelectedOptions
            onChange={(_event, values) =>
              dispatch({
                type: 'predictedClasses',
                values,
              })
            }
            renderInput={(params) => (
              <DeepcellTextField
                data-testid="predicted-classes"
                {...params}
                label="Predicted Morphotypes During Run"
              />
            )}
          />
        ) : (
          <></>
        )}
        <>
          <FormControl sx={{ width: '100%' }}>
            <Stack spacing={2}>
              <DeepcellAutocomplete
                multiple
                label="Morphotypes"
                id="morphological-classifiers"
                options={Object.values(morphotypes)}
                getOptionLabel={(option) => option}
                filterSelectedOptions
                defaultValue={morphologyClassifiers}
                freeSolo
                onChange={(_event, value) =>{
                  dispatch({
                    type: 'morphologyClassifiers',
                    value,
                  })
                  submitSearch({ morphologyClassifiers: value })
                }
                }
                renderInput={(params) => (
                  <DeepcellTextField
                    data-testid="morphological-classifiers"
                    {...params}
                    label="Morphotypes"
                  />
                )}
              />
              {morphologyClassifiers?.length !== 0 && (
                <DeepcellTextField
                  name="morphological-classifiers-probability-greater-than"
                  label="Morphotypes Probability is Above"
                  value={morphologyClassifiersProbabilityGreaterThan}
                  onChange={(event) =>
                    dispatch({
                      type: 'morphologyClassifiersProbabilityGreaterThan',
                      value: event.target.value,
                    })
                  }
                  type="number"
                  inputProps={{
                    step: 0.001,
                    min: 0,
                    max: 1,
                  }}
                />
              )}
            </Stack>
          </FormControl>
        </>
        <Box>
          <Stack
            sx={{ cursor: 'pointer' }}
            onClick={() => setIsAdvancedSearchOpen((prev) => !prev)}
            alignItems="center"
            direction="row"
            gap="6px"
          >
            <StyledAdvancedSearchLink data-testid="advanced-search-toggle" underline="always">
              Advanced Search
            </StyledAdvancedSearchLink>
            {isAdvancedSearchOpen ? (
              <AccordianUpIcon inheritViewBox sx={{ height: '14px', width: '14px' }} />
            ) : (
              <AccordianDownIcon inheritViewBox sx={{ height: '14px', width: '14px' }} />
            )}
          </Stack>
          {isAdvancedSearchOpen ? (
            <>
              <DeepcellTextField
                label="Cell Id"
                value={cellId}
                onChange={(event) =>
                  dispatch({
                    type: 'cellId',
                    value: event.target.value,
                  })
                }
              />
              <DeepcellTextField
                label="Sample Id"
                data-testid="sample-id"
                value={sampleId}
                onChange={(event) =>
                  dispatch({
                    type: 'sampleId',
                    value: event.target.value,
                  })
                }
              />
              <DeepcellTextField
                label="Mixed Sample Id"
                data-testid="mixed-sample-id"
                value={mixedSampleId}
                onChange={(event) =>
                  dispatch({
                    type: 'mixedSampleId',
                    value: event.target.value,
                  })
                }
              />
              {isInternalUser && (
                <>
                  <DeepcellPrimarySelect
                    label="Sample Type"
                    data-testid="sample-type"
                    items={[
                      { value: -1, output: <em>Any</em> },
                      ...Object.entries(SampleType).map((e) => ({
                        key: e[0],
                        value: e[1],
                        output: e[0].substring(12),
                      })),
                    ]}
                    value={sampleType}
                    onChange={(event) =>
                      dispatch({
                        type: 'sampleType',
                        value: event.target.value as number,
                      })
                    }
                  />
                  <FormControl size="small">
                    <Stack spacing={2}>
                      {predictedClasses && predictedClasses.length !== 0 && (
                        <DeepcellTextField
                          name="predicted-probability-greater-than"
                          label="Predicted Probability is Above"
                          placeholder="Enter a probability (e.g. 0.8)"
                          value={predictedProbabilityGreaterThan}
                          onChange={(event) =>
                            dispatch({
                              type: 'predictedProbabilityGreaterThan',
                              value: event.target.value,
                            })
                          }
                          type="number"
                          inputProps={{
                            step: 0.001,
                            min: 0,
                            max: 1,
                          }}
                        />
                      )}
                    </Stack>
                  </FormControl>
                </>
              )}
              <Stack sx={{ mt: '16px' }} spacing={2}>
                <Box>
                  <DeepcellDateTimePicker
                    label="Captured After"
                    value={after as Date}
                    onChange={(date) => dispatch({ type: 'after', value: date })}
                  />
                </Box>
                <Box>
                  <DeepcellDateTimePicker
                    label="Captured Before"
                    value={before as Date}
                    onChange={(date) => dispatch({ type: 'before', value: date })}
                  />
                </Box>
              </Stack>
            </>
          ) : (
            <></>
          )}
        </Box>
        <DeepcellPrimaryButton
          disabled={disabledSubmit}
          data-testid="search-btn"
          contained
          type="submit"
        >
          Browse
        </DeepcellPrimaryButton>
        <DeepcellPrimaryButton
          data-testid="reset-btn"
          outlined
          type="button"
          onClick={handleResetSubmit}
        >
          Reset
        </DeepcellPrimaryButton>
      </Stack>
    </form>
  )
}

export default CellSearchFilters
