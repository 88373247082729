import * as React from 'react'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Dialog from '@mui/material/Dialog'
import { styled } from '@mui/material'
import { DeepcellPrimaryButton } from 'components/shared'

interface CellImageDownloadDialogProps extends React.PropsWithChildren {
  id: string
  keepMounted: boolean
  open: boolean
  onClose: (value?: string) => void
  title: string
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    border: 'none',
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.25)',
    borderRadius: '4px',
    userSelect: 'none',
    padding: `${theme.spacing(3)} ${theme.spacing(2)}`,
  },
  '& .MuiDialogContent-root': {
    padding: '30px 0px',
  },
  '& .MuiDialogTitle-root': {
    padding: 0,
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '25px',
    textAlign: 'center',
    color: theme.palette.text.primary,
  },
  '& .MuiDialogActions-root': {
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

const CellImageDownloadDialog = (props: CellImageDownloadDialogProps): JSX.Element => {
  const { children, title, onClose, open, ...other } = props
  const radioGroupRef = React.useRef<HTMLElement>(null)

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus()
    }
  }

  const handleCancel = () => {
    onClose()
  }

  return (
    <StyledDialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      maxWidth="xs"
      TransitionProps={{ onEntering: handleEntering }}
      open={open}
      {...other}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <DeepcellPrimaryButton
          data-testid="cancel-download-btn"
          outlined
          type="button"
          onClick={handleCancel}
        >
          Cancel
        </DeepcellPrimaryButton>
      </DialogActions>
    </StyledDialog>
  )
}

export default CellImageDownloadDialog
