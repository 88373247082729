import Box from '@mui/material/Box'
import Slider from '@mui/material/Slider'
import { SearchDispatchAction, SearchOptions } from '../cell-browsing/types'

const marks = [
  {
    value: 25,
    label: '25%',
  },
  {
    value: 50,
    label: '50%',
  },
  {
    value: 75,
    label: '75%',
  },
  {
    value: 100,
    label: '100%',
  },
]

function valuetext(value: number) {
  return `${value}`
}

interface QuadrantSlideProps {
  searchOptions: SearchOptions
  handleQueryUpdate: (action: SearchDispatchAction) => void
}

const QuadrantSlider = ({ handleQueryUpdate, searchOptions }: QuadrantSlideProps): JSX.Element => {
  const handleChange = (event: Event) => {
    const { value } = event.target as HTMLInputElement
    const quadrant = Number(value) / 25
    handleQueryUpdate({ type: 'quadrant', value: quadrant })
  }

  const getSliderValue = () => {
    return searchOptions.quadrant * 25 || 25
  }

  return (
    <Box data-testid="quadrantSlider" sx={{ width: 200 }}>
      <Slider
        aria-label="Restricted values"
        defaultValue={getSliderValue()}
        getAriaValueText={valuetext}
        step={null}
        onChange={(event) => handleChange(event)}
        valueLabelDisplay="auto"
        marks={marks}
      />
    </Box>
  )
}

export default QuadrantSlider
