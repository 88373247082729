import { CellClass } from '@deepcell/dc_core_proto/deepcell_schema2_pb'
import { ColDef } from 'ag-grid-community'
import { DeepcellAutocomplete, DeepcellTextField } from 'components/shared'
import DeepcellPopover from 'components/shared/DeepcellPopover'
import OkCancelDialogBody from 'components/shared/OkCancelDialogBody'
import _ from 'lodash'
import React, { ReactElement, useState } from 'react'
import useNotificationSlice from 'redux/slices/hooks/useNotificationSlice'
import { NoContentReturned, Run, updateRun, UpdateRunRequest } from 'utils/api'
import { CellClassEncoderDecoder } from 'utils/proto-utils'
import useAuthTokens from 'utils/useAuthTokens'
import {
  BasicDataType,
  Row,
  RowDataType,
  getCellClassStrings,
  outputColumnDefs,
  outputMultipleRowData,
  outputSingleRowData,
} from './BasicInfoTableConfig'
import AgGridRunDetailsTable from '../shared/AgGridRunDetailsTable'

interface BasicInfoTableProps {
  runs: Run[]
  onEdit: (runs: Run[]) => void
}

const gridOptions = {
  defaultCsvExportParams: {
    columnKeys: ['label', 'values'],
  },
}

export default function BasicInfoTable(props: BasicInfoTableProps): ReactElement {
  const { isInternalUser } = useAuthTokens()

  const { displayNotification } = useNotificationSlice()
  const { runs, onEdit } = props
  const [value, setValue] = useState<BasicDataType>('')
  const [selectedField, selectField] = useState<Row | null>(null)
  const [popoverAnchor, setPopoverAnchor] = useState<Element | null>(null)

  const single = runs.length === 1
  let rowData: RowDataType[] = []
  const selectedRun = runs.length > 0 ? runs[0] : undefined

  const select = (r: Row) => (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setValue(single ? r.data : '')
    selectField(r)
    setPopoverAnchor(e.currentTarget)
  }

  const columnDefs: ColDef[] = outputColumnDefs(select)

  function unselect() {
    selectField(null)
  }

  if (single) {
    const run = runs[0]
    rowData = outputSingleRowData(run)

    if (run?.versions?.controller)
      rowData.push({
        label: 'Controller Version',
        values: run?.versions?.controller || '',
      })
    if (run?.versions?.morphometrics)
      rowData.push({
        label: 'HFM Version',
        values: run?.versions?.morphometrics || '',
      })

    // Display bead model, camera, and other internal library version information that were used at the time of the run
    // This feature is only available to internal deepcell users
    if (isInternalUser) {
      if (run?.versions?.cell_image_library)
        rowData.push({
          label: 'Cell Image Library',
          values: run?.versions?.cell_image_library || '',
        })
      if (run?.versions?.bead_focus_model_family)
        rowData.push({
          label: 'Bead Focus Model',
          values: run?.versions?.bead_focus_model_family || '',
        })
      if (run?.camera_model)
        rowData.push({
          label: 'Camera Model',
          values: run?.camera_model || '',
        })
      if (run?.versions?.sorting_fpga)
        rowData.push({
          label: 'Sorting FPGA Version',
          values: run?.versions?.sorting_fpga || '',
        })
      if (run?.versions?.camera_fw)
        rowData.push({
          label: 'Camera FW',
          values: run?.versions?.camera_fw || '',
        })
    }
  } else {
    rowData = outputMultipleRowData(runs)
    const controllerVersions = _.join(
      _.uniq(
        runs
          .map((run) => run.versions?.controller)
          .filter((controller) => controller && controller.trim() !== '') // Filter out empty or whitespace-only values
      ),
      ', '
    )
    if (controllerVersions)
      rowData.push({
        label: 'Controller Version',
        values: controllerVersions,
      })

    const morphometricsVersions = _.join(
      _.uniq(
        runs
          .map((run) => run.versions?.morphometrics)
          .filter((morphometrics) => morphometrics && morphometrics.trim() !== '') // Filter out empty or whitespace-only values
      ),
      ', '
    )
    if (morphometricsVersions)
      rowData.push({
        label: 'HFM Version',
        values: morphometricsVersions,
      })

    // Display bead model, camera, and other internal library version information that were used at the time of the run
    // This feature is only available to internal deepcell users
    if (isInternalUser) {
      const cellImageLibraryVersions = _.join(
        _.uniq(
          runs
            .map((run) => run.versions?.cell_image_library)
            .filter((cell_image_library) => cell_image_library && cell_image_library.trim() !== '') // Filter out empty or whitespace-only values
        ),
        ', '
      )
      if (cellImageLibraryVersions)
        rowData.push({
          label: 'Cell Image Library',
          values: cellImageLibraryVersions,
        })

      const beadFocusModels = _.join(
        _.uniq(
          runs
            .map((run) => run.versions?.bead_focus_model_family)
            .filter(
              (bead_focus_model_family) =>
                bead_focus_model_family && bead_focus_model_family.trim() !== ''
            ) // Filter out empty or whitespace-only values
        ),
        ', '
      )
      if (beadFocusModels)
        rowData.push({
          label: 'Bead Focus Model',
          values: beadFocusModels,
        })

      const cameraModels = _.join(
        _.uniq(
          runs
            .map((run) => run.camera_model)
            .filter((camera_model) => camera_model && camera_model.trim() !== '') // Filter out empty or whitespace-only values
        ),
        ', '
      )
      if (cameraModels)
        rowData.push({
          label: 'Camera Model',
          values: cameraModels,
        })

      const sortingFPGAs = _.join(
        _.uniq(
          runs
            .map((run) => run.versions?.sorting_fpga)
            .filter((sorting_fpga) => sorting_fpga && sorting_fpga.trim() !== '') // Filter out empty or whitespace-only values
        ),
        ', '
      )
      if (sortingFPGAs)
        rowData.push({
          label: 'Sorting FPGA Version',
          values: sortingFPGAs,
        })

      const cameraFWs = _.join(
        _.uniq(
          runs
            .map((run) => run.versions?.camera_fw)
            .filter((camera_fw) => camera_fw && camera_fw.trim() !== '') // Filter out empty or whitespace-only values
        ),
        ', '
      )
      if (cameraFWs)
        rowData.push({
          label: 'Camera FW',
          values: cameraFWs,
        })
    }
  }

  async function tryUpdateRun(run?: Run) {
    if (!run || !selectedRun || !selectedField) {
      unselect()
      return
    }

    const { field } = selectedField
    if (field) {
      const safeTargetClasses =
        value instanceof Array
          ? value?.map((v) => CellClassEncoderDecoder.convertFromString(v.toString()))
          : []
      const safeValue: BasicDataType = field === 'target_cell_classes' ? safeTargetClasses : value
      try {
        const payload: UpdateRunRequest = { run_id: run.run_id, [field]: safeValue }
        const updatedRun = await updateRun(payload)
        onEdit([updatedRun])
      } catch (err) {
        if (err instanceof NoContentReturned) {
          const editedRuns = single
            ? [{ ...run, [field]: safeValue }]
            : runs.map((_run) => ({ ..._run, [field]: safeValue }))
          onEdit(editedRuns)
        } else {
          const errMessage =
            (err as Error).message || `An unexpected error occurred when trying to Update ${field}`
          displayNotification({ message: errMessage, type: 'error' })
        }
      }
    }
    unselect()
  }

  async function tryUpdateRuns(run?: Run) {
    if (single) {
      await tryUpdateRun(run)
    } else {
      await Promise.all(runs.map((r) => tryUpdateRun(r)))
    }
  }

  const autoValue = value instanceof Array ? value.map((n) => n.toString()) : []
  const input =
    selectedField?.field === 'target_cell_classes' ? (
      <DeepcellAutocomplete
        label="Target Morphotypes"
        multiple
        id="target-cell-classes"
        options={getCellClassStrings([...Object.values(CellClass)])}
        value={autoValue}
        onChange={(_e, values) => setValue(values)}
        getOptionLabel={(option) => option}
        style={{ width: 300 }}
      />
    ) : (
      <DeepcellTextField
        value={value}
        label={selectedField?.name}
        onChange={(e) => setValue(e.target.value)}
      />
    )

  async function dialogAction(canceled: boolean) {
    if (!canceled) await tryUpdateRuns(selectedRun)
    setPopoverAnchor(null)
  }

  return (
    <>
      <AgGridRunDetailsTable
        testId="run-details-grid"
        customClass="context-menu-ag-grid"
        headerHeight={0}
        rowData={rowData}
        gridOptions={gridOptions}
        rowGap={8}
        columnDefs={columnDefs}
      />

      <DeepcellPopover
        className="MuiPopover-paper"
        anchorEl={popoverAnchor}
        open={Boolean(popoverAnchor)}
        onClose={() => setPopoverAnchor(null)}
      >
        <OkCancelDialogBody
          titleLabel={`Editing ${selectedField?.name}`}
          pending={false}
          handleButtonClick={(canceled) => dialogAction(canceled)}
        >
          {input}
        </OkCancelDialogBody>
      </DeepcellPopover>
    </>
  )
}
