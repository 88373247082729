import { Checkbox, Chip } from '@mui/material'
import { DeepcellAutocomplete } from 'components/shared'
import DeepcellLabel from 'components/shared/DeepcellLabel'

interface FieldsToIncludeProps {
  options?: string[]
  values?: string[]
  onChange(vals: string[]): void
}

export const FieldsToInclude = ({
  options,
  values,
  onChange,
}: FieldsToIncludeProps): JSX.Element => {
  return (
    <>
      <DeepcellLabel label="Choose fields to include" />
      <DeepcellAutocomplete
        label=""
        multiple
        disableCloseOnSelect
        options={options}
        renderOption={(p, option, { selected }) => {
          const isRunId = option === 'Run Id'
          return (
            <li {...p}>
              <Checkbox
                style={{ marginRight: 8 }}
                checked={isRunId || selected}
                disabled={isRunId}
              />
              {option}
            </li>
          )
        }}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip label={option} {...getTagProps({ index })} disabled={option === 'Run Id'} />
          ))
        }
        onChange={(__, val) => onChange(val)}
        value={['Run Id', ...(values?.filter((v) => v !== 'Run Id') ?? [])]}
        sx={{ mt: '8px !important' }}
      />
    </>
  )
}

export default FieldsToInclude
