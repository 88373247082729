import { SelectChangeEvent } from '@mui/material'
import { ChangeEvent } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useCellVisualizationsSlice } from 'redux/slices/hooks/useCellVisualizationsSlice'
import useEventsManager from 'redux/slices/hooks/useEventsManager'

const useDisplayImagesControlLogic = () => {
  const eventsManager = useEventsManager()

  const {
    setCellImagesFilter,
    cellVisualizations: { cellImagesFilter },
  } = useCellVisualizationsSlice()
  const { displayImages, imageSize, spacingAdjust } = cellImagesFilter

  useHotkeys(
    'shift+I, i',
    () => {
      setCellImagesFilter({
        cellImagesFilter: { ...cellImagesFilter, displayImages: !displayImages },
        eventsManager,
      })
    },
    {},
    [setCellImagesFilter, cellImagesFilter, displayImages]
  )

  const onDisplayImagesChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCellImagesFilter({
      cellImagesFilter: { ...cellImagesFilter, displayImages: e.target.checked },
      eventsManager,
    })
  }

  const onImageSizeChange = (e: SelectChangeEvent<unknown>) => {
    const val = e.target.value as number
    setCellImagesFilter({
      cellImagesFilter: { ...cellImagesFilter, imageSize: val },
      eventsManager,
    })
  }
  const onSpacingAdjustChange = (e: SelectChangeEvent<unknown>) => {
    const val = e.target.value as number
    setCellImagesFilter({
      cellImagesFilter: { ...cellImagesFilter, spacingAdjust: val },
      eventsManager,
    })
  }

  return {
    displayImages,
    onDisplayImagesChange,
    imageSize,
    spacingAdjust,
    onImageSizeChange,
    onSpacingAdjustChange,
  }
}

export const useDisplayImagesControl = (): ReturnType<typeof useDisplayImagesControlLogic> => {
  return useDisplayImagesControlLogic()
}

export default useDisplayImagesControl
