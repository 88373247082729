export const HOME = '/'
export const CELL_BROWSING = '/cell-browsing'
export const CELL_BROWSINGV3 = '/cell-browsingV3'
export const CELL_VISUALIZATIONS = '/cell-visualizations'
export const CELL_VISUALIZATIONS_SESSION = `${CELL_VISUALIZATIONS}/:sessionId`
export const CELL_VISUALIZATIONS_SESSION_VERSION = `${CELL_VISUALIZATIONS_SESSION}/versions/:versionId`
export const CELL_VISUALIZATIONS_SESSION_VERSION_PREFILTER = `${CELL_VISUALIZATIONS_SESSION_VERSION}/prefilter`
export const RUNS = '/runs'
export const SAMPLES = '/samples'
export const LOGIN = '/login'

export const ALL_CELL_VISUALIZATION_ROUTES = [
  CELL_VISUALIZATIONS,
  CELL_VISUALIZATIONS_SESSION,
  CELL_VISUALIZATIONS_SESSION_VERSION,
  CELL_VISUALIZATIONS_SESSION_VERSION_PREFILTER,
]
export const HEALTH_STATUS = '/health'
export const DEMO_REGISTER = '/register'
export const DEMO_DATASETS = '/datasets'
