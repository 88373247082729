import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { Button, Menu, MenuItem } from '@mui/material'
import { useState } from 'react'

type SmallSelectProps = {
  items: (string | number)[]
  onChange(val: string | number): void
  value: string | number
}
export const SmallSelect = ({ items, onChange, value }: SmallSelectProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = !!anchorEl

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSelect = (val: string | number) => () => {
    onChange(val)
    handleClose()
  }

  return (
    <>
      <Button
        disableElevation
        sx={{
          mr: 1,
          color: 'black',
          backgroundColor: 'rgba(238, 239, 255, 1)',
          width: '53px',
          height: '22px',
          minWidth: '53px',
        }}
        variant="contained"
        endIcon={open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        onClick={handleClick}
      >
        {value}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        slotProps={{ paper: { style: { width: '53px' } } }}
      >
        {items.map((item) => (
          <MenuItem key={item} onClick={handleSelect(item)}>
            {item}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default SmallSelect
