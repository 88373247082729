import { keys } from 'lodash'
import { useMemo } from 'react'
import { CellDataField, GROUP_RUN_INFORMATION, getDataFields } from 'redux/slices'
import useAuthTokens from 'utils/useAuthTokens'
import { usePlotData } from './plot/usePlotData'
import { CellInfo } from './tsv/types'
import { getCustomFields } from './utils'

/**
 * Filters list of available data fields present in the dataset.
 * And also filters fields that for internal vs. external users
 *
 * Try to use this hook for any purpose where you need a
 *
 * @TODO Currently this is based on a hard-coded schema from getDataFields()
 * Change this to depend on a schema defined in the session later
 *
 * @returns Data fields in the dataset
 * {
 *      dataFields: DataField[] // all data fields
 *      quantitativeDataFields: DataField[] // all data fields that are quantitative (i.e. continuous real variables)
 *      categoricalDataFields: DataField[] // all data fields that are categorical (i.e. a list of items)
 * }
 */
const useDataFieldsInDataset = (): {
  dataFields: CellDataField[]
  quantitativeDataFields: CellDataField[]
  categoricalDataFields: CellDataField[]
} => {
  const { isInternalUser } = useAuthTokens()
  const { cellInfoUnfiltered: cellsData } = usePlotData()
  const dataFields: CellDataField[] = useMemo(() => {
    const allDataFields = getDataFields()

    if (!cellsData || !cellsData.length) return []

    const customFields = getCustomFields(keys(cellsData[0]))

    const allCellDataFields = allDataFields.flatMap((option: CellDataField) => {
      const output: CellDataField[] = []
      // Check if the feature is internal only and the user is not internal
      if (option.isInternalOnly && !isInternalUser) {
        return output
      }

      // If cellsData is defined, only keep features that are in the data
      const firstCell = cellsData[0]
      const attribute = option.attribute as keyof CellInfo

      if (attribute in firstCell) {
        const value = firstCell[attribute]
        if (value !== undefined && value !== '') {
          output.push(option)
        }
      }
      return output
    })

    const lastRunInfoIndex = allCellDataFields.reduce(
      (acc, cdf, i) => (cdf.category === GROUP_RUN_INFORMATION ? i : acc),
      -1
    )
    if (lastRunInfoIndex > -1) {
      allCellDataFields.splice(lastRunInfoIndex + 1, 0, ...customFields)
    } else {
      // if Run Information isn't in there, just put it at the beginning
      allCellDataFields.splice(0, 0, ...customFields)
    }

    return allCellDataFields
  }, [cellsData, isInternalUser])

  return {
    dataFields,
    quantitativeDataFields: dataFields.filter((option) => option.isContinuous),
    categoricalDataFields: dataFields.filter((option) => !option.isContinuous),
  }
}

export default useDataFieldsInDataset
