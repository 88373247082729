import { isEqual } from 'lodash'
import { useEffect, useState } from 'react'
import { CellVisualizationsState } from 'redux/slices'
import { useCellVisualizationsSlice } from 'redux/slices/hooks/useCellVisualizationsSlice'
import usePreFilter from 'components/cell-visualizations/prefilter/usePreFilter'
import { nonContinuous } from './utils'

/**
 * Tracks the plot relevant redux variables and calls the passed in function when they change
 */
export const usePlotDataChangeDetector = (onChange: () => void): void => {
  const {
    cellVisualizations: {
      plotDisplayType,
      selectedCellInfoGroupName,
      cellInfoGroups,
      selectedComparisonDimensions,
    },
  } = useCellVisualizationsSlice()

  const { morphotypesToRemove } = usePreFilter()

  const [prevData, setPrevData] =
    useState<
      Pick<
        CellVisualizationsState,
        | 'plotDisplayType'
        | 'selectedCellInfoGroupName'
        | 'cellInfoGroups'
        | 'selectedComparisonDimensions'
        | 'morphotypesToRemove'
      >
    >()

  useEffect(() => {
    const newestData = {
      plotDisplayType,
      selectedCellInfoGroupName,
      cellInfoGroups: nonContinuous(cellInfoGroups),
      selectedComparisonDimensions,
      morphotypesToRemove,
    }

    const dataDidChange = !prevData || !isEqual(prevData, newestData)

    if (dataDidChange) {
      onChange()
      setPrevData(newestData)
    }
  }, [
    cellInfoGroups,
    morphotypesToRemove,
    onChange,
    plotDisplayType,
    prevData,
    selectedCellInfoGroupName,
    selectedComparisonDimensions,
  ])
}

export default usePlotDataChangeDetector
