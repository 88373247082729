import { useState } from 'react'
import { AgGridEvent, ColumnApi, ColumnState } from 'ag-grid-community'
import { JsonParam, SetQuery } from 'use-query-params'
import { QueryParamConfigMap } from 'serialize-query-params'
import { withIgnoreBlanks } from './custom-query-params'

export type ColumnStateQueryState = {
    columnState?: ColumnState[]
}

export const ColumnStateQueryParam = {
    columnState: withIgnoreBlanks(JsonParam),
}

export type StateChangeHandler = (event: AgGridEvent) => void

type ColumnStateManager = {
    initColumnState: (updatedColumnApi: ColumnApi, columnState: ColumnState[] | undefined) => void
    bindColumnStateHandlers: {
        onColumnPinned: StateChangeHandler
        onColumnResized: StateChangeHandler
        onColumnMoved: StateChangeHandler
        onSortChanged: StateChangeHandler
    }
}

/**
 * Handles initializing and saving Ag-Grid Table column state to the query params.
 *
 * To use this, call initColumnState from onGridReady
 *
 * And then attach all the needed event handlers to the grid by doing this:
 * <AgGridReact {...bindColumnStateHandlers}></AgGridReact>
 */
const useColumnStateManager = <QueryState>(
    search: QueryState,
    setQuery: SetQuery<QueryParamConfigMap>
): ColumnStateManager => {
    const [columnApi, setColumnApi] = useState<ColumnApi | null>(null)

    function initColumnState(updatedColumnApi: ColumnApi, columnState: ColumnState[] | undefined) {
        setColumnApi(updatedColumnApi)

        if (columnState) {
            updatedColumnApi.applyColumnState({
                state: columnState,
                applyOrder: true,
            })
        }
    }

    function onColumnStateChanged(_event: AgGridEvent) {
        const columnState = columnApi?.getColumnState()
        setQuery({ ...search, columnState })
    }

    const bindColumnStateHandlers = {
        onColumnPinned: onColumnStateChanged,
        onColumnResized: onColumnStateChanged,
        onColumnMoved: onColumnStateChanged,
        onSortChanged: onColumnStateChanged,
    }

    return { initColumnState, bindColumnStateHandlers }
}

export default useColumnStateManager
