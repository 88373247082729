import * as ENVIRONMENTS from 'constants/environments'
import * as DEMO from 'constants/demo'
import { getDemoToken } from 'utils/api'
import { USER_AUTHORIZATION_KEY } from './useAuthTokens'

async function getAccessTokenFromM2M(){
    const res = await getDemoToken()
    if (res.data.token){
        return res.data.token
    }
    throw new Error('Demo Token API response was OK, but demo token not found on response.')
}

function mockIdTokenClaims() {
    return {[USER_AUTHORIZATION_KEY] : DEMO.ID_TOKEN_PERMISSIONS}
}

const useDemoAuth = {
    isLoading: false,
    user: { org_id: DEMO.ORG_ID, email: DEMO.EMAIL},
    loginWithRedirect: false,
    error: false,
    isAuthenticated: true,
    logout: false,
    getAccessTokenSilently: getAccessTokenFromM2M,
    getIdTokenClaims: mockIdTokenClaims
}

export function getCellImagesAPIRoute(environment: string | undefined): string{ 
    if (environment === ENVIRONMENTS.EXTERNAL_DEMO){
        return DEMO.DEMO_CELL_IMAGE_API_ROUTE
    } 
    return DEMO.PROD_CELL_IMAGE_API_ROUTE
}

export function getBatchURLAPIRoute(environment: string | undefined): string{
    if (environment === ENVIRONMENTS.EXTERNAL_DEMO){
        return DEMO.DEMO_BATCH_URL_API_ROUTE
    }
    return DEMO.PROD_BATCH_URL_API_ROUTE
}

export const useAuthWrapper = function(fn: Function){  // eslint-disable-line
    if (process.env.REACT_APP_ENV === ENVIRONMENTS.EXTERNAL_DEMO){
        return useDemoAuth
    }
    return fn()
}