import { CellResponse } from 'utils/api'
import { useEffect } from 'react'
import useCellBrowsingSlice from 'redux/slices/hooks/useCellBrowsingSlice'
import useCellBrowsingQueryParams from './useCellBrowsingQueryParams'
import { useSearchMetricsCells } from './cellSearchHelpers'

interface SampleDistributionReturn {
  data: CellResponse[]
  loading: boolean
  error: string | undefined
}

/**
 * 
 * Automatically fetches sample distribution quadrant data from API,
 * stores in redux and returns
 */
const useSampleDistribution = (): SampleDistributionReturn => {
  const { search } = useCellBrowsingQueryParams()

  const {
    result: cellDataMetrics,
    error,
    isLoading,
  } = useSearchMetricsCells({ ...search, quadrant: search.quadrant })

  const {
    setRowData,
    cellBrowsing: { rowData },
  } = useCellBrowsingSlice()

  useEffect(() => {
    setRowData(cellDataMetrics?.cells)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cellDataMetrics?.cells])

  return { data: rowData, loading: isLoading, error }
}

export default useSampleDistribution
