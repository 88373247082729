import UPNG from 'upng-js'
import UTIF from 'utif2'

export const convertToTiff = (base64String: string): Blob => {
  const bufferArray = Uint8Array.from(atob(base64String), (c) => c.charCodeAt(0)).buffer
  const img = UPNG.decode(bufferArray)
  const rgba = new Uint8Array(UPNG.toRGBA8(img)[0])
  const tiff = UTIF.encodeImage(rgba, img.width, img.height)
  const tiffBlob = new Blob([tiff])
  return tiffBlob
}

export default convertToTiff
