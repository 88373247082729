import { CellId } from '@deepcell/dc_core_proto/deepcell_schema2_pb'
import _ from 'lodash'
import { QueryStatus } from 'react-query'
import { CellPlotDatum } from 'redux/slices'
import { useCellVisualizationsSlice } from 'redux/slices/hooks/useCellVisualizationsSlice'
import { CellImageFilters } from 'utils/api'
import useCellImage, { CellImageInfo } from 'utils/useCellImage'
import { CellInfo } from './types'

/**
 * Lookup cell data by CELL_ID from the cell.cellId
 * @param cell Cell to lookup
 * @returns CellInfo or {} if not found
 */
export const useMorphometricData = (cell?: CellPlotDatum): Partial<CellInfo> => {
  const { getCellInfoByCellId } = useCellVisualizationsSlice()
  return getCellInfoByCellId(cell?.cellId ?? '')
}

export const getCellImageInfoFromCellId = (
  cellId: string,
  width = 70,
  filters = {} as CellImageFilters
): CellImageInfo => {
  const [timestamp, instrumentid, number, frame] = cellId.split('_')

  return {
    cellId: {
      getNumber: () => number,
      getTime: () => timestamp,
      getInstrumentId: () => instrumentid,
    } as unknown as CellId,
    frame: +(frame ?? '') ?? 0,
    width,
    filters,
  } as CellImageInfo
}

export const useCellImageUrlFromCellId = (
  cellId: string
): { imageDataUrl?: string; status?: QueryStatus } => {
  const cellImageInfo = getCellImageInfoFromCellId(cellId)

  const { data: imageDataUrl, status } = useCellImage(cellImageInfo)

  return { imageDataUrl, status }
}

/**
 * Returns a new array with the intersection of two sets of plot data
 */
export const intersectData = (a?: CellPlotDatum[], b?: CellPlotDatum[]): CellPlotDatum[] =>
  _.intersectionBy(a ?? [], b ?? [], (data) => `${data.x}${data.y}`)

/**
 * Returns a new array with the data sorted by the value of the x coordinate
 */
export const sortData = (a: CellPlotDatum[]): CellPlotDatum[] => _.sortBy(a, (data) => data.x)

/**
 * Returns the width of the data in the x coordinate
 */
export const getDataWidth = (data: CellPlotDatum[]): number => {
  const min = _.minBy(data, (d) => d.x)?.x as number
  const max = _.maxBy(data, (d) => d.x)?.x as number
  return max - min
}
