import RouterPrompt from 'components/shared/RouterPrompt'
import useFlagCondition from 'components/shared/useFlagCondition'
import { useEffect } from 'react'
import { useCellVisualizationsSlice } from 'redux/slices/hooks/useCellVisualizationsSlice'

export const CellVisualizationDirtyPrompt = (): JSX.Element => {
  const {
    cellVisualizations: { isDirty },
  } = useCellVisualizationsSlice()

  const demoEnabled = useFlagCondition('demoEnabled')

  useEffect(() => {
    if (isDirty) {
      window.onbeforeunload = () => true
    } else {
      window.onbeforeunload = () => undefined
    }
  }, [isDirty])

  return (
    <RouterPrompt
      when={isDirty && !demoEnabled}
      dialogProps={{
        titleLabel: 'Warning',
        description:
          'You will lose all your edits if you exit the session without saving it. Do you still wish to exit?',
      }}
      ignorePaths="prefilter"
    />
  )
}

export default CellVisualizationDirtyPrompt
