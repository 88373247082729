import { ButtonProps } from '@mui/material'
import { DeepcellPrimaryButton, DeepcellPrimaryButtonProps } from 'components/shared'

type VariantOption = 'outlined' | 'contained' | 'small' | 'text'

export interface SelectableButtonProps extends ButtonProps {
  selected?: boolean
  selectedVariant?: VariantOption
  unselectedVariant?: VariantOption
}

export const SelectableButton = (props: SelectableButtonProps): JSX.Element => {
  const {
    onClick,
    children,
    id,
    startIcon,
    selected = false,
    selectedVariant = 'contained',
    unselectedVariant = 'outlined',
    ...rest
  } = props

  // TODO: Either think of a better way of doing this or it is time to refactor DeepcellPrimaryButton
  const buttonProps = {
    id,
    ...(selected ? { [selectedVariant]: true } : { [unselectedVariant]: true }),
    onClick,
    startIcon,
  } as unknown as DeepcellPrimaryButtonProps

  return (
    <DeepcellPrimaryButton
      {...rest}
      {...buttonProps}
      sx={{
        background: selected ? (t) => t.palette.success.main : undefined,
        ...rest.sx,
      }}
    >
      {children}
    </DeepcellPrimaryButton>
  )
}

export default SelectableButton
