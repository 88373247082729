import * as ENVIRONMENTS from 'constants/environments'
import { useAuth0 } from '@auth0/auth0-react'
import { styled, useTheme } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Button from '@mui/material/Button'
import Toolbar from '@mui/material/Toolbar'
import { useAuthWrapper } from 'utils/demoEnvironmentUtils'
import useAuthTokens from 'utils/useAuthTokens'
import NavigationTopBarTitle from './NavigationTopBarTitle'
import { TopNavMenu } from './TopNavMenu'

const NavAppBar = styled(AppBar)(({ theme }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  boxShadow: '0',
  zIndex: theme.zIndex.drawer + 1,
}))

const NavTopBarLoginButton = styled(Button)({
  color: 'white',
})

const ProfilePicture = styled('img')({
  width: '30px',
  height: '30px',
  borderRadius: '15px',
})

export const NavigationTopBar = (): JSX.Element => {
  const theme = useTheme()

  const { user, isAuthenticated, loginWithRedirect } = useAuthWrapper(useAuth0)
  const { logout } = useAuthTokens()

  let appTitle: string
  let backgroundColor: string

  if (process.env.REACT_APP_ENV === ENVIRONMENTS.PRODUCTION) {
    appTitle = 'Axon Data Suite'
    backgroundColor = theme.palette.text.primary
  } else if (process.env.REACT_APP_ENV === ENVIRONMENTS.STAGING) {
    appTitle = 'Axon Data Suite'
    backgroundColor = theme.palette.info.main
  } else {
    let suffix = 'Local'
    if (process.env.REACT_APP_ENV) {
      suffix = `${process.env.REACT_APP_ENV}`
    }
    appTitle = `Axon Data Suite - Development - ${suffix}`
    backgroundColor = theme.palette.info.main
  }

  function handleLogout() {
    logout({ returnTo: `${window.location.origin}` })
  }

  function handleLogin() {
    loginWithRedirect({
      appState: {
        returnTo: window.location.pathname,
      },
    })
  }

  return (
    <NavAppBar
      position="fixed"
      sx={{
        backgroundColor,
      }}
    >
      <Toolbar variant="dense">
        <TopNavMenu />
        <NavigationTopBarTitle appTitle={appTitle} />
        {isAuthenticated ? (
          <>
            <NavTopBarLoginButton onClick={handleLogout}>Log out</NavTopBarLoginButton>
            <ProfilePicture alt="user profile" src={user?.picture} />
          </>
        ) : (
          <NavTopBarLoginButton onClick={handleLogin}>Login</NavTopBarLoginButton>
        )}
      </Toolbar>
    </NavAppBar>
  )
}

export default NavigationTopBar
