import { Box, Checkbox, DialogContentText, Grid, Paper, Stack } from '@mui/material'
import { DeepcellDialog, DeepcellPrimaryButton } from 'components/shared'
import useFlags from 'components/shared/useFlags'
import { useState } from 'react'
import { usePlotlySlice } from 'redux/slices/hooks'
import useEventsManager from 'redux/slices/hooks/useEventsManager'
import { SessionMetaData } from 'utils/api'
import { DeleteIconButton } from '../../shared'
import useCellVisualizationUrlParams from '../../useCellVisualizationUrlParams'
import useSessionApi from '../../useSessionApi'
import { SessionStaticInfo } from '../SessionStaticInfo'
import { useLoadedSessions } from './useLoadedSessions'

interface Props {
  shouldLoadSessions?: boolean
}

export const CellVisualizationSessionSelection = ({
  shouldLoadSessions = true,
}: Props): JSX.Element => {
  const { allowSessionDelete } = useFlags()
  const eventsManager = useEventsManager()

  const { deleteSession } = useSessionApi()

  const { updateSession } = useCellVisualizationUrlParams()
  const { resetRange } = usePlotlySlice()

  const [sessionToDelete, setSessionToDelete] = useState<SessionMetaData>()

  const { sessions, setSessions } = useLoadedSessions(shouldLoadSessions)

  const handleOpenSession = async (sessionId: number) => {
    eventsManager.sendSessionSelectionEvent(sessionId)

    resetRange()
    updateSession(sessionId)
  }

  const handleDeleteClick = (id: number) => {
    const s = sessions?.find((x) => x.session_id === id)
    setSessionToDelete(s)
  }

  const handleDelete = async () => {
    if (!sessionToDelete) return
    const successfullyDeleted = await deleteSession({
      id: sessionToDelete.session_id,
      name: sessionToDelete.version_config.name ?? '',
    })

    if (successfullyDeleted) {
      setSessions((curr) => curr?.filter((x) => x.session_id !== sessionToDelete.session_id))
    }

    setSessionToDelete(undefined)
  }

  const imageSize = 140

  return (
    <>
      <Stack spacing={2}>
        {sessions &&
          sessions?.map((sessionMetaData) => {
            const {
              author_email: authorEmail,
              updated_at: updatedAt,
              session_id: sessionId,
              version_config: { name, projectCode, plotImgSrc },
            } = sessionMetaData
            return (
              <Stack direction="row" spacing={2} key={sessionId}>
                <Checkbox />
                <Paper
                  sx={{
                    justifyContent: 'space-between',
                    p: 2,
                    width: '100%',
                    display: 'flex',
                  }}
                >
                  <Grid
                    container
                    spacing={2}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item>
                      {plotImgSrc ? (
                        <img alt="" src={plotImgSrc} style={{ maxWidth: imageSize }} />
                      ) : (
                        <Box
                          sx={{
                            height: imageSize,
                            width: imageSize,
                            backgroundColor: (t) => t.palette.info.light,
                            borderRadius: 2,
                          }}
                        />
                      )}
                    </Grid>
                    <Grid container item spacing={1} maxWidth={650}>
                      <Grid item sm={12} md={6}>
                        <SessionStaticInfo label="Name" info={name ?? ''} />
                      </Grid>
                      <Grid item sm={12} md={6}>
                        <SessionStaticInfo label="Project Code" info={projectCode ?? ''} />
                      </Grid>
                      <Grid item sm={12} md={6}>
                        <SessionStaticInfo label="Start Time" info={updatedAt.toString()} />
                      </Grid>
                      <Grid item sm={12} md={6}>
                        <SessionStaticInfo label="User" info={authorEmail ?? ''} />
                      </Grid>
                    </Grid>
                    <Grid item sx={{ display: 'flex' }}>
                      <DeepcellPrimaryButton
                        contained
                        sx={{ width: 190, height: 50, mr: 4 }}
                        onClick={() => handleOpenSession(sessionId)}
                      >
                        Open Session
                      </DeepcellPrimaryButton>
                      {allowSessionDelete === 'yes' ? (
                        <DeleteIconButton
                          data-testid="delete-button"
                          onClick={() => handleDeleteClick(sessionId)}
                        />
                      ) : null}
                    </Grid>
                  </Grid>
                </Paper>
              </Stack>
            )
          })}
      </Stack>

      <DeepcellDialog
        open={!!sessionToDelete}
        handleConfirm={handleDelete}
        handleCancel={() => setSessionToDelete(undefined)}
        okLabel="Yes"
        cancelLabel="No"
        titleLabel="Confirm Delete"
      >
        <DialogContentText>{`Are you sure you want to delete ${
          sessionToDelete?.version_config.name ?? ''
        }?`}</DialogContentText>
      </DeepcellDialog>
    </>
  )
}
export default CellVisualizationSessionSelection
