import { DeepcellPrimaryButton } from 'components/shared'
import { useCellVisualizationsSlice } from 'redux/slices/hooks/useCellVisualizationsSlice'
import useEventsManager from 'redux/slices/hooks/useEventsManager'

export const ToggleVisibilityButton = (): JSX.Element => {
  const eventsManager = useEventsManager()

  const { setSelectedCellInfoGroupData, selectedCellInfoGroup } = useCellVisualizationsSlice()
  const { data } = selectedCellInfoGroup
  const hideText = data.some((llc) => llc.isHidden) ? 'Show' : 'Hide'

  const onLinkClick = () => {
    if (hideText === 'Hide') {
      eventsManager.sendFilterShowAllEvent()
    } else {
      eventsManager.sendFilterHideAllEvent()
    }

    if (!selectedCellInfoGroup) return

    setSelectedCellInfoGroupData(data.map((x) => ({ ...x, isHidden: hideText === 'Hide' })))
  }

  return (
    <DeepcellPrimaryButton text size="small" onClick={onLinkClick}>
      {hideText} All
    </DeepcellPrimaryButton>
  )
}

export default ToggleVisibilityButton
