import { createSvgIcon } from '@mui/material'

const DrawerCloseIcon = createSvgIcon(
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="0.650106"
      y="0.650106"
      width="26.6998"
      height="26.6998"
      rx="4.55074"
      fill="white"
      stroke="#A4EFC0"
      strokeWidth="1.30021"
    />
    <path
      d="M17.9861 10.9971C16.8491 11.8231 15.8307 12.7915 14.9573 13.8765C14.8476 14.0128 14.8476 14.2036 14.9573 14.3399C15.8307 15.4249 16.8491 16.3932 17.9861 17.2193"
      stroke="#303A47"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.6111 10.9971C12.4741 11.8231 11.4557 12.7915 10.5823 13.8765C10.4726 14.0128 10.4726 14.2036 10.5823 14.3399C11.4557 15.4249 12.4741 16.3932 13.6111 17.2193"
      stroke="#303A47"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  'DrawerClose'
)

export default DrawerCloseIcon
