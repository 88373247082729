import Plotly from 'plotly.js/dist/plotly-axon'
import createPlotlyComponent from 'react-plotly.js/factory';
import { CellPlotData } from 'redux/slices'
import { usePlotData } from '../plot/usePlotData'

// customizable method: use your own `Plotly` object
const Plot = createPlotlyComponent(Plotly);

interface StaticPlotLegendProps {
  height: number
}
export const StaticPlotLegend = ({ height }: StaticPlotLegendProps): JSX.Element => {
  const { plotData } = usePlotData()

  const data =
    plotData?.flatMap((x) =>
      !x.name
        ? ({ ...x, marker: { ...x.marker, showscale: true }, opacity: 0 } as CellPlotData)
        : []
    ) ?? []

  return (
    <Plot
      className="hide-graph-container"
      data={data}
      layout={{
        showlegend: false,
        width: 200,
        height,
        margin: { t: 0, b: 0, l: 0, r: 0, pad: 0 },
        xaxis: { visible: false, showgrid: false, showline: false },
        yaxis: { visible: false, showgrid: false, showline: false },
        clickmode: 'none',
      }}
      config={{
        staticPlot: true,
        displayModeBar: false,
        scrollZoom: false,
        autosizable: false,
        displaylogo: false,
        editable: false,
      }}
    />
  )
}

export default StaticPlotLegend
