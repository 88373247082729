import moment from 'moment'
import { useState } from 'react'
import useCellBrowsingSlice from 'redux/slices/hooks/useCellBrowsingSlice'
import { SampleTypeEncoderDecoder } from 'utils/proto-utils'
import { saveAs } from 'file-saver'
import { getPredictionValue } from './tableView/metadata'

const headers = [
  { label: 'Cell Id', key: 'cellId' },
  { label: 'Run', key: 'run' },
  { label: 'Time', key: 'time' },
  { label: 'Sample', key: 'sample' },
  { label: 'Mixed Sample', key: 'mixedSample' },
  { label: 'Sample Type', key: 'sampleType' },
  { label: 'Number of Images', key: 'noOfImages' },
  { label: 'Image Size', key: 'imageSize' },
  { label: 'Predictions', key: 'predictions' },
]

export type CSVData = {
  cellId: string
  run: string
  time: string
  sample: string
  mixedSample: string
  sampleType: string
  noOfImages: string | number
  imageSize: string
  predictions: string[] | string
}

interface CellIdCSVDownloadReturns {
  handleCSVExport: () => void
  handleTSVExport: () => void
  csvData: CSVData[]
  headers: typeof headers
}
/**
 * Converts the selected cell metadata JSON to corresponding TSV
 */
export const jsonToTsv = (jsonData: CSVData[], headerConfig: typeof headers): string => {
  const header = headerConfig.map((field) => field.label).join('\t')
  const rows = jsonData.map((obj) => {
    return headerConfig.map((field) => obj[field.key as keyof typeof obj]).join('\t')
  })

  const tsvContent = [header, ...rows].join('\n')

  return tsvContent
}
/**
 * Handles exporting of CSV and TSV file with selected cells metadata
 */
const useCellIdExport = (): CellIdCSVDownloadReturns => {
  const [csvData, setCsvData] = useState<CSVData[]>([])
  const {
    cellBrowsing: { selectedCells },
  } = useCellBrowsingSlice()

  const handleCSVExport = () => {
    const data = selectedCells.map((selectedCell) => {
      const { cell } = selectedCell
      const imageList = cell.getImagesList() || []
      const cellId = cell.getCellId()
      return {
        cellId: `${cellId?.getTime()} #${cellId?.getNumber()} ${cellId?.getInstrumentId()}`,
        run: cell.getRunId() || '-',
        time: moment.utc(cellId?.getTime()).local().format('YYYY-MM-DD h:mm:ss.SSS A') || '-',
        sample: cell.getSample()?.getSampleId() || '-',
        mixedSample: cell.getSample()?.getMixedSampleId() || '-',
        sampleType:
          SampleTypeEncoderDecoder.convertToString(cell?.getSample()?.getSampleType()) || '-',
        noOfImages: cell?.getNumImages() || '-',
        imageSize: `${imageList[0]?.getBlobWidth()}x${imageList[0]?.getBlobHeight()}` || '-',
        predictions: getPredictionValue(cell) || '-',
      }
    })

    setCsvData(data)
  }

  /**
   * Export TSV file containing metadata of selected cells
   */
  const handleTSVExport = () => {
    handleCSVExport()
    const tsvData = jsonToTsv(csvData, headers)
    const blob = new Blob([tsvData], { type: 'text/tsv' })
    saveAs(blob, '[TSV] CellBrowsing.tsv')
  }

  return { handleCSVExport, handleTSVExport, csvData, headers }
}

export default useCellIdExport
