import { createSvgIcon } from '@mui/material'

const SuccessIcon = createSvgIcon(
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 30 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.1333 28L7.6 23.7333L2.8 22.6667L3.26667 17.7333L0 14L3.26667 10.2667L2.8 5.33333L7.6 4.26667L10.1333 0L14.6667 1.93333L19.2 0L21.7333 4.26667L26.5333 5.33333L26.0667 10.2667L29.3333 14L26.0667 17.7333L26.5333 22.6667L21.7333 23.7333L19.2 28L14.6667 26.0667L10.1333 28ZM11.2667 24.6L14.6667 23.1333L18.1333 24.6L20 21.4L23.6667 20.5333L23.3333 16.8L25.8 14L23.3333 11.1333L23.6667 7.4L20 6.6L18.0667 3.4L14.6667 4.86667L11.2 3.4L9.33333 6.6L5.66667 7.4L6 11.1333L3.53333 14L6 16.8L5.66667 20.6L9.33333 21.4L11.2667 24.6ZM13.2667 18.7333L20.8 11.2L18.9333 9.26667L13.2667 14.9333L10.4 12.1333L8.53333 14L13.2667 18.7333Z"
      fill="#47B881"
    />
  </svg>,
  'Success'
)

export default SuccessIcon
