import { TablePagination, TablePaginationProps } from '@mui/material'
import { useState } from 'react'
import { PaginationParams } from 'redux/slices'

type UseTablePaginationParams = {
  defaultPage: number
  defaultItemsPerPage: number
  onPaginationChange(p: PaginationParams): void
}

type CompactTablePaginationProps = UseTablePaginationParams &
  Omit<TablePaginationProps, 'page' | 'onPageChange' | 'rowsPerPage'>

export const useTablePagination = (
  params: UseTablePaginationParams
): {
  page: number
  itemsPerPage: number
  changePage: (newPage: number) => void
  changeItemsPerPage: (newItemsPerPage: number | string) => void
} => {
  const { defaultPage, defaultItemsPerPage, onPaginationChange } = params
  const [page, setPage] = useState(defaultPage ?? 0)
  const [itemsPerPage, setItemsPerPage] = useState(defaultItemsPerPage)

  return {
    page,
    itemsPerPage,
    changePage: (newPage: number) => {
      setPage(newPage)
      onPaginationChange({ page: newPage, itemsPerPage })
    },
    changeItemsPerPage: (newItemsPerPage: number | string) => {
      const newItemsPerPageAsNumber = +newItemsPerPage
      setItemsPerPage(newItemsPerPageAsNumber)
      setPage(0)
      onPaginationChange({ page: 0, itemsPerPage: newItemsPerPageAsNumber })
    },
  }
}

export const CompactTablePagination = (props: CompactTablePaginationProps): JSX.Element => {
  const { defaultPage, defaultItemsPerPage, onPaginationChange, ...tablePaginationProps } = props

  const { page, itemsPerPage, changePage, changeItemsPerPage } = useTablePagination({
    defaultPage,
    defaultItemsPerPage,
    onPaginationChange,
  })

  return (
    <TablePagination
      {...tablePaginationProps}
      page={page}
      rowsPerPage={itemsPerPage}
      onPageChange={(_, p) => changePage(p)}
      onRowsPerPageChange={({ target: { value } }) => changeItemsPerPage(value)}
    />
  )
}

export default CompactTablePagination
