import * as ROUTES from 'constants/routes'
import { Box, Grid, Stack, Typography } from '@mui/material'
import { useRef, useState } from 'react'
import { useTrackingCode } from 'react-hubspot-tracking-code-hook'
import { useCellVisualizationsSlice } from 'redux/slices/hooks/useCellVisualizationsSlice'
import useEventManager from 'redux/slices/hooks/useEventsManager'
import { DatasetItem, datasetItems as allDatasets } from 'redux/types'
import useIsMobile from 'utils/useIsMobile'
import MoreContentArrow from 'components/shared/MoreContentArrow'
import useCellVisualizationUrlParams from '../useCellVisualizationUrlParams'
import { DataSetCard } from './DataSetCard'
import { MobilePrompt } from './MobilePrompt'

export const CellVisualizationDemoLandingPage = (): JSX.Element => {
  const demoLandingPageRef = useRef(null)
  const eventsManager = useEventManager()

  const { updateSession } = useCellVisualizationUrlParams()
  const { setSelectedDataset } = useCellVisualizationsSlice()
  const isMobile = useIsMobile()

  const [showMobilePrompt, setShowMobilePrompt] = useState(isMobile)

  const { setPathPageView, setTrackPageView } = useTrackingCode()
  setPathPageView(ROUTES.DEMO_DATASETS)
  setTrackPageView()

  const handleExplore = (id: DatasetItem['id']) => () => {
    const dsi = allDatasets.find((x) => x.id === id)
    if (dsi) {
      setSelectedDataset(dsi)
      // analytics
      eventsManager.sendDemoDatasetEvent(dsi.label)
      updateSession(id)
    }
  }

  return (
    <>
      <Stack sx={{ overflowY: 'auto', height: '100vh', margin: 0 }} ref={demoLandingPageRef}>
        <Box alignSelf="center" component="h1" sx={{ margin: 1 }}>
          Choose your Journey
        </Box>
        <Grid
          container
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexWrap: 'wrap',
            marginBottom: '40px',
          }}
        >
          <Grid
            container
            spacing={6}
            xs={12}
            sm={8}
            sx={{
              display: 'inline-flex',
              justifyContent: 'center',
              alignItems: 'stretch',
              flexWrap: 'wrap',
              padding: '40px',
            }}
          >
            {allDatasets.map((datasetItem, index) => {
              return (
                <Grid
                  item
                  key={datasetItem.id}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  xs={12}
                  sm={allDatasets.length > 3 ? 6 : 4}
                >
                  <DataSetCard
                    title={`${index + 1}. ${datasetItem.label}`}
                    body={<Typography>{datasetItem.description}</Typography>}
                    imgSrc={`/images/DataSet${index + 1}.png`}
                    onExplore={handleExplore(datasetItem.id)}
                    videoUrl={datasetItem.videoUrl}
                  />
                </Grid>
              )
            })}
          </Grid>
        </Grid>
        <MobilePrompt open={showMobilePrompt} onContinue={() => setShowMobilePrompt(false)} />

        <MoreContentArrow divRef={demoLandingPageRef} />
      </Stack>
    </>
  )
}

export default CellVisualizationDemoLandingPage
