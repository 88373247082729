import { createSvgIcon } from '@mui/material'

const AccordianUpIcon = createSvgIcon(
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.5 8.75C9.58008 7.47787 8.50329 6.3371 7.29757 5.35665C7.12269 5.21445 6.87731 5.21445 6.70243 5.35665C5.49671 6.3371 4.41992 7.47787 3.5 8.75"
      stroke="#5F55D1"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  'AccordianUp'
)

export default AccordianUpIcon
