import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { ButtonProps, Card, CardContent, styled } from '@mui/material'
import { animated } from '@react-spring/web'
import { DeepcellPrimaryButton } from 'components/shared'
import React, { useEffect, useState } from 'react'
import useFooterHeight from 'utils/useFooterHeight'
import { useDrawerSpring } from './useDrawerSpring'

/**
 * Take a number and return the pixel width string for it
 */
const pw = (w: number) => `${w}px`

const AnimatedCard = animated(Card)
const AnimatedButton = animated(DeepcellPrimaryButton)

const DrawerButton = styled(DeepcellPrimaryButton)({
    '& .MuiButton-endIcon': {
        marginLeft: 'auto',
        marginRight: 1,
    },
    justifyContent: 'start',
    left: '-10px',
    border: 'none',
    '&:hover': {
        boxShadow: 'none !important',
        border: 'none !important',
    },
})

const FloatingButton = styled(AnimatedButton)({})

interface DeepcellDrawerProps {
    drawerWidth?: number
    buttonText?: string
    floatingButtonProps?: ButtonProps
    drawerButtonProps?: ButtonProps
    onResolve?: (isNowOpen: boolean) => void
    open?: boolean
}

export const DeepcellDrawer = ({
    children,
    drawerWidth = 400,
    buttonText = 'Click me bruh',
    floatingButtonProps,
    drawerButtonProps,
    onResolve,
    open,
}: React.PropsWithChildren<DeepcellDrawerProps>): JSX.Element => {
    const { buttonSprings, drawerSprings, toggle, isOpen } = useDrawerSpring({
        drawerWidth,
        onResolve,
    })
    const panelWidthNum = drawerWidth + 10
    const panelWidthPx = pw(panelWidthNum)

    const footerHeight = useFooterHeight()

    const [buttonEl, setButtonEl] = useState<HTMLButtonElement | null>(null)
    const buttonRect = buttonEl?.getBoundingClientRect()
    const buttonTop = buttonRect?.top ?? 0

    useEffect(() => {
        if (open === undefined) return

        const currentOpen = isOpen()
        if (currentOpen) {
            if (open === false) {
                toggle()
            }
        } else if (open) {
            toggle()
        }
    }, [isOpen, open, toggle])

    const handleFloatingButtonClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (!buttonEl) setButtonEl(e.currentTarget)
        if (floatingButtonProps && floatingButtonProps.onClick) {
            floatingButtonProps.onClick(e)
        }
        toggle()
    }

    const handleDrawerButtonClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (drawerButtonProps && drawerButtonProps.onClick) {
            drawerButtonProps.onClick(e)
        }
        toggle()
    }

    return (
        <>
            <FloatingButton
                style={buttonSprings}
                size="large"
                outlined
                endIcon={<KeyboardArrowRightIcon />}
                {...floatingButtonProps}
                onClick={handleFloatingButtonClick}
                sx={{
                    position: 'absolute',
                    justifyContent: 'start',
                    zIndex: 98,
                    left: '-10px',
                    backgroundColor: 'white',
                    boxShadow: 5,
                    ...floatingButtonProps?.sx,
                }}
            >
                {buttonText}
            </FloatingButton>
            <AnimatedCard
                style={{
                    ...drawerSprings,
                    position: 'absolute',
                    zIndex: 99,
                    height: `calc(100% - ${buttonTop}px - ${footerHeight}px - 10px)`,
                    width: panelWidthPx,
                    top: buttonTop,
                    left: '-10px',
                    paddingLeft: '10px',
                }}
                elevation={5}
            >
                <DrawerButton
                    sx={{ width: panelWidthPx }}
                    size="large"
                    text
                    endIcon={<KeyboardArrowLeftIcon />}
                    {...drawerButtonProps}
                    onClick={handleDrawerButtonClick}
                >
                    {buttonText}
                </DrawerButton>
                <CardContent>{children}</CardContent>
            </AnimatedCard>
        </>
    )
}

export default DeepcellDrawer
