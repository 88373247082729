import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface RunDetailsState {
    rowsPerPage: number
    pageCount: number
}

const runDetailsInitialState: RunDetailsState = {
    rowsPerPage: 50,
    pageCount: 0,
}

export const runDetailsSlice = createSlice({
    name: 'runDetails',
    initialState: runDetailsInitialState,
    reducers: {
        update: (_state, action: PayloadAction<RunDetailsState>) => action.payload,
    },
})

export const RunDetailsActions = runDetailsSlice.actions
export const RunDetailsReducer = runDetailsSlice.reducer
