import { useEffect, useState } from 'react'
import { GetRunsParams, getRuns } from 'utils/api'
import { useQueryClient } from 'react-query'
import { Box, IconButton, Skeleton, Stack, styled } from '@mui/material'
import AgGridRunDetailsTable from 'components/shared/AgGridRunDetailsTable'
import useCellImage from 'utils/useCellImage'
import PlusIcon from 'components/shared/icons/PlusIcon'
import MinusIcon from 'components/shared/icons/MinusIcon'
import { AgGridReactProps } from 'ag-grid-react'
import useCellBrowsingSlice from 'redux/slices/hooks/useCellBrowsingSlice'
import { getCellDetailsColumnDefs, getCellDetailsRowData } from './tableView/metadata'
import { SearchOptions } from './types'

interface Props extends AgGridReactProps {
  searchOptions: SearchOptions
}

const ZoomIconContainer = styled(Box)({
  display: 'flex',
  gap: '6px',
  position: 'absolute',
  bottom: '10px',
  left: '10px',
})

function CellDetailsPanel(props: Props): JSX.Element {
  const [cellDescription, setCellDescription] = useState<string | undefined>('')

  const { searchOptions, onGridReady } = props

  const queryClient = useQueryClient()

  const {
    cellBrowsing: { selectedCell },
  } = useCellBrowsingSlice()

  useEffect(() => {
    if (searchOptions?.runIds && searchOptions.runIds.length > 0) {
      const params = { run_ids: searchOptions.runIds } as GetRunsParams
      ;(async () => {
        try {
          const responseRunCellData = await queryClient.fetchQuery(
            ['getCellRunData', params],
            getRuns,
            {
              staleTime: 0,
            }
          )
          setCellDescription(responseRunCellData[0].description)
        } catch (err) {
          console.error(
            `Unable to fetch the description of Run Id ${searchOptions.runIds} associated with the cell image`
          )
        }
      })()
    }
  }, [queryClient, searchOptions.runIds])

  const IMAGE_SIZE = 250
  const [zoomImage, setZoomImage] = useState(IMAGE_SIZE)

  const { data: imageDataUrl } = useCellImage({
    cellId: selectedCell.cell.getCellId(),
    frame: 0,
    width: 250,
    filters: { centerCropSize: zoomImage },
  })

  // Reset Cell image size when cellId changes
  useEffect(() => {
    setZoomImage(IMAGE_SIZE)
  }, [selectedCell])

  const handleZoomIn = () => {
    setZoomImage((prev) => {
      if (prev > 150) {
        return 150
      }
      return prev
    })
  }

  const handleZoomOut = () => {
    setZoomImage((prev) => {
      if (prev < IMAGE_SIZE) {
        return IMAGE_SIZE
      }
      return prev
    })
  }

  if (!selectedCell?.cell.getCellId()) {
    return <></>
  }

  const rowData = getCellDetailsRowData(
    selectedCell.cell,
    selectedCell.cell.getCellId(),
    cellDescription
  )

  return (
    <Box>
      <Stack sx={{ my: '10px' }} direction="row" justifyContent="center">
        {imageDataUrl ? (
          <Box
            sx={{
              width: `${IMAGE_SIZE}px`,
              height: `${IMAGE_SIZE}px`,
              position: 'relative',
            }}
          >
            <img width={IMAGE_SIZE} height={IMAGE_SIZE} src={imageDataUrl} alt="cell" />
            <ZoomIconContainer>
              <IconButton sx={{ p: 0 }} onClick={handleZoomIn}>
                <PlusIcon inheritViewBox sx={{ width: '23px', height: '23px' }} />
              </IconButton>
              <IconButton sx={{ p: 0 }} onClick={handleZoomOut}>
                <MinusIcon inheritViewBox sx={{ width: '23px', height: '23px' }} />
              </IconButton>
            </ZoomIconContainer>
          </Box>
        ) : (
          <Skeleton
            variant="rectangular"
            width={IMAGE_SIZE}
            height={IMAGE_SIZE}
            sx={{ borderRadius: '3.5px' }}
          />
        )}
      </Stack>
      <Box>
        <AgGridRunDetailsTable
          testId="cell-details"
          customClass="ag-borderless-header context-menu-ag-grid"
          headerHeight={0}
          columnDefs={getCellDetailsColumnDefs}
          rowData={rowData}
          onGridReady={onGridReady}
        />
      </Box>
    </Box>
  )
}

export default CellDetailsPanel
