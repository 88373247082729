import { Stack } from '@mui/material'
import { useNotificationSlice } from 'redux/slices/hooks'
import { useCellVisualizationsSlice } from 'redux/slices/hooks/useCellVisualizationsSlice'
import { SelectableButton, SelectableButtonProps } from './SelectableButton'

export const ScatterDensitySwitchButtons = (): JSX.Element => {
  const { showProgress } = useNotificationSlice()
  const {
    setPlotDisplayType,
    cellVisualizations: { plotDisplayType },
  } = useCellVisualizationsSlice()

  const onClick = (newPlotDisplayType: typeof plotDisplayType) => () => {
    let wait = 0
    if (plotDisplayType === 'scatter' && newPlotDisplayType === 'density') {
      showProgress('Switching to Density Plot')
      wait = 200
    }

    /*
     * When switching from scatter plot to density plot there can be long delay while the density map is being generated
     * Setting this timeout guarantees that the user will see the progress bar above
     */
    const timeoutId = setTimeout(() => {
      setPlotDisplayType(newPlotDisplayType)
      clearTimeout(timeoutId)
    }, wait)
  }

  const buttonProps: SelectableButtonProps = {
    sx: { height: '34px', width: '135px' },
    unselectedVariant: 'text',
  }

  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{
        height: '44px',
        width: '305px',
        border: (t) => `1px solid ${t.palette.primary.main}`,
        borderRadius: '4px',
        alignItems: 'center',
        justifyContent: 'space-around',
      }}
    >
      <SelectableButton
        {...buttonProps}
        selected={plotDisplayType === 'scatter'}
        onClick={onClick('scatter')}
      >
        Scatter Plot
      </SelectableButton>
      <SelectableButton
        {...buttonProps}
        selected={plotDisplayType === 'density'}
        onClick={onClick('density')}
      >
        Density Plot
      </SelectableButton>
    </Stack>
  )
}

export default ScatterDensitySwitchButtons
