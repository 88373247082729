import CircularProgress from '@mui/material/CircularProgress'
import Stack from '@mui/material/Stack'
import { GridReadyEvent, GridApi } from 'ag-grid-community'
import { useState } from 'react'
import useNotificationSlice from 'redux/slices/hooks/useNotificationSlice'
import { Run, RunQualityMetric, updateRun } from 'utils/api'
import useAuthTokens from 'utils/useAuthTokens'
import { DialogContentText, Typography } from '@mui/material'
import { DeepcellDialog } from 'components/shared'
import PrimaryButton from './PrimaryButton'
import { getQCMetricsColumnDefs } from './metadata'
import AgGridRunDetailsTable from '../shared/AgGridRunDetailsTable'

interface IQCMetricsGrid {
  run: Run
  onEdit: (runs: Run[]) => void
}
interface IRowData extends Omit<RunQualityMetric, 'total_image_count'> {
  run_percent: string
}

export const runBuckets = ['0-25%', '26-50%', '51-75%', '76-100%']

export const defaultRowData = (): IRowData[] => {
  const noMetricsRowData = []
  for (let i = 0; i < 4; i += 1) {
    noMetricsRowData.push({
      run_percent: runBuckets[i],
      cell_debris: 0,
      blebbing_image: 0,
      chip_blemish_covering_image: 0,
      contamination_covering_image: 0,
      cutoff_image: 0,
    })
  }
  return noMetricsRowData
}

export const getRunRowData = (rowData: Record<number, RunQualityMetric>): IRowData[] => {
  const runRowData: IRowData[] = []
  for (let i = 1; i <= 4; i += 1) {
    runRowData.push({
      run_percent: runBuckets[i - 1],
      cell_debris: rowData[i]?.cell_debris ?? 0,
      blebbing_image: rowData[i]?.blebbing_image ?? 0,
      chip_blemish_covering_image: rowData[i]?.chip_blemish_covering_image ?? 0,
      contamination_covering_image: rowData[i]?.contamination_covering_image ?? 0,
      cutoff_image: rowData[i]?.cutoff_image ?? 0,
    })
  }
  return runRowData
}

export const generateQualityMetricsPayload = (
  rowData: IRowData[]
): Record<number, RunQualityMetric> => {
  const payload: Record<number, RunQualityMetric> = {}

  for (let i = 0; i <= 3; i += 1) {
    payload[i + 1] = {
      total_image_count: 200,
      cutoff_image: Number(rowData[i].cutoff_image),
      contamination_covering_image: Number(rowData[i].contamination_covering_image),
      chip_blemish_covering_image: Number(rowData[i].chip_blemish_covering_image),
      blebbing_image: Number(rowData[i].blebbing_image),
      cell_debris: Number(rowData[i].cell_debris),
    }
  }
  return payload
}

const QCMetricsGrid = (props: IQCMetricsGrid): JSX.Element => {
  const { permissions } = useAuthTokens()
  const { displayNotification } = useNotificationSlice()
  const [gridApi, setGridApi] = useState<GridApi | null>(null)
  const { run, onEdit } = props
  const [loading, setLoading] = useState<Record<'approve' | 'disapprove' | 'save', boolean>>({
    approve: false,
    disapprove: false,
    save: false,
  })

  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [runQualityStatusState, setRunQualityStatusState] = useState<boolean>(false)

  const {
    run_quality_metrics: runQualityMetrics,
    run_quality_approved: runQualityApproved,
    run_quality_approved_email: runQualityApprovedBy,
    run_quality_score_email: runQualityScorer,
  } = run

  const getAgGridRenderedRowData = (): IRowData[] | undefined => {
    const renderedNodes = gridApi?.getRenderedNodes()
    return renderedNodes?.map((item): IRowData => item.data)
  }

  const handleMetricsDataSubmission = async () => {
    const updatedRowData: IRowData[] | undefined = getAgGridRenderedRowData()

    if (updatedRowData !== undefined) {
      const runMetricsPayload = generateQualityMetricsPayload(updatedRowData)
      const params = {
        run_id: run.run_id,
        run_quality_metrics: runMetricsPayload,
      }

      try {
        setLoading({ ...loading, save: true })
        const res = await updateRun(params)
        const successMessage = 'Run Quality Review submitted successfully'
        displayNotification({ message: successMessage, type: 'success' })
        onEdit([res])
      } catch (err) {
        const errMessage =
          (err as Error).message ||
          'An unexpected error occurred when trying to update metrics data'
        displayNotification({ message: errMessage, type: 'error' })
      } finally {
        setLoading({ ...loading, save: false })
      }
    }
  }

  const handleApproveDisapproveAction = async (runQualityStatus: boolean) => {
    const statusParams = {
      run_id: run.run_id,
      run_quality_approved: runQualityStatus,
    }

    const updatedRowData: IRowData[] | undefined = getAgGridRenderedRowData()
    if (runQualityStatus && updatedRowData !== undefined) {
      const runMetricsPayload = generateQualityMetricsPayload(updatedRowData)
      const metricsParams = {
        run_id: run.run_id,
        run_quality_metrics: runMetricsPayload,
      }
      try {
        setLoading({ ...loading, approve: true })
        await updateRun(metricsParams)
        const res = await updateRun(statusParams)
        const successMessage = 'Run Quality Review approved successfully'
        displayNotification({ message: successMessage, type: 'success' })
        onEdit([res])
      } catch (err) {
        const errMessage =
          (err as Error).message ||
          'An unexpected error occurred when trying to update metrics data'
        displayNotification({ message: errMessage, type: 'error' })
      } finally {
        setLoading({ ...loading, approve: false })
      }
    } else {
      try {
        setLoading({ ...loading, disapprove: true })
        const res = await updateRun(statusParams)
        const successMessage = 'Run Quality Review disapproved successfully'
        displayNotification({ message: successMessage, type: 'success' })
        onEdit([res])
      } catch (err) {
        const errMessage =
          (err as Error).message ||
          'An unexpected error occurred when trying to update metrics data'
        displayNotification({ message: errMessage, type: 'error' })
      } finally {
        setLoading({ ...loading, disapprove: false })
      }
    }
  }

  const handleConfirm = async (runQualityStatus: boolean) => {
    setOpenDialog(false)
    await handleApproveDisapproveAction(runQualityStatus)
  }

  let rowData: IRowData[] = []
  if (runQualityMetrics !== undefined) {
    rowData = getRunRowData(runQualityMetrics as unknown as Record<number, RunQualityMetric>)
  } else {
    rowData = defaultRowData()
  }

  return (
    <>
      <AgGridRunDetailsTable
        testId="qc-metric-grid"
        customClass="context-menu-ag-grid"
        headerHeight={32}
        rowData={rowData}
        defaultColDef={{
          suppressMenu: true,
        }}
        columnDefs={getQCMetricsColumnDefs(run.run_id, runBuckets)}
        editType="fullRow"
        onGridReady={(params: GridReadyEvent) => setGridApi(params.api)}
      />
      {permissions.has('qc:approve') && permissions.has('qc:edit') && (
        <>
          <Stack sx={{ padding: '10px 14px' }} direction="row" spacing={1}>
            <PrimaryButton
              onClick={() => {
                setOpenDialog(true)
                setRunQualityStatusState(true)
              }}
              style={{ width: '150px' }}
              disabled={loading.approve || loading.disapprove}
              endIcon={loading.approve && <CircularProgress color="secondary" size={16} />}
            >
              Approve
            </PrimaryButton>
            <PrimaryButton
              onClick={() => {
                setOpenDialog(true)
                setRunQualityStatusState(false)
              }}
              style={{ width: '150px' }}
              disabled={loading.approve || loading.disapprove}
              endIcon={loading.disapprove && <CircularProgress color="secondary" size={16} />}
            >
              Disapprove
            </PrimaryButton>
          </Stack>
          {runQualityApproved !== undefined &&
            runQualityApprovedBy !== undefined &&
            runQualityApprovedBy.length > 0 && (
              <Typography sx={{ px: '14px' }}>
                Run quality was previously {runQualityApproved ? 'approved' : 'disapproved'} by{' '}
                <u>{runQualityApprovedBy}</u>
              </Typography>
            )}
          {runQualityScorer !== undefined && runQualityScorer.length > 0 && (
            <Typography sx={{ px: '14px' }}>
              Manual run quality was performed by <u>{runQualityScorer}</u>
            </Typography>
          )}
        </>
      )}
      {permissions.has('qc:edit') && !permissions.has('qc:approve') && (
        <PrimaryButton
          onClick={handleMetricsDataSubmission}
          style={{ width: '150px' }}
          disabled={loading.save}
          endIcon={loading.save && <CircularProgress color="secondary" size={16} />}
        >
          Save
        </PrimaryButton>
      )}

      <DeepcellDialog
        open={openDialog}
        handleConfirm={() => handleConfirm(runQualityStatusState)}
        handleCancel={() => setOpenDialog(false)}
        okLabel="Yes"
        cancelLabel="No"
        titleLabel="Confirm Run Quality Status "
      >
        <DialogContentText>{`Are you sure you want to ${
          runQualityStatusState ? 'Approve' : 'Disapprove'
        }?`}</DialogContentText>
      </DeepcellDialog>
    </>
  )
}
export default QCMetricsGrid
