import { Box, DialogContentText, Stack, Typography } from '@mui/material'
import usePreFilter from 'components/cell-visualizations/prefilter/usePreFilter'
import { DeepcellPrimaryButton } from 'components/shared'
import { useState } from 'react'
import { PinnedCellGroup } from 'redux/slices'
import PlotCellImages from '../cellSelectionDrawer/PlotCellImages'
import { usePlotData } from '../plot/usePlotData'
import { DeleteButtonWithConfirmation } from '../shared'
import { SeeAllMorphoTypeImages } from './SeeAllMorphoTypeImages'

interface Props {
  morphotype: PinnedCellGroup
  active: boolean
  onDelete(): void
}

export const MorphotypeSelectionCard = ({ morphotype, active, onDelete }: Props): JSX.Element => {
  const { points } = morphotype.cells
  const { length: cellCount } = points ?? []
  const { setMorphotypeToRemoveHighlight } = usePreFilter()
  const {
    scatterPlotMetaData: {
      x: { length: totalPointLength },
    },
  } = usePlotData()
  const percentage = ((cellCount / totalPointLength) * 100).toFixed(2)

  const onMouseMove = (id: number, shouldHighlight: boolean) => {
    if (morphotype.isHighlighted !== shouldHighlight) {
      setMorphotypeToRemoveHighlight({ id, shouldHighlight })
    }
  }

  const [viewAllImages, setViewAllImages] = useState(false)
  const onClickViewAllImages = () => {
    setViewAllImages(true)
    onMouseMove(morphotype.id, false)
  }

  return (
    <Box
      onMouseEnter={() => onMouseMove(morphotype.id, true)}
      onMouseLeave={() => onMouseMove(morphotype.id, false)}
      sx={{
        position: 'relative',
        backgroundColor: (t) => (morphotype.isHighlighted ? t.palette.primary.light : '#F6F6F6'),
        minHeight: '143px',
        padding: '10px',
      }}
    >
      <Stack direction="row" justifyContent="space-between">
        <Stack>
          <Typography>No. of cells: {cellCount}</Typography>
          <Typography>{`Percentage of cells: ${percentage}%`}</Typography>
        </Stack>
        <DeleteButtonWithConfirmation
          onConfirmDelete={onDelete}
          buttonProps={{ iconStyleProps: { color: 'rgba(95, 85, 209, 1)' } }}
        >
          <DialogContentText>Are you sure you want to delete this selection?</DialogContentText>
        </DeleteButtonWithConfirmation>
      </Stack>
      <PlotCellImages
        cells={points?.slice(0, 25)}
        imgProps={{
          style: { height: 46, width: 46, opacity: active ? undefined : 0.15, borderRadius: '4px' },
        }}
      />
      <DeepcellPrimaryButton text onClick={onClickViewAllImages}>
        View All Cell Images
      </DeepcellPrimaryButton>
      <SeeAllMorphoTypeImages
        open={viewAllImages}
        handleClose={() => setViewAllImages(false)}
        morphotype={morphotype}
        percentage={percentage}
      />
    </Box>
  )
}

export default MorphotypeSelectionCard
