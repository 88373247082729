import { Stack } from '@mui/material'
import { DeepcellPrimaryButton } from 'components/shared'
import { isEqual } from 'lodash'
import { useMemo, useRef } from 'react'
import { useCellVisualizationsSlice } from 'redux/slices/hooks/useCellVisualizationsSlice'
import useCellVisualizationUrlParams from '../useCellVisualizationUrlParams'
import useSessionApi from '../useSessionApi'
import { getCellInfoToGroups } from '../utils'

export const PreFilterButtons = (): JSX.Element => {
  const { exitPreFilter } = useCellVisualizationUrlParams()
  const { saveVersionConfig } = useSessionApi()
  const {
    setCellInfoGroups,
    cellVisualizations: { cellsData, morphotypesToRemove },
    prefilteredCellIdSet,
    setMorphotypesToRemove,
  } = useCellVisualizationsSlice()
  const mountedCellIds = useRef(prefilteredCellIdSet)
  const mountedMorphotypesToRemove = useRef(morphotypesToRemove)

  const valsAreUnchanged = useMemo(
    () => isEqual(mountedCellIds.current, prefilteredCellIdSet),
    [prefilteredCellIdSet]
  )

  const refreshCellInfoGroups = () => {
    if (!cellsData) return
    const unFilteredData = cellsData?.filter((cd) => !prefilteredCellIdSet.has(cd.CELL_ID))
    if (unFilteredData.length) {
      setCellInfoGroups(getCellInfoToGroups(unFilteredData))
    }
  }

  const onSave = () => {
    saveVersionConfig()
    refreshCellInfoGroups()
  }

  const onSkip = () => {
    if (!isEqual(mountedMorphotypesToRemove.current, morphotypesToRemove)) {
      setMorphotypesToRemove(mountedMorphotypesToRemove.current)
    }
    exitPreFilter()
  }

  return (
    <Stack direction="row" spacing={2}>
      <DeepcellPrimaryButton
        sx={{ height: '37px', width: '240px', border: '1px solid rgba(164, 239, 192, 1)' }}
        contained
        onClick={onSkip}
      >
        Skip to cell visualization
      </DeepcellPrimaryButton>
      <DeepcellPrimaryButton
        sx={{ height: '37px', width: '172px', border: '1px solid rgba(164, 239, 192, 1)' }}
        onClick={onSave}
        contained
        disabled={valsAreUnchanged}
      >
        Save & Continue
      </DeepcellPrimaryButton>
    </Stack>
  )
}

export default PreFilterButtons
