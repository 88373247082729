import { NotificationSlice } from '../NotificationSlice'
import useSliceWrapper from './useSliceWrapper'

const useGetMethodsAndData = () => {
    const methodsAndData = useSliceWrapper('notification', NotificationSlice)
    const { displayNotification } = methodsAndData

    const extraMethods = {
        showProgress: (message: string) => {
            displayNotification({
                message,
                showProgress: true,
                type: 'info',
            })
        },

        showSuccess: (message: string, timeout = 5000) => {
            displayNotification({
                message,
                type: 'success',
                timeout,
            })
        },

        showError: (message: string) => {
            displayNotification({
                message,
                type: 'error',
            })
        },
    }
    return { ...methodsAndData, ...extraMethods }
}

export const useNotificationSlice = (): ReturnType<typeof useGetMethodsAndData> =>
    useGetMethodsAndData()

export default useNotificationSlice
