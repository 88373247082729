import { createSvgIcon } from '@mui/material'

const ChartIcon = createSvgIcon(
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M28 0.5H4C2.067 0.5 0.5 2.067 0.5 4V28C0.5 29.933 2.067 31.5 4 31.5H28C29.933 31.5 31.5 29.933 31.5 28V4C31.5 2.067 29.933 0.5 28 0.5Z"
      fill="white"
      stroke="#A4EFC0"
    />
    <path
      d="M14.75 10.5H24.5M14.75 10.5C14.75 11.3284 14.0784 12 13.25 12C12.4216 12 11.75 11.3284 11.75 10.5M14.75 10.5C14.75 9.67157 14.0784 9 13.25 9C12.4216 9 11.75 9.67157 11.75 10.5M11.75 10.5H8M14.75 22.5H24.5M14.75 22.5C14.75 23.3284 14.0784 24 13.25 24C12.4216 24 11.75 23.3284 11.75 22.5M14.75 22.5C14.75 21.6716 14.0784 21 13.25 21C12.4216 21 11.75 21.6716 11.75 22.5M11.75 22.5H8M20.75 16.5H24.5M20.75 16.5C20.75 17.3284 20.0784 18 19.25 18C18.4216 18 17.75 17.3284 17.75 16.5M20.75 16.5C20.75 15.6716 20.0784 15 19.25 15C18.4216 15 17.75 15.6716 17.75 16.5M17.75 16.5H8"
      stroke="#5F55D1"
    />
  </svg>,
  'Chart'
)

export default ChartIcon
