import { useWindowSize } from 'react-use'
import { useDebounce } from 'use-debounce'

/**
 * Returns window width after delay (default is 500ms)
 */
export const useDebouncedWindowWidth = (delay = 500): number => {
  const { width } = useWindowSize()
  const [debouncedWindowWidth] = useDebounce(width, delay)

  return debouncedWindowWidth
}

export default useDebouncedWindowWidth
