import { Card, CardContent } from '@mui/material'
import { Stack } from '@mui/system'
import { animated, useSpring } from '@react-spring/web'
import { useEffect, useState } from 'react'
import { useWindowSize } from 'react-use'
import { PinnedCellGroup } from 'redux/slices'
import { useCellVisualizationsSlice } from 'redux/slices/hooks/useCellVisualizationsSlice'
import MergePinned from '../Pinned/MergePinned'
import PinnedGroups from '../Pinned/PinnedGroups'
import { usePanelHeight } from './usePanelHeight'
import { ComparePanelContent } from './ComparePanelContent'
import ExportMorphotypeContent from './ExportMorphotypeContent'

const AnimatedCard = animated(Card)
const AnimatedCardContent = animated(CardContent)

const panelWidth = 520
const exportPanelWidth = 325
const fullPanelWidth = panelWidth * 2 + exportPanelWidth

export const CellSelectionDrawer = (): JSX.Element => {
  const { width: fullWindowWidth } = useWindowSize()

  const { heightCSS: panelHeight, topCSS: panelTop } = usePanelHeight()

  const {
    cellVisualizations: { showCellGroups, showCompare, exportCellMorphotype },
  } = useCellVisualizationsSlice()

  const showExportMorphotype = exportCellMorphotype.length

  let currentPanelWidth = panelWidth
  if (showCellGroups && showCompare && showExportMorphotype) {
    currentPanelWidth = fullPanelWidth
  }
  else if (showCellGroups && showCompare && !showExportMorphotype) {
    currentPanelWidth = panelWidth * 2
  }
  else if (showCellGroups && showExportMorphotype) {
      currentPanelWidth = panelWidth + exportPanelWidth
  } else if (!showCellGroups && !showCompare) {
    currentPanelWidth = 0
  }

  const [cellGroupsSprings, cellGroupsApi] = useSpring(() => ({
    from: { x: fullWindowWidth },
  }))

  const [cGCardContentSprings, cGCardContentApi] = useSpring(() => ({
    from: { display: 'initial' },
  }))
  const [compareCardContentSprings, compareCardContentApi] = useSpring(() => ({
    from: { display: 'initial' },
  }))

  const [exportMorphotypeContent, exportMorphotypeContentApi] = useSpring(() => ({
    from: { display: 'initial' },
  }))

  useEffect(() => {
    cellGroupsApi.start({
      to: {
        // the -8 is to give enough room to show the scrollbar
        x: fullWindowWidth - currentPanelWidth - 8,
      },
    })
  }, [currentPanelWidth, cellGroupsApi, fullWindowWidth])

  useEffect(() => {
    cGCardContentApi.start({
      to: {
        display: showCellGroups ? 'initial' : 'none',
      },
    })
  }, [cGCardContentApi, showCellGroups])

  useEffect(() => {
    exportMorphotypeContentApi.start({
      to: {
        display: showExportMorphotype ? 'initial' : 'none',
      }
    })
  }, [showExportMorphotype, exportMorphotypeContentApi])

  useEffect(() => {
    compareCardContentApi.start({
      to: {
        display: showCompare ? 'initial' : 'none',
      },
    })
  }, [compareCardContentApi, showCompare])

  const [displayMergeMode, setDisplayMergeMode] = useState(false)

  const [selectedPinnedCellGroupToMerge, setSelectedPinnedCellGroupToMerge] =
    useState<PinnedCellGroup>()

  const onMergeButtonClick = (pcg: PinnedCellGroup) => {
    setSelectedPinnedCellGroupToMerge(pcg)
    setDisplayMergeMode(true)
  }

  const onMergeClose = () => {
    setSelectedPinnedCellGroupToMerge(undefined)
    setDisplayMergeMode(false)
  }

  return (
    <AnimatedCard
      style={cellGroupsSprings}
      sx={{
        position: 'absolute',
        height: panelHeight,
        width: `${fullPanelWidth}px`,
        top: panelTop,
      }}
      elevation={5}
    >
      <Stack direction="row">
        <AnimatedCardContent
          style={cGCardContentSprings}
          sx={{
            width: panelWidth,
            height: panelHeight,
            overflowY: 'auto',
          }}
        >
          {displayMergeMode ? (
            <MergePinned
              onCloseClick={onMergeClose}
              defaultSelected={selectedPinnedCellGroupToMerge}
            />
          ) : (
            <PinnedGroups onMergeClick={onMergeButtonClick} />
          )}
        </AnimatedCardContent>
        <AnimatedCardContent
          style={exportMorphotypeContent}
          sx={{
            width: exportPanelWidth,
            height: panelHeight,
            overflowY: 'auto',
            overflowX: 'hidden',
          }}
        >
          <ExportMorphotypeContent />
        </AnimatedCardContent>
        <AnimatedCardContent
          style={compareCardContentSprings}
          sx={{
            width: panelWidth,
            height: panelHeight,
            overflowY: 'auto',
            overflowX: 'hidden',
            borderLeft: `${showCellGroups && showCompare ? '1px solid #EEEFFF' : 'none'}`,
          }}
        >
          <ComparePanelContent />
        </AnimatedCardContent>
      </Stack>
    </AnimatedCard>
  )
}

export default CellSelectionDrawer
