import { createSvgIcon } from '@mui/material'

const PlusIcon = createSvgIcon(
  <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="0.319444"
      y="0.319444"
      width="22.3611"
      height="22.3611"
      rx="2.23611"
      fill="white"
      stroke="#A4EFC0"
      strokeWidth="0.638889"
    />
    <path
      d="M11.5 16.5312C10.1656 16.5312 8.88591 16.0012 7.94237 15.0576C6.99883 14.1141 6.46875 12.8344 6.46875 11.5C6.46875 10.1656 6.99883 8.88591 7.94237 7.94237C8.88591 6.99883 10.1656 6.46875 11.5 6.46875C12.8344 6.46875 14.1141 6.99883 15.0576 7.94237C16.0012 8.88591 16.5312 10.1656 16.5312 11.5C16.5312 12.8344 16.0012 14.1141 15.0576 15.0576C14.1141 16.0012 12.8344 16.5312 11.5 16.5312ZM11.5 17.25C13.025 17.25 14.4875 16.6442 15.5659 15.5659C16.6442 14.4875 17.25 13.025 17.25 11.5C17.25 9.97501 16.6442 8.51247 15.5659 7.43414C14.4875 6.3558 13.025 5.75 11.5 5.75C9.97501 5.75 8.51247 6.3558 7.43414 7.43414C6.3558 8.51247 5.75 9.97501 5.75 11.5C5.75 13.025 6.3558 14.4875 7.43414 15.5659C8.51247 16.6442 9.97501 17.25 11.5 17.25V17.25Z"
      fill="#0F0F0F"
    />
    <path
      d="M11.5 8.625C11.5953 8.625 11.6867 8.66286 11.7541 8.73026C11.8215 8.79765 11.8594 8.88906 11.8594 8.98438V11.1406H14.0156C14.1109 11.1406 14.2023 11.1785 14.2697 11.2459C14.3371 11.3133 14.375 11.4047 14.375 11.5C14.375 11.5953 14.3371 11.6867 14.2697 11.7541C14.2023 11.8215 14.1109 11.8594 14.0156 11.8594H11.8594V14.0156C11.8594 14.1109 11.8215 14.2023 11.7541 14.2697C11.6867 14.3371 11.5953 14.375 11.5 14.375C11.4047 14.375 11.3133 14.3371 11.2459 14.2697C11.1785 14.2023 11.1406 14.1109 11.1406 14.0156V11.8594H8.98438C8.88906 11.8594 8.79765 11.8215 8.73026 11.7541C8.66286 11.6867 8.625 11.5953 8.625 11.5C8.625 11.4047 8.66286 11.3133 8.73026 11.2459C8.79765 11.1785 8.88906 11.1406 8.98438 11.1406H11.1406V8.98438C11.1406 8.88906 11.1785 8.79765 11.2459 8.73026C11.3133 8.66286 11.4047 8.625 11.5 8.625V8.625Z"
      fill="#0F0F0F"
    />
  </svg>,
  'Plus'
)

export default PlusIcon
