import { SelectChangeEvent, Stack } from '@mui/material'
import { DeepcellPrimaryButton, DeepcellPrimarySelect } from 'components/shared'
import { useState } from 'react'
import { usePlotlySlice } from 'redux/slices/hooks'
import { useCellVisualizationsSlice } from 'redux/slices/hooks/useCellVisualizationsSlice'
import useEventsManager from 'redux/slices/hooks/useEventsManager'
import { CellInfo } from '../tsv/types'
import useDataFieldsInDataset from '../useDataFieldsInDataset'
import { EMBEDDING_MAX, EmbeddingDensityWarning } from './EmbeddingDensityWarning'

export const EmbeddingDensityPanel = (): JSX.Element => {
  const { resetRange } = usePlotlySlice()
  const { dataFields } = useDataFieldsInDataset()
  const {
    setSelectedComparisonDimensions,
    setCellImagesFilter,
    cellVisualizations: { cellInfoGroups, cellImagesFilter },
  } = useCellVisualizationsSlice()

  const eventsManager = useEventsManager()
  const [variables, setVariables] = useState<{
    primary: keyof CellInfo | ''
    other: keyof CellInfo | ''
  }>({ primary: '', other: '' })
  const [warningCount, setWarningCount] = useState<number>()

  const getDimensions = (field: keyof CellInfo) => ({
    field,
    values: cellInfoGroups[field]?.data.map((d) => d.value) ?? [],
  })

  const clickResetButton = () => {
    const button = document.getElementById('dataVizResetView')
    button?.click()
  }

  const handleChange = (key: keyof typeof variables) => (e: SelectChangeEvent<unknown>) =>
    setVariables((v) => ({ ...v, [key]: `${e.target.value}` }))

  const createPlots = () => {
    if (variables.primary) {
      setSelectedComparisonDimensions(
        variables.other
          ? [getDimensions(variables.primary), getDimensions(variables.other)]
          : [getDimensions(variables.primary)]
      )
    }

    setWarningCount(undefined)
    resetRange()
    clickResetButton()
  }

  const confirmVariableCount = () => {
    if (variables.primary) {
      let count = getDimensions(variables.primary).values.length

      if (variables.other) {
        count *= getDimensions(variables.other).values.length
      }

      if (count > EMBEDDING_MAX) {
        setWarningCount(count)
      } else {
        setCellImagesFilter({
          cellImagesFilter: { ...cellImagesFilter, displayImages: false },
          eventsManager,
        })
        createPlots()
      }
    } else {
      setSelectedComparisonDimensions(undefined)
    }
  }

  const items = [
    ...dataFields.flatMap((df) =>
      df.isContinuous ? [] : { value: df.attribute, output: df.label }
    ),
  ]

  const clear = () => {
    setVariables({ primary: '', other: '' })
    resetRange()
    clickResetButton()
    setSelectedComparisonDimensions(undefined)
  }

  return (
    <Stack spacing={2}>
      <DeepcellPrimarySelect
        data-testid="primary-variable-dropdown"
        label="Primary Experimental Variable"
        items={items}
        value={variables.primary}
        onChange={handleChange('primary')}
      />
      {variables.primary ? (
        <DeepcellPrimarySelect
          data-testid="other-variable-dropdown"
          label="Other Experimental Variable (Optional)"
          items={items}
          value={variables.other}
          onChange={handleChange('other')}
        />
      ) : null}
      <DeepcellPrimaryButton contained onClick={confirmVariableCount}>
        Create Comparison Plots
      </DeepcellPrimaryButton>
      <DeepcellPrimaryButton text size="small" onClick={clear}>
        reset
      </DeepcellPrimaryButton>
      <EmbeddingDensityWarning
        count={warningCount ?? 0}
        open={warningCount !== undefined}
        onConfirm={createPlots}
        onCancel={() => setWarningCount(undefined)}
      />
    </Stack>
  )
}

export default EmbeddingDensityPanel
