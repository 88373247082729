import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import DeepcellTextField from './DeepcellTextField'

interface DeepcellDatePickerProps {
    label: string
    value: Date | null
    onChange: (date: Date) => void
    dataTestId?: string
    disableFuture?: boolean
    disablePast?: boolean
    autoFocus?: boolean
    name?: string
    disabled?: boolean
    inputFormat?: string
}

export const DeepcellDatePicker = ({
    label,
    value,
    onChange,
    dataTestId,
    inputFormat,
    ...rest
}: DeepcellDatePickerProps): JSX.Element => {
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
                label={label}
                value={value}
                onChange={(data: Date | null) => onChange(data as Date)}
                inputFormat={inputFormat ?? 'MM-dd-yyyy'}
                renderInput={(params) => (
                    <DeepcellTextField
                        // https://lightrun.com/answers/hacker0x01-react-datepicker-readonlytrue-disables-datepicker-instead-of-preventing-edit-and-keyboard-appearing-on-mobiles
                        onFocus={(e) => e.target.blur()}
                        data-testid={dataTestId}
                        id="date-picker"
                        {...params}
                    />
                )}
                {...rest}
            />
        </LocalizationProvider>
    )
}

export default DeepcellDatePicker
