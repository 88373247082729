import { configureStore } from '@reduxjs/toolkit'
import {
  NotificationReducer,
  RunSearchFilterReducer,
  RunsReducer,
  UserReducer,
  CellVisualizationsReducer,
  VisualizationReducer,
  PlotlyReducer,
  WalkthroughReducer,
  RunDetailsReducer,
  RemoteFlagsReducer,
  CellBrowsingReducer,
} from './slices'

export const store = configureStore({
  reducer: {
    runSearchFilter: RunSearchFilterReducer,
    runs: RunsReducer,
    runDetails: RunDetailsReducer,
    user: UserReducer,
    cellVisualizations: CellVisualizationsReducer,
    notification: NotificationReducer,
    visualization: VisualizationReducer,
    plotly: PlotlyReducer,
    walkthrough: WalkthroughReducer,
    remoteFlags: RemoteFlagsReducer,
    cellBrowsing: CellBrowsingReducer,
  },
  middleware: [],
})

export type RootState = ReturnType<typeof store.getState>
