import { Skeleton, Stack, TablePagination, styled } from '@mui/material'
import { CellId } from '@deepcell/dc_core_proto/deepcell_schema2_pb'
import useCellImage from 'utils/useCellImage'
import useRunDetailsSlice from 'redux/slices/hooks/useRunDetailsSlice'
import * as api from '../../utils/api'
import { FindCellsResponse } from '../../utils/api'

const GalleryContainer = styled('div')({
  display: 'flex',
  minHeight: '105px',
  alignItems: 'flex-start',
  alignContent: 'flex-start',
  gap: '5px',
  flexShrink: 0,
  flexWrap: 'wrap',
})

const ImageContainer = styled('img')({
  width: '50px',
  height: '50px',
  borderRadius: '3.5px',
})

const CellImage = ({ cellId }: { cellId: CellId }) => {
  const { data: imageDataUrl } = useCellImage({
    cellId,
    frame: 0,
    width: 50,
    filters: {},
  })

  if (imageDataUrl) {
    return <ImageContainer src={imageDataUrl} />
  }
  return <Skeleton variant="rectangular" width={50} height={50} style={{ borderRadius: '3.5px' }} />
}

const StyledTablePagination = styled(TablePagination)({
  '& .MuiTablePagination-select': {
    backgroundColor: '#EEEFFF',
    padding: '4px 10px',
    borderRadius: '5px',
    '&:focus': {
      backgroundColor: '#EEEFFF',
    },
  },
  '& .MuiTablePagination-root': {
    display: 'flex',
    width: '100%',
    flexGrow: 1,
  },
  '& .MuiTablePagination-spacer': {
    display: 'none',
  },
  '& .MuiTablePagination-toolbar': {
    display: 'flex',
    padding: '0px',
  },
})

interface CellImagesGalleryProps {
  cellData: FindCellsResponse
}

const CellImagesGallery = ({ cellData }: CellImagesGalleryProps): JSX.Element => {
  const { runDetails: runImages, update } = useRunDetailsSlice()
  const handlePageChange = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    update({ ...runImages, pageCount: newPage })
  }

  return (
    <>
      <GalleryContainer data-testid="cell-images-gallery">
        {cellData?.cells.map((cell: api.CellResponse, i) => {
          const cellId = cell.cell.getCellId()
          const key = `${cellId?.getTime()}_${cellId?.getNumber()}_${cellId?.getInstrumentId()}`
          if (cellId) {
            return <CellImage cellId={cellId} key={key || i.toString()} />
          }
          return <></>
        })}
      </GalleryContainer>

      <Stack justifyContent="space-between" alignItems="center" direction="row" sx={{ mt: '10px' }}>
        <StyledTablePagination
          data-testid="cell-images-pagination"
          page={runImages.pageCount}
          count={cellData?.count || 0}
          rowsPerPage={runImages.rowsPerPage}
          labelDisplayedRows={({ from, to }) => `${from}-${to} of ${cellData?.count}`}
          rowsPerPageOptions={[50]}
          labelRowsPerPage=""
          onPageChange={handlePageChange}
        />
      </Stack>
    </>
  )
}

export default CellImagesGallery
