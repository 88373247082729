import { Card, Stack, Typography } from '@mui/material'
import PlotCellImage from 'components/cell-visualizations/PlotCellImage'
import useSessionApi from 'components/cell-visualizations/useSessionApi'
import { DeepcellDialog, DeepcellTextField } from 'components/shared'
import moment from 'moment'
import { useState } from 'react'
import { useCellVisualizationsSlice } from 'redux/slices/hooks/useCellVisualizationsSlice'
import useFlags from 'components/shared/useFlags'
import {
  ClassifierSuccessOutput,
  TrainClassifierSuccessDialog,
} from './TrainClassifierSuccessDialog'

export interface TrainClassifierDialogProps {
  open?: boolean
  onTrainClassifier?(): void
  onClose?(): void
}
export const TrainClassifierDialog = (props: TrainClassifierDialogProps): JSX.Element => {
  const { open, onTrainClassifier, onClose } = props

  const { saveVersionConfig } = useSessionApi()

  const { cellVisualizationsModelNameInvalidCharacters } = useFlags()
  const invalidModelNameChars = cellVisualizationsModelNameInvalidCharacters.split('')

  const {
    getMergedPinnedCellGroups,
    cellVisualizations: { projectCode, name },
  } = useCellVisualizationsSlice()

  const pinnedCellInfo = getMergedPinnedCellGroups()?.flatMap((pc) =>
    pc.active
      ? {
          name: pc.name,
          cellId: pc.cells.points ? pc.cells.points[0].cellId ?? '' : '',
        }
      : []
  )

  const [classifierName, setClassifierName] = useState('')
  const [error, setError] = useState('')
  const [successOutput, setSuccessOutput] = useState<ClassifierSuccessOutput>()
  const [showModal, setShowModal] = useState<boolean>(false)

  const handleClose = () => {
    setError('')
    setClassifierName('')
    if (onClose) onClose()
  }

  const handleTrainClassifierClick = async () => {
    if (!classifierName) {
      setError('Classifier Name is required')
      return
    }

    const errorOutput = await saveVersionConfig({
      classifierName,
      projectCode,
      trainClassifier: true,
    })

    if (errorOutput) {
      setShowModal(false)
    } else {
      setShowModal(true)
    }

    if (onTrainClassifier) onTrainClassifier()
    if (onClose) onClose()
    setSuccessOutput({
      classifierName,
      sessionName: name,
      trainedDate: moment().format('MMM Do YYYY, h:mm:ss a'),
    })
  }

  /** Flag invalid characters that don't work elsewhere in the Deepcell platform
   *
   * For example, Model Management Service doesn't handle ':' well, since we use that in canonical model identifiers
   * See ModelVer, for example
   */
  function getInvalidChars(modelName: string) {
    return invalidModelNameChars.filter((char) => modelName.includes(char))
  }

  /** Trim whitespace from beginning and end.  This breaks Controller software (CBS-715) */
  function sanitizeModelName() {
    setClassifierName(classifierName.trim())
  }

  const onClassifierChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const invalidChars = getInvalidChars(e.target.value)

    if (invalidChars.length > 0) {
      const suffix = invalidChars.length > 1 ? 's' : ''
      setError(`Classifier name cannot contain the character${suffix} ${invalidChars.join('')}`)
    } else {
      setError('')
    }
    setClassifierName(e.target.value)
  }

  return (
    <>
      <DeepcellDialog
        handleCancel={handleClose}
        handleConfirm={handleTrainClassifierClick}
        open={!!open}
        okLabel="Train Classifier"
        okDisabled={!!error}
        titleLabel="Train Cell Group Classifier"
      >
        <Stack spacing={2} sx={{ width: 400, px: 1 }}>
          <Typography align="center">
            Please confirm you would like to train a classifier to sort cells on instrument using
            cell groups
          </Typography>
          <DeepcellTextField
            label="Classifier Name"
            value={classifierName}
            onChange={onClassifierChange}
            onBlur={sanitizeModelName}
            error={!!error}
            helperText={error}
          />
          <Card sx={{ p: 2 }}>
            <Typography mb={1}>Cell Groups</Typography>
            <Stack spacing={1} maxHeight={300} sx={{ overflowY: 'auto' }}>
              {pinnedCellInfo?.map((pci) => (
                <Stack
                  key={pci.cellId}
                  direction="row"
                  p={1}
                  spacing={2}
                  borderRadius={1}
                  sx={{ bgcolor: (t) => t.palette.info.light }}
                >
                  <PlotCellImage
                    cellId={pci.cellId ?? ''}
                    imgProps={{ width: 40, height: 40, style: { borderRadius: 8 } }}
                  />
                  <Typography>{pci.name}</Typography>
                </Stack>
              ))}
            </Stack>
          </Card>
          <Typography align="center" fontSize="12px" color={(t) => t.palette.text.secondary}>
            Note: Once you publish classifiers it creates a version.You can access versions from top
            navigation.
          </Typography>
        </Stack>
      </DeepcellDialog>
      <TrainClassifierSuccessDialog
        open={showModal}
        onClose={() => {
          setShowModal(false)
          setSuccessOutput(undefined)
        }}
        output={successOutput}
      />
    </>
  )
}

export default TrainClassifierDialog
