const MAX_MARKER_SIZE = 6
const MIN_MARKER_SIZE = 2

export const getMarkerSize = (dataLength: number): number => {
    // @TODO DVDF-5 Make the marker size depend on more dynamic values instead of fixed constants.
    // for example -- actual graph pixel dimensions in the window, and viewport range after zoom,
    // or number of visible points.
    //
    // This is a dumb hack to pick a slightly better default than just MARKER_SIZE=4
    // Assume approximately a 600x600 viewport and 100% pixel coverage ignoring overplotting
    //
    // Then we want roughly:
    // markerSize * markerSize * # of cells = 600 x 600 x 50%
    //
    // Solving for markerSize gives this the equation below.
    // And values like the following:
    // 10k points --> 4px
    // 25k points --> 3px
    // 50k points --> 2px
    // 100k points --> 2px (2px is the MIN_MARKER_SIZE)
    let markerSize = Math.round(Math.sqrt((600 * 600 * 0.5) / dataLength))
    markerSize = Math.min(Math.max(markerSize, MIN_MARKER_SIZE), MAX_MARKER_SIZE)

    return markerSize
}

export default getMarkerSize
