import { Stack } from '@mui/system'
import useFlagCondition from 'components/shared/useFlagCondition'
import { useState } from 'react'
import CompareGroups from '../Compare/CompareGroups'
import SelectableButton from '../header/SelectableButton'
import { EmbeddingDensityPanel } from './EmbeddingDensityPanel'

export const ComparePanelContent = (): JSX.Element => {
  const densityComparisonEnabled = useFlagCondition('densityComparisonEnabled')
  const [showFeatureDistribution, setShowFeatureDistribution] = useState(true)

  return (
    <Stack spacing={1}>
      {densityComparisonEnabled ? (
        <Stack
          direction="row"
          spacing={2}
          mb=".3rem"
          sx={{
            border: (t) => `1px solid ${t.palette.primary.main}`,
            borderRadius: '4px',
            padding: '4px',
            width: 'max-content',
            alignSelf: 'center',
          }}
        >
          <SelectableButton
            selected={showFeatureDistribution}
            unselectedVariant="text"
            onClick={() => setShowFeatureDistribution(true)}
            sx={{ padding: '10px 25px' }}
          >
            Feature Distribution
          </SelectableButton>
          <SelectableButton
            selected={!showFeatureDistribution}
            unselectedVariant="text"
            onClick={() => setShowFeatureDistribution(false)}
            sx={{ padding: '10px 25px' }}
          >
            Embedding Density
          </SelectableButton>
        </Stack>
      ) : null}
      <div style={{ display: showFeatureDistribution ? undefined : 'none' }}>
        <CompareGroups />
      </div>
      <div style={{ display: showFeatureDistribution ? 'none' : undefined }}>
        <EmbeddingDensityPanel />
      </div>
    </Stack>
  )
}

export default ComparePanelContent
