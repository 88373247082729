import { createSvgIcon } from '@mui/material'

const AccordianDownIcon = createSvgIcon(
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.5 5.25C9.58008 6.52213 8.50329 7.6629 7.29757 8.64335C7.12269 8.78555 6.87731 8.78555 6.70243 8.64335C5.49671 7.6629 4.41992 6.52213 3.5 5.25"
      stroke="#5F55D1"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  'AccordianDown'
)

export default AccordianDownIcon
