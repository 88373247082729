import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import { Box, IconButton, Stack, Typography } from '@mui/material'
import _ from 'lodash'
import { useRef } from 'react'
import { useWindowSize } from 'react-use'
import { CellInfoGroups } from 'redux/slices'
import { useCellVisualizationsSlice } from 'redux/slices/hooks/useCellVisualizationsSlice'
import useFooterHeight from 'utils/useFooterHeight'
import LegendItem from './LegendItem'

interface FiltersAppliedDetailsProps {
  onBackClick(): void
}
export const FiltersAppliedDetails = ({ onBackClick }: FiltersAppliedDetailsProps): JSX.Element => {
  const leftButtonRef = useRef<HTMLDivElement>(null)
  const {
    cellVisualizations: { cellInfoGroups },
    hiddenCellInfoGroupValues,
    setCellInfoGroupData,
  } = useCellVisualizationsSlice()

  const footerHeight = useFooterHeight()
  const { height: windowHeight } = useWindowSize()
  const innerHeight =
    windowHeight - (leftButtonRef.current?.getBoundingClientRect().bottom ?? 0) - footerHeight - 50

  const hiddenCellInfoGroupNames = _.keys(
    hiddenCellInfoGroupValues
  ) as (keyof typeof hiddenCellInfoGroupValues)[]

  const onHide = (
    cellInfoGroupName: keyof CellInfoGroups,
    itemValue: string,
    wasHidden: boolean
  ) => {
    setCellInfoGroupData(
      cellInfoGroupName,
      cellInfoGroups[cellInfoGroupName]?.data.map((x) =>
        x.value === itemValue ? { ...x, isHidden: !wasHidden } : x
      ) ?? []
    )
  }

  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={1} mb={2} alignItems="center" ref={leftButtonRef}>
        <IconButton onClick={onBackClick}>
          <KeyboardArrowLeftIcon fontSize="large" />
        </IconButton>
        <h3>Filters Applied</h3>
      </Stack>
      <Box sx={{ height: innerHeight, overflow: 'auto', p: 1 }}>
        {hiddenCellInfoGroupNames.flatMap((key) => {
          if (!key) return []
          const group = cellInfoGroups[key]
          if (!group) return []
          const { data } = group

          return (
            <Stack key={group.label} spacing={2} mb={2}>
              <Typography>{group.label}</Typography>
              {data.map((item) => (
                <LegendItem
                  key={item.value}
                  bgColor={item.color}
                  label={item.value}
                  // TODO: probably want to get this picker working here too
                  // onClick={pickerEnabled ? handleOnClick : undefined}
                  dataLabel={item.value}
                  isHidden={item.isHidden}
                  onHide={(wh) => onHide(key, item.value, wh)}
                />
              ))}
            </Stack>
          )
        })}
      </Box>
    </Stack>
  )
}

export default FiltersAppliedDetails
