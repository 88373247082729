import UploadIcon from '@mui/icons-material/Upload'
import { DeepcellPrimaryButton } from 'components/shared'
import { ChangeEvent, useRef } from 'react'
import useEventsManager from 'redux/slices/hooks/useEventsManager'
import useNotificationSlice from 'redux/slices/hooks/useNotificationSlice'
import useSessionApi from '../useSessionApi'

const validExtensions = ['tsv', 'zip']

export const UploadCellDataTsvButton = (): JSX.Element => {
  const { showError } = useNotificationSlice()
  const eventsManager = useEventsManager()

  const { uploadNewBlobToCloud } = useSessionApi()

  const onUpload = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return
    const file = e.target.files[0]

    const extension = file.name.split('.').slice(-1)[0]

    if (validExtensions.includes(extension)) {
      uploadNewBlobToCloud({ dataFromBlob: file, fileName: file.name })
    } else {
      eventsManager.sendDataUploadEvent(file, false)
      showError(
        `You've uploaded a .${extension} file, but the supported extensions are .tsv and .zip`
      )
    }
  }

  const uploadInputRef = useRef<HTMLInputElement>(null)

  return (
    <DeepcellPrimaryButton
      sx={{ width: 230 }}
      startIcon={<UploadIcon />}
      contained
      onClick={() => uploadInputRef.current && uploadInputRef.current.click()}
    >
      Upload Cell Data TSV
      <input ref={uploadInputRef} hidden type="file" onChange={onUpload} />
    </DeepcellPrimaryButton>
  )
}

export default UploadCellDataTsvButton
